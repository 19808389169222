import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Clock from "../Clock/Clock";
import { Link } from "react-router-dom";
import SimReportTable from "./SimReportTable";
import AuthUser from "../../Hooks/authUser";
import Loading from "../../Shared/Loading/Loading";
import moment from "moment";
const SimReport = () => {
  const { userInfo } = AuthUser();
  const [loading, setLoading] = useState(false);
  const [sims, setSims] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  // for filter
  const [simSearch, setSimSearch] = useState("");
  const [searchBy, setSearchBy] = useState("buy");
  const [status, setStatus] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    const url = `https://servergenzam.genzamwallet.com/api/v1/user/specific?fieldName=${"referResellerId"}&&fieldValue=${
      userInfo?._id
    }`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setUser(data?.data);
      });
  }, [userInfo?._id]);
  console.log(user);

  const url = `https://servergenzam.genzamwallet.com/api/v1/simSale/specific/?fieldName=${"resellerEmail"}&&fieldValue=${
    selectedUser ? selectedUser : userInfo?.email
  }`;

  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setSims(data?.data);
        setLoading(false);
      });
  }, [url]);
  if (loading) {
    return <Loading />;
  }
  console.log(sims);
  const filterSimData = sims?.filter((f) => {
    const startDate = moment(start).format("l");
    const endDate = moment(end).format("l");
    const date =
      searchBy === "buy"
        ? moment(f?.saleData?.buyDate).format("l")
        : moment(f?.updatedAt).format("l");

    return (
      (f?.iccidNumber && f?.iccidNumber == simSearch) ||
      (f?.saleData?.simNumber && f?.saleData?.simNumber == simSearch) ||
      (f?.saleData?.operatorName && f?.saleData?.operatorName == simSearch) ||
      f?.status === status ||
      (date >= startDate && date <= endDate)
    );
  });

  console.log(selectedUser);
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            {userInfo?.role} DASHBOARD - SIM REPORTS
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>

      <div className=" bg-thirdLightPrimary m-5 p-5 rounded-md">
        <div className="  bg-lightPrimary rounded-md ">
          <div className=" flex gap-5 p-5 text-xs">
            <div className=" w-full">
              <p className=" pb-2">User</p>
              <select
                type="text"
                id="first_name"
                className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => setSelectedUser(e.target.value)}
                placeholder="Select"
                required
              >
                {" "}
                <option selected value={userInfo?.email}>
                  {userInfo?.email}
                </option>
                {user?.map((u) => (
                  <option value={u?.email}>{u?.email}</option>
                ))}
              </select>
            </div>
            <div>
              <p className=" pb-2">From Date</p>

              <DatePicker
                className="bg-gray-50 border border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                selected={start}
                onChange={(date) => setStart(date)}
              />
            </div>
            <div>
              <p className=" pb-2">To Date</p>
              <DatePicker
                className="bg-gray-50 border border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                selected={end}
                onChange={(date) => setEnd(date)}
              />
            </div>
          </div>
          <div className=" grid grid-cols-3 gap-3 p-5 text-xs">
            <div>
              <p className=" pb-2">Keyword</p>
              <input
                className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                placeholder=" ICCID Number, SIM Number , Operator Name etc"
                onChange={(e) => setSimSearch(e.target.value)}
              />
            </div>

            <div>
              <p className=" pb-2">Search By</p>
              <select
                type="text"
                id="first_name"
                className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                onChange={(e) => setSearchBy(e.target?.value)}
                required
              >
                {" "}
                <option selected value="buy">
                  Buying Date
                </option>
                <option value="sale">Sale Dtae</option>
              </select>
            </div>

            <div>
              <p className=" pb-2">Status</p>

              <select
                type="text"
                id="first_name"
                className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                onChange={(e) => setStatus(e?.target?.value)}
              >
                {" "}
                <option selected value="">
                  None{" "}
                </option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* sim report table */}

      <div className=" bg-thirdLightPrimary p-5 m-5 rounded-md">
        <div className="overflow-x-auto m-5  bg-lightPrimary p-5">
          <table className="min-w-full text-xs bg-gray-100">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col className="w-24" />
            </colgroup>
            <thead className=" text-white bg-black">
              <tr className="text-center">
                <th className="p-3">#</th>
                <th className="p-3">ID</th>
                <th className="p-3 whitespace-nowrap">FIRST NAME</th>
                <th className="p-3">Operator</th>
                <th className="p-3">OFFER</th>
                <th className="p-3 whitespace-nowrap">ICCID NUMBER</th>
                <th className="p-3 whitespace-nowrap">BUY DATE</th>
                <th className="p-3 whitespace-nowrap">SALE DATE</th>
              </tr>
            </thead>
            <tbody>
              {filterSimData?.map((sim, i) => (
                <SimReportTable sim={sim} i={i} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SimReport;
