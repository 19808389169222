import { Icon } from "@iconify/react";
import React, { useState } from "react";
import PostHooks from "../../../Hooks/PostHooks";

const PostLuceOperator = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const operatorName = e.target?.operatorName.value;
    const operatorDetails = e.target?.operatorDetails.value;

    const operator = { operatorName, operatorDetails };
    // console.log(operator);
    await PostHooks(
      "https://servergenzam.genzamwallet.com/api/v1/luceOperator",
      operator,
      "operator successfully submitted"
    );
  };

  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-2xl flex justify-center  gap-x-5 items-center font-semibold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2 w-fit mx-auto">
            <Icon icon="flat-color-icons:sim-card"></Icon>
            POST LUCE OPERATOR
          </h1>
        </div>
      </div>

      {/* post table  */}
      <div className="bg-white border border-4 rounded-lg shadow relative m-10">
        <div className="flex items-start justify-between p-5 border-b rounded-t">
          <h3 className="text-xl font-semibold">POST LUCE OPERATOR</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="product-modal"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="p-6 space-y-6">
          {/* form start */}
          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="">
                <label
                  htmlFor="product-name"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Operator Name
                </label>
                <input
                  type="text"
                  name="operatorName"
                  required
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Operator Name ”"
                />
              </div>

              <div className="w-full mt-4">
                <div className="">
                  <label className="text-sm font-medium text-gray-900 block mb-2">
                    Offer Details
                  </label>
                  <textarea
                    className="rounded-md focus:outline-none w-full"
                    type="textarea"
                    name="operatorDetails"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="mt-5 rounded-b">
              <button
                className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                type="submit"
              >
                Post
              </button>
            </div>
          </form>

          {/* close form */}
        </div>
      </div>
    </div>
  );
};

export default PostLuceOperator;
