import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const EditWifiOperator = () => {
  const { id } = useParams();
  const [wifiOperator, setWifiOperator] = useState(null);
  const [loading, setLoading] = useState(true);

  const [inputFields, setInputFields] = useState([]);

  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/wifiOperator/${id}`
        );

        // Populate the form with the fetched data
        setWifiOperator(response.data);
        setInputFields(response?.data?.packages);
        setLoading(false);
      } catch (error) {
        alert(
          "An error occurred while fetching offer data. Please try again later."
        );
        setLoading(false);
      }
    };

    fetchOfferData();
  }, [id]);

  console.log(wifiOperator);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("hs");
    const packages = inputFields;
    const operator = { operatorName: wifiOperator?.operatorName, packages };
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/wifiOperator/${id}`;
    UpdateHooks(BASE_URL, operator, true, "wifi operator Updated");
  };

  const handleInputChange = (index, event, name) => {
    const values = [...inputFields];
    if (name === "offerName") {
      values[index].offerName = event.target.value;
    }
    if (name === "perFavore") {
      values[index].perFavore = event.target.value;
    }
    if (name === "verificaLa") {
      values[index].verificaLa = event.target.value;
    }
    if (name === "offerDetails") {
      values[index].offerDetails = event.target.value;
    }

    setInputFields(values);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!wifiOperator) {
    return <div>WiFi operator data not found.</div>;
  }

  return (
    <div className="p-6 space-y-6">
      <form onSubmit={handleSubmit}>
        {wifiOperator?.packages?.map((p, index) => (
          <div p={p} className="border p-2 rounded-md mt-5">
            <div className="flex items-center gap-x-1">
              <div className="w-full border rounded-lg shadow p-2">
                <label className="font-medium text-dark">
                  Package Information
                  <span className="text-warning">*</span>
                </label>
                <div className="grid md:grid-cols-2 grid-cols-2 gap-2 bg-primary/10 mt-2 p-2 rounded-md text-sm w-full">
                  <div className="">
                    <label className="text-sm font-medium text-gray-900 block mb-2">
                      Offer Name
                    </label>
                    <input
                      type="text"
                      name="offerName"
                      onChange={(event) =>
                        handleInputChange(index, event, "offerName")
                      }
                      defaultValue={p?.offerName}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                      placeholder="€"
                      required
                    />
                  </div>
                  <div className="">
                    <label className="text-sm font-medium text-gray-900 block mb-2">
                      Offer Details
                    </label>
                    <input
                      type="text"
                      onChange={(event) =>
                        handleInputChange(index, event, "offerDetails")
                      }
                      defaultValue={p?.offerDetails}
                      name="offerDetails"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                      placeholder="type offer details"
                      required
                    />
                  </div>
                  <div className="">
                    <label className="text-sm font-medium text-gray-900 block mb-2">
                      Verifica la copertura della fibra Sky Wi-fi
                    </label>
                    <input
                      type="text"
                      onChange={(event) =>
                        handleInputChange(index, event, "verificaLa")
                      }
                      defaultValue={p?.verificaLa}
                      name="verificaLa"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                      placeholder=" Verifica la copertura della fibra Sky Wi-fi"
                      required
                    />
                  </div>
                  <div className="">
                    <label className="text-sm font-medium text-gray-900 block mb-2">
                      Per favore È necessario stampare il modulo e riempirlo
                    </label>
                    <input
                      type="text"
                      onChange={(event) =>
                        handleInputChange(index, event, "perFavore")
                      }
                      defaultValue={p?.perFavore}
                      name="perFavore"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                      placeholder=" per favore e necessario"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* close form */}
          </div>
        ))}

        <div className="mt-5 rounded-b">
          <button
            className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            type="submit"
          >
            Update !
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditWifiOperator;
