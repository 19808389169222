import { Icon } from "@iconify/react";
import React from "react";

const WhyChoseUs = () => {
  return (
    <div>
      <section
        id="features"
        class="container mx-auto px-4 space-y-6 bg-slate-50 py-8 dark:bg-transparent md:py-12 lg:py-20"
      >
        <div class="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
          <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Features
          </h2>

          <p class="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            The product can personalize user experiences by understanding
            individual preferences and tailoring recommendations or content
            based on user behavior and historical data.
          </p>
        </div>

        <div class="mx-auto grid justify-center gap-4 sm:grid-cols-2 md:max-w-[64rem] md:grid-cols-3">
          <div class="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <Icon
                className="h-12 w-12 fill-current"
                icon="cib:digital-ocean"
              ></Icon>
              <div class="space-y-2">
                <h3 class="font-bold">Digital Product Trading</h3>
                <p class="text-sm text-muted-foreground">
                  Join our platform for digital goods trade.
                </p>
              </div>
            </div>
          </div>

          <div class="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <div class="space-y-2">
                <Icon
                  className="h-12 w-12 fill-current"
                  icon="grommet-icons:technology"
                ></Icon>
                <h3 class="font-bold">Expand with Genzam-IT</h3>
                <p class="text-sm">
                  Grow your business with our digital products.
                </p>
              </div>
            </div>
          </div>

          <div class="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <Icon
                className="h-12 w-12 fill-current"
                icon="mdi:marketplace-outline"
              ></Icon>
              <div class="space-y-2">
                <h3 class="font-bold">Digital Product Marketplace</h3>
                <p class="text-sm text-muted-foreground">
                  Trade diverse digital goods effortlessly.
                </p>
              </div>
            </div>
          </div>

          <div class="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <Icon
                className="h-12 w-12 fill-current"
                icon="medical-icon:i-social-services"
              ></Icon>
              <div class="space-y-2">
                <h3 class="font-bold">"Genzam-IT Services</h3>
                <p class="text-sm text-muted-foreground">
                  Explore our wide range of digital products.
                </p>
              </div>
            </div>
          </div>

          <div class="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <Icon
                className="h-12 w-12 fill-current"
                icon="arcticons:revolution"
              ></Icon>
              <div class="space-y-2">
                <h3 class="font-bold">Join the Digital Revolution</h3>
                <p class="text-sm text-muted-foreground">
                  Discover new opportunities with Genzam-IT.
                </p>
              </div>
            </div>
          </div>

          <div class="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2">
            <div class="flex h-[180px] flex-col justify-between rounded-md p-6">
              <Icon
                className="h-12 w-12 fill-current"
                icon="ic:outline-hub"
              ></Icon>
              <div class="space-y-2">
                <h3 class="font-bold">Your Digital Hub</h3>
                <p class="text-sm text-muted-foreground">
                  Connect with a thriving digital ecosystem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChoseUs;
