import { Icon } from "@iconify/react";
import React from "react";

const OrderView = () => {
  return (
    <div className="  min-h-screen m-5">
      <div>
        <div className=" bg-green-500 max-w-xs mx-auto m-5 rounded-md">
          <h1 className=" text-xl text-white text-center">Completed</h1>
        </div>
      </div>

      <div className=" ">
        {/* //second div */}
        <div class="min-w-full">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Operator Details:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Operator</td>
                  <td class="px-6 py-4 text-xs">SKY WI-FI</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Offer Name </td>
                  <td class="px-6 py-4 text-xs">
                    SKYGLASS 43" 11.90 55" 17.90 65" 24.90 Clienti con anticipo
                    Non clienti senza anticipo
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Offer Details </td>
                  <td class="px-6 py-4 text-xs ">
                    PRIMA DEVI VERIFICARE COPERTURA
                    https://www.sky.it/sky-wifi-fibra/verifica-copertura SKY
                    WIFI BOLLETINO NON SEI SOLO CONTO CORRENTE ANCHE CARTA DI
                    CREDITO SKYGLASS 43" 11.90 55" 17.90 65" 24.90 Clienti con
                    anticipo Non clienti senza anticipo SEMPRE 29.90 EURO UNO
                    VOLTA ATTIVAZIONE 29.00 EURO CONTRATTO 18 MESE PER SENZA
                    VINCOLA
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* // second div */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Introduction:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Name</td>
                  <td class="px-6 py-4 text-xs">md</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Surname</td>
                  <td class="px-6 py-4 text-xs">ali</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Date of birth </td>
                  <td class="px-6 py-4 text-xs">02.01.1975</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Tax ID code</td>
                  <td class="px-6 py-4 text-xs ">laimdx75a02z249s</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Mobile</td>
                  <td class="px-6 py-4 text-xs ">3473150875</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">E-mail </td>
                  <td class="px-6 py-4 text-xs ">alimd12121988@gmail.com</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Address </td>
                  <td class="px-6 py-4 text-xs ">
                    via salita casale N. 2 palma campania napoli 80036
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Via Name </td>
                  <td class="px-6 py-4 text-xs ">via salita casale</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Civico </td>
                  <td class="px-6 py-4 text-xs ">2</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm"> CAP </td>
                  <td class="px-6 py-4 text-xs ">80036</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Citta </td>
                  <td class="px-6 py-4 text-xs ">palma campania</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Province </td>
                  <td class="px-6 py-4 text-xs ">Province</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* third div */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Agent Info:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Name</td>
                  <td class="px-6 py-4 text-xs">OSTHIR STORE</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Phone</td>
                  <td class="px-6 py-4 text-xs">+393894505444/+393313364836</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Address </td>
                  <td class="px-6 py-4 text-xs">
                    Piazza Antonio de Martino, 64 CAP - 80036, Palma Campania
                    (NA)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* 
        four div  */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Order Details:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Order Code</td>
                  <td class="px-6 py-4 text-xs">100034</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Date</td>
                  <td class="px-6 py-4 text-xs">07.09.2022</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Type </td>
                  <td class="px-6 py-4 text-xs">wifi</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* five div  */}

        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Documents:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">C. Identita </td>
                  <td class="px-6 py-4 text-2xl text-primary">
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">SOGGOIRNO</td>
                  <td class="px-6 py-4 text-2xl text-primary">
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">CODICE FISCALE </td>
                  <td class="px-6 py-4 text-2xl text-primary">
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">PATENTE</td>
                  <td class="px-6 py-4 text-2xl text-primary">
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Contratto di Modullo WIFI </td>
                  <td class="px-6 py-4 text-2xl text-primary">
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Carica modulo di Sky </td>
                  <td class="px-6 py-4 text-2xl text-primary">
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* six div */}

        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Pagamento:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Type</td>
                  <td class="px-6 py-4 text-xs">Conto Corrente</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">IBAN</td>
                  <td class="px-6 py-4 text-xs">IT72I3608105138255930355937</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">IBAN Document </td>
                  <td class="px-6 py-4 text-2xl text-primary">
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderView;
