import { Icon } from "@iconify/react";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import UpdateHooks from "../../Hooks/UpdateHooks";

const AdminViewAllSim = () => {
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [sim, setSim] = useState([]);

  useEffect(() => {
    fetchUserData();
  }, [refresh]);
  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/user")
      .then((res) => res.json())
      .then((data) => setUsers(data?.data));
  }, []);

  const fetchUserData = () => {
    axios
      .get(`https://servergenzam.genzamwallet.com/api/v1/allsim/getSimById/${id}`)
      .then((response) => {
        setSim(response.data);
      })
      .catch((error) => {
        console.error("Error loading user data:", error);
      });
  };
  const handleCopyId = (id) => {
    // Copy the SIM Tracking ID to the clipboard
    navigator.clipboard
      .writeText(id)
      .then(() => {
        alert("SIM Tracking ID copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };
  console.log(sim);
  const handelUpdate = async (e) => {
    e.preventDefault();
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/allsim/${id}`;

    await UpdateHooks(
      BASE_URL,
      {
        resellerEmail: userEmail ? userEmail : sim?.resellerEmail,
      },
      true,
      "Sim User Updated"
    );

    setRefresh(!refresh);
  };

  return (
    <div>
      <div class="bg-white m-5 overflow-hidden shadow rounded-lg border">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {sim.operatorName}
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            SIM Tracking ID- {sim?._id}
          </p>
          <button
            className="mt-1 max-w-2xl text-sm text-gray-500  underline  "
            onClick={() => handleCopyId(sim._id)}
          >
            Copy Tracking ID
          </button>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500"> Operator Name</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sim?.operatorName}
              </dd>
            </div>

            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Buy Price</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sim?.buyPrice}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">User</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sim.resellerEmail}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                User Email address
              </dt>
              <select
                type="text"
                name="useEmail"
                onChange={(e) => setUserEmail(e.target.value)}
                className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                required
              >
                {" "}
                {users?.map((user) => (
                  <option
                    selected={sim?.resellerEmail === user?.email}
                    value={user?.email}
                    key={user?._id}
                  >
                    {user?.email}
                  </option>
                ))}
              </select>
            </div>

            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">ICCID number</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sim.iccidNumber}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">SIM number</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sim.simNumber}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Issue Date</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sim.buyDate}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Status</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sim.status}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Other Information
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {sim.othersInformation}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <button
        onClick={handelUpdate}
        className="bg-primary py-2 px-4 rounded-md mx-5 text-white hover:scale-105 duration-500"
      >
        UPDATE !
      </button>
    </div>
  );
};

export default AdminViewAllSim;
