import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Clock from "../Clock/Clock";
import GreetingMessage from "../GreetingMessage/GreetingMessage";
import AuthUser from "../../Hooks/authUser";
import Notification from "../Notification/Notification";
import Loading from "../../Shared/Loading/Loading";
import { Icon } from "@iconify/react";
import ScrollingText from "../GreetingMessage/ScrollingText";

const ResellerHome = () => {
  const { userInfo } = AuthUser();
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false); // Add this line

  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);

  //operator
  const [operatorOffers, setOperatorOffers] = useState([]);
  const [latestOffers, setLatestOffers] = useState([]);
  const [upcomingOffers, setUpcomingOffers] = useState([]);

  //Instock

  /* The above code is written in JavaScript and it is performing the following tasks: */
  const userEmail = userInfo.email;
  const [sims, setSims] = useState([]);
  const [operatorData, setOperatorData] = useState([]);

  useEffect(() => {
    // Fetch data when component mounts
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/allsim")
      .then((response) => {
        setSims(response.data.sims);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Once the data is fetched, analyze the operators for the specific user
  useEffect(() => {
    if (sims.length) {
      // Filter sims for the specific user
      const userSims = sims.filter((sim) => {
        return sim.resellerEmail === userEmail && sim.status === "available";
      });

      const operatorCount = {};

      userSims.forEach((sim) => {
        if (operatorCount[sim.operatorName]) {
          operatorCount[sim.operatorName]++;
        } else {
          operatorCount[sim.operatorName] = 1;
        }
      });

      // Transform operatorCount into an array
      const transformedData = Object.entries(operatorCount).map(
        ([operator, count]) => ({ operator, count })
      );
      setOperatorData(transformedData);
    }
  }, [sims, userEmail]);

  const value = operatorData?.map((op) => op?.count);
  const totalOperator = value?.reduce((acc, cur) => acc + cur, 0);
  console.log(totalOperator);
  // Instock End

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/bills/specific/bill?fieldName=${"customerName"}&&fieldValue=${
        userInfo?.email
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        setBills(data?.data);
        setLoading(false);
      });
  }, [userInfo?.email]);
  // Fetch all operator offers and filter based on posttype
  useEffect(() => {
    const fetchOperatorOffers = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/operatorOffer?size=${size}&&page=${page}`
        );
        setOperatorOffers(response?.data?.operatorOffers);
        setQuantity(response?.data?.total);

        // Filter offers based on posttype
        const latestOffersData = response.data?.operatorOffers?.filter(
          (offer) => offer.posttype === "latestOffer"
        );
        setLatestOffers(latestOffersData);

        const upcomingOffersData = response.data?.operatorOffers?.filter(
          (offer) => offer.posttype === "upCommingOffer"
        );
        setUpcomingOffers(upcomingOffersData);
      } catch (error) {
        console.error("Error fetching operator offers:", error);
      }
    };

    fetchOperatorOffers();
  }, []);

  const [breakingNews, setBreakingNews] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationText, setNotificationText] = useState("");

  useEffect(() => {
    // Fetch breaking news from the backend API
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/notification") // Replace with your backend API URL
      .then((response) => {
        setBreakingNews(response.data);
      })
      .catch((error) => {
        console.error("Error fetching breaking news:", error);
      });
  }, []);

  useEffect(() => {
    // Check if breaking news is available and show the notification
    if (breakingNews.length > 0) {
      setNotificationText(breakingNews[0].title); // Show the first breaking news
      setShowNotification(true);

      // Hide the notification after a few seconds (e.g., 5 seconds)
      const timeout = setTimeout(() => {
        setShowNotification(false);
      }, 20000);

      // Clean up the timeout to avoid memory leaks
      return () => clearTimeout(timeout);
    }
  }, [breakingNews]);

  if (loading) {
    return <Loading />;
  }

  const values = bills?.map((bill) => bill?.debit);
  const totalDue = values?.reduce((acc, cur) => acc + cur, 0);

  const handleCopyClick = () => {
    const textField = document.createElement("textarea");
    textField.innerText = userInfo._id;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    setIsCopied(true);

    // Reset the "Copied" message after a brief delay
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex  justify-between">
        <div>
          <p className="    font-semibold p-2 ">RESELLER DASHBOARD</p>
        </div>

        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>

      <div className="flex justify-center mt-2 text-white items-center ">
        <ScrollingText></ScrollingText>
      </div>

      <div className=" m-5  p-5 bg-thirdLightPrimary rounded  ">
        <div className="  bg-lightPrimary p-5 rounded-md">
          <div className=" flex justify-between ">
            <div className=" w-full">
              <h1 className="">
                <span className="bg-yellow-600 text-white">
                  {" "}
                  <GreetingMessage></GreetingMessage>
                </span>
              </h1>
            </div>
            {/* <div>
            <button
              type="button"
              class="bg-blue-700 py-2 px-3 rounded-md text-white"
            >
              Notifications
              <span class="bg-teal-500 font-bold text-white text-center py-1 px-2 text-xs rounded">
                4
              </span>
            </button>
          </div> */}
          </div>
          <div className=" ">
            <div className=" ">
              <div className=" p-2">
                <h1 className=" flex justify-start  items-center gap-2">
                  {" "}
                  <Icon icon="carbon:user-profile"></Icon> {userInfo.name}
                </h1>
              </div>

              <div>
                <div className=" p-2 text-red-600">
                  <h1 className=" flex justify-start  items-center gap-2">
                    {" "}
                    <Icon className=" " icon="mdi:money">
                      {" "}
                    </Icon>{" "}
                    Due Balance: € {totalDue}
                  </h1>
                </div>
              </div>
            </div>
            <div className=" p-2">
              <h1 className=" flex justify-start  items-center gap-2">
                {" "}
                <Icon icon="eos-icons:role-binding-outlined"></Icon> Genzam-IT
                Dynamic {userInfo.role} Account
              </h1>
            </div>
            <div className="p-2">
              <h1
                className="flex justify-start items-center gap-2 cursor-pointer"
                onClick={handleCopyClick}
              >
                <Icon icon="material-symbols:store" />
                Store ID: {userInfo._id}{" "}
                <span className="text-green-500">Copy</span>
              </h1>
              {isCopied && <p className="text-green-500">Copied!</p>}
            </div>
          </div>
        </div>
      </div>
      <Notification text={notificationText} isVisible={showNotification} />
      <div className="  bg-thirdLightPrimary m-5 p-5 rounded-md ">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-lightPrimary rounded-md text-xs ">
          <div className=" flex justify-between ">
            <Link to="/resellerDashboard/offers">
              <div className=" ">
                <h2 className="text-sm font-bold mb-4 underline">
                  Latest Offer
                </h2>
              </div>
            </Link>
            <Link to="/resellerDashboard/offers">
              <div className=" ">
                <h2 className="text-sm font-bold mb-4 underline">
                  {" "}
                  All Offers
                </h2>
              </div>
            </Link>
          </div>

          <table className="table-auto w-full  bg-gray-400 rounded-md">
            <thead className=" bg-black text-white">
              <tr>
                <th className=" border px-4 py-2">#</th>
                <th className=" border px-4 py-2">Operator</th>
                <th className=" border px-4 py-2">Offer Name </th>
                <th className=" border px-4 py-2">Offer Valid For</th>
              </tr>
            </thead>
            <tbody>
              {latestOffers?.slice(0, 19)?.map((offer, index) => (
                <tr
                  key={offer._id}
                  className="border px-2 py-1 text-center bg-white"
                >
                  <td className="border px-2 py-1 text-center">{index + 1}</td>
                  <td className="border px-2 py-1 text-center">
                    {offer.operator}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {offer.offerName}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {offer.offerValidFor}
                  </td>
                </tr>
              ))}

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>

        {/* 
        Second div start */}
        <div className=" pt-5">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8  bg-lightPrimary rounded-md ">
            <div className=" ">
              <Link to="/resellerDashboard/offers">
                <div className=" ">
                  <h2 className="text-sm font-bold mb-4 underline">
                    Upcomming Offer
                  </h2>
                </div>
              </Link>
            </div>

            <table className="table-auto w-full text-xs    ">
              <thead className=" bg-black text-white">
                <tr>
                  <th className=" border px-4 py-2">#</th>
                  <th className=" border px-4 py-2">Offer Name </th>
                  <th className=" border px-4 py-2"> Offer Details</th>{" "}
                </tr>
              </thead>
              <tbody>
                {upcomingOffers?.slice(0, 19)?.map((offer, index) => (
                  <tr
                    key={offer._id}
                    className="border px-2 py-1 bg-white text-center"
                  >
                    <td className="border px-2 py-1 text-center">
                      {index + 1}
                    </td>

                    <td className="border px-2 py-1 text-center">
                      {offer.operator}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {offer.offerName}
                    </td>
                  </tr>
                ))}

                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>

        {/* third div */}

        <div className=" pt-5">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-lightPrimary rounded-md ">
            <div className=" ">
              <Link to="#">
                <div className=" ">
                  <h2 className="text-sm font-bold mb-4 underline">
                    CURRENT STOCK STATUS
                  </h2>
                </div>
              </Link>
            </div>

            <table className="table-auto w-full text-xs    ">
              <thead className=" bg-black text-white">
                <tr>
                  <th className=" border px-4 py-2"># </th>
                  <th className=" border px-4 py-2">OPERATOR NAME </th>
                  <th className=" border px-4 py-2">IN STOCK</th>{" "}
                </tr>
              </thead>
              <tbody>
                {operatorData?.map((operator, i) => (
                  <tr
                    operator={operator}
                    className="border px-2 py-1 text-center bg-white"
                  >
                    <td className="border px-4 py-2 text-center">{i + 1}</td>
                    <td className="border px-2 py-1 text-center">
                      {" "}
                      {operator?.operator}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {operator?.count}
                    </td>
                  </tr>
                ))}
                <tr className="border px-2 py-1 text-center bg-white">
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-2 py-1 text-center">Total =</td>
                  <td className="border px-4 py-2 text-center">
                    {totalOperator}
                  </td>
                </tr>

                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResellerHome;
