import React from "react";

const Faq = () => {
  return (
    <div>
      <div class="relative w-full bg-white px-6 pt-10 pb-8 mt-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-2xl sm:rounded-lg sm:px-10">
        <div class="mx-auto px-5">
          <div class="flex flex-col items-center">
            <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              FAQ
            </h2>
            <p class="mt-3 text-lg text-neutral-500 md:text-xl">
              Frequenty asked questions
            </p>
          </div>
          <div class="mx-auto mt-8 grid max-w-xl divide-y divide-neutral-200">
            <div class="py-5">
              <details class="group">
                <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                  <span> What is Genzam-IT?</span>
                  <span class="transition group-open:rotate-180">
                    <svg
                      fill="none"
                      height="24"
                      shape-rendering="geometricPrecision"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <p class="group-open:animate-fadeIn mt-3 text-neutral-600">
                  Genzam-IT is a digital product transfer platform that
                  facilitates the trading of various digital goods. It offers a
                  business opportunity for individuals or entities to become
                  multi-service providers by offering a wide range of digital
                  products.
                </p>
              </details>
            </div>
            <div class="py-5">
              <details class="group">
                <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                  <span> How does Genzam-IT work?</span>
                  <span class="transition group-open:rotate-180">
                    <svg
                      fill="none"
                      height="24"
                      shape-rendering="geometricPrecision"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <p class="group-open:animate-fadeIn mt-3 text-neutral-600">
                  Genzam-IT works as a marketplace where users can buy and sell
                  digital goods. As a multi-service provider, you can
                  participate in this platform by offering your own digital
                  products for sale. Customers can browse through the available
                  digital goods and make purchases through the platform.
                </p>
              </details>
            </div>
            <div class="py-5">
              <details class="group">
                <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                  <span>
                    {" "}
                    What types of digital products can be traded on Genzam-IT?
                  </span>
                  <span class="transition group-open:rotate-180">
                    <svg
                      fill="none"
                      height="24"
                      shape-rendering="geometricPrecision"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <p class="group-open:animate-fadeIn mt-3 text-neutral-600">
                  Genzam-IT allows the trading of various digital goods, which
                  may include but are not limited to software licenses, digital
                  art, e-books, music, video content, and other digital
                  downloads.
                </p>
              </details>
            </div>
            <div class="py-5">
              <details class="group">
                <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                  <span>
                    {" "}
                    How can I become a multi-service provider on Genzam-IT?
                  </span>
                  <span class="transition group-open:rotate-180">
                    <svg
                      fill="none"
                      height="24"
                      shape-rendering="geometricPrecision"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <p class="group-open:animate-fadeIn mt-3 text-neutral-600">
                  To become a multi-service provider on Genzam-IT, you need to
                  sign up and create an account. Once registered, you can list
                  your digital products for sale on the platform. The specific
                  process for becoming a provider may vary, so it's best to
                  refer to the platform's official documentation or guidelines.
                </p>
              </details>
            </div>
            <div class="py-5">
              <details class="group">
                <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                  <span> What is the SIM activation login for?</span>
                  <span class="transition group-open:rotate-180">
                    <svg
                      fill="none"
                      height="24"
                      shape-rendering="geometricPrecision"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </summary>
                <p class="group-open:animate-fadeIn mt-3 text-neutral-600">
                  The SIM activation login is likely a feature provided by
                  Genzam-IT for certain digital products or services that
                  require activation through a SIM card. It may be used to
                  verify the user's identity or enable access to specific
                  services. However, without further context, it's challenging
                  to provide a precise answer. If you need assistance with the
                  SIM activation login, it's recommended to contact Genzam-IT's
                  customer support for more information.
                </p>
              </details>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
