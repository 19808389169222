import React from "react";

const Company = () => {
  return (
    <div className=" mt-10">
      <div class="mx-auto flex max-w-[58rem] mb-5 flex-col items-center space-y-4 text-center">
        <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Trusted by thousands of organizations
        </h2>
      </div>

      <section class="bg-white dark:bg-gray-900 ">
        <div class="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
          <div class="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-5 dark:text-gray-400">
            <a class="flex items-center lg:justify-center" target="_blank">
              <img
                class=" hover:text-gray-900 dark:hover:text-white"
                src="https://vivarecharge.com/wp-content/uploads/2022/09/airtel-logo.png"
                alt=""
              />
            </a>
            <a class="flex items-center lg:justify-center" target="_blank">
              <img
                class=" hover:text-gray-900 dark:hover:text-white"
                src="https://vivarecharge.com/wp-content/uploads/2022/09/Movistar-Logo.png"
                alt=""
              />
            </a>
            <a class="flex items-center lg:justify-center" target="_blank">
              <img
                class=" hover:text-gray-900 dark:hover:text-white"
                src="https://vivarecharge.com/wp-content/uploads/2022/09/vodafone-logo.png"
                alt=""
              />
            </a>
            <a class="flex items-center lg:justify-center" target="_blank">
              <img
                class=" hover:text-gray-900 dark:hover:text-white"
                src="https://vivarecharge.com/wp-content/uploads/2022/09/digicel-logo.png"
                alt=""
              />
            </a>
            <a class="flex items-center lg:justify-center" target="_blank">
              <img
                class=" hover:text-gray-900 dark:hover:text-white"
                src="https://vivarecharge.com/wp-content/uploads/2022/09/tigo-1.png"
                alt=""
              />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Company;
