import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Clock from "../../Component/Clock/Clock";
const UserOrderReports = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <div className=" min-h-screen">
      
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">USER DASHBOARD - ORDER REPORTS</p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>    
          </p> 
        </div>
      </div>


      <div className=" bg-thirdLightPrimary m-5 rounded-md">
        <div className=" grid grid-cols-4 gap-3 p-5 text-xs">
          <div>
            <p className=" pb-2">From Date</p>

            <DatePicker
              className="bg-gray-50 border border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
          <div>
            <p className=" pb-2">To Date</p>

            <DatePicker
              className="bg-gray-50 border border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <div>
            <p className=" pb-2">Search By</p>

            <select
              type="text"
              id="first_name"
              className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select"
              required
            >
              {" "}
              <option selected>Select </option>
              <option>Buying Date</option>
              <option>Sale Dtae</option>
            </select>
          </div>

          <div>
            <p className=" pb-2">Status</p>

            <select
              type="text"
              id="first_name"
              className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select"
              required
            >
              {" "}
              <option selected>Select </option>
              <option>Available for Sales</option>
              <option>Active</option>
              <option>Active Pending</option>
              <option>Activated</option>
              <option> Not Assigned </option>
            </select>
          </div>
        </div>

        <div className=" p-5 b">
          <button className=" bg-primary p-2 rounded-md">Search</button>
        </div>
      </div>
    </div>
  );
};

export default UserOrderReports;
