import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import Loading from "../../../Shared/Loading/Loading";
import saveAs from "file-saver";
import { useParams } from "react-router-dom";

const UserViewLuceOrder = () => {
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLoad(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/luceOrders/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setOrder(data);
        setLoad(false);
      });
  }, [id]);
  console.log(order);
  if (load) {
    return <Loading />;
  }

  const {
    resellerEmail,
    resellerName,
    resellerId,
    orderType,
    orderStatus,
    operator,
    payment,
    matricolaContatore,
    clientWindtre,
    numberCellulare,
    clientAltriOperatore,
    umeroCellulare,
  } = order;
  const {
    civic,
    cap,
    province,
    viaName,
    activateAddress,
    email,
    phone,
    codiceFiscale,
    dateOfBirth,
    surName,
    citta,
    name,
  } = order?.introduction;
  const {
    cIdentityImg,
    codiceFiscaleImg,
    caricaModuloDiSkyImg,
    dichiarationImg,
    pagamentoImg,
    soggoirnoImg,
    patenteImg,
    gasDichirationImg,
    prezzoImg,
    boccetaCompleto,
    vecchiaBollettaImg,
  } = order?.documents;
  return (
    <div className="  min-h-screen m-5">
      <div>
        <div className=" bg-green-500 max-w-xs mx-auto m-5 rounded-md">
          <h1 className=" text-xl text-white text-center">{orderStatus}</h1>
        </div>
      </div>

      <div className=" ">
        {/* //second div */}
        <div class="min-w-full">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Operator Details:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Operator</td>
                  <td class="px-6 py-4 text-xs">{operator}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Offer Name </td>
                  <td class="px-6 py-4 text-xs">
                    SKYGLASS 43" 11.90 55" 17.90 65" 24.90 Clienti con anticipo
                    Non clienti senza anticipo
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Offer Details </td>
                  <td class="px-6 py-4 text-xs ">
                    PRIMA DEVI VERIFICARE COPERTURA
                    https://www.sky.it/sky-wifi-fibra/verifica-copertura SKY
                    WIFI BOLLETINO NON SEI SOLO CONTO CORRENTE ANCHE CARTA DI
                    CREDITO SKYGLASS 43" 11.90 55" 17.90 65" 24.90 Clienti con
                    anticipo Non clienti senza anticipo SEMPRE 29.90 EURO UNO
                    VOLTA ATTIVAZIONE 29.00 EURO CONTRATTO 18 MESE PER SENZA
                    VINCOLA
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* // second div */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Introduction:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Name</td>
                  <td class="px-6 py-4 text-xs">{name}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Surname</td>
                  <td class="px-6 py-4 text-xs">{surName}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Date of birth </td>
                  <td class="px-6 py-4 text-xs">{dateOfBirth}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Via Name </td>
                  <td class="px-6 py-4 text-xs">{viaName}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Codice Fiscale</td>
                  <td class="px-6 py-4 text-xs">{codiceFiscale}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Civic</td>
                  <td class="px-6 py-4 text-xs ">{civic}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Mobile</td>
                  <td class="px-6 py-4 text-xs ">{phone}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">E-mail </td>
                  <td class="px-6 py-4 text-xs ">{email}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Activate Address </td>
                  <td class="px-6 py-4 text-xs ">{activateAddress}</td>
                </tr>

                <tr>
                  <td class="px-6 py-4 text-sm"> CAP </td>
                  <td class="px-6 py-4 text-xs ">{cap}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Citta </td>
                  <td class="px-6 py-4 text-xs ">{citta}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Province </td>
                  <td class="px-6 py-4 text-xs ">{province}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* third div */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Agent Info:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Name</td>
                  <td class="px-6 py-4 text-xs">{resellerName}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Email</td>
                  <td class="px-6 py-4 text-xs">{resellerEmail}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Id </td>
                  <td class="px-6 py-4 text-xs">{resellerId}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/*
  four div  */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Order Details:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">Payment</td>
                  <td class="px-6 py-4 text-xs">{payment}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Order Code</td>
                  <td class="px-6 py-4 text-xs">{order?._id}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Date</td>
                  <td class="px-6 py-4 text-xs">{order?.createdAt}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Type </td>
                  <td class="px-6 py-4 text-xs">{orderType}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* five div  */}

        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Documents:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">C. Identita </td>
                  <td
                    onClick={() => saveAs(cIdentityImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">SOGGOIRNO</td>
                  <td
                    onClick={() => saveAs(soggoirnoImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">CODICE FISCALE </td>
                  <td
                    onClick={() => saveAs(codiceFiscaleImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">PATENTE</td>
                  <td
                    onClick={() => saveAs(patenteImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>

                <tr>
                  <td class="px-6 py-4 text-sm">Carica modulo di Sky </td>
                  <td
                    onClick={() => saveAs(caricaModuloDiSkyImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Carica modulo di Sky </td>
                  <td
                    onClick={() => saveAs(caricaModuloDiSkyImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Dichiaration </td>
                  <td
                    onClick={() => saveAs(dichiarationImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">POD EE/PDR GAS DICHIRATION </td>
                  <td
                    onClick={() => saveAs(gasDichirationImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">
                    PREZZO NETTO NATURA CASA GAS
                  </td>
                  <td
                    onClick={() => saveAs(prezzoImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">VECCHIA BOLLETTA</td>
                  <td
                    onClick={() => saveAs(vecchiaBollettaImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Bocceta Completo</td>
                  <td
                    onClick={() => saveAs(boccetaCompleto)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* six div */}

        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Others:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-sm">CLIENTE ALTRI OPERATORE</td>
                  <td class="px-6 py-4 text-xs">{clientAltriOperatore}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">UMERO CELLULARE</td>
                  <td class="px-6 py-4 text-xs">{umeroCellulare}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">CLIENTE WINDTRE</td>
                  <td class="px-6 py-4 text-xs">{clientWindtre}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">NUMBERO CELLULARE</td>
                  <td class="px-6 py-4 text-xs">{numberCellulare}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">MATRICOLA CONTATORE</td>
                  <td class="px-6 py-4 text-xs">{matricolaContatore}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Pagamento Document </td>
                  <td
                    onClick={() => saveAs(pagamentoImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserViewLuceOrder;
