import React from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Typewriter from "typewriter-effect";
import AuthUser from "../../Hooks/authUser";
import Bot from "../Bot/Bot";

const Login = () => {
  const { http, setToken } = AuthUser();

  const handelSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    http
      .post("/user/login", { email: email, password: password })
      .then((res) => {
        if (res?.data?.status === "success") {
          console.log(res.data.data);
          swal("Success", "Successfully Login ", "success");
          setToken(
            res.data.data.user.email,
            res.data.data.token,
            res.data.data.user.role,
            res.data.data.user,
            res.data.data.userIp
          );

          window.location.reload();
          // window.location.reload();
        } else {
          // console.log("rrrrrr");
        }
      })
      .catch((err) => {
        console.log("Error", err.response.data.message);

        if (
          err.response.data.message ===
          "No user Found. Please Create an account"
        ) {
          swal("Error", "No user Found. Please Create an account!", "error");
        }
        if (
          err.response.data.message ===
          "Please check your email to verify your account."
        ) {
          swal(
            "Error",
            "Please check your email to verify your account!",
            "error"
          );
        }
        if (err.response.data.message === "email or password are not correct") {
          swal("Error", "Email or Password Wrong! ", "error");
        }
        if (err.response.data.message === "Device limit exceeded") {
          swal("Error", "Device limit exceeded! ", "error");
        }
        if (err.response.data.message === "Your account is inactive") {
          swal("Error", "Your account is inactive", "error");
        }
      });
  };

  return (
    <div className=" min-h-screen">
      <div className="relative mx-auto w-full max-w-md  m-20    bg-thirdLightPrimary px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:rounded-xl sm:px-10  ">
        <div className="w-full">
          <div className="text-center">
            <h1 className="text-3xl font-semibold text-gray-900">Genzam-It</h1>{" "}
            <Typewriter
              options={{
                strings: ["Version 2.0"],
                autoStart: true,
                loop: true,
              }}
            />
            <p className="mt-2 text-gray-500">SIM Activation</p>
          </div>
          <div className="mt-5">
            <form onSubmit={handelSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your E-mail
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Your E-mail"
                  required=""
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 pt-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                />
              </div>
              <div className="flex items-center justify-between pt-2 pb-2">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      aria-describedby="remember"
                      type="checkbox"
                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                      required=""
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="remember"
                      className="text-gray-500 dark:text-gray-300"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <Link
                  to="/forgot"
                  className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Forgot password?
                </Link>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-primary    hover:bg-secondary focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
      <Bot></Bot>
    </div>
  );
};

export default Login;
