import { Icon } from "@iconify/react";
import React, { useState, useEffect } from "react";

const GreetingMessage = () => {
  const [greeting, setGreeting] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    // Get user's location
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          // Use a reverse geocoding API to get the city based on coordinates
          const apiKey = "YOUR_OPENWEATHERMAP_API_KEY";
          const response = await fetch(
            `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}`
          );

          const data = await response.json();
          setCity(data.name);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }

    const currentHour = new Date().getHours();
    let newGreeting = "";

    if (currentHour >= 5 && currentHour < 12) {
      newGreeting = "Good Morning !!";
    } else if (currentHour >= 12 && currentHour < 18) {
      newGreeting = "Good Afternoon !!";
    } else {
      newGreeting = "Good Evening !!";
    }

    setGreeting(newGreeting);
  }, []);

  return (
    <div className="bg-yellow-400 max-w-xs p-5 rounded-md text-container">
      <h1 className="text transition-transform ease-in-out duration-300 hover:translate-x-2 flex gap-x-2 justify-start items-center">
        <Icon
          className="text-xl text-white"
          icon="mdi:human-greeting-variant"
        ></Icon>
        {greeting} {city && `in ${city}`}
      </h1>
    </div>
  );
};

export default GreetingMessage;
