import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Clock from "../../Component/Clock/Clock";
import AuthUser from "../../Hooks/authUser";
import Loading from "../../Shared/Loading/Loading";
import { Icon } from "@iconify/react";
import Pagination from "../../Shared/Pagination";
import ScrollingText from "../../Component/GreetingMessage/ScrollingText";

const UserSimSales = () => {
  const [sims, setSims] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = AuthUser();

  const [simSearch, setSimSearch] = useState("");
  const [ordersFilter, setSimFilter] = useState([]);

  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);
  //sim
  const url = `https://servergenzam.genzamwallet.com/api/v1/allsim/specific/?fieldName=${"resellerEmail"}&&fieldValue=${
    userInfo?.email
  }&&fieldName2=${"status"}&&fieldValue2=${"available"}&&size=${size}&&page=${page}`;
  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setSims(data?.data);
        setSimFilter(data?.data);
        setQuantity(data?.total);
        console.log(data?.data);
        setLoading(false);
      });
  }, [url]);

  if (loading) {
    return <Loading />;
  }

  const filterSimData = sims?.filter((f) => {
    return (
      f?.iccidNumber?.toLowerCase().includes(simSearch?.toLowerCase()) ||
      f?.simNumber?.toLowerCase().includes(simSearch?.toLowerCase()) ||
      f?.operatorName?.toLowerCase().includes(simSearch?.toLowerCase())
    );
  });

  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            USER DASHBOARD - SIM SALES LIST
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>

      <div className="flex justify-center mt-2 text-white items-center ">
        <ScrollingText></ScrollingText>
      </div>

      <div className="bg-thirdLightPrimary m-5  rounded-md p-5 ">
        {/* search bar */}
        <div className=" rounded-md ">
          <div className="flex  w-lg  bg-lightPrimary text-xs rounded-sm shadow-lg my-5 mx-5 p-6 ">
            <div className="w-full ">
              <p className=" text-sm">Search SIM</p>
              <div className="mt-5 sm:flex sm:items-center">
                <input
                  id="q"
                  onChange={(e) => setSimSearch(e.target.value)}
                  className="inline w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-sm"
                  placeholder="ICCID Number ,SIM Number,Operator Name etc."
                  type="search"
                />
                <button
                  onClick={() => setSimFilter(filterSimData)}
                  className="mt-3  inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* table section */}
        <div className="relative overflow-x-auto sm:rounded-lg m-5 p-5  bg-lightPrimary ">
          <div className="  ">
            <h2 className="text-sm font-bold mb-4 underline">
              Sale List (Total: {ordersFilter?.length} SIM)
            </h2>
          </div>

          <table className="table-auto w-full text-xs bg-gray-400 rounded-md">
            <thead className=" text-white bg-black">
              <tr>
                <th className="border px-2 py-1">#</th>
                <th className="border px-4 py-2">Operator</th>
                <th className="border px-4 py-2">ICCID Number</th>
                <th className="border px-4 py-2">SIM Number</th>
                <th className="border px-4 py-2">Buy Date</th>
                <th className="border px-4 py-2">Buy Price</th>
                <th className="border px-4 py-2">Action</th>
              </tr>
            </thead>
            <tbody>
              {ordersFilter?.reverse().map((row, index) => (
                <tr
                  key={index}
                  className="border px-2 py-1 bg-white text-center"
                >
                  <td className="border px-2 py-1 text-center">{index + 1}</td>
                  <td className="border px-4 py-2 text-center">
                    {row.operatorName}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {row.iccidNumber}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {row.simNumber}
                  </td>
                  <td className="border px-4 py-2 text-center">
                    <p>{new Date(row.buyDate).toLocaleDateString()}</p>
                  </td>
                  <td className="border px-4 py-2 text-center">
                    {row.buyPrice}
                  </td>
                  <td>
                    <Link to={`${row?._id}`}>
                      <span className="p-2 text-red-600">Sale</span>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination
            quantity={quantity}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSimSales;
