import React from "react";

const UserTransactionReportView = () => {
  return (
    <div className=" min-h-screen ">
      <div className=" bg-thirdLightPrimary">
        <p className="  font-semibold p-2 ">
          USER DASHBOARD - TRANSACTION REPORT VIEW
        </p>
      </div>
      <div className="  bg-thirdLightPrimary m-5 rounded-md">
        <div className="container  mx-auto sm:p-4 dark:text-gray-100 ">
          <p className="mb-4 text-2xl font-semibold leadi text-center">
            Transaction Reports
          </p>
          <p className=" text-center pb-5">
            Report Period: 21-May-2023 To 19-Jun-2023
          </p>
          <div className="overflow-x-auto">
            <table className="min-w-full text-xs">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24"></col>
              </colgroup>
              <thead className="dark:bg-gray-700 text-white bg-black">
                <tr className="text-left">
                  <th className="p-3">#</th>
                  <th className="p-3">Code</th>
                  <th className="p-3">Date </th>
                  <th className="p-3">Order/Payment </th>
                  <th className="p-3 "> Details </th>
                  <th className="p-3 "> Debit </th>
                  <th className="p-3 "> Credit </th>
                  <th className="p-3 "> Balance </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-opacity-20 bg-gray-400 dark:border-gray-700 dark:bg-gray-900 ">
                  <td className="p-3">
                    <p>1</p>
                  </td>
                  <td className="p-3">
                    <p>2C20230401721 </p>
                  </td>
                  <td className="p-3">
                    <p>10.06.2023 </p>
                  </td>
                  <td className="p-3">
                    <p> 2J20230601913 </p>
                  </td>

                  <td className="p-3">
                    <p>
                      Bill for (#MK20230601918 | mddelwar - 114.00)
                      (#MJ20230601877 | mdparvez - 15.00) (#G120230601943 |
                      genzamancona - 5.00) May 2023{" "}
                    </p>
                  </td>
                  <td className="p-3">
                    <p> €165.00 </p>
                  </td>
                  <td className="p-3">
                    <p> €31.00 </p>
                  </td>
                  <td className="p-3">
                    <p> €165.00</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTransactionReportView;
