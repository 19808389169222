import React from "react";
import { Link } from "react-router-dom";
import Clock from "../Clock/Clock";

const Countries = () => {
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - COUNTRIES
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary m-5 p-3 rounded-md">
        <div class="relative overflow-x-auto shadow-md  bg-lightPrimary p-5 sm:rounded-lg m-5">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs  uppercase bg-black text-white dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3 text-xs">
                  Country Name
                </th>

                <th scope="col" class="px-6 py-3 text-xs ">
                  Status
                </th>

                <th scope="col" class="px-6 py-3 text-xs">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 text-xs dark:hover:bg-gray-600">
                <td class="px-6 py-4">Italy</td>

                <td class="px-6 py-4">Enable </td>

                <td class="flex items-center px-6 py-4 space-x-3">
                  <p></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Countries;
