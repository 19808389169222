import { Icon } from "@iconify/react";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../Shared/Loading/Loading";

const AdminManageSim = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState("");

  useEffect(() => {
    setLoading(true);
    // Fetch data from the backend API endpoint
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/allsim")
      .then((response) => {
        setData(response.data.sims);
        setFilteredData(response.data.sims);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const openModal = (offerId) => {
    setSelectedOfferId(offerId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return <Loading />;
  }

  const handleFilter = (searchValue) => {
    const filtered = data.filter(
      (sims) =>
        sims.simNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        sims.resellerEmail.toLowerCase().includes(searchValue.toLowerCase()) ||
        sims.iccidNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
        sims.status.toLowerCase().includes(searchValue.toLowerCase()) ||
        sims.offerName.toLowerCase().includes(searchValue.toLowerCase()) ||
        sims._id.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleDeleteOffer = (offerId) => {
    openModal(offerId);
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`https://servergenzam.genzamwallet.com/api/v1/allsim/${selectedOfferId}`)
      .then((response) => {
        setData((prevData) =>
          prevData.filter((offer) => offer._id !== selectedOfferId)
        );
        closeModal();
      })
      .catch((error) => {
        console.error("Error deleting offer:", error);
      });
  };

  return (
    <div>
      <div>
        <div className="max-w-3xl mx-auto text-center mt-5">
          <h1 className="text-4xl flex justify-center gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="material-symbols:manage-history-rounded" />
            Manage ALL SIM
          </h1>
        </div>
      </div>

      <div>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="mt-10 mx-auto max-w-xl py-2 px-6 rounded-full bg-gray-50 border flex focus-within:border-gray-300"
        >
          <input
            type="text"
            placeholder="Search User by Email, ICCID Number , SIM Number "
            className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-0 py-0"
            name="searchuser"
            onChange={(e) => handleFilter(e.target.value)}
          />
          <button className="flex flex-row items-center justify-center min-w-[130px] px-4 rounded-full font-medium tracking-wide border disabled:cursor-not-allowed disabled:opacity-50 transition ease-in-out duration-150 text-base bg-primary text-white font-medium tracking-wide border-transparent py-1.5 h-[38px] -mr-3">
            Search
          </button>
        </form>
      </div>

      <div className="overflow-x-auto m-5">
        <table className="min-w-full text-xs bg-gray-100">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="  ">
            <tr className=" text-left  text-xs bg-black text-white ">
              <th className="p-3 border">#</th>
              <th className="p-3 text-left border">Email</th>
              <th className="p-3 border">Operator Name</th>
              <th className="p-3 border">Offer Name</th>
              <th className="p-3 border">ICCID Number</th>
              <th className="p-3 border">SIM Number</th>

              <th className="p-3 border ">Status</th>
              <th className="p-3 border ">Action</th>
            </tr>
          </thead>
          <tbody>
            {[...filteredData]?.reverse()?.map((sim, index) => (
              <tr
                key={sim?._id}
                className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
              >
                <td className="p-3 border">
                  <p>{index + 1}</p>
                </td>
                <td className="p-3  text-justify border">
                  <p>{sim?.resellerEmail}</p>
                </td>
                <td className="p-3  text-justify border">
                  <p>{sim?.operatorName}</p>
                </td>

                <td className="p-3  text-justify border">
                  <p>{sim?.offerName}</p>
                </td>
                <td className="p-3  text-justify border">
                  <p>{sim?.iccidNumber}</p>
                </td>
                <td className="p-3  text-justify border">
                  <p>{sim?.simNumber}</p>
                </td>

                <td className="p-3  text-justify border">
                  <p>{sim?.status}</p>
                </td>
                <td className="p-3 text-xl items-center justify-center flex gap-x-5">
                  <p className="flex gap-x-3 text-primary">
                    <Icon
                      icon="ant-design:delete-filled"
                      onClick={() => handleDeleteOffer(sim._id)} // Open the confirmation modal
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                  <p className="flex gap-x-3 text-primary">
                    <Link to={`/adminDashboard/AdminViewAllSim/${sim?._id}`}>
                      <Icon icon="carbon:view-filled" />
                    </Link>
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}

      <div
        className="modal-overlay"
        style={{ display: isModalOpen ? "flex" : "none" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title">Confirm Deletion</h2>
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete this notification?</p>
          </div>
          <div className="modal-footer">
            <button className="cancel" onClick={closeModal}>
              Cancel
            </button>
            <button className="confirm" onClick={handleConfirmDelete}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminManageSim;
