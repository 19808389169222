import React, { useState, useEffect } from "react";

const ScrollingTextItem = ({ text, isActive }) => {
  return (
    <div className={`overflow-hidden ${isActive ? "active" : ""}`}>
      <div className="inline-block whitespace-nowrap animate-scrolling">
        {text}
      </div>
      <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
        .animate-scrolling {
          animation: scroll 35s linear infinite;
        }
        .overflow-hidden {
          overflow: hidden;
          white-space: nowrap;
        }
        .active {
          animation-play-state: running;
        }
      `}</style>
    </div>
  );
};

const ScrollingText = () => {
  const [texts, setTexts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    // Fetch the scrolling text data from your API endpoint
    fetch("https://servergenzam.genzamwallet.com/api/v1/scrollingTextRoutes/texts")
      .then((response) => response.json())
      .then((data) => setTexts(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    let interval;

    if (texts.length > 0) {
      interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 20000); // Change to the duration of your animation (70 seconds)
    }

    return () => clearInterval(interval);
  }, [texts]);

  return (
    <div>
      <div className="scrolling-text-container">
        {texts.map((text, index) => (
          <ScrollingTextItem
            key={text._id}
            text={text.title}
            isActive={index === activeIndex}
          />
        ))}
      </div>
      <style jsx>{`
        .scrolling-text-container {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </div>
  );
};

export default ScrollingText;
