import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Clock from "../../Component/Clock/Clock";
import {
  appuntiUpload,
  multipleImageUpload,
  singleImageUpload,
} from "../../Hooks/ImageUpload";
import AuthUser from "../../Hooks/authUser";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PostHooks from "../../Hooks/PostHooks";
import Loading from "../../Shared/Loading/Loading";
const UserSimSalesOrder = () => {
  const { userInfo } = AuthUser();
  const navigate = useNavigate();

  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [dateOfIssue, setDateOfIssue] = useState(new Date());
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [appuntiImg, setAppuntiImg] = useState([]);
  const [essunoPortabilitaSenzaCopia, setEssunoPortabilitaSenzaCopia] =
    useState("");
  const [caricaOriginaleEFotocopia, setCaricaOriginaleEFotocopia] =
    useState("");
  const [caricaLaFotoDella, setCaricaLaFotoDella] = useState("");
  const [ibanImg, setIbanImg] = useState("");

  const [offerName, setOfferName] = useState("");
  const { id } = useParams();
  const [saleData, setSaleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [operatorsName, setOperatorsName] = useState("");
  const [operators, setOperators] = useState([]);
  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/simOperator")
      .then((res) => res.json())
      .then((data) => setOperators(data));
  }, []);

  useEffect(() => {
    const fetchSaleData = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/allsim/getSimById/${id}` // Add a forward slash "/" before the id
        );
        setSaleData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sale data:", error);
        setLoading(false);
      }
    };

    fetchSaleData();
  }, [id]);
  console.log(saleData);
  //Form Data
  const [formData, setFormData] = useState({
    iccidNumber: "",
    mobile: "",
    appuntiNote: "",
    type: "",
    ibanNumber: "",
    firstName: "",
    surName: "",
    taxIdCode: "",
    nationality: "",
    documentNumber: "",
    placeOfIssue: "",
    residentInStreetNumberCivicInterrior: "",
    location: "",
    prov: "",
    cap: "",
    telephono: "",
    email: "",
    ricarica: "",

    vechioSchedaIccdNumero: "",
    verchioNumeroCellulare: "",
    appunti: "",
  });

  const simSale = {
    ...formData,
    ibanImg,
    operators: operatorsName,
    DateOfIssueOfTheDocument: dateOfIssue,
    expirationDateOfTheDocument: expirationDate,
    dateOfBirth: dateOfBirth,
    offerta: offerName,
    appunti: appuntiImg,
    caricaLaFotoDella,
    caricaOriginaleEFotocopia,
    essunoPortabilitaSenzaCopia,
    resellerEmail: userInfo?.email,
    resellerName: userInfo?.name,
    resellerId: userInfo?._id,
    simId: id,
    role: userInfo?.role,
    saleData: saleData,
  };
  // filter data for offer
  const filterOffer = operators?.filter(
    (op) => op?.operatorName === saleData?.operatorName
  );
  // handel simSale
  const handelAddSim = async (e) => {
    e.preventDefault();
    // console.log(simSale);
    await PostHooks(
      "https://servergenzam.genzamwallet.com/api/v1/simSale",
      simSale,
      "simSale successfully submitted"
    );

    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/allsim/${id}`;

    await fetch(BASE_URL, {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        status: "notAvailable",
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("success", data);
        navigate("/userDashboard/user-sim-sales");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);

    if (event?.target?.name === "caricaLaFotoDella") {
      singleImageUpload(formData, setCaricaLaFotoDella);
    }
    if (event?.target?.name === "ibanImg") {
      singleImageUpload(formData, setIbanImg);
    }
    if (event?.target?.name === "caricaOriginaleEFotocopia") {
      singleImageUpload(formData, setCaricaOriginaleEFotocopia);
    }
    if (event?.target?.name === "essunoPortabilitaSenzaCopia") {
      singleImageUpload(formData, setEssunoPortabilitaSenzaCopia);
    }
    if (event?.target?.name === "appuntiImg") {
      appuntiUpload(formData, setAppuntiImg, appuntiImg);
    }
  };

  console.log(appuntiImg);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevenFormData) => ({
      ...prevenFormData,
      [name]: value,
    }));
  };

  if (loading) {
    return <Loading />;
  }

  if (!saleData) {
    return (
      <div className="min-h-screen flex items-center justify-center text-primary text-3xl font-medium">
        <p>No data found for the provided ID.</p>;
      </div>
    );
  }

  // const handleMultipleUploadImage = async (event) => {
  //   const image = event.target.files;
  //   console.log(image);
  //   const formData = new FormData();
  //   for (let i = 0; i < image.length; i++) {
  //     formData.append("multiImage", image[i]);
  //   }

  //   multipleImageUpload(formData, setMultipleImg);
  // };

  return (
    <div className=" min-h-screen ">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            {" "}
            USER DASHBOARD - SIM SALES LIST / SIM SALE
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>

      <div className=" bg-thirdLightPrimary m-5 p-5 text-xs rounded-md">
        <div className="grid  grid-cols-2">
          <div>
            <p>1.Print below linked attachment.</p>
            <p>
              2.Take customer signature and upload it again to process order.
            </p>
          </div>

          <div>
            <p>1.Stampa sotto l'allegato modullo.</p>
            <p>
              2.Prendi la firma del cliente e caricala di nuovo per elaborare
              l'ordine.
            </p>
          </div>
        </div>

        <div className=" text-center p-5">
          <p className=" text-blue-500 font-semibold underline">CLICK ME</p>
        </div>

        <div className=" text-center bg-gray-400 max-w-lg p-2 rounded-md mx-auto ">
          <p>
            If you don't take signature from customer, Your order not process in
            our end. Thanks! Happy selling!
          </p>
        </div>
      </div>

      {/* second div  */}
      <form onSubmit={handelAddSim}>
        <div className="  bg-thirdLightPrimary p-5 rounded-md text-xs m-5">
          <p>Vendita SIM</p>

          <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

          <div className=" bg-gray-400 p-5 rounded-md m-5">
            <p>
              Operatore:{" "}
              <span className=" font-semibold"> {saleData?.operatorName}</span>{" "}
            </p>
            <p>
              Numero ICCID :{" "}
              <span className=" font-semibold"> {saleData?.iccidNumber}</span>{" "}
            </p>

            <p>
              Numero di cellulare:{" "}
              <span className=" font-semibold">{saleData?.simNumber}</span>{" "}
            </p>
            <p className=" pt-5">
              দয়াকরে, বিক্রি করার সময় সিম কার্ডের সিরিয়াল নাম্বার নিচের ফিল্ড
              (ICCID, Mobile) গুলায় লিখে দিবেন, অন্যথায় সিম কার্ড একটিভ হবেনা..
            </p>
            <p className=" pt-5">
              È ora di vendere il numero di serie della scheda SIM si prega di
              scrivere qui, altrimenti la scheda SIM non sarà attiva.
            </p>
          </div>
          <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

          <div className=" grid grid-cols-2 gap-5 m-5 text-xs bg-gray-400 rounded-md p-5">
            <div>
              <p className=" pb-2">ICCID Number</p>
              <input
                onChange={handleChange}
                type="text"
                className=" w-full text-xs "
                placeholder="ICCID Number"
                name="iccidNumber"
              />
            </div>
            <div>
              <p className="pb-2">Mobile</p>
              <input
                onChange={handleChange}
                type="text"
                className=" w-full text-xs"
                placeholder="Enter Sim Number"
                name="mobile"
              />
            </div>
          </div>

          <div className="  m-5 p-5 pb-5 bg-gray-400 rounded-md">
            <p>Offerta</p>
            <div className=" pt-5">
              <select
                type="text"
                id="first_name"
                name="offerName"
                onChange={(e) => setOfferName(e.target.value)}
                className="bg-gray-50 border text-xs  border-gray-300  text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                required
              >
                <option selected>..Choose...</option>
                {filterOffer[0]?.offerName?.map((offer) => (
                  <option value={offer?.offerName}>{offer?.offerName}</option>
                ))}
              </select>
            </div>

            {/* facilities table */}
            {filterOffer[0]?.offerName?.map((offer) => (
              <div>
                {offer?.offerName === offerName && (
                  <div className="w-full overflow-x-auto bg-white p-2 rounded-lg mt-3">
                    <table
                      className="w-full text-left border border-collapse rounded sm:border-separate border-slate-200"
                      cellspacing="0"
                    >
                      <tbody>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            Cost Per Month
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.costPerMonth}
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            totalRecharge
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.totalRecharge}
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            Offer Valid For
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.offerValidFor}
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            Status
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.status}
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            Free Internet Pack (GB)
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.freeInternetPack}
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            Free Minuites for Local Operator
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.freeMinutesForLocalOperator}
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            Free Minutes for International
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.freeMinutesForInternational}
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            International Minutes Valid For
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.internationalMinutesValidFor}
                          </td>
                        </tr>
                        <tr className="bg-gray-100">
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            Iban
                          </td>
                          <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                            {offer?.iban}
                          </td>
                        </tr>
                        {offer?.iban === "yes" && (
                          <tr className="bg-gray-100">
                            <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                              Iban Number
                            </td>
                            <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                              <input
                                onChange={handleChange}
                                type="text"
                                name="ibanNumber"
                                className=" w-full text-xs "
                                placeholder=" Enter Iban number"
                              />
                            </td>
                          </tr>
                        )}
                        {offer?.iban === "yes" && (
                          <tr className="bg-gray-100">
                            <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                              Iban Image
                            </td>
                            <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                              <input
                                name="ibanImg"
                                onChange={handleChangeUploadImage}
                                type="file"
                                className=" w-full text-xs border "
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className=" grid grid-cols-2 gap-5 p-4  ">
            <div className="  bg-gray-400 rounded-md p-5">
              <div>
                <p className=" pb-2">Type</p>
                <select
                  type="text"
                  onChange={handleChange}
                  name="type"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option disabled selected>
                    Select
                  </option>
                  <option value="maschio">Maschio</option>
                  <option value="femmina">Femmina</option>
                </select>
              </div>

              <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">First name </p>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="firstName"
                    className=" w-full text-xs "
                    placeholder=" Enter client first name"
                  />
                </div>
                <div>
                  <p className=" pb-2">Surname </p>
                  <input
                    onChange={handleChange}
                    name="surName"
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client last name"
                  />
                </div>
              </div>

              <div className=" pt-2">
                <p className=" pb-2">Tax ID code</p>
                <input
                  name="taxIdCode"
                  onChange={handleChange}
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client Tax ID Code"
                />
              </div>

              <div className=" grid grid-cols-2  items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">Date of birth</p>
                  <DatePicker
                    name="dateOfBirth"
                    className=" text-xs "
                    selected={dateOfBirth}
                    onChange={(date) => setDateOfBirth(date)}
                  />
                </div>
                <div>
                  <p className=" pb-2">Nationality</p>
                  <input
                    onChange={handleChange}
                    name="nationality"
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client nationality "
                  />
                </div>
              </div>

              <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">Document Number</p>
                  <input
                    onChange={handleChange}
                    name="documentNumber"
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client document number"
                  />
                </div>
                <div>
                  <p className=" pb-2">Place of issue</p>
                  <input
                    onChange={handleChange}
                    name="placeOfIssue"
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client document place of issue"
                  />
                </div>
              </div>

              <div className=" grid grid-cols-2  items-center    border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">Date of Issue of The Document</p>
                  <DatePicker
                    name="DateOfIssueOfTheDocument"
                    className="  text-xs "
                    selected={dateOfIssue}
                    onChange={(date) => setDateOfIssue(date)}
                  />
                </div>
                <div>
                  <p className=" pb-2">Expiration Date of The Document</p>
                  <DatePicker
                    name="expirationDateOfTheDocument"
                    className="  text-xs "
                    selected={expirationDate}
                    onChange={(date) => setExpirationDate(date)}
                  />
                </div>
              </div>

              <div>
                <p className=" pt-2">
                  RESIDENT IN STREET, NUMBER, CIVIC, INTERIOR
                </p>
                <textarea
                  onChange={handleChange}
                  name="residentInStreetNumberCivicInterrior"
                  className="h-40 mt-2 text-sm   outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                  placeholder="Enter Client address"
                ></textarea>
              </div>

              <div className=" grid grid-cols-3 gap-5 items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">LOCATION </p>
                  <input
                    name="location"
                    onChange={handleChange}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client localita"
                  />
                </div>
                <div>
                  <p className=" pb-2">PROV </p>
                  <input
                    name="prov"
                    type="text"
                    onChange={handleChange}
                    className=" w-full text-xs "
                    placeholder=" Enter client prov"
                  />
                </div>
                <div>
                  <p className=" pb-2">CAP </p>
                  <input
                    name="cap"
                    type="text"
                    onChange={handleChange}
                    className=" w-full text-xs "
                    placeholder=" Enter client cap"
                  />
                </div>
              </div>
              <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">Telephono </p>
                  <input
                    name="telephono"
                    onChange={handleChange}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client telephono"
                  />
                </div>
                <div>
                  <p className=" pb-2">E-mail </p>
                  <input
                    name="email"
                    onChange={handleChange}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client e-mail"
                  />
                </div>
              </div>
            </div>
            {/* second div */}
            <div className=" bg-gray-400 rounded-md p-5 ">
              <div>
                <p className=" pb-2 pt-2">Ricarica</p>
                <select
                  type="text"
                  onChange={handleChange}
                  name="ricarica"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option value="10ricarica">€10 Ricarica</option>
                  <option value="20ricarica">€20 Ricarica</option>
                  <option value="25ricarica">€25 Ricarica</option>
                  <option value="30ricarica">€30 Ricarica</option>
                  <option value="35ricarica">€35 Ricarica</option>
                </select>
              </div>

              <p className="pt-5  pl-1">MNP/PORTABILITA CON ALTRO GESTORE</p>
              <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
              <div>
                <p className=" pb-2 pt-2">Operators</p>
                <select
                  type="text"
                  name="operators"
                  onChange={(e) => setOperatorsName(e?.target.value)}
                  className="bg-gray-50 border   border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                >
                  {" "}
                  <option selected value="">
                    None
                  </option>
                  <option value="coopVoce">Coop Voce</option>
                  <option value="digi">DIGI</option>
                  <option value="fastWeb">FAST WEB</option>
                  <option value="heraComm">HERA COMM</option>
                  <option value="hoOtherName">HO Other Name (vei)</option>
                  <option value="iliad">iliad</option>
                  <option value="iren">IREN</option>
                  <option value="kenaMobileNoverca">KENA MOBILE-NOVERCA</option>
                  <option value="linkem">LINKEM</option>
                  <option value="lycaMobile">LYCA MOBILE</option>
                  <option value="others">OTHERS</option>
                  <option value="">POST MOBILE // 2014</option>
                  <option value="postMobileFull">POST MOBILE FULL</option>
                  <option value="rabonaMobile">RABONA MOBILE</option>
                  <option value="ringo">RINGO</option>
                  <option value="skyeTvOrNetflix">SKY TV OR NETFLIX</option>
                  <option value="skyWifi">SKY-WI-FI</option>
                  <option value="skyGlass">SKYGLASS</option>
                  <option value="tscali">TSCALI</option>
                  <option value="unoMobile">UNO MOBILE</option>
                  <option value="veryMobile">VERYMOBILE</option>
                  <option value="vodafone">VODAFONE</option>
                </select>
              </div>

              {operatorsName && (
                <div>
                  <div>
                    <p className=" pb-2 pt-2">VECHIO SCHEDA ICCD NUMERO:</p>
                    <input
                      name="vechioSchedaIccdNumero"
                      onChange={handleChange}
                      type="text"
                      className=" w-full text-xs "
                      placeholder=" Enter 19 Digit ICCID Number"
                    />
                  </div>
                  <div>
                    <p className=" pb-2 pt-2">VECHIO NUMERO CELLULARE:</p>
                    <input
                      onChange={handleChange}
                      name="verchioNumeroCellulare"
                      type="text"
                      className=" w-full text-xs "
                      placeholder=" Enter mobile number"
                    />
                  </div>
                  <div>
                    <p className=" pb-2 pt-2">
                      Carica la foto della vecchia SIM/Upload the photo of the
                      old SIM:
                    </p>

                    <input
                      onChange={handleChangeUploadImage}
                      name="caricaLaFotoDella"
                      type="file"
                      className=" w-full text-xs border "
                    />
                  </div>
                  <div>
                    <div className=" grid grid-cols-1 gap-4">
                      <div>
                        <p className=" pb-2 pt-2">
                          Carica Originale e Fotocopia Codice Fiscale: *
                        </p>
                        <input
                          onChange={handleChangeUploadImage}
                          name="caricaOriginaleEFotocopia"
                          type="file"
                          className=" w-full text-xs border "
                        />
                      </div>
                      <div>
                        <p className=" pb-2 ">
                          essuno portabilita senza copia di Codice Fiscale
                          obbligatorio?
                        </p>
                        <input
                          onChange={handleChangeUploadImage}
                          name="essunoPortabilitaSenzaCopia"
                          type="file"
                          className=" w-full text-xs border "
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className=" pt-2">Appunti</p>
                    <textarea
                      onChange={handleChange}
                      name="appuntiNote"
                      className="h-40 mt-2 text-sm   outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                      placeholder="Enter note"
                    ></textarea>
                  </div>
                </div>
              )}
              {/* condisonal div */}

              <div>
                <p className=" pt-2 font-semibold">Appunti</p>
                <div className=" text-1px pl-5 pt-2">
                  <p>1. La dimensione massima per file è di 2 MB.</p>
                  <p>2. Carica solo file PNG, JPEG, PDF.</p>
                </div>

                <div className=" grid grid-cols-1 gap-4 pt-2 ">
                  {/* <input
                    multiple
                    onChange={handleMultipleUploadImage}
                    type="file"
                    className=" w-full text-xs border "
                  /> */}
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className=" text-left  bg-primary text-white hover:bg-secondary text-xs p-2 rounded-md "
              >
                Conferma Vendita
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserSimSalesOrder;
