import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const RequireAuth = ({ children }) => {
  //   const [user, loading] = useAuthState(auth);
  let location = useLocation();

  //   if (loading) {
  //     return <Loading />;
  //   }

  //   if (!user) {
  //     return <Navigate to="/login" state={{ from: location }} replace />;
  //   }
  return children;
};

export default RequireAuth;
