import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const LandingFooter = () => {
  return (
    <div>
      <footer class="bg-primary dark:bg-gray-900 ">
        <div class=" ">
          <div class="sm:flex sm:items-center sm:justify-between pt-5 pb-5 pl-5 pr-5">
            <span class="text-sm text-black sm:text-center dark:text-gray-400">
              © 2023{" "}
              <a href="https://flowbite.com/" class="hover:underline">
                Genzam-IT
              </a>
              . All Rights Reserved.
            </span>
            <div class="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
              <Link
                to="https://www.facebook.com/genzamstore"
                class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <Icon icon="ic:baseline-facebook" className=" w-4 h-4"></Icon>
                <span class="sr-only">Facebook page</span>
              </Link>
              <Link
                to="https://instagram.com/genzamstore?igshid=MzRlODBiNWFlZA=="
                class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <Icon icon="ri:instagram-fill" className=" w-4 h-4"></Icon>
                <span class="sr-only">Instagram Page</span>
              </Link>
              <Link
                to="mdi:youtube"
                class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <Icon icon="mingcute:youtube-fill"></Icon>
                <span class="sr-only">YouTube</span>
              </Link>
              <Link
                to="https://www.tiktok.com/@genzamstore?_t=8eKdt6rpA78&_r=1"
                class="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <Icon icon="iconoir:tiktok"></Icon>
                <span class="sr-only">Tiktok account</span>
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingFooter;
