import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../Shared/Loading/Loading";
import saveAs from "file-saver";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const AdminGasOrderView = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [order, setOrder] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  useEffect(() => {
    setLoading(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/newGasOrder/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setOrder(data);
        setLoading(false);
        console.log(data);
      });
  }, [id, refresh]);

  if (loading) {
    return <Loading />;
  }

  const handelUpdate = async (e) => {
    e.preventDefault();
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/newGasOrder/${id}`;
    if (orderStatus || paymentStatus) {
      await UpdateHooks(
        BASE_URL,
        {
          orderStatus: orderStatus ? orderStatus : order?.orderStatus,
          paymentStatus: paymentStatus ? paymentStatus : order?.paymentStatus,
        },
        true,
        "Status Updated"
      );
    } else {
      alert("please select status");
    }

    setRefresh(!refresh);
  };

  return (
    <div className="  min-h-screen m-5">
      <div>
        <div className=" bg-green-500 max-w-xs mx-auto m-5 rounded-md">
          <h1 className=" text-xl text-white text-center">Order Summery</h1>
        </div>
      </div>

      <div className=" ">
        {/* //second div */}
        <div class="min-w-full">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Operator Details:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-xs">Operator</td>
                  {order?.operatorDetails?.length && (
                    <td class="px-6 py-4 text-xs">
                      {order?.operatorDetails[0]?.operatorName}
                    </td>
                  )}
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs ">Operator Details</td>
                  {order?.operatorDetails?.length && (
                    <td class="px-6 py-4 text-xs">
                      {order?.operatorDetails[0]?.operatorDetails}
                    </td>
                  )}
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Date</td>
                  {order?.operatorDetails?.length && (
                    <td class="px-6 py-4 text-xs">
                      {order?.operatorDetails[0]?.createdAt}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* // second div */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Introduction:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-xs">Name</td>
                  <td class="px-6 py-4 text-xs">{order?.introduction?.name}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Surname</td>
                  <td class="px-6 py-4 text-xs">
                    {order?.introduction?.surName}
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Date of birth </td>
                  <td class="px-6 py-4 text-xs">
                    {order?.introduction?.dateOfBirth}
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Tax ID code</td>
                  <td class="px-6 py-4 text-xs ">
                    {order?.introduction?.taxIdCode}
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Mobile</td>
                  <td class="px-6 py-4 text-xs ">
                    {order?.introduction?.phone}
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">E-mail </td>
                  <td class="px-6 py-4 text-xs ">
                    {order?.introduction?.email}
                  </td>
                </tr>

                <tr>
                  <td class="px-6 py-4 text-xs"> CAP </td>
                  <td class="px-6 py-4 text-xs ">{order?.introduction?.cap}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Citta </td>
                  <td class="px-6 py-4 text-xs ">
                    {order?.introduction?.citta}
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Province </td>
                  <td class="px-6 py-4 text-xs ">
                    {order?.introduction?.province}
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Soggoirno</td>
                  <td class="px-6 py-4 text-xs ">
                    {order?.introduction?.soggoirno}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* third div */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Agent Info:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-xs">Name</td>
                  <td class="px-6 py-4 text-xs">{order?.resellerName}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-sm">Phone</td>
                  <td class="px-6 py-4 text-xs">{order?.resellerNumber}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4text-xs">Email </td>
                  <td class="px-6 py-4 text-xs">{order?.resellerEmail}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* 
        four div  */}
        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Order Details:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-xs">Order Code</td>
                  <td class="px-6 py-4 text-xs">{order?._id}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Date</td>
                  <td class="px-6 py-4 text-xs">{order?.createdAt}</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Activate address</td>
                  <td class="px-6 py-4 text-xs">
                    {order?.introduction?.activateAddress}
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Type </td>
                  <td class="px-6 py-4 text-xs">{order?.orderType}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* five div  */}

        <div class="min-w-full mt-5">
          <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Documents:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-xs">C. Identita </td>
                  <td
                    onClick={() => saveAs(order?.documents?.cIdentityImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">CODICE FISCALE</td>
                  <td
                    onClick={() => saveAs(order?.documents?.codiceFiscaleImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Tax Id code</td>
                  <td
                    onClick={() => saveAs(order?.documents?.taxIdCodeImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">Carica modulo di Sky</td>
                  <td
                    onClick={() =>
                      saveAs(
                        order?.documents?.caricaModuloDiSkyImg,
                        "image.jpg"
                      )
                    }
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">DICHIARATION</td>
                  <td
                    onClick={() => saveAs(order?.documents?.dichiarationImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">
                    PAGAMENTO E INVIO DELLA BOLLETTA ANCHE CONTO CORRENTE
                  </td>
                  <td
                    onClick={() => saveAs(order?.documents?.pagamentoImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">SOGGOIRNO</td>
                  <td
                    onClick={() => saveAs(order?.documents?.soggoirnoImg)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">PATENTE</td>
                  <td
                    onClick={() => saveAs(order?.documents?.patente)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">POD EE/PDR GAS DICHIRATION</td>
                  <td
                    onClick={() => saveAs(order?.documents?.gasDichiration)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">
                    PREZZO NETTO NATURA CASA GAS
                  </td>
                  <td
                    onClick={() => saveAs(order?.documents?.prezzo)}
                    class="px-6 py-4 text-2xl text-primary cursor-pointer"
                  >
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
                {order?.documents?.ibanImg && (
                  <tr>
                    <td class="px-6 py-4 text-xs">Iban Img</td>
                    <td
                      onClick={() => saveAs(order?.documents?.ibanImg)}
                      class="px-6 py-4 text-2xl text-primary cursor-pointer"
                    >
                      <Icon icon="mingcute:download-fill"></Icon>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* six div */}

        <div class="min-w-full mt-5">
          {/* <div class="overflow-x-auto bg-gray-50">
            <p className=" m-5 text-xl">Pagamento:</p>
            <table class="table-auto w-full divide-y divide-gray-200">
              <thead class="bg-gray-50"></thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr>
                  <td class="px-6 py-4 text-xs">Type</td>
                  <td class="px-6 py-4 text-xs">Conto Corrente</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">IBAN</td>
                  <td class="px-6 py-4 text-xs">IT72I3608105138255930355937</td>
                </tr>
                <tr>
                  <td class="px-6 py-4 text-xs">IBAN Document </td>
                  <td class="px-6 py-4 text-2xl text-primary cursor-pointer">
                    <Icon icon="mingcute:download-fill"></Icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}

          <form
            onSubmit={handelUpdate}
            className=" flex items-center justify-center gap-x-5 bg-gray-400 m-5 p-5"
          >
            <div>
              <label
                htmlFor="paymentStatus"
                className="block text-sm font-medium text-gray-700"
              >
                Payment Status
              </label>
              <select
                onChange={(e) => setPaymentStatus(e.target.value)}
                id="paymentStatus"
                name="paymentStatus"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option selected disabled>
                  ... select ...
                </option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="paymentStatus"
                className="block text-sm font-medium text-gray-700"
              >
                Order Status
              </label>
              <select
                onChange={(e) => setOrderStatus(e.target.value)}
                id="paymentStatus"
                name="paymentStatus"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option selected disabled>
                  ... select ...
                </option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
                <option value="onProcess">On Process</option>
                <option value="rejected">Reject</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="paymentStatus"
                className="block text-sm font-medium text-gray-700"
              >
                Action
              </label>
              <button
                id="paymentStatus"
                type="submit"
                name="paymentStatus"
                className="mt-1  block w-full py-2 px-3 font-medium text-white hover:scale-105 duration-300  bg-green-500 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                UPDATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminGasOrderView;
