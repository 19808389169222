import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UpdateHooks from "../../Hooks/UpdateHooks";
import swal from "sweetalert";

const AdminUpdatePaymentView = () => {
  const { id } = useParams();
  const [bill, setBill] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [pay, setPay] = useState(0);

  useEffect(() => {
    axios
      .get(`https://servergenzam.genzamwallet.com/api/v1/bills/${id}`)
      .then((response) => {
        // Set the form data with the fetched bill details
        setBill(response.data);
      })
      .catch((error) => {
        console.log("Error fetching bill details: ", error);
      });
  }, [id, refresh]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/bills/${id}`;
    const debitAmount = +bill.debit;
    if (debitAmount >= pay) {
      const newDebit = debitAmount - pay;
      await UpdateHooks(
        BASE_URL,
        {
          debit: Number(newDebit),
          paymentStatus: newDebit === 0 ? "Complete" : "Due",
          credit: Number(bill?.credit) + Number(pay),
          balance: Number(newDebit),
        },
        true,
        "Debit Updated"
      );
      setPay(0);
      setRefresh(!refresh);
    } else {
      swal("error", "You cannot pay more than total amount", "error");
    }
  };

  return (
    <div>
      <div className="max-w-md mx-auto mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="text-2xl py-4 px-6 bg-gray-900 text-white text-center font-bold uppercase">
          update Customer bills
        </div>
        <form className="py-4 px-6">
          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">
              Customers
            </label>
            <p
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="customers"
            >
              {bill.customerName}
            </p>
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">Debit</label>
            <input
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
              type="text"
              name="debit"
              value={bill?.debit}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-bold mb-2">
              Customer Paying Amount
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              onChange={(e) => setPay(e.target.value)}
              value={pay}
              name="credit"
            />
          </div>

          {/* ... (rest of the form) ... */}

          <div className="flex items-center justify-center mb-4">
            <button
              className="bg-gray-900 text-white py-2 px-4 rounded hover:bg-gray-800 focus:outline-none focus:shadow-outline"
              onClick={handleUpdate}
            >
              Update Bill
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminUpdatePaymentView;
