import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Clock from "../../Component/Clock/Clock";
import AuthUser from "../../Hooks/authUser";
import Swal from "sweetalert2";
import Loading from "../../Shared/Loading/Loading";
import Pagination from "../../Shared/Pagination";
import { Icon } from "@iconify/react";

const UserOrder = () => {
  const { userInfo } = AuthUser();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderSearch, setOrderSearch] = useState("");
  const [ordersFilter, setOrdersFilter] = useState([]);

  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(12);

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/simSale/specific/?fieldName=${"resellerId"}&&fieldValue=${
        userInfo?._id
      }&&size=${size}&&page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setOrders(data?.data);
        setOrdersFilter(data?.data);
        setQuantity(data?.total);
        setLoading(false);
      });
  }, [refresh, userInfo?._id, page, size]);

  const handelDelete = (orderId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://servergenzam.genzamwallet.com/api/v1/simSale/${orderId}`, {
          method: "DELETE",
        }).then((res) => {
          if (res.status === 200) {
            setRefresh(!refresh);
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
          }
        });
      }
    });
  };
  console.log(orders);

  if (loading) {
    return <Loading />;
  }

  const filterOrderData = orders?.filter((f) => {
    return (
      f?.iccidNumber?.toLowerCase().includes(orderSearch?.toLowerCase()) ||
      f?.mobile?.toLowerCase().includes(orderSearch?.toLowerCase()) ||
      f?._id?.toLowerCase().includes(orderSearch?.toLowerCase()) ||
      f?.saleData?.operatorName
        ?.toLowerCase()
        .includes(orderSearch?.toLowerCase())
    );
  });

  console.log(filterOrderData);
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 "> USER DASHBOARD - ORDER LIST</p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary rounded-md text-xs m-5 p-5">
        <div className="flex  w-lg rounded-md  bg-lightPrimary text-xs  shadow-lg my-5 mx-5 p-6 ">
          <div className="w-full ">
            <p>Search SIM</p>
            <div className="mt-5 sm:flex sm:items-center">
              <input
                id="q"
                onChange={(e) => setOrderSearch(e.target.value)}
                className="inline w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-sm"
                placeholder="ICCID Number ,SIM Number,Operator Name etc."
                type="search"
              />
              <button
                onClick={() => setOrdersFilter(filterOrderData)}
                className="mt-3  inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="container   bg-lightPrimary p-5 m-5 rounded-md mx-auto sm:p-4 dark:text-gray-100 ">
          <p className=" text-sm mb-2">
            Order List (Total Results: {ordersFilter.length})
          </p>
          <div className="overflow-x-auto">
            <table className="min-w-full text-xs  ">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24"></col>
              </colgroup>
              <thead className="dark:bg-gray-700">
                <tr className="text-left text-white bg-black">
                  <th className="p-3 border ">#</th>
                  <th className="p-3 border">OrderID </th>
                  <th className="p-3 border">Operator </th>

                  <th className="p-3 border">Offer </th>
                  <th className="p-3 border">ICCID Number </th>
                  <th className="p-3 border "> Order Date </th>
                  <th className="p-3  border"> Order By </th>
                  <th className="p-3 border "> Status </th>
                  <th className="p-3 border "> Action </th>
                </tr>
              </thead>
              <tbody>
                {ordersFilter?.map((order, i) => (
                  <tr
                    key={order?._id}
                    order={order}
                    className="border-b border-opacity-20 dark:border-gray-700 bg-white dark:bg-gray-900 "
                  >
                    <td className="p-3 border">
                      <p>{i + 1}</p>
                    </td>
                    <td className="p-3 border">
                      <p>{order?._id} </p>
                    </td>
                    <td className="p-3 border">
                      <p>{order?.saleData?.operatorName}</p>
                    </td>
                    <td className="p-3 border">
                      <p>{order?.offerta}</p>
                    </td>

                    <td className="p-3 border">
                      <p> {order?.saleData?.iccidNumber} </p>
                    </td>

                    <td className="p-3 border">
                      <p>{new Date(order.createdAt).toLocaleDateString()}</p>
                    </td>

                    <td className="p-3 border">
                      <p> {order?.resellerName} </p>
                    </td>
                    <td className="p-3 border">
                      <p className="bg-yellow-400 p-1 rounded-md w-fit text-white cursor-pointer">
                        {" "}
                        {order?.status}
                      </p>
                    </td>

                    <td className="p-3 border">
                      <div className=" grid grid-cols-3 gap-4 text-xs p-3 ">
                        {/* <div>
                        <p>print</p>
                      </div>
                      <Link to="vieworder">
                        <div>view</div>
                      </Link> */}
                        {order?.status === "pending" && (
                          <div
                            onClick={() => handelDelete(order?._id)}
                            className=" "
                          >
                            <span>
                              {" "}
                              <Icon
                                className="text-xl cursor-pointer text-red-600 "
                                icon="ic:baseline-delete"
                              ></Icon>
                            </span>
                          </div>
                        )}
                        {order?.status === "pending" && (
                          <Link to={`update/${order?._id}`} className="">
                            <Icon
                              className=" text-xl  text-indigo-500  "
                              icon="akar-icons:edit"
                            ></Icon>
                          </Link>
                        )}

                        <div>
                          <Link to={`sim-invoice/${order?._id}`}>
                            <Icon
                              className="cursor-pointer text-xl text-indigo-500"
                              icon="material-symbols:print-outline"
                            />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            quantity={quantity}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>
    </div>
  );
};

export default UserOrder;
