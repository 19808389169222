import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../../Shared/Loading/Loading";
import Clock from "../Clock/Clock";
import Pagination from "../../Shared/Pagination";

const Offers = () => {
  const [operatorOffers, setOperatorOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("");

  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(12);

  useEffect(() => {
    setLoading(true);
    const fetchOperatorOffers = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/operatorOffer?size=${size}&&page=${page}`
        );
        setOperatorOffers(response.data?.operatorOffers);
        setQuantity(response?.data?.total);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching operator offers:", error);
        setLoading(false);
      }
    };

    fetchOperatorOffers();
  }, [page, size]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterCriteria(event.target.value);
  };

  const handleReset = () => {
    setSearchQuery(""); // Clear the search query
    setFilterCriteria(""); // Clear the filter criteria
  };

  if (loading) {
    return <Loading />;
  }

  // Filter the operatorOffers based on searchQuery and filterCriteria
  const filteredOperatorOffers = operatorOffers.filter((offer) => {
    const searchRegex = new RegExp(searchQuery, "i");
    return (
      (offer?.posttype && offer.posttype.match(searchRegex)) ||
      (offer?.operator && offer.operator.match(searchRegex)) ||
      (offer?.offerName && offer.offerName.match(searchRegex)) ||
      (offer?.iban && offer.iban.match(searchRegex)) ||
      (offer?.poststatus && offer.poststatus.match(searchRegex))
    );
  });
  return (
    <div className="min-h-screen">
      <div className="bg-thirdLightPrimary flex justify-between">
        <div>
          <p className="font-semibold p-2">RESELLER DASHBOARD - OFFERS </p>
        </div>
        <div>
          <p className="font-semibold p-2">
            <Clock />
          </p>
        </div>
      </div>
      <div className="bg-thirdLightPrimary m-5 p-5 rounded-md pb-5">
        <div className="flex w-lg  bg-lightPrimary rounded-sm shadow-lg my-5 mx-5 p-6">
          <div className="w-full">
            <p className="text-sm">Search Offer</p>
            <form className="mt-5 sm:flex sm:items-center">
              <input
                type="text"
                name="search"
                value={searchQuery}
                onChange={handleSearchChange}
                className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1 sm:text-sm"
                placeholder="Operator, Offer Name, etc."
                autoFocus
              />
              <button
                type="submit"
                className="mt-3 inline-flex text-xs w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default form submission behavior
                  handleReset(); // Call the handleReset function when the button is clicked
                }}
              >
                Reset
              </button>
            </form>
          </div>
        </div>
        <div className="relative  bg-lightPrimary p-5 overflow-x-auto shadow-md sm:rounded-lg m-5">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-black dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Offer Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Operator
                </th>
                <th scope="col" className="px-6 py-3">
                  Offer Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  IBAN
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Mapping filteredOperatorOffers state to display each offer */}
              {filteredOperatorOffers?.map((offer, index) => (
                <tr
                  key={offer?._id}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50 dark:bg-gray-600"
                  } border-b dark:border-gray-800 hover:bg-gray-50 text-xs dark:hover:bg-gray-600`}
                >
                  <td className="px-6 py-4">{index + 1}</td>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {offer?.posttype}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {offer?.operator}
                  </th>
                  <td className="px-6 py-4">{offer?.offerName}</td>

                  <td className="px-6 py-4">{offer?.defaultPrice}</td>
                  <td className="px-6 py-4">{offer?.iban}</td>
                  <td className="px-6 py-4">{offer?.poststatus}</td>
                  <td className="flex items-center px-6 py-4 space-x-3">
                    {/* Link to view specific offer on another page */}
                    <Link
                      to={`viewoffer/${offer?._id}`} // Navigate to the 'viewoffer' page with the specific offer's ID
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination
            quantity={quantity}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>
    </div>
  );
};

export default Offers;
