import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const AdminScrollingText = () => {
  const [formData, setFormData] = useState({
    title: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://servergenzam.genzamwallet.com/api/v1/scrollingTextRoutes/texts",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Successful form submission, do something (e.g., show a success message)
        console.log("Form submitted successfully!");
        // Reset the form after successful submission
        setFormData({
          title: "",
        });
      } else {
        // Error in form submission, handle the error (e.g., show an error message)
        console.error("Form submission failed.");
      }
    } catch (error) {
      console.error("Error in form submission:", error);
    }
  };

  return (
    <div>
      <div className=" text-left m-5 text-green-600 p-5 justify-between flex">
        <h1 className=" text-2xl font-extrabold ">Manage Scrolling Text</h1>
        <Link to="/adminDashboard/adminscrollingtextmanage">
          {" "}
          <Icon className=" text-3xl" icon="ic:outline-manage-accounts"></Icon>
        </Link>
      </div>

      <div className="bg-white border border-4 rounded-lg shadow relative m-10">
        <div className="flex items-start justify-between p-5 border-b rounded-t">
          <h3 className="text-xl font-semibold">Post Scrolling Text</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="product-modal"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div className="p-6 space-y-6">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-6 gap-6 mb-5">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="product-name"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Text
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={handleChange}
                  name="title"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Your Notification Title"
                  required
                />
              </div>
              {/* Add other input fields for Category, Brand, Price, and Product Details */}
            </div>
            {/* <div className="col-span-full">
              <label
                htmlFor="product-details"
                className="text-sm font-medium text-gray-900 block mb-2"
              >
               Content
              </label>
              <textarea
              name="content"
              value={formData.content}
              onChange={handleChange}
                rows="6"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                placeholder="Enter Your Content"
              ></textarea>
            </div> */}
            <div className="p-6 border-t border-gray-200 rounded-b">
              <button
                className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                type="submit"
              >
                Post
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminScrollingText;
