import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AdminWifiOfferPostManage = () => {
  const [operatorOffers, setOperatorOffers] = useState([]);

  useEffect(() => {
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/wifiOffer")
      .then((response) => {
        setOperatorOffers(response.data.wifiOffers);
        // console.log(response.data);
      })
      .catch((error) => {
        console.log("Response Data is ", error);
      });
  }, []);

  const handleDeleteOffer = (offerId) => {
    // Send a DELETE request to the server to delete the offer with the given offerId
    axios
      .delete(`https://servergenzam.genzamwallet.com/api/v1/wifiOffer/${offerId}`)
      .then((response) => {
        // If the deletion was successful, remove the offer from the state
        setOperatorOffers((prevOffers) =>
          prevOffers.filter((offer) => offer._id !== offerId)
        );
      })
      .catch((error) => {
        console.log("Error deleting offer: ", error);
      });
  };

  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-4xl flex justify-center  gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="material-symbols:manage-history-rounded"></Icon>
            Manage Wifi Offer
          </h1>
        </div>
      </div>

      <div className="overflow-x-auto m-5">
        <table className="min-w-full text-xs bg-gray-100">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="">
            <tr className="text-center">
              <th className="p-3">#</th>

              <th className="p-3">Wifi Operator Name</th>
              <th className="p-3">Wifi Offer Name</th>
              <th className="p-3">Wifi Offer Details</th>

              <th className="p-3">Status</th>
              <th className="p-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {operatorOffers?.reverse()?.map((offer, index) => (
              <tr
                key={offer?._id}
                className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
              >
                <td className="p-3">
                  <p>{index + 1}</p>
                </td>
                <td className="p-3 text-center">
                  <p>{offer?.WifiOperatorName}</p>
                </td>
                <td className="p-3 text-center">
                  <p>{offer?.wifiOfferName}</p>
                </td>
                <td className="p-3 text-center">
                  <p>{offer?.wifiOfferDetails}</p>
                </td>

                <td className="p-3 text-center">
                  <p className="dark:text-gray-400">{offer?.wifiOfferStatus}</p>
                </td>
                <td className="p-3 text-xl items-center justify-center flex">
                  <p className="flex gap-x-3 text-primary">
                    <Link to={`/adminDashboard/adimwifiofferedit/${offer._id}`}>
                      <Icon
                        icon="basil:edit-solid"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                    <Icon
                      icon="ant-design:delete-filled"
                      onClick={() => handleDeleteOffer(offer._id)}
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminWifiOfferPostManage;
