import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Clock from "../Clock/Clock";
import AuthUser from "../../Hooks/authUser";
import Loading from "../../Shared/Loading/Loading";
import Pagination from "../../Shared/Pagination";

const Bills = () => {
  const { userInfo } = AuthUser();
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(false);

  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);
  useEffect(() => {
    setLoading(true);
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/bills/specific/bill?fieldName=${"customerName"}&&fieldValue=${
        userInfo?.email
      }&&size=${size}&&page=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setBills(data?.data);
        setQuantity(data?.total);
        setLoading(false);
      });
  }, [userInfo?.email, page, size]);

  if (loading) {
    return <Loading />;
  }
  const values = bills?.map((bill) => bill?.debit);
  const totalDue = values?.reduce((acc, cur) => acc + cur, 0);
  return (
    <div className="min-h-screen ">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">RESELLER DASHBOARD - BILLS </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" grid grid-cols-1 gap-5  bg-thirdLightPrimary p-2 m-2 rounded-md max-w-xs mx-auto">
        <Link to="billshistory">
          {" "}
          <div>
            <button className=" bg-secondary  p-2 rounded-md  w-full text-white ">
              {" "}
              Bill History
            </button>
          </div>
        </Link>

        <Link to="paymenthistory">
          <div>
            <button className="  bg-secondary p-2 rounded-md  w-full text-white">
              {" "}
              Payment History
            </button>
          </div>
        </Link>
        <Link to="transactionhistory">
          <div>
            <button className="  p-2 rounded-md w-full  bg-red-500  text-white">
              {" "}
              Transaction History
            </button>
          </div>
        </Link>
      </div>

      {/* table */}
      <div className=" p-5 bg-thirdLightPrimary m-5 rounded-md">
        <div className="container  mx-auto sm:p-4  bg-lightPrimary  rounded-md dark:text-gray-100 ">
          <p className="mb-4  font-semibold leadi text-xs">
            {" "}
            Latest Transactions
          </p>
          <div className="overflow-x-auto">
            <table className="min-w-full text-xs">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24"></col>
              </colgroup>
              <thead className="bg-black text-white">
                <tr className="text-left">
                  <th className="p-3 border px-2 py-1">#</th>
                  <th className="p-3 border px-2 py-1">Code</th>
                  <th className="p-3 border px-2 py-1">Date</th>
                  <th className="p-3 border px-2 py-1">Details</th>
                  <th className="p-3 border px-2 py-1">Debit</th>
                  <th className="p-3 border px-2 py-1">Credit</th>
                  <th className="p-3 border px-2 py-1">Balance</th>
                  <th className="p-3 border px-2 py-1">Status</th>
                </tr>
              </thead>
              <tbody>
                {bills?.map((bill, i) => (
                  <tr
                    key={bill?._id}
                    bill={bill}
                    className="border-b border-opacity-20 dark:border-gray-700 bg-white"
                  >
                    <td className="p-3 border px-2 py-1">
                      <p>{i + 1}</p>
                    </td>
                    <td className="p-3 border px-2 py-1">
                      <p>{bill?._id} </p>
                    </td>
                    <td className="p-3 border px-2 py-1">
                      <p>{new Date(bill?.date).toLocaleDateString()} </p>
                    </td>

                    <td className="p-3 ">
                      <p>{bill?.billDetails}</p>
                    </td>
                    <td className="p-3 border px-2 py-1">
                      <p>{bill?.debit}</p>
                    </td>
                    <td className="p-3 border px-2 py-1">
                      <p>{bill?.credit}</p>
                    </td>
                    <td className="p-3 border px-2 py-1">
                      <p>{bill?.balance}</p>
                    </td>
                    <td className="p-3 border px-2 py-1">
                      <p>{bill?.paymentStatus}</p>
                    </td>
                  </tr>
                ))}
                <tr className="border bg-white px-2 py-1 text-center">
                  <td className="border px-2 py-1 text-center"> </td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"> </td>
                  <td className="border px-2 py-1 text-center"> </td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-2 py-1 text-center"></td>
                  <td className="border  px-4 py-2 text-center font-semibold text-red-600">
                    Total Due =
                  </td>
                  <td className="border px-4 py-2 text-center text-red-600 font-semibold">
                    {totalDue}
                  </td>
                </tr>

                {/* Add more rows as needed */}
              </tbody>
            </table>

            <Pagination
              quantity={quantity}
              page={page}
              setPage={setPage}
              size={size}
              setSize={setSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bills;
