import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Clock from "../Clock/Clock";
import { Icon } from "@iconify/react";
import Loading from "../../Shared/Loading/Loading";
import AuthUser from "../../Hooks/authUser";
import Pagination from "../../Shared/Pagination";

const Sold = () => {
  const [sims, setSims] = useState([]);
  const [simSearch, setSimSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { userInfo } = AuthUser();

  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);
  //sim
  const url = `https://servergenzam.genzamwallet.com/api/v1/allsim/specific/?fieldName=${"resellerEmail"}&&fieldValue=${
    userInfo?.email
  }&&size=${size}&&page=${page}`;
  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setSims(data?.data);
        setQuantity(data?.total);
        setLoading(false);
      });
  }, [url]);
  if (loading) {
    return <Loading />;
  }

  const filterStock = sims?.filter((f) => f?.status === "notAvailable");

  const filterOrderData = filterStock?.filter((f) => {
    return (
      f?.iccidNumber?.toLowerCase().includes(simSearch?.toLowerCase()) ||
      f?.simNumber?.toLowerCase().includes(simSearch?.toLowerCase()) ||
      f?.saleData?.operatorName
        ?.toLowerCase()
        .includes(simSearch?.toLowerCase())
    );
  });
  return (
    <div className=" min-h-screen">
      <div className="  ">
        <div className=" bg-thirdLightPrimary  flex   justify-between">
          <div>
            <p className="    font-semibold p-2 ">
              RESELLER DASHBOARD - SOLD OUT{" "}
            </p>
          </div>
          <div>
            {" "}
            <p className=" font-semibold p-2 ">
              <Clock></Clock>
            </p>
          </div>
        </div>

        <div className="  bg-thirdLightPrimary m-5 p-5 rounded-md  ">
          <div className="flex  w-lg bg-lightPrimary  text-xs rounded-sm shadow-lg my-5 mx-5 p-6 ">
            <div className="w-full ">
              <p>Search SIM</p>
              <div className="mt-5 sm:flex sm:items-center">
                <input
                  id="q"
                  onChange={(e) => setSimSearch(e.target.value)}
                  value={simSearch}
                  className="inline w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-sm"
                  placeholder="ICCID Number ,SIM Number,Operator Name etc."
                  type="search"
                />
                <button
                  onClick={() => setSimSearch("")}
                  className="mt-3  inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div class="relative overflow-x-auto sm:rounded-sm m-5 p-5 bg-lightPrimary">
            <div className=" p-2  ">
              <p> SIM List (Total Results: {filterOrderData?.length})</p>
            </div>
            <table class="w-full text-sm text-left   text-black dark:text-gray-400">
              <thead class="text-xs text-black font-semibold uppercase  bg-black text-white">
                <tr>
                  <th scope="col" class="px-6 py-3 border">
                    #
                  </th>

                  <th scope="col" class="px-6 py-3 border">
                    Operator
                  </th>
                  <th scope="col" class="px-6 py-3 border">
                    User
                  </th>
                  <th scope="col" class="px-6 py-3 border">
                    ICCID Number
                  </th>
                  <th scope="col" class="px-6 py-3 border">
                    Sim Number
                  </th>
                  <th scope="col" class="px-6 py-3 border">
                    Buy Date
                  </th>
                  <th scope="col" class="px-6 py-3 border">
                    Buy Price
                  </th>
                  <th scope="col" class="px-6 py-3 border">
                    Status
                  </th>

                  <th scope="col" class="px-6 py-3 border">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filterOrderData?.reverse().map((sim, i) => (
                  <tr
                    key={sim?._id}
                    sim={sim}
                    class="border-b bg-white dark:border-gray-700 dark:hover:bg-gray-600"
                  >
                    <td class="px-6 py-4 border">
                      {filterOrderData.length - i}
                    </td>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white border"
                    >
                      {sim?.operatorName}
                    </th>
                    <td class="px-6 py-4 border">
                      {sim?.userName ? sim?.userName : userInfo?.name}
                    </td>
                    <td class="px-6 py-4 border">{sim?.iccidNumber}</td>
                    <td class="px-6 py-4 border">{sim?.simNumber}</td>
                    <td class="px-6 py-4 border">
                      {" "}
                      {new Date(sim?.buyDate).toLocaleDateString()}
                    </td>
                    <td class="px-6 py-4 border">{sim?.buyPrice}</td>
                    <td class="px-6 py-4 border">
                      {sim?.status === "available" ? "Available" : "Actived"}
                    </td>

                    <td class="flex items-center justify-center px-6 py-4 space-x-3">
                      {/* <Link
          to={`${sim?._id}`}
          class="text-blue-600 text-center dark:text-blue-500 hover:underline"
        >
          <Icon className="" icon="fa-solid:edit"></Icon>
        </Link> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Pagination
              quantity={quantity}
              page={page}
              setPage={setPage}
              size={size}
              setSize={setSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sold;
