import ResellerHome from "../Component/Reseller/ResellerHome";
import ResellerDashbord from "../Pages/ResellerDashBord/ResellerDashbord";

const PrivateRoute = [

  { path: "reseller", Component: ResellerHome },
  { path: "/resellerdashbord", Component: ResellerDashbord },

];

export default PrivateRoute;
