import React from "react";
import { Link } from "react-router-dom";
import Clock from "../Clock/Clock";

const Operators = () => {
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - OPERATOR
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary m-5 p-3 rounded-md">
        <div class="relative overflow-x-auto  bg-lightPrimary p-5 shadow-md sm:rounded-lg m-5">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs  uppercase bg-black text-white dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3 text-xs">
                  Operator Name
                </th>

                <th scope="col" class="px-6 py-3 text-xs ">
                  Default Price
                </th>
                <th scope="col" class="px-6 py-3 text-xs">
                  Wifi Service
                </th>
                <th scope="col" class="px-6 py-3 text-xs">
                  Status
                </th>

                <th scope="col" class="px-6 py-3 text-xs">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 text-xs dark:hover:bg-gray-600">
                <td class="px-6 py-4">Coop Voce</td>
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  €10.00
                </th>
                <td class="px-6 py-4"> No </td>

                <td class="px-6 py-4">Enable </td>

                <td class="flex items-center px-6 py-4 space-x-3">
                  <p></p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Operators;
