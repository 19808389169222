import React, { useState, useCallback } from "react";
import Clock from "../Clock/Clock";
import AuthUser from "../../Hooks/authUser";
import { toast } from "react-toastify";
import UpdateHooks from "../../Hooks/UpdateHooks";
import { server_url } from "../../Config/API";

const ChangePassword = () => {
  const { userInfo } = AuthUser();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");

  const handleChangePassword = async (e) => {
    e.preventDefault();
    console.log(newPassword, retypeNewPassword);
    if (newPassword !== retypeNewPassword) {
      toast.error("New passwords do not match.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    if (!userInfo || !userInfo._id) {
      toast.error("User Not Found", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const BASE_URL = `${server_url}/user/change-password/${userInfo?._id}`;
    await UpdateHooks(
      BASE_URL,
      {
        email: userInfo?.email,
        newPassword: newPassword,
        oldPassword: oldPassword,
      },
      true,
      "Password Changed"
    );

    e.target.reset();
  };

  return (
    <form onSubmit={handleChangePassword} className="min-h-screen">
      <div className="bg-thirdLightPrimary flex justify-between">
        <div>
          <p className="font-semibold p-2">
            RESELLER DASHBOARD - CHANGE PASSWORD
          </p>
        </div>
        <div>
          <p className="font-semibold p-2">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className="bg-thirdLightPrimary m-5 p-5 rounded-md">
        <div className="overflow-x-auto">
          <table className="min-w-full text-xs bg-gray-50 rounded-md">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col className="" />
            </colgroup>
            <tbody>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3">
                  <p className="">Current Password</p>
                </td>
                <td className="p-3">
                  <input
                    type="text"
                    className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1 sm:text-xs"
                    placeholder="Enter Your Current Password"
                    required
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3">
                  <p className="">New Password</p>
                </td>
                <td className="p-3">
                  <input
                    type="text"
                    className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1 sm:text-xs"
                    placeholder="Enter Your New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3">
                  <p className="">Re-Type New Password</p>
                </td>
                <td className="p-3">
                  <input
                    type="text"
                    className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1 sm:text-xs"
                    placeholder="Enter Re-Type New Password"
                    value={retypeNewPassword}
                    required
                    onChange={(e) => setRetypeNewPassword(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="p-5">
        <button className="bg-primary p-3 rounded-md" type="submit">
          Change Password
        </button>
      </div>
    </form>
  );
};

export default ChangePassword;
