import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

const ViewContactDetails = () => {
  const { id } = useParams(); // Use useParams to get the id from the URL

  const [info, setInfo] = useState(); // Destructure the useState

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/contactForm/${id}`
        );

        // Populate the form with the fetched data
        setInfo(response.data);
      } catch (error) {
        alert(
          "An error occurred while fetching contact form data. Please try again later."
        );
      }
    };

    fetchInfo();
  }, [id]);

  // console.log(id);
  return (
    <div>
      <div class="bg-white m-5 overflow-hidden shadow rounded-lg border">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Form Submission
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Genzam-IT</p>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Nome</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.nome}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Cognome</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.cognome}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Telefono</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.telefono}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Email</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.email}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Ragione</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.ragione}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">P.Iva</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.piva}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">P.Iva</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.piva}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Indirizzo e Civico
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.indirizzoeCivico}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Cap</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.cap}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                Comune del Punto Vendita
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.comuneDelPuntoVendita}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Provincia</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.provincia}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Message</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.message}
              </dd>
            </div>
            <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">
                CUSTOMER OTHER OPERATOR
              </dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {info?.customerOtherOperator}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default ViewContactDetails;
