import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Modal.css";

const ContactList = () => {
  const [contactInfo, setContactInfo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState("");

  useEffect(() => {
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/contactForm")
      .then((response) => {
        setContactInfo(response.data);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  }, []);

  const openModal = (offerId) => {
    setSelectedOfferId(offerId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteOffer = (offerId) => {
    openModal(offerId); // Open the confirmation modal
  };

  const handleConfirmDelete = () => {
    axios
      .delete(`https://servergenzam.genzamwallet.com/api/v1/contactForm/${selectedOfferId}`)
      .then((response) => {
        setContactInfo((prevContactInfo) =>
          prevContactInfo.filter((info) => info._id !== selectedOfferId)
        );
        closeModal(); // Close the confirmation modal
      })
      .catch((error) => {
        console.log("Error deleting offer:", error);
      });
  };

  return (
    <div>
      <div className="max-w-3xl mx-auto text-center mt-5">
        <h1 className="text-4xl flex justify-center gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          <Icon icon="material-symbols:manage-history-rounded" />
          Manage Contact List
        </h1>
      </div>
      <div className="overflow-x-auto m-5">
        <table className="min-w-full text-xs bg-gray-100">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className=" text-white bg-black">
            <tr className="text-center">
              <th className="p-3 border">#</th>
              <th className="p-3 border">Name</th>
              <th className="p-3 border">Email</th>
              <th className="p-3 border">Reason</th>
              <th className="p-3 border">Piva</th>
              <th className="p-3 border">Province</th>
              <th className="p-3 border">Phone</th>
              <th className="p-3 border">Action</th>
            </tr>
          </thead>
          <tbody>
            {contactInfo?.reverse()?.map((info, index) => (
              <tr
                key={info?._id}
                className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
              >
                <td className="p-3 border">
                  <p>{index + 1}</p>
                </td>
                <td className="p-3 text-center border">
                  <p>{info?.nome}</p>
                </td>
                <td className="p-3 text-center border">
                  <p>{info?.email}</p>
                </td>
                <td className="p-3 text-center border">
                  <p>{info?.ragione}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">{info?.piva}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">{info?.provincia}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">{info?.telefono}</p>
                </td>
                <td className="p-3 text-xl items-center justify-center flex">
                  <p className="flex gap-x-3 text-primary">
                    <Link to={`/adminDashboard/contactview/${info._id}`}>
                      <Icon
                        icon="carbon:view-filled"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                    <Icon
                      icon="ant-design:delete-filled"
                      onClick={() => handleDeleteOffer(info._id)}
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div
        className="modal-overlay"
        style={{ display: isModalOpen ? "flex" : "none" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title">Confirm Deletion</h2>
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete this notification?</p>
          </div>
          <div className="modal-footer">
            <button className="cancel" onClick={closeModal}>
              Cancel
            </button>
            <button className="confirm" onClick={handleConfirmDelete}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactList;
