import React from "react";
import { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    nome: "",
    cognome: "",
    email: "",
    ragione: "",
    piva: "",
    indirizzoeCivico: "",
    cap: "",
    comuneDelPuntoVendita: "",
    provincia: "",
    telefono: "",
    massage: "",
    customerOtherOperator: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://servergenzam.genzamwallet.com/api/v1/contactForm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Successful form submission, do something (e.g., show a success message)
        console.log("Form submitted successfully!");
        // Reset the form after successful submission
        setFormData({
          nome: "",
          cognome: "",
          email: "",
          ragione: "",
          piva: "",
          indirizzoeCivico: "",
          cap: "",
          comuneDelPuntoVendita: "",
          provincia: "",
          telefono: "",
          massage: "",
          customerOtherOperator: "",
        });
      } else {
        // Error in form submission, handle the error (e.g., show an error message)
        console.error("Form submission failed.");
      }
    } catch (error) {
      console.error("Error in form submission:", error);
    }
  };

  return (
    <div>
      <div class="container my-12 mx-auto px-2 md:px-4">
        <section class="mb-32">
          <div class="flex justify-center">
            <div class="text-center md:max-w-xl lg:max-w-3xl">
              <h2 class="mb-12 px-6 text-3xl font-bold">Contact us</h2>
            </div>
          </div>

          <div class="flex flex-wrap">
            <form
              onSubmit={handleSubmit}
              class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 lg:mb-0 lg:w-5/12 lg:px-6"
            >
              <div className=" grid gap-x-5 grid-cols-2 ">
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Nome
                  </label>
                  <input
                    type="text"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    placeholder="Enter Your Nome"
                  />
                </div>
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Cognome
                  </label>
                  <input
                    type="text"
                    name="cognome"
                    value={formData.cognome}
                    onChange={handleChange}
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder=" Enter Your Cognome"
                  />
                </div>
              </div>

              <div className=" grid grid-cols-2 gap-x-5">
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Ragione
                  </label>
                  <input
                    value={formData.ragione}
                    onChange={handleChange}
                    type="text"
                    name="ragione"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder="enter your Ragione "
                  />
                </div>

                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    P.Iva
                  </label>
                  <input
                    value={formData.piva}
                    onChange={handleChange}
                    type="text"
                    name="piva"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder="Enter Your P.Iva"
                  />
                </div>
              </div>

              <div className=" grid grid-cols-2 gap-x-5">
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Indirizzo e Civico
                  </label>
                  <input
                    value={formData.indirizzoeCivico}
                    onChange={handleChange}
                    name="indirizzoeCivico"
                    type="text"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder="Enter Your Indirizzo E Civico"
                  />
                </div>

                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Cap
                  </label>
                  <input
                    type="text"
                    value={formData.cap}
                    onChange={handleChange}
                    name="cap"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder="Enter Your Cap"
                  />
                </div>
              </div>

              <div className=" grid grid-cols-2 gap-x-5">
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Comune del Punto Vendita
                  </label>
                  <input
                    value={formData.comuneDelPuntoVendita}
                    onChange={handleChange}
                    type="text"
                    name="comuneDelPuntoVendita"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder="Enter Your Comune del Punto Vendita "
                  />
                </div>

                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Provincia
                  </label>
                  <input
                    value={formData.provincia}
                    onChange={handleChange}
                    type="text"
                    name="provincia"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder="Enter Your Provincia "
                  />
                </div>
              </div>

              <div className=" grid grid-cols-2 gap-x-5">
                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Email
                  </label>
                  <input
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    name="email"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder="Enter your email address"
                  />
                </div>

                <div class="mb-3 w-full">
                  <label
                    class="block font-medium mb-[2px] text-teal-700"
                    htmlFor="exampleInput90"
                  >
                    Telefono
                  </label>
                  <input
                    value={formData.telefono}
                    onChange={handleChange}
                    type="number"
                    name="telefono"
                    class="px-2 py-2 border w-full outline-none rounded-md"
                    id="exampleInput90"
                    placeholder="Enter your email address"
                  />
                </div>
              </div>

              <div class="mb-3 w-full">
                <label
                  class="block font-medium mb-[2px] text-teal-700"
                  htmlFor="exampleInput90"
                >
                  Message
                </label>
                <textarea
                  type="text"
                  class="px-2 py-2 border rounded-[5px] w-full outline-none"
                  name="massage"
                  value={formData.massage}
                  onChange={handleChange}
                  id=""
                ></textarea>
              </div>

              <div className="mb-3 w-full">
                <label
                  className="block font-medium mb-[2px] text-teal-700"
                  htmlFor="exampleInputEmail"
                >
                  BECOME A MEMBER
                </label>
                <select
                  value={formData.customerOtherOperator}
                  onChange={handleChange}
                  className="px-2 py-2 border w-full outline-none rounded-md"
                  id="exampleInputEmail"
                  name="customerOtherOperator"
                >
                  <option value="">Select</option>
                  <option value="Rivenditore Windtre">Normal User</option>
                  <option value="Rivenditore Very Mobile"> Reseller</option>

                  {/* Add more options as needed */}
                </select>
              </div>

              <button
                type="submit"
                class="mb-6 inline-block w-full rounded bg-teal-400 px-6 py-2.5 font-medium uppercase leading-normal text-white hover:shadow-md hover:bg-teal-500"
              >
                Send
              </button>
            </form>

            <div class="w-full shrink-0 grow-0 basis-auto lg:w-7/12">
              <div class="flex flex-wrap">
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                  <div class="flex items-start">
                    <div class="shrink-0">
                      <div class="inline-block rounded-md bg-teal-400-100 p-4 text-teal-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          class="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-6 grow">
                      <p class="mb-2 font-bold">Technical support</p>
                      <p class="text-neutral-500 ">genzamofficial@gmail.com</p>
                      <p class="text-neutral-500 ">+39 351178 9888</p>
                    </div>
                  </div>
                </div>
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                  <div class="flex items-start">
                    <div class="shrink-0">
                      <div class="inline-block rounded-md bg-teal-400-100 p-4 text-teal-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          class="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-6 grow">
                      <p class="mb-2 font-bold ">Sales questions</p>
                      <p class="text-neutral-500 ">genzamsales@gamilcom.com</p>
                      <p class="text-neutral-500 ">+39 351178 9888</p>
                    </div>
                  </div>
                </div>
                {/* <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                  <div class="align-start flex">
                    <div class="shrink-0">
                      <div class="inline-block rounded-md bg-teal-400-100 p-4 text-teal-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          class="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-6 grow">
                      <p class="mb-2 font-bold ">Press</p>
                      <p class="text-neutral-500 ">press@example.com</p>
                      <p class="text-neutral-500 ">+1 234-567-89</p>
                    </div>
                  </div>
                </div> */}
                {/* <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:w-6/12 md:px-3 lg:px-6">
                  <div class="align-start flex">
                    <div class="shrink-0">
                      <div class="inline-block rounded-md bg-teal-400-100 p-4 text-teal-700">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          class="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 12.75c1.148 0 2.278.08 3.383.237 1.037.146 1.866.966 1.866 2.013 0 3.728-2.35 6.75-5.25 6.75S6.75 18.728 6.75 15c0-1.046.83-1.867 1.866-2.013A24.204 24.204 0 0112 12.75zm0 0c2.883 0 5.647.508 8.207 1.44a23.91 23.91 0 01-1.152 6.06M12 12.75c-2.883 0-5.647.508-8.208 1.44.125 2.104.52 4.136 1.153 6.06M12 12.75a2.25 2.25 0 002.248-2.354M12 12.75a2.25 2.25 0 01-2.248-2.354M12 8.25c.995 0 1.971-.08 2.922-.236.403-.066.74-.358.795-.762a3.778 3.778 0 00-.399-2.25M12 8.25c-.995 0-1.97-.08-2.922-.236-.402-.066-.74-.358-.795-.762a3.734 3.734 0 01.4-2.253M12 8.25a2.25 2.25 0 00-2.248 2.146M12 8.25a2.25 2.25 0 012.248 2.146M8.683 5a6.032 6.032 0 01-1.155-1.002c.07-.63.27-1.222.574-1.747m.581 2.749A3.75 3.75 0 0115.318 5m0 0c.427-.283.815-.62 1.155-.999a4.471 4.471 0 00-.575-1.752M4.921 6a24.048 24.048 0 00-.392 3.314c1.668.546 3.416.914 5.223 1.082M19.08 6c.205 1.08.337 2.187.392 3.314a23.882 23.882 0 01-5.223 1.082"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="ml-6 grow">
                      <p class="mb-2 font-bold">Bug report</p>
                      <p class="text-neutral-500 ">bugs@example.com</p>
                      <p class="text-neutral-500">+1 234-567-89</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
