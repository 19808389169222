import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../Shared/Loading/Loading";
import saveAs from "file-saver";
import { Icon } from "@iconify/react";
import UpdateHooks from "../../Hooks/UpdateHooks";

const AdminSimSalesView = () => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState("");
  const { id } = useParams();
  const [sim, setSim] = useState({});
  useEffect(() => {
    setLoading(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/simSale/getSimSaleById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSim(data);
        setLoading(false);
      });
  }, [id, refresh]);
  console.log(sim);

  if (loading) {
    return <Loading />;
  }
  console.log(sim?.simId);
  // console.log(sim);
  const handelUpdateStatus = async () => {
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/simSale/${id}`;
    if (status) {
      await UpdateHooks(BASE_URL, { status }, true, "Status Updated");
    }
    if (status === "rejected") {
      const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/allsim/${sim?.simId}`;
      console.log("enter");
      await fetch(BASE_URL, {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          status: "available",
        }),
      })
        .then((res) => res.json())

        .catch((err) => {
          console.log("err", err);
        });
    }
    setRefresh(!refresh);
  };

  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  ">
        <p className="  font-semibold p-2 "> VIEW ORDER</p>
      </div>

      <div className=" bg-thirdLightPrimary m-5 rounded-md text-xs">
        <div className=" grid grid-cols-2 p-5  gap-2">
          {/* first Div */}
          <div className=" border ">
            <p className=" text-xs pb-2 p-2  ">SIM Details</p>

            <table className="min-w-full text-xs">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead className="dark:bg-gray-700 bg-gray-300">
                <tr className="text-left">
                  <th className="p-3 text-xs" colSpan="2">
                    SIM Details
                  </th>
                </tr>
              </thead>
              <tbody className="bg-gray-200">
                <tr className="border border-opacity-20 bg-gray-100">
                  <td className="p-3">Operator</td>
                  <td className="p-3 bg-gray-50">
                    {sim?.saleData?.operatorName}
                  </td>
                </tr>
                <tr className="border border-opacity-20 bg-gray-100">
                  <td className="p-3">Offer</td>
                  <td className="p-3 bg-gray-50">{sim?.offerta}</td>
                </tr>
                <tr className="border border-opacity-20 bg-gray-100">
                  <td className="p-3">ICCID Number</td>
                  <td className="p-3 bg-gray-50">
                    {sim?.saleData?.iccidNumber}
                  </td>
                </tr>
                <tr className="border border-opacity-20 bg-gray-100">
                  <td className="p-3">Mobile Number</td>
                  <td className="p-3 bg-gray-50">{sim?.saleData?.simNumber}</td>
                </tr>
                <tr className="border border-opacity-20 bg-gray-100">
                  <td className="p-3">Buy Price</td>
                  <td className="p-3 bg-gray-50">{sim?.saleData?.buyPrice}</td>
                </tr>
                <tr className="border border-opacity-20 bg-gray-100">
                  <td className="p-3">SIM Status</td>
                  <td className="p-3 bg-gray-50">{sim?.status}</td>
                </tr>
                <tr className="border border-opacity-20 bg-gray-100">
                  <td className="p-3">Others Information</td>
                  <td className="p-3 bg-gray-50">
                    {sim?.saleData?.othersInformation}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className=" border ">
              <p className=" text-xs pb-2 p-2  ">Order Details</p>
              <table className="min-w-full text-xs  ">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col className="  " />
                </colgroup>
                <thead className="dark:bg-gray-700 bg-gray-300 ">
                  <tr className="text-left grid grid-cols-2    ">
                    <th className="p-3 text-xs ">Order Details</th>
                  </tr>
                </thead>
                <tbody className=" bg-gray-200">
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Entry by </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.resellerName}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Role </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.role}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Entry Time </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.createdAt}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Completed Date </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.DateOfIssueOfTheDocument}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Status </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.status}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {(sim?.status === "pending" || sim?.status === "onProcess") && (
              <div className=" p-5 text-xs">
                <div>
                  <p className=" pb-2">Order Status</p>
                  <select
                    type="text"
                    onChange={(e) => setStatus(e.target.value)}
                    id="first_name"
                    className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Select"
                    required
                  >
                    {" "}
                    <option selected disabled>
                      Please Chose one
                    </option>
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                    <option value="rejected">Rejected</option>
                    <option value="onProcess">On Process</option>
                  </select>
                </div>
              </div>
            )}
          </div>

          {/* second div */}
          <div>
            <div className=" border ">
              <p className=" text-xs pb-2 p-2  ">Sale Details</p>
              <table className="min-w-full text-xs  ">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col className="  " />
                </colgroup>
                <thead className="dark:bg-gray-700 bg-gray-300 ">
                  <tr className="text-left grid grid-cols-2    ">
                    <th className="p-3 text-xs ">Sale Details</th>
                  </tr>
                </thead>
                <tbody className=" bg-gray-200">
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Operator</p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.saleData?.operatorName}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Offer</p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.offerta}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">First Name </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.firstName}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Sure Name </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.surName}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Place of issue</p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.placeOfIssue}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Location </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.location}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">ICCID </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p> {sim?.iccidNumber}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Mobile </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.mobile}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Telephono </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.telephono}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Prov </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.prov}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Tax ID Code </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.taxIdCode}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Type </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.type}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Verchio Numero Cellulare </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.verchioNumeroCellulare}</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Vechio Scheda Iccid Numero </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.vechioSchedaIccdNumero}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Address </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.residentInStreetNumberCivicInterrior}</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Cap </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.cap}</p>
                    </td>
                  </tr>
                  {/* <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Carica Originale E Fotocopia </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.caricaOriginaleEFotocopia}</p>
                    </td>
                  </tr> */}
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Nationality </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>{sim?.nationality}</p>
                    </td>
                  </tr>
                  {/* <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Sale Price </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p></p>
                    </td>
                  </tr> */}
                </tbody>
              </table>

              <div className=" border ">
                <p className=" text-xs pb-2 p-2  ">MNP - Number Portability</p>
                <table className="min-w-full text-xs  ">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col className="  " />
                  </colgroup>
                  <thead className="dark:bg-gray-700 bg-gray-300 ">
                    <tr className="text-left grid grid-cols-2    ">
                      <th className="p-3 text-xs ">MNP - Number Portability</th>
                    </tr>
                  </thead>
                  {sim?.operators && (
                    <tbody className=" bg-gray-200">
                      <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                        <td className="p-3 ">
                          <p className=" ">Operator </p>
                        </td>
                        <td className="p-3 bg-gray-50 ">
                          <p>{sim?.operators} </p>
                        </td>
                      </tr>

                      <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                        <td className="p-3 ">
                          <p className=" ">ICCID Number </p>
                        </td>
                        <td className="p-3 bg-gray-50 ">
                          <p>{sim?.vechioSchedaIccdNumero} </p>
                        </td>
                      </tr>

                      <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                        <td className="p-3 ">
                          <p className=" ">Mobile Number </p>
                        </td>
                        <td className="p-3 bg-gray-50 ">
                          <p>{sim?.verchioNumeroCellulare}</p>
                        </td>
                      </tr>

                      <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                        <td className="p-3 ">
                          <p className=" ">Foto della vecchia SIM </p>
                        </td>

                        <td
                          onClick={() => saveAs(sim?.caricaLaFotoDella)}
                          class="px-6 py-4 text-2xl text-primary cursor-pointer"
                        >
                          <Icon icon="mingcute:download-fill"></Icon>
                        </td>
                      </tr>
                      <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                        <td className="p-3 ">
                          <p className=" ">
                            Originale e Fotocopia Codice Fiscale{" "}
                          </p>
                        </td>
                        <td
                          onClick={() => saveAs(sim?.caricaOriginaleEFotocopia)}
                          class="px-6 py-4 text-2xl text-primary cursor-pointer"
                        >
                          <Icon icon="mingcute:download-fill"></Icon>
                        </td>
                      </tr>
                      <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                        <td className="p-3 ">
                          <p className=" ">
                            essuno portabilita senza copia di Codice Fiscale
                            obbligatorio?
                          </p>
                        </td>
                        <td
                          onClick={() =>
                            saveAs(
                              sim?.essunoPortabilitaSenzaCopia,
                              "image.jpg"
                            )
                          }
                          class="px-6 py-4 text-2xl text-primary cursor-pointer"
                        >
                          <Icon icon="mingcute:download-fill"></Icon>
                        </td>
                      </tr>
                      <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                        <td className="p-3 ">
                          <p className=" ">Notes </p>
                        </td>
                        <td className="p-3 bg-gray-50 ">
                          <p>{sim?.appuntiNote}</p>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>

              <div className=" border ">
                <p className=" text-xs pb-2 p-2  ">Documents</p>
                <table className="min-w-full text-xs  ">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col className="  " />
                  </colgroup>
                  <thead className="dark:bg-gray-700 bg-gray-300 ">
                    <tr className="text-left grid grid-cols-2    ">
                      <th className="p-3 text-xs ">Documents</th>
                    </tr>
                  </thead>

                  <tbody className=" bg-gray-200">
                    {sim?.appunti?.map((a, i) => (
                      <tr
                        a={a}
                        className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center"
                      >
                        <td className="p-3 ">
                          <p className=" ">Attachment {i + 1}</p>
                        </td>
                        <td
                          onClick={() => saveAs(a)}
                          class="px-6 py-4 text-2xl text-primary cursor-pointer"
                        >
                          <Icon icon="mingcute:download-fill"></Icon>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className=" pt-2">
            <button
              onClick={handelUpdateStatus}
              className=" bg-primary p-2 text-white hover:bg-secondary rounded"
            >
              Update Order !
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSimSalesView;
