import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Clock from "../Component/Clock/Clock";

const UserViewOffer = () => {
  const { id } = useParams();
  const [offerData, setOfferData] = useState({});

  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/operatorOffer/${id}`
        );
        setOfferData(response.data);
      } catch (error) {
        console.error("Error fetching specific offer data:", error);
      }
    };

    fetchOfferData();
  }, [id]);
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 "> USER DASHBOARD - OFFER VIEW</p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>

      <div className=" m-5  bg-thirdLightPrimary rounded-md p-5">
        <div className="overflow-x-auto-300  bg-gray-100  ">
          <table className="min-w-full text-xs  rounded-md ">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col className="" />
            </colgroup>
            <thead className="dark:bg-gray-700 text-xs bg-black text-white">
              <tr className="text-left grid grid-cols-2   ">
                <th className="p-3"> View Offer</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2  items-center">
                <td className="p-3 ">
                  <p className=" ">Operator</p>
                </td>
                <td className="p-3">
                  <p>{offerData?.operator}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Offer Name </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.offerName}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Cost Per Month </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.costPerMonth}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Total Recharge </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.totalRecharge}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Offer Valid For </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.offerValidFor}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Free Internet Pack (GB) </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.freeInternetPack}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Free Minuites for Local Operator </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.freeMinutesLocalOperator}</p>
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Free Minutes for International </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.freeMinutesInternational}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Unlimited Minutes To </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.unlimitedMinutesTo}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">International Minutes Valid For </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.internationalMinutesValidFor}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Others Information </p>
                </td>
                <td className="p-3">
                  <p>{offerData?.othersInformation}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserViewOffer;
