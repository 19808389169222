import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  appuntiUpload,
  multipleImageUpload,
  singleImageUpload,
} from "../../Hooks/ImageUpload";
import PostHooks from "../../Hooks/PostHooks";
import UpdateHooks from "../../Hooks/UpdateHooks";
import AuthUser from "../../Hooks/authUser";
import Loading from "../../Shared/Loading/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from "@iconify/react";
import saveAs from "file-saver";

const UserUpdateOrder = () => {
  const { userInfo } = AuthUser();
  const { id } = useParams();

  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [dateOfIssue, setDateOfIssue] = useState(new Date());
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [appuntiImg, setAppuntiImg] = useState([]);
  const [essunoPortabilitaSenzaCopia, setEssunoPortabilitaSenzaCopia] =
    useState("");
  const [caricaOriginaleEFotocopia, setCaricaOriginaleEFotocopia] =
    useState("");
  const [caricaLaFotoDella, setCaricaLaFotoDella] = useState("");
  const [ibanImg, setIbanImg] = useState("");

  const [loading, setLoading] = useState(false);
  const [operatorsName, setOperatorsName] = useState("");
  const [sim, setSim] = useState([]);

  console.log(sim);
  useEffect(() => {
    setLoading(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/simSale/getSimSaleById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSim(data);
        setLoading(false);
      });
  }, [id]);

  //Form Data
  const [formData, setFormData] = useState({});

  // handel simSale
  const handelAddSim = async (e) => {
    e.preventDefault();

    const iccidNumber = e.target?.iccidNumber?.value;
    const mobile = e.target?.mobile?.value;
    const appuntiNote = e.target?.appuntiNote?.value;
    const type = e.target?.type?.value;
    const ibanNumber = e.target?.ibanNumber?.value;
    const firstName = e.target?.firstName?.value;
    const surName = e.target?.surName?.value;
    const taxIdCode = e.target?.taxIdCode?.value;
    const nationality = e.target?.nationality?.value;
    const documentNumber = e.target?.documentNumber?.value;
    const placeOfIssue = e.target?.placeOfIssue?.value;
    const residentInStreetNumberCivicInterrior =
      e.target?.residentInStreetNumberCivicInterrior?.value;
    const location = e.target?.location?.value;
    const prov = e.target?.prov?.value;
    const cap = e.target?.cap?.value;
    const telephono = e.target?.telephono?.value;
    const email = e.target?.email?.value;
    const ricarica = e.target?.ricarica?.value;
    const vechioSchedaIccdNumero = e.target?.vechioSchedaIccdNumero?.value;
    const verchioNumeroCellulare = e.target?.verchioNumeroCellulare?.value;

    const simSale = {
      ibanNumber,
      type,
      appuntiNote,
      mobile,
      iccidNumber,
      documentNumber,
      nationality,
      taxIdCode,
      surName,
      firstName,
      cap,
      prov,
      location,
      residentInStreetNumberCivicInterrior,
      placeOfIssue,
      verchioNumeroCellulare,
      vechioSchedaIccdNumero,
      ricarica,
      email,
      telephono,
      ibanImg,
      operators: operatorsName ? operatorsName : sim?.operatorsName,
      DateOfIssueOfTheDocument: dateOfIssue ? dateOfIssue : sim?.dateOfIssue,
      expirationDateOfTheDocument: expirationDate
        ? expirationDate
        : sim?.expirationDate,
      dateOfBirth: dateOfBirth ? dateOfBirth : sim?.dateOfBirth,
      appunti: appuntiImg?.length ? appuntiImg : sim?.appunti,
      caricaLaFotoDella: caricaLaFotoDella
        ? caricaLaFotoDella
        : sim?.caricaLaFotoDella,
      caricaOriginaleEFotocopia: caricaOriginaleEFotocopia
        ? caricaOriginaleEFotocopia
        : sim?.caricaOriginaleEFotocopia,
      essunoPortabilitaSenzaCopia: essunoPortabilitaSenzaCopia
        ? essunoPortabilitaSenzaCopia
        : sim?.essunoPortabilitaSenzaCopia,
    };

    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/simSale/${id}`;

    await UpdateHooks(BASE_URL, simSale, true, "sim Updated");
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);

    if (event?.target?.name === "caricaLaFotoDella") {
      singleImageUpload(formData, setCaricaLaFotoDella);
    }
    if (event?.target?.name === "ibanImg") {
      singleImageUpload(formData, setIbanImg);
    }
    if (event?.target?.name === "caricaOriginaleEFotocopia") {
      singleImageUpload(formData, setCaricaOriginaleEFotocopia);
    }
    if (event?.target?.name === "essunoPortabilitaSenzaCopia") {
      singleImageUpload(formData, setEssunoPortabilitaSenzaCopia);
    }
    if (event?.target?.name === "appuntiImg") {
      appuntiUpload(formData, setAppuntiImg, appuntiImg);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen">
      <form onSubmit={handelAddSim}>
        <div className="  bg-thirdLightPrimary p-5 rounded-md text-xs m-5">
          <p>Vendita SIM</p>

          <div className="mt-3">
            <p>
              <span className="font-semibold">Operators:</span>{" "}
              <span>{sim?.operators}</span>
            </p>
            <p className="mt-1">
              <span className="font-semibold">Offerta:</span>{" "}
              <span>{sim?.offerta}</span>
            </p>
          </div>

          <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

          <div className=" grid grid-cols-2 gap-5 m-5 text-xs bg-gray-400 rounded-md p-5">
            <div>
              <p className=" pb-2">ICCID Number</p>
              <input
                type="text"
                className=" w-full text-xs "
                placeholder="ICCID Number"
                name="iccidNumber"
                defaultValue={sim?.iccidNumber}
              />
            </div>
            <div>
              <p className="pb-2">Mobile</p>
              <input
                type="text"
                className=" w-full text-xs"
                placeholder="Enter Sim Number"
                name="mobile"
                defaultValue={sim?.mobile}
              />
            </div>
          </div>

          <div className=" grid grid-cols-2 gap-5 p-4  ">
            <div className="  bg-gray-400 rounded-md p-5">
              <div>
                <p className=" pb-2">Type</p>
                <select
                  type="text"
                  name="type"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option disabled selected>
                    Select
                  </option>
                  <option
                    selected={sim?.type === "maschio" ? true : false}
                    value="maschio"
                  >
                    Maschio
                  </option>
                  <option
                    selected={sim?.type === "femmina" ? true : false}
                    value="femmina"
                  >
                    Femmina
                  </option>
                </select>
              </div>

              <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">First name </p>
                  <input
                    type="text"
                    name="firstName"
                    defaultValue={sim?.firstName}
                    className=" w-full text-xs "
                    placeholder=" Enter client first name"
                  />
                </div>
                <div>
                  <p className=" pb-2">Surname </p>
                  <input
                    name="surName"
                    defaultValue={sim?.surName}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client last name"
                  />
                </div>
              </div>

              <div className=" pt-2">
                <p className=" pb-2">Tax ID code</p>
                <input
                  name="taxIdCode"
                  defaultValue={sim?.taxIdCode}
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client Tax ID Code"
                />
              </div>

              <div className=" grid grid-cols-2  items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">Date of birth</p>
                  <DatePicker
                    name="dateOfBirth"
                    className=" text-xs "
                    onChange={(date) => setDateOfBirth(date)}
                  />
                </div>
                <div>
                  <p className=" pb-2">Nationality</p>
                  <input
                    defaultValue={sim?.nationality}
                    name="nationality"
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client nationality "
                  />
                </div>
              </div>

              <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">Document Number</p>
                  <input
                    name="documentNumber"
                    defaultValue={sim?.documentNumber}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client document number"
                  />
                </div>
                <div>
                  <p className=" pb-2">Place of issue</p>
                  <input
                    name="placeOfIssue"
                    defaultValue={sim?.placeOfIssue}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client document place of issue"
                  />
                </div>
              </div>

              <div className=" grid grid-cols-2  items-center    border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">Date of Issue of The Document</p>
                  <DatePicker
                    name="DateOfIssueOfTheDocument"
                    className="  text-xs "
                    onChange={(date) => setDateOfIssue(date)}
                  />
                </div>
                <div>
                  <p className=" pb-2">Expiration Date of The Document</p>
                  <DatePicker
                    name="expirationDateOfTheDocument"
                    className="  text-xs "
                    onChange={(date) => setExpirationDate(date)}
                  />
                </div>
              </div>

              <div>
                <p className=" pt-2">
                  RESIDENT IN STREET, NUMBER, CIVIC, INTERIOR
                </p>
                <textarea
                  name="residentInStreetNumberCivicInterrior"
                  defaultValue={sim?.residentInStreetNumberCivicInterrior}
                  className="h-40 mt-2 text-sm   outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                  placeholder="Enter Client address"
                ></textarea>
              </div>

              <div className=" grid grid-cols-3 gap-5 items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">LOCATION </p>
                  <input
                    name="location"
                    defaultValue={sim?.location}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client localita"
                  />
                </div>
                <div>
                  <p className=" pb-2">PROV </p>
                  <input
                    name="prov"
                    defaultValue={sim?.prov}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client prov"
                  />
                </div>
                <div>
                  <p className=" pb-2">CAP </p>
                  <input
                    name="cap"
                    defaultValue={sim?.cap}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client cap"
                  />
                </div>
              </div>
              <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
                <div>
                  <p className=" pb-2">Telephono </p>
                  <input
                    name="telephono"
                    defaultValue={sim?.telephono}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client telephono"
                  />
                </div>
                <div>
                  <p className=" pb-2">E-mail </p>
                  <input
                    name="email"
                    defaultValue={sim?.email}
                    type="text"
                    className=" w-full text-xs "
                    placeholder=" Enter client e-mail"
                  />
                </div>
              </div>
            </div>
            {/* second div */}
            <div className=" bg-gray-400 rounded-md p-5 ">
              <div>
                <p className=" pb-2 pt-2">Ricarica</p>
                <select
                  type="text"
                  name="ricarica"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option
                    selected={sim?.ricarica === "10ricarica" ? true : false}
                    value="10ricarica"
                  >
                    €10 Ricarica
                  </option>
                  <option
                    selected={sim?.ricarica === "20ricarica" ? true : false}
                    value="20ricarica"
                  >
                    €20 Ricarica
                  </option>
                  <option
                    selected={sim?.ricarica === "25ricarica" ? true : false}
                    value="25ricarica"
                  >
                    €25 Ricarica
                  </option>
                  <option
                    selected={sim?.ricarica === "30ricarica" ? true : false}
                    value="30ricarica"
                  >
                    €30 Ricarica
                  </option>
                  <option
                    selected={sim?.ricarica === "35ricarica" ? true : false}
                    value="35ricarica"
                  >
                    €35 Ricarica
                  </option>
                </select>
              </div>

              <div className=" mt-5">
                <div className=" mt-3 mb-3">
                  <p>
                    Previous Data MNP/PORTABILITA CON ALTRO GESTORE You Can
                    Download & View
                  </p>
                </div>
                {sim?.operators && (
                  <tbody className=" bg-gray-200">
                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">Operator </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p>{sim?.operators} </p>
                      </td>
                    </tr>

                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">ICCID Number </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p>{sim?.vechioSchedaIccdNumero} </p>
                      </td>
                    </tr>

                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">Mobile Number </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p>{sim?.verchioNumeroCellulare}</p>
                      </td>
                    </tr>

                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">Foto della vecchia SIM </p>
                      </td>

                      <td
                        onClick={() => saveAs(sim?.caricaLaFotoDella)}
                        class="px-6 py-4 text-2xl text-primary cursor-pointer"
                      >
                        <Icon icon="mingcute:download-fill"></Icon>
                      </td>
                    </tr>
                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">
                          Originale e Fotocopia Codice Fiscale{" "}
                        </p>
                      </td>
                      <td
                        onClick={() => saveAs(sim?.caricaOriginaleEFotocopia)}
                        class="px-6 py-4 text-2xl text-primary cursor-pointer"
                      >
                        <Icon icon="mingcute:download-fill"></Icon>
                      </td>
                    </tr>
                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">
                          essuno portabilita senza copia di Codice Fiscale
                          obbligatorio?
                        </p>
                      </td>
                      <td
                        onClick={() =>
                          saveAs(sim?.essunoPortabilitaSenzaCopia, "image.jpg")
                        }
                        class="px-6 py-4 text-2xl text-primary cursor-pointer"
                      >
                        <Icon icon="mingcute:download-fill"></Icon>
                      </td>
                    </tr>
                    {/* <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">Notes </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p>{sim?.appuntiNote}</p>
                      </td>
                    </tr> */}
                  </tbody>
                )}
              </div>

              <p className="pt-5  pl-1">MNP/PORTABILITA CON ALTRO GESTORE</p>
              <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
              <div>
                <p className=" pb-2 pt-2">Operators</p>
                <select
                  type="text"
                  name="operators"
                  onChange={(e) => setOperatorsName(e?.target.value)}
                  className="bg-gray-50 border   border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                >
                  {" "}
                  <option selected value="">
                    None
                  </option>
                  <option value="coopVoce">Coop Voce</option>
                  <option value="digi">DIGI</option>
                  <option value="fastWeb">FAST WEB</option>
                  <option value="heraComm">HERA COMM</option>
                  <option value="hoOtherName">HO Other Name (vei)</option>
                  <option value="iliad">iliad</option>
                  <option value="iren">IREN</option>
                  <option value="kenaMobileNoverca">KENA MOBILE-NOVERCA</option>
                  <option value="linkem">LINKEM</option>
                  <option value="lycaMobile">LYCA MOBILE</option>
                  <option value="others">OTHERS</option>
                  <option value="">POST MOBILE // 2014</option>
                  <option value="postMobileFull">POST MOBILE FULL</option>
                  <option value="rabonaMobile">RABONA MOBILE</option>
                  <option value="ringo">RINGO</option>
                  <option value="skyeTvOrNetflix">SKY TV OR NETFLIX</option>
                  <option value="skyWifi">SKY-WI-FI</option>
                  <option value="skyGlass">SKYGLASS</option>
                  <option value="tscali">TSCALI</option>
                  <option value="unoMobile">UNO MOBILE</option>
                  <option value="veryMobile">VERYMOBILE</option>
                  <option value="vodafone">VODAFONE</option>
                </select>
              </div>

              {operatorsName && (
                <div>
                  <div>
                    <p className=" pb-2 pt-2">VECHIO SCHEDA ICCD NUMERO:</p>
                    <input
                      name="vechioSchedaIccdNumero"
                      defaultValue={sim?.vechioSchedaIccdNumero}
                      type="text"
                      className=" w-full text-xs "
                      placeholder=" Enter 19 Digit ICCID Number"
                    />
                  </div>
                  <div>
                    <p className=" pb-2 pt-2">VECHIO NUMERO CELLULARE:</p>
                    <input
                      name="verchioNumeroCellulare"
                      defaultValue={sim?.verchioNumeroCellulare}
                      type="text"
                      className=" w-full text-xs "
                      placeholder=" Enter mobile number"
                    />
                  </div>
                  <div>
                    <p className=" pb-2 pt-2">
                      Carica la foto della vecchia SIM/Upload the photo of the
                      old SIM:
                    </p>

                    <input
                      onChange={handleChangeUploadImage}
                      name="caricaLaFotoDella"
                      type="file"
                      className=" w-full text-xs border "
                    />
                  </div>
                  <div>
                    <div className=" grid grid-cols-1 gap-4">
                      <div>
                        <p className=" pb-2 pt-2">
                          Carica Originale e Fotocopia Codice Fiscale: *
                        </p>
                        <input
                          onChange={handleChangeUploadImage}
                          name="caricaOriginaleEFotocopia"
                          type="file"
                          className=" w-full text-xs border "
                        />
                      </div>
                      <div>
                        <p className=" pb-2 ">
                          essuno portabilita senza copia di Codice Fiscale
                          obbligatorio?
                        </p>
                        <input
                          onChange={handleChangeUploadImage}
                          name="essunoPortabilitaSenzaCopia"
                          type="file"
                          className=" w-full text-xs border "
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className=" pt-2">Appunti</p>
                    <textarea
                      name="appuntiNote"
                      defaultValue={sim?.appuntiNote}
                      className="h-40 mt-2 text-sm   outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                      placeholder="Enter note"
                    ></textarea>
                  </div>
                </div>
              )}
              {/* condisonal div */}

              <div>
                <p className=" pt-2 font-semibold">Appunti</p>
                <div className=" text-1px pl-5 pt-2">
                  <p>1. La dimensione massima per file è di 2 MB.</p>
                  <p>2. Carica solo file PNG, JPEG, PDF.</p>
                </div>

                <div className=" grid grid-cols-1 gap-4 pt-2 ">
                  {/* <input
                    multiple
                    onChange={handleMultipleUploadImage}
                    type="file"
                    className=" w-full text-xs border "
                  /> */}
                  {/* import saveAs from "file-saver"; */}
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />

                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                  <input
                    onChange={handleChangeUploadImage}
                    name="appuntiImg"
                    type="file"
                    className=" w-full text-xs border "
                  />
                </div>
              </div>

              <div className=" mt-5">
                <div className=" mt-2 mb-2">
                  <p>Previous Data You Can Download & View</p>
                </div>
                <tbody className=" bg-gray-200">
                  {sim?.appunti?.map((a, i) => (
                    <tr
                      a={a}
                      className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center  "
                    >
                      <td className="p-3 ">
                        <p className=" ">Attachment {i + 1}</p>
                      </td>
                      <td
                        onClick={() => saveAs(a)}
                        class="px-6 py-4 text-2xl text-primary cursor-pointer"
                      >
                        <Icon icon="mingcute:download-fill"></Icon>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className=" text-left  bg-primary text-white hover:bg-secondary text-xs p-2 rounded-md "
              >
                Update !
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserUpdateOrder;
