import { Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import UpdateHooks from "../../Hooks/UpdateHooks";
import { server_url } from "../../Config/API";
import { useParams } from "react-router-dom";

const AdminAgentViewProfile = () => {
  const [user, setUser] = useState({});
  const [startDate, setStartDate] = useState(user?.dateOfBirth);
  const { id } = useParams();
  const BASE_URL = `${server_url}/user/${id}`;
  useEffect(() => {
    const url = `https://servergenzam.genzamwallet.com/api/v1/user/${id}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setUser(data?.data);
        console.log(data?.data);
      });
  }, [id]);

  const handelUpdate = (e) => {
    e.preventDefault();
    const email = e.target.email?.value;
    const password = e.target.password?.value;
    const name = e.target.name?.value;
    const status = e.target.status?.value;
    const phone = e.target.phone?.value;
    const agentCode = e.target.agentCode?.value;
    const contactPerson = e.target.contactPerson?.value;
    const codiceFiscal = e.target.codiceFiscal?.value;
    const pivaNumber = e.target.pivaNumber?.value;
    const pecId = e.target.pecId?.value;
    const codiceUnivoco = e.target.codiceUnivoco?.value;
    const address = e.target.address?.value;
    const country = e.target.country?.value;
    const city = e.target.city?.value;
    const userName = e.target.userName.value;

    const newData = {
      name,
      userName,
      password,
      email,
      status,
      phone,
      city,
      country,
      address,
      codiceUnivoco,
      pecId,
      pivaNumber,
      codiceFiscal,
      contactPerson,
      agentCode,
    };
    UpdateHooks(BASE_URL, newData, true, "Profile Updated");
  };
  return (
    <div className="  min-h-screen">
      <div className=" bg-thirdLightPrimary  ">
        <p className="  font-semibold p-2 ">Edit Agent</p>
      </div>
      <div className=" bg-thirdLightPrimary m-5 rounded-md">
        <div className="overflow-x-auto  ">
          <form onSubmit={handelUpdate}>
            <table className="min-w-full text-xs bg-gray-50 rounded-md ">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="" />
              </colgroup>

              <tbody>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">User Name</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Enter Your User Name"
                      name="userName"
                      defaultValue={user?.userName}
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Agent Code</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Agent Code"
                      name="agentCode"
                      defaultValue={user?.agentCode}
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Agent Name</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Agent Name "
                      name="name"
                      defaultValue={user?.name}
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Contact Person </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Contact Person"
                      defaultValue={user?.contactPerson}
                      name="contactPerson"
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Phone </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Phone"
                      defaultValue={user?.phone}
                      name="phone"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">E-mail </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" E-email"
                      defaultValue={user?.email}
                      name="email"
                      disabled
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">CODICE FISCAL</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" CODICE FISCAL"
                      defaultValue={user?.codiceFiscal}
                      name="codiceFiscal"
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">P.IVA NUMBER </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="P.IVA NUMBER "
                      defaultValue={user?.pivaNumber}
                      name="pivaNumber"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">PEC ID</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" PEC ID"
                      defaultValue={user?.pecId}
                      name="pecId"
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">CODICE UNIVOCO</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" CODICE UNIVOCO"
                      defaultValue={user?.codiceUnivoco}
                      name="codiceUnivoco"
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Address </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Address"
                      defaultValue={user?.address}
                      name="address"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Country </p>
                  </td>
                  <td className="p-3 ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" country"
                      defaultValue={user?.country}
                      name="country"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">City </p>
                  </td>
                  <td className="p-3 ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="city"
                      defaultValue={user?.city}
                      name="city"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Status </p>
                  </td>
                  <td className="p-3 ">
                    <div>
                      {" "}
                      <select
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Select"
                        name="status"
                        required
                      >
                        <option
                          selected={user?.status === "inactive" ? true : false}
                          value="inactive"
                        >
                          Inactive
                        </option>
                        <option
                          selected={user?.status === "active" ? true : false}
                          value="active"
                        >
                          Active
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>

                <div className=" p-5">
                  <button className=" bg-primary p-3 rounded-md text-white font-semibold">
                    {" "}
                    Update Agent{" "}
                  </button>
                </div>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminAgentViewProfile;
