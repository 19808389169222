import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const EditLuceOperator = () => {
  const { id } = useParams();
  const [luceOperator, setLuceOperator] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/luceOperator/${id}`
        );

        // Populate the form with the fetched data
        setLuceOperator(response.data);
        setLoading(false);
      } catch (error) {
        alert(
          "An error occurred while fetching offer data. Please try again later."
        );
        setLoading(false);
      }
    };

    fetchOfferData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const operatorName = e.target?.operatorName.value;
    const operatorDetails = e.target?.operatorDetails.value;

    const operator = { operatorName, operatorDetails };
    // console.log(operator);
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/luceOperator/${id}`;
    UpdateHooks(BASE_URL, operator, true, "luce operator Updated");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!luceOperator) {
    return <div>WiFi operator data not found.</div>;
  }

  return (
    <div className="p-6 space-y-6">
      {/* form start */}
      <form onSubmit={handleSubmit}>
        <div className="">
          <div className="">
            <label
              htmlFor="product-name"
              className="text-sm font-medium text-gray-900 block mb-2"
            >
              Operator Name
            </label>
            <input
              type="text"
              name="operatorName"
              defaultValue={luceOperator?.operatorName}
              required
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
              placeholder="Enter Operator Name ”"
            />
          </div>

          <div className="w-full mt-4">
            <div className="">
              <label className="text-sm font-medium text-gray-900 block mb-2">
                Offer Details
              </label>
              <textarea
                className="rounded-md focus:outline-none w-full"
                type="textarea"
                name="operatorDetails"
                defaultValue={luceOperator?.operatorDetails}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="mt-5 rounded-b">
          <button
            className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            type="submit"
          >
            Update !
          </button>
        </div>
      </form>

      {/* close form */}
    </div>
  );
};

export default EditLuceOperator;
