import React from "react";
import "./Loading.css";
import { InfinitySpin } from "react-loader-spinner";

const Loading = () => {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <InfinitySpin width="200" color="#4fa94d" />
    </div>
  );
};

export default Loading;
