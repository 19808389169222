import { Icon } from "@iconify/react";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const AdminBillsTable = ({ payment, index, fetchBills }) => {
  const [userName, setuserName] = useState("");
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/user/specific/?fieldName=${"email"}&&fieldValue=${
        payment?.customerName
      }`
    )
      .then((response) => response.json())
      .then((data) => setuserName(data?.data[0]?.name));
  });
  const handleDeleteBill = (billId) => {
    axios
      .delete(`https://servergenzam.genzamwallet.com/api/v1/bills/${billId}`)
      .then(() => {
        // Bill successfully deleted, fetch the updated list of bills
        fetchBills();
      })
      .catch((error) => {
        console.log("Error deleting bill: ", error);
      });
  };

  return (
    <tr
      key={payment._id} // Use payment._id as the key
      className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
    >
      <td className="p-3 border">
        <p>{index + 1}</p>
      </td>
      <td className="p-3 text-center border">
        <p>{userName}</p>
      </td>

      <td className="p-3 text-center border">
        <p>{payment.customerName}</p>
      </td>
      <td className="p-3 text-center border">
        <p>{payment.date} </p>
      </td>
      <td className="p-3 text-left border">
        <p>{payment.billDetails}</p>
      </td>
      <td className="p-3 text-center border">
        <p className="dark:text-gray-400">{payment.debit}</p>
      </td>
      <td className="p-3 text-center border">
        <p className="dark:text-gray-400">{payment?.credit}</p>
      </td>
      <td className="p-3 text-center border">
        <p className="dark:text-gray-400">{payment.paymentStatus}</p>
      </td>
      <td className="p-3 text-xl items-center justify-center flex ">
        <p className="flex gap-x-3 text-yellow-400">
          <Link to={`/adminDashboard/adminupdatepaymentview/${payment._id}`}>
            <Icon icon="basil:edit-solid" style={{ cursor: "pointer" }} />
          </Link>
          <Icon
            icon="ant-design:delete-filled"
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteBill(payment._id)}
          />
        </p>
      </td>
    </tr>
  );
};

export default AdminBillsTable;
