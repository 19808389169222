import { Component } from "react";
import Bills from "../Component/Bills/Bills";
import BillsHistory from "../Component/Bills/BillsHistory";
import PaymentHistory from "../Component/Bills/PaymentHistory";
import TransactionHistory from "../Component/Bills/TransactionHistory";
import TransactionReports from "../Component/Bills/TransactionReports";
import Offers from "../Component/Offers/Offers";
import ViewOffer from "../Component/Offers/ViewOffer";
import Order from "../Component/Orders/Order";
import UpdateOrder from "../Component/Orders/UpdateOrder";
import ViewOrder from "../Component/Orders/ViewOrder";
import ResellerHome from "../Component/Reseller/ResellerHome";
import AllSim from "../Component/Sim/AllSim";
import EditSim from "../Component/Sim/EditSim";
import InStock from "../Component/Sim/InStock";
import OrderSim from "../Component/Sim/OrderSim";
import Sold from "../Component/Sim/Sold";
import SimSales from "../Component/SimSales/SimSales";
import Agent from "../Component/Users/Agent";
import Edituser from "../Component/Users/Edituser";
import NewUser from "../Component/Users/NewUser";
import UserList from "../Component/Users/UserList";
import NewGasOrder from "../Component/Wifi/NewGasOrder";
import NewLuceOrder from "../Component/Wifi/NewLuceOrder";
import NewWifiOrder from "../Component/Wifi/NewWifiOrder";
import OrderHistory from "../Component/Wifi/OrderHistory";
import ViewWifi from "../Component/Wifis/ViewWifi";
import Wifi from "../Component/Wifis/Wifi";
import ResellerIndex from "../Pages/ResellerDashBord/ResellerIndex";
import EditAgent from "../Component/Users/EditAgent";
import NewAgent from "../Component/Users/NewAgent";
import SimReport from "../Component/SimReports.js/SimReport";
import Operators from "../Component/Settings/Operators";
import Countries from "../Component/Settings/Countries";
import Cities from "../Component/Settings/Cities";
import ChnagePassword from "../Component/Settings/ChangePassword";
import ChangePassword from "../Component/Settings/ChangePassword";
import agentSimSalesOrder from "../Component/SimSales/AgentSimSalesOrder";
import AgentSimSalesOrder from "../Component/SimSales/AgentSimSalesOrder";
import OrderView from "../Component/Wifi/OrderView";
import ViewWifiOrder from "../Component/Reseller/ViewOrder/ViewWifiOrder";
import ViewGasOrder from "../Component/Reseller/ViewOrder/ViewGasOrder";
import ViewLuceOrder from "../Component/Reseller/ViewOrder/ViewLuceOrder";
import UpdateWifiOrder from "../Component/Reseller/UpdateOrder/UpdateWifiOrder";
import UpdateGasOrder from "../Component/Reseller/UpdateOrder/UpdateGasOrder";
import UpdateLuceOrder from "../Component/Reseller/UpdateOrder/UpdateLuceOrder";
import UpdateSimOrder from "../Component/Reseller/UpdateSimOrder";
import RequestSimHistory from "../Component/Sim/RequestSimHistory";
import SimInvoice from "../Component/Invoice/SimInvoice";

const ResellerRoute = [
  // { path: "/", Component: Home }

  // header Footer Routing
  { path: "resellerhome", Component: ResellerHome },
  { path: "simsales", Component: SimSales },
  { path: "newwifiorder", Component: NewWifiOrder },
  { path: "newgasoder", Component: NewGasOrder },
  { path: "luceorder", Component: NewLuceOrder },
  { path: "wifiorderhistory", Component: OrderHistory },
  { path: "allsim", Component: AllSim },
  { path: "allsim/:id", Component: EditSim },
  { path: "ordersim", Component: OrderSim },
  { path: "instock", Component: InStock },
  { path: "instock/editsim", Component: EditSim },
  { path: "sold", Component: Sold },
  { path: "bills", Component: Bills },
  { path: "billshistory", Component: BillsHistory },
  { path: "bills/billshistory", Component: BillsHistory },
  { path: "bills/paymenthistory", Component: PaymentHistory },
  {
    path: "bills/transactionhistory",
    Component: TransactionHistory,
  },
  {
    path: "bills/transactionhistory/transactionreports",
    Component: TransactionReports,
  },
  { path: "order", Component: Order },
  { path: "order/updateorder", Component: UpdateOrder },
  { path: "order/wifi/:id", Component: ViewWifiOrder },
  { path: "order/gas/:id", Component: ViewGasOrder },
  { path: "order/luce/:id", Component: ViewLuceOrder },
  { path: "offers", Component: Offers },
  { path: "offers/viewoffer/:id", Component: ViewOffer },
  { path: "wifi", Component: Wifi },
  { path: "wifi/viewwifi/:id", Component: ViewWifi },
  { path: "userlist", Component: UserList },
  { path: "userlist/:id", Component: Edituser },
  { path: "newuser", Component: NewUser },
  { path: "agent", Component: Agent },
  { path: "agent/:id", Component: EditAgent },
  { path: "newagent", Component: NewAgent },
  { path: "simreport", Component: SimReport },
  { path: "operators", Component: Operators },
  { path: "countries", Component: Countries },
  { path: "cities", Component: Cities },
  { path: "changepassword", Component: ChangePassword },
  {
    path: "simsales/agent-sim-sales-order/:id",
    Component: AgentSimSalesOrder,
  },
  { path: "viewOrder", Component: OrderView },
  { path: "wifiorderhistory/update/wifi/:id", Component: UpdateWifiOrder },
  { path: "wifiorderhistory/update/gas/:id", Component: UpdateGasOrder },
  { path: "wifiorderhistory/update/luce/:id", Component: UpdateLuceOrder },

  { path: "order/update/:id", Component: UpdateSimOrder },
  {
    path: "requestsimhistrory",
    Component: RequestSimHistory,
  },

  {
    path: "order/sim-invoice/:id",
    Component: SimInvoice,
  },
];

export default ResellerRoute;
