import { Input } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import UpdateHooks from "../../Hooks/UpdateHooks";
import { server_url } from "../../Config/API";

const Edituser = () => {
  const [user, setUser] = useState({});
  const [startDate, setStartDate] = useState(user?.dateOfBirth);
  const { id } = useParams();
  const BASE_URL = `${server_url}/user/${id}`;
  useEffect(() => {
    const url = `https://servergenzam.genzamwallet.com/api/v1/user/${id}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setUser(data?.data);
        console.log(data?.data);
      });
  }, [id]);

  const handelUpdate = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const name = e.target.name.value;
    const status = e.target.status.value;
    const coopVoce = e.target.coopVoce.value;
    const loginId = e.target.loginId.value;
    const phone = e.target.phone.value;
    const dateOfBirth = startDate;
    const userName = e.target.userName.value;
    const contactPerson = e.target.contactPerson.value;
    const codiceFiscal = e.target.codiceFiscal.value;
    const pivaNumber = e.target.pivaNumber.value;
    const pecId = e.target.pecId.value;
    const codiceUnivoco = e.target.codiceUnivoco.value;
    const address = e.target.address.value;
    const city = e.target.city.value;
    const country = e.target.country.value;
    const newData = {
      email,
      userName,
      password,
      name,
      status,
      coopVoce,
      loginId,
      phone,
      dateOfBirth,
      ///start
      contactPerson,
      codiceFiscal,
      pivaNumber,
      pecId,
      codiceUnivoco,
      address,
      city,
      country,
    };
    UpdateHooks(BASE_URL, newData, true, "Profile Updated");
  };
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary   ">
        <p className="  font-semibold p-2 "> EDIT USER </p>
      </div>
      <div className=" m-5 bg-thirdLightPrimary rounded-md">
        <div className="overflow-x-auto  ">
          <form onSubmit={handelUpdate}>
            <table className="min-w-full text-xs bg-gray-50 rounded-md ">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="" />
              </colgroup>
              <thead className="dark:bg-gray-700">
                <tr className="text-left grid grid-cols-2 bg-gray-200 rounded-md  ">
                  <th className="p-3">Operator</th>
                  <th className="p-3 ">Quantity</th>
                </tr>
              </thead>

              <tbody>
                <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">User Name</p>
                  </td>
                  <td className="p-3">
                    <input
                      id="coopVoce"
                      name="userName"
                      type="text"
                      defaultValue={user?.userName}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Enter Your User Name"
                      required
                    />
                  </td>
                </tr>

                {/* <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Coop Voce</p>
                  </td>
                  <td className="p-3">
                    <input
                      id="coopVoce"
                      name="coopVoce"
                      type="text"
                      defaultValue={user?.coopVoce}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Phone"
                      required
                    />
                  </td>
                </tr> */}

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Name</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="name"
                      name="name"
                      defaultValue={user?.name}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Name"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Phone</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="phone"
                      name="phone"
                      defaultValue={user?.phone}
                      type="text"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Phone"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Email</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="email"
                      defaultValue={user?.email}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your email"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Date of Birth </p>
                  </td>
                  <td className="p-3  ">
                    <DatePicker
                      className="bg-gray-50 border border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Login ID</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="loginId"
                      name="loginId"
                      defaultValue={user?.loginId}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Login ID"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Contact Person</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="contactPerson"
                      defaultValue={user?.contactPerson}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your Contact Person"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Codice Fiscal</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="codiceFiscal"
                      defaultValue={user?.codiceFiscal}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your Codice Fiscal"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Piva Number</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="codiceFiscal"
                      defaultValue={user?.pivaNumber}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your Piva Number"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" "> Pec Id</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="pecId"
                      defaultValue={user?.pecId}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your Pec Id"
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" "> Codice Univoco</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="codiceUnivoco"
                      defaultValue={user?.codiceUnivoco}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your Codice Univoco"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">address</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="address"
                      defaultValue={user?.address}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your Address"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">City</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="city"
                      defaultValue={user?.city}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your City"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Country</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="country"
                      defaultValue={user?.country}
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your Country"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Password</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="password"
                      value={user?.password}
                      disabled
                      name="password"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Password"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Status </p>
                  </td>
                  <td className="p-3 ">
                    <div>
                      {" "}
                      <select
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Select"
                        name="status"
                        required
                      >
                        <option
                          selected={user?.status === "inactive" ? true : false}
                          value="inactive"
                        >
                          Inactive
                        </option>
                        <option
                          selected={user?.status === "active" ? true : false}
                          value="active"
                        >
                          Active
                        </option>
                      </select>
                    </div>
                  </td>
                </tr>

                <div className=" p-5">
                  <button
                    type="submit"
                    className=" bg-primary p-3 font-semibold text-white rounded-md"
                  >
                    {" "}
                    Update User{" "}
                  </button>
                </div>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Edituser;
