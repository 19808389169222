import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";
import Clock from "../../Component/Clock/Clock";
import axios from "axios";

const UserChangePassword = () => {
  const { userInfo } = AuthUser();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypeNewPassword, setRetypeNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleChangePassword = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    setLoading(true);

    try {
      const response = await axios.patch(
        `https://servergenzam.genzamwallet.com/api/v1/user/change-password/${userInfo._id}`,
        { currentPassword, newPassword }
      );

      setStatusMessage(response.data.message);
      setCurrentPassword("");
      setNewPassword("");
      setRetypeNewPassword("");
    } catch (error) {
      setStatusMessage(
        "Password change failed. Please make sure your current password is correct."
      );
    }

    setLoading(false);
  };

  return (
    <div className="min-h-screen">
      <div className="bg-thirdLightPrimary flex justify-between">
        <div>
          <p className="font-semibold p-2">USER DASHBOARD - CHANGE PASSWORD</p>
        </div>
        <div>
          <p className="font-semibold p-2">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className="bg-thirdLightPrimary m-5 p-5 rounded-md">
        <div className="overflow-x-auto">
          <form onSubmit={handleChangePassword}>
            <table className="min-w-full text-xs  bg-lightPrimary p-5 rounded-md">
              <colgroup>
                <col />
                <col />
              </colgroup>
              <tbody>
                <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3">
                    <p className="">Current Password</p>
                  </td>
                  <td className="p-3">
                    <input
                      type="password"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1 sm:text-xs"
                      placeholder="Enter Your Current Password"
                      autoFocus
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20 bg-gray-100 p-5 m-2 grid grid-cols-2 items-center">
                  <td className="p-3">
                    <p className="">New Password</p>
                  </td>
                  <td className="p-3">
                    <input
                      type="password"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1 sm:text-xs"
                      placeholder="Enter Your New Password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3">
                    <p className="">Re-Type New Password</p>
                  </td>
                  <td className="p-3">
                    <input
                      type="password"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1 sm:text-xs"
                      placeholder="Enter Re-Type New Password"
                      value={retypeNewPassword}
                      onChange={(e) => setRetypeNewPassword(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="p-5">
              <button
                className="bg-primary p-3 rounded-md"
                type="submit"
                disabled={
                  loading ||
                  !currentPassword ||
                  !newPassword ||
                  newPassword !== retypeNewPassword
                }
              >
                {loading ? "Changing Password..." : "Change Password"}
              </button>
              {statusMessage && <p>{statusMessage}</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserChangePassword;
