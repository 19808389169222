import React from "react";
import moment from "moment";

const SimReportTable = ({ sim, i }) => {
  return (
    <tr
      key={sim?.key}
      sim={sim}
      className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
    >
      <td className="p-3">
        <p>{i + 1}</p>
      </td>
      <td className="p-3 text-center">
        <p>{sim?._id}</p>
      </td>
      <td className="p-3 text-center">
        <p>{sim?.firstName}</p>
      </td>
      <td className="p-3 text-center">
        <p className="dark:text-gray-400">
          {sim?.saleData && sim?.saleData?.operatorName}
        </p>
      </td>
      <td className="p-3 text-center">
        <p className="dark:text-gray-400">
          {sim?.saleData && sim?.saleData?.offerName}
        </p>
      </td>

      <td className="p-3 text-center">
        <p className=" p-3">{sim?.iccidNumber}</p>
      </td>
      <td className="p-3 text-center">
        <p className=" p-3 whitespace-nowrap">
          {moment(sim?.saleData?.buyDate).format("MMM Do YY")}
        </p>
      </td>
      <td className="p-3 text-center">
        <p className=" p-3 whitespace-nowrap">
          {moment(sim?.updatedAt).format("MMM Do YY")}
        </p>
      </td>
    </tr>
  );
};

export default SimReportTable;
