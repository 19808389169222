import { Icon } from "@iconify/react";
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker"; // Import the date picker component
import "react-datepicker/dist/react-datepicker.css";
const AdminReports = () => {
  const [simSalesData, setSimSalesData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedReseller, setSelectedReseller] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/simSale")
      .then((response) => response.json())
      .then((data) => setSimSalesData(data.simSales))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const groupedData = simSalesData.reduce((result, item) => {
    if (!result[item.resellerName]) {
      result[item.resellerName] = [];
    }
    result[item.resellerName].push(item);
    return result;
  }, {});

  const resellerNames = Object.keys(groupedData);

  const filteredData = Object.keys(groupedData)
    .filter((resellerName) => resellerName.includes(selectedReseller))
    .map((resellerName) => ({
      resellerName,
      data: groupedData[resellerName],
    }))
    .filter((filteredItem) => {
      return filteredItem.data.some((order) => {
        const orderStatus = order.status;
        const orderMonth = new Date(order.updatedAt).getMonth();
        const orderYear = new Date(order.updatedAt).getFullYear();
        const selectedMonthValue = selectedMonth.getMonth();
        const selectedYearValue = selectedMonth.getFullYear();
        return (
          orderStatus === "completed" &&
          orderMonth === selectedMonthValue &&
          orderYear === selectedYearValue
        );
      });
    });

  // console.log(filteredData);

  return (
    <div>
      <div className="max-w-3xl mx-auto text-center mt-5">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          All Report List
        </h1>
      </div>

      <div className="mt-10 mb-5 mx-auto max-w-xl py-2 px-6 rounded-full bg-gray-50 border flex focus-within:border-gray-300">
        <input
          type="text"
          className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-0 py-0"
          placeholder="Filter by  Name"
          value={selectedReseller}
          onChange={(e) => setSelectedReseller(e.target.value)}
          list="resellerNames"
        />
      </div>
      <div className=" text-center">
        <DatePicker
          className="mt-5 mb-5 mx-auto max-w-xl py-2 px-6 text-center rounded-full bg-gray-50 border flex focus-within:border-gray-300"
          selected={selectedMonth}
          onChange={(date) => setSelectedMonth(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          placeholderText="Select a month"
        />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full text-xs">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="bg-black text-white rounded-sm">
            <tr className="text-center">
              <th className="p-3 border">#</th>
              <th className="p-3 border">ResellerName</th>
              <th className="p-3 border">ResellerEmail</th>
              <th className="p-3 border">Role</th>
              <th className="p-3 border">Total Complete Order</th>
              <th className="p-3 border">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((filteredItem, index) => (
              <tr
                key={filteredItem.resellerName}
                className="border-b border-opacity-20 text-center dark:border-gray-700 bg-gray-50  rounded-md shadow text-black"
              >
                <td className="p-3 border">
                  <p>{index + 1}</p>
                </td>
                <td className="p-3 text-center border">
                  <p>{filteredItem.resellerName}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">
                    {filteredItem.data[0].resellerEmail}
                  </p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">
                    {filteredItem.data[0].role}
                  </p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">
                    {
                      filteredItem.data.filter((order) => {
                        const orderMonth = new Date(order.updatedAt).getMonth();
                        const orderYear = new Date(
                          order.updatedAt
                        ).getFullYear();
                        const selectedMonthValue = selectedMonth.getMonth();
                        const selectedYearValue = selectedMonth.getFullYear();
                        return (
                          order.status === "completed" &&
                          orderMonth === selectedMonthValue &&
                          orderYear === selectedYearValue
                        );
                      }).length
                    }
                  </p>
                </td>
                <td className="p-3 text-xl items-center text-black gap-x-5 justify-center flex">
                  <div>{/* Add your delete icon here */}</div>
                  <div>
                  <Link
  to={`/adminDashboard/admin-view-reports/${encodeURIComponent(
    filteredItem.data[0].resellerId
  )}?month=${selectedMonth.getMonth()}&year=${selectedMonth.getFullYear()}`}
>
  <Icon className="rounded-md cursor-pointer" icon="carbon:view-filled" />
</Link>

                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminReports;