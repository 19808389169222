import React, { useEffect, useState } from "react";
import "flowbite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { singleImageUpload } from "../../Hooks/ImageUpload";
import PostHooks from "../../Hooks/PostHooks";
import AuthUser from "../../Hooks/authUser";
import Clock from "../Clock/Clock";
const NewGasOrder = () => {
  const { userInfo } = AuthUser();
  const [startDate, setStartDate] = useState(new Date());
  const [cIdentityImg, setCIdentityImg] = useState("");
  const [codiceFiscaleImg, setCodiceFiscaleImg] = useState("");
  const [caricaModuloDiSkyImg, setCaricaModuloDiSkyImg] = useState("");
  const [dichiarationImg, setDichiarationImg] = useState("");
  const [pagamentoImg, setPagamento] = useState("");
  const [soggoirnoImg, setSoggoirnoImg] = useState("");
  const [patente, setPatenteImg] = useState("");
  const [gasDichiration, setGasDichirationImg] = useState("");
  const [prezzo, setPrezzoImg] = useState("");
  const [vecchaiaBolletta, setVecchaiaBollettaImg] = useState("");
  const [ibanImg, setIbanImg] = useState(null);
  const [payment, setPayment] = useState("");

  const [operators, setOperators] = useState([]);
  const [operatorName, setOperatorName] = useState("");

  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/gasOperator")
      .then((res) => res.json())
      .then((data) => setOperators(data));
  }, []);
  const filterOffer = operators?.filter(
    (op) => op?.operatorName === operatorName
  );

  const handelAddGasOrder = async (e) => {
    e.preventDefault();
    const operator = e.target?.operator?.value;
    const name = e.target?.name?.value;
    const surName = e.target?.surName?.value;
    const dateOfBirth = startDate;
    const phone = e.target?.phone?.value;
    const taxIdCode = e.target?.taxIdCode?.value;
    const email = e.target?.email?.value;
    const activateAddress = e.target?.activateAddress?.value;
    const cIdentity = e.target?.cIdentity?.value;
    const citta = e.target?.citta?.value;
    const soggoirno = e.target?.soggoirno?.value;
    const province = e.target?.province?.value;
    const cap = e.target?.cap?.value;
    const CodicePdr = e.target?.CodicePdr?.value;
    const matricolaContatore = e.target?.matricolaContatore?.value;
    const clienteWindtre = e.target?.clienteWindtre?.value;
    const numberoCellulare = e.target?.numberoCellulare?.value;
    const clienteAltriOperatore = e.target?.clienteAltriOperatore?.value;
    const numeroCellulare = e.target?.numberoCellulare?.value;
    const ibanNumber = e.target?.ibanNumber?.value;

    const introduction = {
      name,
      surName,
      dateOfBirth,
      taxIdCode,
      phone,
      email,
      activateAddress,
      cIdentity,
      soggoirno,
      cap,
      citta,
      province,
    };
    const documents = {
      vecchaiaBolletta,
      prezzo,
      gasDichiration,
      patente,
      soggoirnoImg,
      pagamentoImg,
      dichiarationImg,
      caricaModuloDiSkyImg,
      codiceFiscaleImg,
      cIdentityImg,
      ibanImg,
    };
    const newGasOrder = {
      operator,
      operatorDetails: filterOffer,
      payment,
      ibanNumber,
      CodicePdr,
      matricolaContatore,
      clienteWindtre,
      numberoCellulare,
      clienteAltriOperatore,
      numeroCellulare,
      introduction,
      documents,
      resellerId: userInfo?._id,
      resellerEmail: userInfo?.email,
      resellerName: userInfo?.name,
      role: userInfo?.role,
      resellerNumber: userInfo?.phone,
    };
    await PostHooks(
      "https://servergenzam.genzamwallet.com/api/v1/newGasOrder",
      newGasOrder,
      "NewGasOrder successfully submitted"
    );
    console.log(newGasOrder);
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    if (event?.target?.name === "prezzo") {
      singleImageUpload(formData, setPrezzoImg);
    }
    if (event?.target?.name === "gasDichiration") {
      singleImageUpload(formData, setGasDichirationImg);
    }
    if (event?.target?.name === "patente") {
      singleImageUpload(formData, setPatenteImg);
    }
    if (event?.target?.name === "soggoirnoImg") {
      singleImageUpload(formData, setSoggoirnoImg);
    }
    if (event?.target?.name === "pagamento") {
      singleImageUpload(formData, setPagamento);
    }
    if (event?.target?.name === "dichiaration") {
      singleImageUpload(formData, setDichiarationImg);
    }
    if (event?.target?.name === "caricaModuloDiSky") {
      singleImageUpload(formData, setCaricaModuloDiSkyImg);
    }
    if (event?.target?.name === "codiceFiscale") {
      singleImageUpload(formData, setCodiceFiscaleImg);
    }
    if (event?.target?.name === "cIdentityImg") {
      singleImageUpload(formData, setCIdentityImg);
    }
    if (event?.target?.name === "vecchaiaBolletta") {
      singleImageUpload(formData, setVecchaiaBollettaImg);
    }
    if (event?.target?.name === "ibanImg") {
      singleImageUpload(formData, setIbanImg);
    }
  };
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - NEW GAS ORDER{" "}
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" m-5 p-5 rounded-md bg-thirdLightPrimary  ">
        <form
          onSubmit={handelAddGasOrder}
          className=" bg-lightPrimary rounded-md p-5"
        >
          <div className="">
            <p className="  font-semibold pb-10 ">New Gas Order</p>
          </div>
          <div className=" w-2/4 ">
            <p className=" text-xs pb-3 font-semibold">Operator</p>
            <select
              type="text"
              onChange={(e) => setOperatorName(e.target.value)}
              name="operator"
              className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select"
              required
            >
              <option selected value="">
                ..Choose...
              </option>
              {operators?.map((operator) => (
                <option value={operator?.operatorName} key={operator?._id}>
                  {operator?.operatorName}
                </option>
              ))}
            </select>
          </div>
          {operatorName && (
            <div className="mt-3 bg-white p-3 rounded-lg">
              <p className="text-2xl font-medium">
                {filterOffer[0]?.operatorName}
              </p>
              <p className="text-sm mt-2">{filterOffer[0]?.operatorDetails}</p>
            </div>
          )}
          <div className=" text-xs pt-3 font-semibold  ">
            <p>Introduction:</p>
          </div>

          <div className=" grid grid-cols-2 gap-4 pt-5">
            <div>
              <label
                htmlFor="name"
                className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Name"
                required
              />
            </div>
            <div>
              <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                Surname
              </label>
              <input
                type="text"
                name="surName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Sure Name"
                required
              />
            </div>
          </div>

          {/* component */}

          <div className=" grid grid-cols-4 gap-4 pt-4 ">
            <div>
              <label
                htmlFor="date"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                Date of birth
              </label>
              <DatePicker
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>

            <div>
              <div>
                <label
                  htmlFor="tax-code"
                  className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
                >
                  Tax ID code
                </label>
                <input
                  type="text"
                  id="sure_name"
                  name="taxIdCode"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Tax Code"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                Mobile
              </label>
              <input
                type="text"
                name="phone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Mobile Number"
                required
              />
            </div>
            <div>
              <label
                htmlFor="e-mail"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                E-mail
              </label>
              <input
                type="text"
                name="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Mobile Number"
                required
              />
            </div>
          </div>

          <div>
            <div>
              <p className=" text-xs font-semibold pt-2 pb-2">
                The address where you will activate the Wi-Fi (fixed) should be
                write here,
              </p>
              <textarea
                name="activateAddress"
                className="h-40 px-3 text-sm py-1  outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                placeholder="Add your text here"
              ></textarea>
            </div>
          </div>

          <div className=" grid grid-cols-2 gap-4 pt-5">
            <div>
              <label
                htmlFor="Identity"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                C. Identity
              </label>
              <input
                type="text"
                name="cIdentity"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter C. Identity"
                required
              />
            </div>
            <div>
              <label
                htmlFor="SOGGOIRNO"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                SOGGOIRNO
              </label>
              <input
                type="text"
                name="soggoirno"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter SOGGOIRNO"
                required
              />
            </div>
          </div>

          <div className=" grid grid-cols-3 gap-4 pt-4 ">
            <div>
              <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                CAP
              </label>
              <input
                type="text"
                name="cap"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter CAP "
                required
              />
            </div>

            <div>
              <div>
                <label
                  htmlFor="first_name"
                  className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
                >
                  Citta
                </label>
                <input
                  type="text"
                  name="citta"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=" Enter Citta "
                  required
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="Province "
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                Province
              </label>
              <input
                type="text"
                name="province"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=" Enter Province "
                required
              />
            </div>
          </div>

          {/* Document section  */}

          <div>
            <div className=" text-xs pt-3 pb-3 font-semibold  ">
              <p>Documents:</p>
            </div>
            <div className=" lg:grid lg:grid-cols-2 text-xs gap-4 ">
              <div className="max-w-2xl ">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  C. Identity
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="cIdentityImg"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  CODICE FISCALE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="codiceFiscale"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  Carica modulo di Sky
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="caricaModuloDiSky"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  DICHIARATION
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="dichiaration"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  PAGAMENTO E INVIO DELLA BOLLETTA ANCHE CONTO CORRENTE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="pagamento"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  SOGGOIRNO
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="soggoirnoImg"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  PATENTE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="patente"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  POD EE/PDR GAS DICHIRATION
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="gasDichiration"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  PREZZO NETTO NATURA CASA GAS
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="prezzo"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" pt-5 text-xs ">
            <div className=" w-2/4 mt-2">
              <label
                htmlFor="first_name"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                Codice PDR
              </label>
              <input
                type="text"
                name="CodicePdr"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Codice PDR"
                required
              />
            </div>

            <div className=" grid grid-cols-2 gap-4 pt-5">
              <div>
                <label
                  htmlFor="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-xs"
                >
                  MATRICOLA CONTATORE
                </label>
                <input
                  type="text"
                  name="matricolaContatore"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter MATRICOLA CONTATORE"
                  required
                />
              </div>
              <div className="max-w-2xl text-xs">
                <label className="text-xs font-medium text-gray-500">
                  VECCHIA BOLLETTA
                </label>
                <div className="relative z-0 mt-0.5 flex w-full text-xs -space-x-px">
                  <input
                    name="vecchaiaBolletta"
                    onChange={handleChangeUploadImage}
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
            </div>

            <div className=" grid grid-cols-2 gap-4 pt-5">
              <div className=" w-full ">
                <p className=" text-xs pb-3 font-semibold">CLIENTE WINDTRE</p>
                <select
                  type="text"
                  name="clienteWindtre"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option disabled selected>
                    Please Select
                  </option>
                  <option value="si">SI</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="NUMBERO-CELLULARE"
                  className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
                >
                  NUMBERO CELLULARE
                </label>
                <input
                  type="text"
                  name="numberoCellulare"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter NUMBERO CELLULARE"
                  required
                />
              </div>

              <div className=" w-full ">
                <p className=" text-xs pb-3 font-semibold">
                  CLIENTE ALTRI OPERATORE{" "}
                </p>
                <select
                  type="text"
                  name="clienteAltriOperatore"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option disabled selected>
                    Please Select
                  </option>
                  <option value="si">SI</option>
                  <option value="no">NO</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="NUMERO-CELLULARE"
                  className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
                >
                  NUMERO CELLULARE
                </label>
                <input
                  type="text"
                  name="numeroCellulare"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter NUMERO CELLULARE"
                  required
                />
              </div>
            </div>
          </div>

          <div className=" pt-5 ">
            <div className=" text-xs pb-3 font-semibold  ">
              <p>Payment:</p>
            </div>

            <select
              type="text"
              name="payment"
              onChange={(e) => setPayment(e.target.value)}
              id="first_name"
              className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg w-full p-2.5 dark:bg-thirdLightPrimary "
              placeholder="Select"
              required
            >
              {" "}
              <option value="" selected disabled>
                None
              </option>
              <option value="Postal">Postal</option>
              <option value="Current Account / RID IBAN">
                Current Account / RID IBAN
              </option>
            </select>

            <div className="grid grid-cols-2 w-full gap-5">
              {payment === "Current Account / RID IBAN" && (
                <div className="max-w-2xl">
                  <label htmlFor="photobutton" className="text-xs font-medium ">
                    Iban Number
                  </label>
                  <input
                    type="text"
                    name="ibanNumber"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder=" Enter Iban number"
                    required
                  />
                </div>
              )}
              {payment === "Current Account / RID IBAN" && (
                <div className="max-w-2xl">
                  <label htmlFor="photobutton" className="text-xs font-medium ">
                    Iban Image
                  </label>
                  <div className="relative z-0 mt-0.5 flex w-full -space-x-px text-xs">
                    <input
                      name="ibanImg"
                      onChange={handleChangeUploadImage}
                      type="file"
                      className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3  text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                      required
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=" pt-5">
            <button
              type="submit"
              class="text-white bg-secondary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewGasOrder;
