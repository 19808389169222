import React, { useEffect, useState } from "react";
import OrderFilter from "./OrderFilter";
import OrderHistoryTable from "./OrderHistoryTable";
import AuthUser from "../../Hooks/authUser";
import Clock from "../Clock/Clock";
import Pagination from "../../Shared/Pagination";

const OrderHistory = () => {
  const { userInfo } = AuthUser();
  const [luceOrders, setLuceOrders] = useState([]);
  const [wifiOrders, setWifiOrders] = useState([]);
  const [gasOrders, setGasOrders] = useState([]);
  const [luceFilterOrders, setLuceFilterOrders] = useState([]);
  const [wifiFilterOrders, setWifiFilterOrders] = useState([]);
  const [gasFilterOrders, setGasFilterOrders] = useState([]);

  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);

  const luceUrl = `https://servergenzam.genzamwallet.com/api/v1/luceOrders/?fieldName=${"resellerId"}&&fieldValue=${
    userInfo?._id
  }&&size=${size}&&page=${page}`;
  const gasUrl = `https://servergenzam.genzamwallet.com/api/v1/newGasOrder/?fieldName=${"resellerId"}&&fieldValue=${
    userInfo?._id
  }&&size=${size}&&page=${page}`;
  const wifiUrl = `https://servergenzam.genzamwallet.com/api/v1/newWifiOrder/specific/?fieldName=${"resellerId"}&&fieldValue=${
    userInfo?._id
  }&&size=${size}&&page=${page}`;
  //   get luceOrder
  useEffect(() => {
    fetch(luceUrl)
      .then((res) => res.json())
      .then((data) => {
        setLuceOrders(data?.luceOrders);
        setLuceFilterOrders(data?.luceOrders);
        setQuantity(data?.total);
      });
  }, [luceUrl]);

  //   get newWifiOrder
  useEffect(() => {
    fetch(wifiUrl)
      .then((res) => res.json())
      .then((data) => {
        setWifiOrders(data?.data);
        setWifiFilterOrders(data?.data);
        setQuantity(data?.total);
      });
  }, [wifiUrl]);

  //   get gasOrders
  useEffect(() => {
    fetch(gasUrl)
      .then((res) => res.json())
      .then((data) => {
        setGasOrders(data?.newGasOrder);
        setGasFilterOrders(data?.newGasOrder);
        setQuantity(data?.total);
      });
  }, [gasUrl]);

  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - ORDER HISTORY{" "}
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>

      <div className=" m-5 ">
        <div className=" bg-thirdLightPrimary p-5  rounded-md ">
          <div className=" bg-lightPrimary rounded-md p-5">
            <div className="">
              <p className="  font-semibold p-5 ">Order History</p>
            </div>
            {/* search bar */}
            <OrderFilter
              setGasFilterOrders={setGasFilterOrders}
              setLuceFilterOrders={setLuceFilterOrders}
              setWifiFilterOrders={setWifiFilterOrders}
              gasOrders={gasOrders}
              luceOrders={luceOrders}
              wifiOrders={wifiOrders}
            />
          </div>
          {/* data table */}

          <div className=" pt-5  ">
            <div class="relative overflow-x-auto shadow-md sm:rounded-lg bg-lightPrimary p-5">
              <table class="w-full text-sm text-left text-gray-500  dark:text-gray-400">
                <thead class="text-xs text-white uppercase bg-black dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Code
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Order Type
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Date
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Operator
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Mobile
                    </th>

                    <th scope="col" class="px-6 py-3">
                      Payment S.
                    </th>

                    <th scope="col" class="px-6 py-3">
                      Order S.
                    </th>
                    <th scope="col" class="px-6 py-3">
                      View
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {gasFilterOrders?.map((order) => (
                    <OrderHistoryTable
                      key={order?._id}
                      order={order}
                      phone={order?.introduction?.phone}
                    />
                  ))}

                  {luceFilterOrders?.map((order) => (
                    <OrderHistoryTable
                      key={order?._id}
                      order={order}
                      phone={order?.introduction?.phone}
                    />
                  ))}
                  {wifiFilterOrders?.map((order) => (
                    <OrderHistoryTable
                      key={order?._id}
                      order={order}
                      phone={order?.newWifiOrder?.phone}
                    />
                  ))}
                </tbody>
              </table>

              <Pagination
                quantity={quantity}
                page={page}
                setPage={setPage}
                size={size}
                setSize={setSize}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
