import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom"; // Import useParams to get the URL parameter

const AdminManageOfferPostEdit = () => {
  const [startDate, setStartDate] = useState(new Date());
  const { offerId } = useParams();

  const [formData, setFormData] = useState({
    operator: "",
    offerName: "",
    defaultPrice: "",
    iban: "",
    offervaliddate: startDate,
    poststatus: "",
    costPerMonth: "",
    totalRecharge: "",
    posttype: "",
    offerValidFor: "",
    freeInternetPack: "",
    freeMinutesLocalOperator: "",
    freeMinutesInternational: "",
    internationalMinutesValidFor: "",
    unlimitedMinutesTo: "",
    othersInformation: "",
  });

  // Fetch the existing data for the specific offerId
  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/operatorOffer/${offerId}`
        );

        // Populate the form with the fetched data
        setFormData(response.data);
      } catch (error) {
        alert(
          "An error occurred while fetching offer data. Please try again later."
        );
      }
    };

    fetchOfferData();
  }, [offerId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.patch(
        `https://servergenzam.genzamwallet.com/api/v1/operatorOffer/${offerId}`,
        formData
      );

      if (response.status === 200) {
        // console.log(formData);
        alert("Offer data updated successfully");

        // Reset the form after successful update
        setFormData({
          operator: "",
          offerName: "",
          defaultPrice: "",
          iban: "",
          offervaliddate: startDate,
          poststatus: "",
          costPerMonth: "",
          totalRecharge: "",
          posttype: "",
          offerValidFor: "",
          freeInternetPack: "",
          freeMinutesLocalOperator: "",
          freeMinutesInternational: "",
          internationalMinutesValidFor: "",
          unlimitedMinutesTo: "",
          othersInformation: "",
        });

        // Redirect to the previous page
        // history.goBack();
      } else {
        alert("Failed to update offer data. Please try again later.");
      }
    } catch (error) {
      alert(
        "An error occurred while updating offer data. Please try again later."
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-4xl flex justify-center  gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="icon-park:weixin-cards-offers"></Icon>
            Edit
          </h1>
        </div>
      </div>

      {/* post table  */}
      <div className="bg-white border border-4 rounded-lg shadow relative m-10">
        <div className="flex items-start justify-between p-5 border-b rounded-t">
          <h3 className="text-xl font-semibold">
            Latest & Upcomming SIM POST Edit
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="product-modal"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="p-6 space-y-6">
          {/* form start */}
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="product-name"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Operator Name
                </label>
                <input
                  type="text"
                  name="operator"
                  onChange={handleChange}
                  value={formData.operator}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Operator Name ”"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="category"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Offer Name
                </label>
                <input
                  type="text"
                  name="offerName"
                  onChange={handleChange}
                  value={formData.offerName}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Offer Name"
                  required
                />
              </div>

              {/* date picker */}

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="category"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Offer Valid Date Date{" "}
                </label>
                <DatePicker
                  name="offervaliddate"
                  // onChange={handleChange}
                  // value={formData.buyDate}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Offer Name"
                  required
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="brand"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  IBAN
                </label>
                <input
                  type="number"
                  name="iban"
                  onChange={handleChange}
                  value={formData.iban}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter IBAN"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Default Price
                </label>
                <input
                  type="number"
                  name="defaultPrice"
                  onChange={handleChange}
                  value={formData.defaultPrice}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="€"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Cost Per Month{" "}
                </label>
                <input
                  type="Cost Per Month "
                  name="costPerMonth"
                  onChange={handleChange}
                  value={formData.costPerMonth}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="€"
                  required
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Total Recharge
                </label>
                <input
                  type="number"
                  name="totalRecharge"
                  onChange={handleChange}
                  value={formData.totalRecharge}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="€"
                  required
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Offer Valid For
                </label>
                <div className="relative">
                  <select
                    name="offerValidFor"
                    onChange={handleChange}
                    value={formData.offerValidFor}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option value="unlimited">Unlimited</option>
                    <option value="limited">Limited</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Post Status
                </label>
                <div className="relative">
                  <select
                    name="poststatus"
                    onChange={handleChange}
                    value={formData.poststatus}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Post Type
                </label>
                <div className="relative">
                  <select
                    name="posttype"
                    onChange={handleChange}
                    value={formData.posttype}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option value="latestOffer">Latest Offer</option>
                    <option value="upCommingOffer">Upcomming Offer</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Free Internet Pack (GB){" "}
                </label>
                <input
                  type="text"
                  name="freeInternetPack"
                  onChange={handleChange}
                  value={formData.freeInternetPack}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="00 GIGA"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Free Minuites for Local Operator{" "}
                </label>
                <input
                  type="number"
                  name="freeMinutesLocalOperator"
                  onChange={handleChange}
                  value={formData.freeMinutesLocalOperator}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter free Minuites for Local Operator"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Free Minutes for International
                </label>
                <div className="relative">
                  <select
                    name="freeMinutesInternational"
                    onChange={handleChange}
                    formData={formData.freeMinutesInternational}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  International Minutes Valid For{" "}
                </label>
                <input
                  type="text"
                  name="internationalMinutesValidFor"
                  onChange={handleChange}
                  value={formData.internationalMinutesValidFor}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter International Minutes Valid For"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Unlimited Minutes To
                </label>
                <input
                  type="text"
                  name="unlimitedMinutesTo"
                  onChange={handleChange}
                  value={formData.unlimitedMinutesTo}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter International Minutes Valid For"
                />
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="product-details"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Others Information{" "}
                </label>
                <textarea
                  name="othersInformation"
                  onChange={handleChange}
                  value={formData.othersInformation}
                  rows="6"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                  placeholder="Details"
                ></textarea>
              </div>
            </div>

            <div className="p-6 border-t border-gray-200 rounded-b">
              <button
                className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                type="submit"
              >
                Update
              </button>
            </div>
          </form>

          {/* close form */}
        </div>
      </div>
    </div>
  );
};

export default AdminManageOfferPostEdit;
