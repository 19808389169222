import { Input } from "@material-tailwind/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Clock from "../Clock/Clock";
const ViewWifi = () => {
  const { id } = useParams();
  const [wifiOfferData, setWifiOfferData] = useState({});
  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/wifioffer/${id}`
        );
        setWifiOfferData(response.data);
      } catch (error) {
        console.error("Error fetching specific offer data:", error);
      }
    };

    fetchOfferData();
  }, [id]);

  return (
    <div className="min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - VIEW WI-FI
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary m-5 rounded-md">
        <div className="overflow-x-auto   ">
          <table className="min-w-full text-xs bg-gray-50 rounded-md ">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col className="" />
            </colgroup>
            <thead className="dark:bg-gray-700 text-xs">
              <tr className="text-left grid grid-cols-2 bg-gray-200 rounded-md  ">
                <th className="p-3"> View Wifi</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2  items-center">
                <td className="p-3 ">
                  <p className=" ">Operator</p>
                </td>
                <td className="p-3">
                  <p>{wifiOfferData?.WifiOperatorName}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Offer Name </p>
                </td>
                <td className="p-3">
                  <p>{wifiOfferData?.wifiOfferName}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Offer Details </p>
                </td>
                <td className="p-3">
                  <p>{wifiOfferData?.wifiOfferDetails}</p>
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Status </p>
                </td>
                <td className="p-3">
                  <p>{wifiOfferData?.wifiOfferStatus}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewWifi;
