import React, { useState, useEffect } from "react";
import Loading from "../../Shared/Loading/Loading";
import AuthUser from "../../Hooks/authUser";
import RequestSimTable from "./RequestSimTable";

const RequestSimHistory = () => {
  const [sims, setSims] = useState([]);
  const [loading, setLoading] = useState(true); // Start with loading state
  const { userInfo } = AuthUser();
  console.log(sims);

  useEffect(() => {
    const url = `https://servergenzam.genzamwallet.com/api/v1/orderSim/specific/?fieldName=${"ordererEmail"}&&fieldValue=${
      userInfo?.email
    }`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setSims(data?.data);
        setLoading(false); // Set loading to false after data is fetched
      });
  }, [userInfo?.email]); // Trigger fetch when user email changes

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen">
      <div className=" bg-thirdLightPrimary  flex    justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - REQUEST SIM HISTORY{" "}
          </p>
        </div>
      </div>
      <div className="bg-thirdLightPrimary p-5 m-5 rounded-md">
        <div className="m-5 bg-lightPrimary p-5 rounded-md">
          <table className="table-auto w-full text-xs bg-gray-400 rounded-md">
            <thead className="bg-black text-white">
              <tr>
                <th className="border px-4 py-2">#</th>
                <th className="border px-4 py-2"> Order Date</th>
                <th className="border px-4 py-2"> Comfirm Date</th>

                <th className="border px-4 py-2">User</th>
                <th className="border px-4 py-2">Details</th>
                <th className="border px-4 py-2">Total</th>
                <th className="border px-4 py-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {sims?.reverse().map((sim, index) => (
                <RequestSimTable index={index} sim={sim} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RequestSimHistory;
