import React from "react";

const About = () => {
  return (
    <div className="min-h-screen">
      <div class="sm:flex items-center max-w-screen-xl">
        <div class="sm:w-1/2 p-10">
          <div class="image object-center text-center">
            <img src="https://i.imgur.com/WbQnbas.png"></img>
          </div>
        </div>
        <div class="sm:w-1/2 p-5">
          <div class="text">
            <span class="text-gray-500 border-b-2 border-indigo-600 uppercase">
              About us
            </span>
            <h2 class="my-4 font-bold text-3xl  sm:text-4xl ">
              About <span class="text-indigo-600">Our Company</span>
            </h2>
            <p class="text-gray-700">
              Welcome to our innovative digital product transfer business! At
              our company, we provide a seamless platform for individuals and
              businesses to trade an extensive array of digital goods. Our
              mission is to empower entrepreneurs and enthusiasts alike to
              become multi-service providers and thrive in the digital economy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
