import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import "./Modal.css"; // Import the modal CSS file

const AdminScrollingTextManage = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState("");

  useEffect(() => {
    // Fetch data from the backend API endpoint
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/scrollingTextRoutes/texts")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const openModal = (offerId) => {
    setSelectedOfferId(offerId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteOffer = (offerId) => {
    openModal(offerId); // Open the confirmation modal
  };

  const handleConfirmDelete = () => {
    axios
      .delete(
        `https://servergenzam.genzamwallet.com/api/v1/scrollingTextRoutes/texts/${selectedOfferId}`
      )
      .then((response) => {
        // Remove the deleted offer from the data state
        setData((prevData) =>
          prevData.filter((offer) => offer._id !== selectedOfferId)
        );
        closeModal(); // Close the confirmation modal
      })
      .catch((error) => {
        console.error("Error deleting offer:", error);
      });
  };

  return (
    <div>
      <div>
        <div className="max-w-3xl mx-auto text-center mt-5">
          <h1 className="text-4xl flex justify-center gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="material-symbols:manage-history-rounded" />
            Manage Scrolling Text
          </h1>
        </div>
      </div>

      <div className="overflow-x-auto m-5">
        <table className="min-w-full text-xs bg-gray-100">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="  ">
            <tr className="text-center">
              <th className="p-3">#</th>
              <th className="p-3 text-left">Title</th>
              <th className="p-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.reverse()?.map((offer, index) => (
              <tr
                key={offer?._id}
                className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
              >
                <td className="p-3">
                  <p>{index + 1}</p>
                </td>
                <td className="p-3  text-justify">
                  <p>{offer.title}</p>
                </td>
                <td className="p-3 text-xl items-center justify-center flex">
                  <p className="flex gap-x-3 text-primary">
                    <Icon
                      icon="ant-design:delete-filled"
                      onClick={() => handleDeleteOffer(offer._id)} // Open the confirmation modal
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}

      <div
        className="modal-overlay"
        style={{ display: isModalOpen ? "flex" : "none" }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title">Confirm Deletion</h2>
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className="modal-body">
            <p>Are you sure you want to delete this notification?</p>
          </div>
          <div className="modal-footer">
            <button className="cancel" onClick={closeModal}>
              Cancel
            </button>
            <button className="confirm" onClick={handleConfirmDelete}>
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminScrollingTextManage;
