import { Route, Routes } from "react-router-dom";
import "./App.css";
import PublicRoutes from "./Routes/PublicRoutes";
import PrivateRoute from "./Routes/PrivateRoute";
import RequireAuth from "./Hooks/RequireAuth/RequireAuth";
import ResellerDashbord from "./Pages/ResellerDashBord/ResellerDashbord";
import ResellerHome from "./Component/Reseller/ResellerHome";
import ResellerRoute from "./Routes/ResellerRoute";
import UserDashboard from "./Pages/UserDashboard/UserDashboard";
import UserRoute from "./Routes/UserRoute";
import UserHome from "./Pages/UserDashboard/UserHome";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import AdminRoute from "./Routes/AdminRoute";
import AdminHome from "./Component/AdminDashboard/AdminHome";
import Home from "./Pages/Home/Home";
import LandingPage from "./Component/Home/LandingPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <Routes>
        {/* [private route] this route accessible for only logged in user */}
        <Route>
          {PrivateRoute.map(({ path, Component }, index) => (
            <Route
              key={index}
              path={path}
              element={
                <RequireAuth>
                  <Component />
                </RequireAuth>
              }
            ></Route>
          ))}
        </Route>

        {/* landing page setup */}
        <Route path="/" element={<Home />}>
          <Route index element={<LandingPage></LandingPage>} />
          {PublicRoutes.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        {/* ******* [admin route] this route accessible for only admin ******* */}

        <Route path="/adminDashboard" element={<AdminDashboard />}>
          <Route index element={<AdminHome />}></Route>
          {AdminRoute.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>

        {/* ******* [user route] this route accessible for only user ******* */}
        <Route path="/userDashboard" element={<UserDashboard />}>
          <Route index element={<UserHome />}></Route>

          {UserRoute.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>
        {/* ******* [Reseller route] this route accessible for only Reseller ******* */}

        <Route path="/resellerDashboard" element={<ResellerDashbord />}>
          <Route index element={<ResellerHome />}></Route>

          {ResellerRoute.map(({ path, Component }, index) => (
            <Route key={index} path={path} element={<Component />} />
          ))}
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
