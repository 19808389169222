import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Clock from "../Clock/Clock";
import AuthUser from "../../Hooks/authUser";

const TransactionHistory = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = AuthUser();

  const [reportStartDate, setReportStartDate] = useState(null);
  const [reportEndDate, setReportEndDate] = useState(null);

  useEffect(() => {
    setLoading(true);
    // Fetch all bills from the API
    fetch("https://servergenzam.genzamwallet.com/api/v1/bills")
      .then((res) => res.json())
      .then((data) => {
        // Convert the startDate to the "YYYY-MM-DD" format
        const formattedStartDate = startDate.toISOString().split("T")[0];

        // Filter the bills based on the selected date and user's email
        const filteredData = data.filter((bill) => {
          const transactionDate = bill.date.split("T")[0]; // Assuming the API response has the date field as "date"
          return (
            bill.customerName === userInfo?.email &&
            transactionDate === formattedStartDate
          );
        });

        console.log("Filtered data:", filteredData); // Log the filtered data
        setTransactionData(filteredData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching bills data:", error);
        setLoading(false);
      });
  }, [startDate, userInfo?.email]);

  useEffect(() => {
    // Format the report start and end dates
    if (reportStartDate && reportEndDate) {
      const formattedStartDate = formatDate(reportStartDate);
      const formattedEndDate = formatDate(reportEndDate);
      setReportStartDate(`${formattedStartDate}`);
      setReportEndDate(`${formattedEndDate}`);
    }
  }, [reportStartDate, reportEndDate]);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="min-h-screen">
      <div className="bg-thirdLightPrimary flex justify-between">
        <div>
          <p className="font-semibold p-2">
            RESELLER DASHBOARD - TRANSACTION REPORTS
          </p>
        </div>
        <div>
          <p className="font-semibold p-2">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-1 gap-5 max-w-lg mx-auto p-5 m-5 rounded bg-thirdLightPrimary">
          <div className=" p-5 bg-lightPrimary">
            <div>
              <p className="">Transaction Report</p>
            </div>
            <div>
              <p className="text-xs  mb-1">Select Date</p>
            </div>
            <div>
              <DatePicker
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div>
              <button
                onClick={() => {}}
                className=" mt-2 bg-secondary text-white p-2 rounded-md text-xs"
              >
                Search Transaction
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="bg-thirdLightPrimary m-5 p-5  rounded-md">
          <div className="  bg-lightPrimary rounded-md ">
            <div className="container mx-auto sm:p-4 dark:text-gray-100">
              <p className="mb-4 text-2xl font-semibold leading text-center">
                Transaction Reports
              </p>
              <p className="text-center pb-5">
                {reportStartDate && reportEndDate && (
                  <span>
                    Report Period: {reportStartDate} To {reportEndDate}
                  </span>
                )}
              </p>
              <div className="overflow-x-auto">
                <table className="min-w-full text-xs">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col className="w-24"></col>
                  </colgroup>
                  <thead className="bg-black text-white rounded-md">
                    <tr className="text-left">
                      <th className="p-3">#</th>
                      <th className="p-3">Code</th>
                      <th className="p-3">Date</th>
                      <th className="p-3">Entry By</th>
                      <th className="p-3">Details</th>
                      <th className="p-3">Debit</th>
                      <th className="p-3">Credit</th>
                      <th className="p-3">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionData.map((transaction, index) => (
                      <tr
                        key={index}
                        className="border-b border-opacity-20 dark:border-gray-700 bg-white"
                      >
                        <td className="p-3">
                          <p>{index + 1}</p>
                        </td>
                        <td className="p-3">
                          <p>{transaction._id}</p>
                        </td>
                        <td className="p-3">
                          <p>{transaction.date}</p>
                        </td>
                        <td className="p-3">
                          <p>{transaction.entryBy}</p>
                        </td>
                        <td className="p-3">
                          <p>{transaction.billDetails}</p>
                        </td>
                        <td className="p-3">
                          <p>{transaction.debit}</p>
                        </td>
                        <td className="p-3">
                          <p>{transaction.credit}</p>
                        </td>
                        <td className="p-3">
                          <p>{transaction.paymentStatus}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionHistory;
