import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

const AdminSimPost = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [operators, setOperators] = useState([]);
  const [users, setUsers] = useState([]);
  const [operatorName, setOperatorName] = useState("");
  const [offerName, setOfferName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/simOperator")
      .then((res) => res.json())
      .then((data) => setOperators(data));
  }, []);
  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/user")
      .then((res) => res.json())
      .then((data) => setUsers(data?.data));
  }, []);
  // console.log(operators);
  // filter data for offer
  const filterOffer = operators?.filter(
    (op) => op?.operatorName === operatorName
  );
  // console.log();
  // console.log(offerName, operatorName, userEmail);
  const [formData, setFormData] = useState({
    operator: "",
    iccidNumber: "",
    simNumber: "",
    buyPrice: "",
    buyDate: startDate,
    othersInformation: "",
  });
  const sim = {
    ...formData,
    resellerEmail: userEmail,
    offerName: offerName,
    operatorName: operatorName,
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://servergenzam.genzamwallet.com/api/v1/allsim",
        sim
      );
      if (response.status === 201) {
        console.log(sim);
        alert("SIM POST  successfully");
        setFormData({
          operator: "",

          iccidNumber: "",
          simNumber: "",
          buyPrice: "",
          buyDate: startDate,

          othersInformation: "",
        });
      } else {
        alert("Failed to submit SIM Post. Please try again later.");
      }
    } catch (error) {
      alert(
        "An error occurred while submitting the order. Please try again later."
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevenFormData) => ({
      ...prevenFormData,
      [name]: value,
    }));
  };

  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-4xl flex justify-center  gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="flat-color-icons:sim-card"></Icon>
            SIM POST
          </h1>
        </div>
      </div>
      {/* <div>
        <form className="mt-10 mx-auto max-w-xl py-2 px-6 rounded-full bg-gray-50 border flex focus-within:border-gray-300">
          <input
            type="text"
            placeholder="Search Agent/User"
            className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-0 py-0"
            name="topic"
          />
          <button className="flex flex-row items-center justify-center min-w-[130px] px-4 rounded-full font-medium tracking-wide border disabled:cursor-not-allowed disabled:opacity-50 transition ease-in-out duration-150 text-base bg-black text-white font-medium tracking-wide border-transparent py-1.5 h-[38px] -mr-3">
            Search
          </button>
        </form>
      </div> */}

      {/* post table  */}
      <div className="bg-white border border-4 rounded-lg shadow relative m-10">
        <div className="flex items-start justify-between p-5 border-b rounded-t">
          <h3 className="text-xl font-semibold">SIM Details</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="product-modal"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="p-6 space-y-6">
          {/* form start */}
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label className="text-sm font-medium text-gray-900 block mb-2">
                  User Email
                </label>
                <select
                  type="text"
                  name="useEmail"
                  onChange={(e) => setUserEmail(e.target.value)}
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option selected>..Choose...</option>
                  {users?.map((user) => (
                    <option value={user?.email} key={user?._id}>
                      {user?.email}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="product-name"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Operator Name
                </label>
                <select
                  type="text"
                  name="operatorName"
                  onChange={(e) => setOperatorName(e.target.value)}
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option selected>..Choose...</option>
                  {operators?.map((operator) => (
                    <option value={operator?.operatorName} key={operator?._id}>
                      {operator?.operatorName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="category"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Offer Name
                </label>
                <select
                  type="text"
                  name="offerName"
                  onChange={(e) => setOfferName(e.target.value)}
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option selected>..Choose...</option>
                  {filterOffer[0]?.offerName?.map((offer) => (
                    <option value={offer?.offerName}>{offer?.offerName}</option>
                  ))}
                </select>
              </div>

              {/* date picker */}

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="category"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Buying Date{" "}
                </label>
                <DatePicker
                  name="buyDate"
                  // onChange={handleChange}
                  // value={formData.buyDate}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Offer Name"
                  required
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="brand"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  ICCID Number
                </label>
                <input
                  required
                  type="number"
                  name="iccidNumber"
                  onChange={handleChange}
                  value={formData.iccidNumber}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter ICCID Number"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="brand"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  SIM Number
                </label>
                <input
                  type="number"
                  name="simNumber"
                  onChange={handleChange}
                  value={formData.simNumber}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter SIM Number"
                  required
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Buy Price
                </label>
                <input
                  type="number"
                  name="buyPrice"
                  onChange={handleChange}
                  value={formData.buyPrice}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="€"
                  required
                />
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="product-details"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Others Information{" "}
                </label>
                <textarea
                  required
                  name="othersInformation"
                  onChange={handleChange}
                  value={formData.othersInformation}
                  rows="6"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                  placeholder="Details"
                ></textarea>
              </div>
            </div>

            <div className="p-6 border-t border-gray-200 rounded-b">
              <button
                className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                type="submit"
              >
                Post
              </button>
            </div>
          </form>

          {/* close form */}
        </div>
      </div>
    </div>
  );
};

export default AdminSimPost;
