import React from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import heroimg from "../../Assets/hero.jpg";
import { Icon } from "@iconify/react";
import AuthUser from "../../Hooks/authUser";

const Hero = () => {
  const { userInfo } = AuthUser();
  return (
    <div>
      <div class="bg-white dark:bg-gray-900 lg:grid lg:grid-cols-2 sm:grid sm:grid-cols-1">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
          <div>
            <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
              <Typewriter
                options={{
                  strings: ["Genzam-IT"],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h1>
          </div>

          <div className=" lg:mb-4  sm:mb-2 text-justify p-5">
            <p>
              Join our digital product transfer business and trade digital goods
              Become a multi-service providing business point with a vast range
              of digital products.
            </p>
          </div>
          <div className=" lg:flex lg:justify-center lg:items-center gap-x-2 ">
            <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
              <Link
                to={`/${userInfo?.role}Dashboard`}
                class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
              >
                Get started
                <svg
                  class="w-3.5 h-3.5 ml-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </Link>
            </div>
            <div class="lg:flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
              <Link
                to="/login"
                class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                <Icon icon="flat-color-icons:sim-card"></Icon>
                SIM ACTIVATION LOGIN
              </Link>

              <a
                href="https://wallet.genzam.it"
                class="inline-flex justify-center  items-center py-3 px-5 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                <Icon icon="icon-park:wallet"></Icon>
                Wallet LOGIN
              </a>
            </div>
          </div>
        </div>

        <div>
          <img src={heroimg} className="  w-5/6" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
<div></div>;
