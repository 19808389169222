import { Input } from "@material-tailwind/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import CreateUserHook from "../../Hooks/createUserHook";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";

const AdminNewUser = () => {
  const [startDate, setStartDate] = useState(new Date());
  const { userInfo } = AuthUser();
  const navigate = useNavigate();
  const handelSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    const name = e.target.name.value;
    const status = e.target.status.value;
    // const coopVoce = e.target.coopVoce.value;
    const loginId = e.target.loginId.value;
    const phone = e.target.phone.value;
    const dateOfBirth = startDate;
    const userName = e.target.userName.value;
    const contactPerson = e.target.contactPerson.value;
    const codiceFiscal = e.target.codiceFiscal.value;
    const pivaNumber = e.target.pivaNumber.value;
    const pecId = e.target.pecId.value;
    const codiceUnivoco = e.target.codiceUnivoco.value;
    const address = e.target.address.value;
    const city = e.target.city.value;
    const country = e.target.country.value;
    const user = {
      name,
      userName,
      password,
      email,
      status,
      contactPerson,
      codiceFiscal,
      pivaNumber,
      pecId,
      codiceUnivoco,
      address,
      city,
      country,
      // coopVoce,
      loginId,
      phone,
      dateOfBirth,
      role: "user",
      referResellerEmail: userInfo?.email,
      referResellerId: userInfo?._id,
      referResellerName: userInfo?.name,
    };
    console.log(user);
    CreateUserHook(user, navigate);
    e.target.reset();
  };
  return (
    <div className="min-h-screen">
      <div className="   bg-green-500  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">ADMIN DASHBOARD - NEW USER</p>
        </div>
      </div>
      <div className=" m-5 bg-thirdLightPrimary rounded-md">
        <div className="overflow-x-auto   ">
          <form onSubmit={handelSubmit}>
            <table className="min-w-full text-xs bg-gray-50 rounded-md ">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="" />
              </colgroup>

              <tbody>
                {/* <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Coop Voce</p>
                  </td>
                  <td className="p-3">
                    <div>
                      {" "}
                      <select
                        type="text"
                        id="first_name"
                        name="coopVoce"
                        className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Select"
                        required
                      >
                        {" "}
                        <option selected>Ahmod Khan</option>
                        <option>Genzam </option>
                        <option>Julfa Begume </option>
                      </select>
                    </div>
                  </td>
                </tr> */}
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">User Name</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="userName"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Enter Your User Name"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Name</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="name"
                      name="name"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Name"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Phone</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="phone"
                      name="phone"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Phone"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Email</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="email"
                      name="email"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="type your email"
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Contact Person</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="contactPerson"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Contact Person"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Codice Fiscal</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="codiceFiscal"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Codice Fiscal"
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Piva Number</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="pivaNumber"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Piva Number"
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Pec Id</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="pecId"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Pec Id"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Codice Univoco</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="codiceUnivoco"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Codice Univoco"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Address</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="address"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Address"
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">city</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="city"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Enter your City"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Country</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="country"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Enter your country"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Date of Birth </p>
                  </td>
                  <td className="p-3  ">
                    <DatePicker
                      className="bg-gray-50 border border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Login ID</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="loginId"
                      name="loginId"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Login ID"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Password</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      id="password"
                      name="password"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Password"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Status </p>
                  </td>
                  <td className="p-3 ">
                    <div>
                      {" "}
                      <select
                        type="text"
                        id="status"
                        name="status"
                        className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Select"
                        required
                      >
                        {" "}
                        <option selected value="active">
                          Active
                        </option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </td>
                </tr>

                <div className=" p-5">
                  <button type="submit" className=" bg-primary p-3 rounded-md">
                    {" "}
                    Save User{" "}
                  </button>
                </div>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminNewUser;
