import React from "react";
import Clock from "../../Component/Clock/Clock";

const UserPaymentHistory = () => {
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            USER DASHBOARD - PAYMENT HISTORY
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary m-5 p-5 rounded-md">
        <div className="container  bg-lightPrimary  rounded-md  mx-auto sm:p-4  dark:text-gray-100 ">
          <p className="mb-4  font-semibold leadi text-xs"> Payment History</p>
          <div className="overflow-x-auto">
            <table className="table-auto w-full  text-xs bg-gray-400 rounded-md">
              <thead className=" text-white bg-black">
                <tr>
                  <th className=" border px-4 py-2">#</th>
                  <th className=" border px-4 py-2">Code</th>
                  <th className=" border px-4 py-2">Received Date </th>
                  <th className=" border px-4 py-2">Amount</th>
                  <th className=" border px-4 py-2">Entry</th>
                  <th className=" border px-4 py-2">MNP D.</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border bg-white px-2 py-1 text-center">
                  <td className="border px-2 py-1 text-center"> 1</td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-4 py-2 text-center"> </td>
                  <td className="border px-2 py-1 text-center"></td>
                  <td className="border px-4 py-2 text-center"></td>
                  <td className="border px-2 py-1 text-center"> </td>
                </tr>

                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPaymentHistory;
