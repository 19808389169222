import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

const AdminWifiOfferPost = () => {
  const [startDate, setStartDate] = useState(new Date());
  const { id } = useParams();

  const [formData, setFormData] = useState({
    WifiOperatorName: "",
    wifiOfferName: "",
    wifiOfferStatus: "active",
    wifiOfferDetails: "",
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/wifioffer/${id}`
        );
        setFormData(response.data);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchOfferData();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.put(
        `https://servergenzam.genzamwallet.com/api/v1/wifioffer/${id}`,
        formData
      );
      if (response.status === 200) {
        console.log(formData);
        alert("WiFi offer updated successfully");
      } else {
        alert("Failed to update WiFi offer. Please try again later.");
      }
    } catch (error) {
      alert(
        "An error occurred while updating the WiFi offer. Please try again later."
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <div className="max-w-3xl mx-auto text-center mt-5">
          <h1 className="text-4xl flex justify-center gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="material-symbols:wifi" />
            Wifi Offer EDIT
          </h1>
        </div>
      </div>

      {/* post table */}
      <div className="bg-white border border-4 rounded-lg shadow relative m-10">
        <div className="flex items-start justify-between p-5 border-b rounded-t">
          <h3 className="text-xl font-semibold">Wifi Offer Edit</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="product-modal"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="p-6 space-y-6">
          {/* form start */}
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="product-name"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Wifi Operator Name
                </label>
                <input
                  type="text"
                  name="WifiOperatorName"
                  onChange={handleChange}
                  value={formData.WifiOperatorName}
                  required
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Operator Name"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="category"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Wifi Offer Name
                </label>
                <input
                  type="text"
                  name="wifiOfferName"
                  onChange={handleChange}
                  value={formData.wifiOfferName}
                  required
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Offer Name"
                />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Wi-fi Offer Status
                </label>
                <div className="relative">
                  <select
                    name="wifiOfferStatus"
                    onChange={handleChange}
                    value={formData.wifiOfferStatus}
                    required
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option value="">Select</option>
                    <option value="Active">Active</option>
                    <option value="InActive">InActive</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="product-details"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Wifi Offer Details
                </label>
                <textarea
                  name="wifiOfferDetails"
                  onChange={handleChange}
                  value={formData.wifiOfferDetails}
                  rows="6"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-4"
                  placeholder="Details"
                ></textarea>
              </div>
            </div>

            <div className="p-6 border-t border-gray-200 rounded-b">
              <button
                className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                type="submit"
              >
                Update Wifi Offer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminWifiOfferPost;
