import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link component correctly
import AuthUser from "../../Hooks/authUser";
import Clock from "../Clock/Clock";
import Loading from "../../Shared/Loading/Loading";
import Pagination from "../../Shared/Pagination";
import ScrollingText from "../GreetingMessage/ScrollingText";

const SimSales = () => {
  //sims
  const [sims, setSims] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userInfo } = AuthUser();

  const [simSearch, setSimSearch] = useState("");
  const [ordersFilter, setSimFilter] = useState([]);

  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  //sim
  const url = `https://servergenzam.genzamwallet.com/api/v1/allsim/specific/?fieldName=${"resellerEmail"}&&fieldValue=${
    userInfo?.email
  }&&fieldName2=${"status"}&&fieldValue2=${"available"}&&size=${size}&&page=${page}`;
  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setSims(data?.data);
        setSimFilter(data?.data);
        setQuantity(data?.total);
        console.log(data?.data);
        setLoading(false);
      });
  }, [url]);

  if (loading) {
    return <Loading />;
  }

  const filterSimData = sims?.filter((f) => {
    return (
      f?.iccidNumber?.toLowerCase().includes(simSearch?.toLowerCase()) ||
      f?.simNumber?.toLowerCase().includes(simSearch?.toLowerCase()) ||
      f?.operatorName?.toLowerCase().includes(simSearch?.toLowerCase())
    );
  });
  return (
    <div className="min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - SIM SALES
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>

      <div className="flex justify-center mt-2 text-white items-center ">
        <ScrollingText></ScrollingText>
      </div>

      <div className="bg-thirdLightPrimary m-5 p-5 rounded-md text-xs">
        <div className=" ">
          <div className="flex  w-lg   bg-lightPrimary text-xs rounded-sm shadow-lg p-5">
            <div className="w-full ">
              <p className=" text-sm">Search SIM</p>
              <div className="mt-5 sm:flex sm:items-center">
                <input
                  id="q"
                  onChange={(e) => setSimSearch(e.target.value)}
                  className="inline w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-sm"
                  placeholder="ICCID Number ,SIM Number,Operator Name etc."
                  type="search"
                />
                <button
                  onClick={() => setSimFilter(filterSimData)}
                  className="mt-3  inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Data table */}
        <div className=" p-5  ">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-8 rounded-md bg-lightPrimary ">
            <h2 className="text-sm font-bold mb-4">
              Sale List (Total: {ordersFilter?.length} SIM)
            </h2>
            <table className="table-auto w-full bg-gray-400">
              <thead className=" bg-black text-white">
                <tr>
                  <th className="border px-2 py-1">#</th>
                  <th className="border px-4 py-2">Operator</th>
                  <th className="border px-4 py-2">ICCID Number</th>
                  <th className="border px-4 py-2">SIM Number</th>
                  <th className="border px-4 py-2">Buy Date</th>
                  <th className="border px-4 py-2">Buy Price</th>
                  <th className="border px-4 py-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {ordersFilter?.map((row, index) => (
                  <tr
                    key={index}
                    className="border px-2 py-1 text-center bg-white"
                  >
                    <td className="border px-2 py-1 text-center">
                      {index + 1}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {row.operatorName}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {row.iccidNumber}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {row.simNumber}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {new Date(row?.buyDate).toLocaleDateString()}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {row.buyPrice}
                    </td>
                    <td>
                      <Link to={`agent-sim-sales-order/${row?._id}`}>
                        <span className="p-2 text-red-600  extrabold ">
                          Sale
                        </span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Pagination
            quantity={quantity}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>
    </div>
  );
};

export default SimSales;
