import React from "react";
// import WebNav from "../../Shared/Loading/WebNav";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../Shared/Loading/Footer";
import AuthUser from "../../Hooks/authUser";
import { useEffect } from "react";
import Bot from "../../Component/Bot/Bot";
import AgentNavbar from "../../Component/MobileResponsiveNavbar/AgentNavbar";

const ResellerDashbord = () => {
  const navigate = useNavigate();
  const { userInfo, logout } = AuthUser();
  useEffect(() => {
    if (userInfo?.role === "reseller") {
    } else {
      logout();
      navigate("/login");
    }
  }, []);
  return (
    <div className=" bg-secondary ">
      {/* <WebNav></WebNav> */}
      <AgentNavbar></AgentNavbar>
      <Outlet></Outlet>
      <Bot></Bot>
      <Footer></Footer>
    </div>
  );
};

export default ResellerDashbord;
