import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ManageSimOperator = () => {
  const [operators, setOperators] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteOperatorId, setDeleteOperatorId] = useState(null);

  useEffect(() => {
    fetchSimOperators();
  }, []);

  const fetchSimOperators = () => {
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/simOperator")
      .then((response) => {
        setOperators(response.data);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
      });
  };

  const handleDeleteOperator = (operatorId) => {
    axios
      .delete(`https://servergenzam.genzamwallet.com/api/v1/simOperator/${operatorId}`)
      .then((response) => {
        setOperators(operators.filter((op) => op._id !== operatorId));
        setShowModal(false);
      })
      .catch((error) => {
        console.log("Error deleting operator: ", error);
      });
  };

  return (
    <div>
      <div className="max-w-3xl mx-auto text-center mt-5">
        <h1 className="text-4xl flex justify-center gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          <Icon icon="material-symbols:manage-history-rounded" />
          Manage SIM Operator List
        </h1>
      </div>
      <div className="overflow-x-auto m-5">
        <table className="min-w-full text-xs bg-gray-100">
          <colgroup>
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="  text-black">
            <tr className="text-center">
              <th className="p-3">#</th>
              <th className="p-3">Operator</th>
              <th className="p-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {operators?.map((op, index) => (
              <tr
                key={op._id}
                className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
              >
                <td className="p-3">
                  <p>{index + 1}</p>
                </td>
                <td className="p-3 text-center">
                  <p>{op?.operatorName}</p>
                </td>
                <td className="p-3 text-xl items-center justify-center flex">
                  <p className="flex gap-x-3 text-primary">
                    <Link to={`/adminDashboard/editsimoperator/${op._id}`}>
                      <Icon icon="bxs:edit" style={{ cursor: "pointer" }} />
                    </Link>
                    <Icon
                      icon="ant-design:delete-filled"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDeleteOperatorId(op._id);
                        setShowModal(true);
                      }}
                    />
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center backdrop">
          <div className="bg-secondary w-96 p-6 rounded shadow-lg">
            <h2 className="text-lg text-white font-semibold mb-4">
              Confirm Delete
            </h2>
            <p className="text-white">
              Are you sure you want to delete this operator?
            </p>
            <div className="flex justify-end mt-6">
              <button
                className="text-white rounded mr-4 bg-green-600 p-2 hover:bg-green-800"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="text-white p-2 rounded bg-red-600 hover:bg-red-800 font-semibold"
                onClick={() => {
                  handleDeleteOperator(deleteOperatorId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageSimOperator;
