import Bot from "../Component/Bot/Bot";
import About from "../Component/Home/About";
import CompanyServices from "../Component/Home/CompanyServices";
import Contact from "../Component/Home/Contact";
import Forgot from "../Component/Home/Forgot";
import InsertOtp from "../Component/Home/InsertOtp";
import LandingNavbar from "../Component/Home/LandingNavbar";
import Login from "../Component/Home/Login";
import ResetCode from "../Component/Home/ResetCode";
import Reset from "../Component/Home/ResetCode";
import SetNewPassword from "../Component/Home/SetNewPassword";
import UpdatePassword from "../Component/Home/UpdatePassword";
import Home from "../Pages/Home/Home";
import VerifyEmail from "../utils/VerifyEmail";

const PublicRoutes = [
  // login & foegot password
  // { path: "/", Component: Login },

  { path: "/login", Component: Login },
  { path: "/forgot", Component: Forgot },
  { path: "/:id/verify", Component: VerifyEmail },
  { path: "/resetpassword", Component: ResetCode },
  { path: "/update-password", Component: UpdatePassword },
  { path: "/insert-token", Component: InsertOtp },
  { path: "/setNewPassword", Component: SetNewPassword },

  { path: "/bot", Component: Bot },
  { path: "/about", Component: About },
  { path: "/contact", Component: Contact },
  {
    path: "/companyservice",
    Component: CompanyServices,
  },
];

export default PublicRoutes;
