import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";
import Loading from "../../Shared/Loading/Loading";
import UpdateHooks from "../../Hooks/UpdateHooks";

const UserEditSim = () => {
  const { id } = useParams();
  const { userInfo } = AuthUser();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [selectedEmail, setSelectEmail] = useState("");
  const [sim, setSim] = useState({});
  useEffect(() => {
    setLoading(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/allsim/getSimById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSim(data);
        console.log(data);
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    const url = `https://servergenzam.genzamwallet.com/api/v1/user/specific?fieldName=${"referResellerId"}&&fieldValue=${
      userInfo?._id
    }`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setUser(data?.data);
      });
  }, [userInfo?._id]);

  if (loading) {
    return <Loading />;
  }

  const selectedUser = user?.filter((u) => u?.email === selectedEmail);
  console.log("selected user", selectedUser);

  const { operatorName, offerName, buyPrice, buyDate, iccidNumber, simNumber } =
    sim;

  const handelUpdate = async (e) => {
    e.preventDefault();
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/allsim/${id}`;
    if (selectedUser) {
      // console.log(selectedUser[0]?.name);
      await UpdateHooks(
        BASE_URL,
        {
          userName: selectedUser[0]?.name,
          userEmail: selectedUser[0]?.email,
          userId: selectedUser[0]?._id,
        },
        true,
        "sim Updated"
      );
    } else {
      alert("please select status");
    }
  };
  return (
    <div className=" min-h-screen p-5 rounded">
      <div className=" text-sm p-10  bg-thirdLightPrimary rounded-md">
        <p className="text-2xl font-medium">Edit Sim</p>
        <p className="text-sm">You can edit user. for provide sim</p>
        <div className="pb-3 pt-3">
          <label
            htmlFor="first_name"
            className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
          >
            Operator
          </label>
          <p className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {operatorName}
          </p>
        </div>
        <div className="pb-3 pt-3">
          <label
            htmlFor="first_name"
            className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
          >
            Offer Name
          </label>
          <p className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {offerName}
          </p>
        </div>

        <div>
          <div>
            <p className=" text-xs pb-3 pt-3 font-semibold">User</p>
          </div>
          <div className="  ">
            <select
              type="text"
              onChange={(e) => setSelectEmail(e.target?.value)}
              id="first_name"
              className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select"
              required
            >
              <option selected disabled>
                Select User
              </option>
              {user?.map((u) => (
                <option value={u?.email}>{u?.email}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="pb-3 pt-3">
          <label
            htmlFor="first_name"
            className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
          >
            ICCID Number
          </label>
          <p className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {iccidNumber}
          </p>
        </div>

        <div className="pb-3 pt-3">
          <label
            htmlFor="first_name"
            className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
          >
            SIM Number
          </label>
          <p className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {simNumber}
          </p>
        </div>

        <div className="pb-3 pt-3">
          <label
            htmlFor="first_name"
            className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
          >
            Buying Price
          </label>
          <p className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {buyPrice}
          </p>
        </div>

        <div className="pb-3 pt-3">
          <label
            htmlFor="first_name"
            className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
          >
            Buying Date
          </label>
          <p className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {buyDate}
          </p>
        </div>

        <button
          onClick={handelUpdate}
          className=" bg-primary text-white  p-2 rounded text-xs   hover:bg-secondary"
        >
          Update SIM
        </button>
      </div>
    </div>
  );
};

export default UserEditSim;
