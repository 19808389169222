import React from "react";
import Clock from "../../Component/Clock/Clock";

const UserOrderView = () => {
  return (
    <div>
     

      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">  USER DASHBOARD - VIEW ORDER</p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>   
          </p> 
        </div>
      </div>
      <div className=" bg-thirdLightPrimary m-5 rounded-md text-xs">
        <div className=" grid grid-cols-2 p-5  gap-2">
          {/* first Div */}
          <div className=" border ">
            <p className=" text-xs e pb-2 p-2  ">SIM Details</p>
            <table className="min-w-full text-xs  ">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="  " />
              </colgroup>
              <thead className="dark:bg-gray-700 bg-black text-white  ">
                <tr className="text-left grid grid-cols-2    ">
                  <th className="p-3 text-xs ">SIM Details</th>
                </tr>
              </thead>
              <tbody className=" bg-gray-200">
                <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Operator</p>
                  </td>
                  <td className="p-3 bg-gray-50 ">
                    <p>WIND TRE S.P.A </p>
                  </td>
                </tr>

                <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">ICCID Number</p>
                  </td>
                  <td className="p-3 bg-gray-50 ">
                    <p>**8939880846002008599</p>
                  </td>
                </tr>

                <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Mobile Number</p>
                  </td>
                  <td className="p-3 bg-gray-50 ">
                    <p>**3272047716</p>
                  </td>
                </tr>

                <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Buy Price </p>
                  </td>
                  <td className="p-3 bg-gray-50 ">
                    <p>€0.00</p>
                  </td>
                </tr>
                <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">SIM Status </p>
                  </td>
                  <td className="p-3 bg-gray-50 ">
                    <p>Activate Pending</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className=" border ">
              <p className=" text-xs pb-2 p-2   ">Order Details</p>
              <table className="min-w-full text-xs  ">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col className="  " />
                </colgroup>
                <thead className="dark:bg-gray-700 bg-black text-white ">
                  <tr className="text-left grid grid-cols-2    ">
                    <th className="p-3 text-xs ">Order Details</th>
                  </tr>
                </thead>
                <tbody className=" bg-gray-200">
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">ICCID </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p> </p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Mobile </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>0000</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Entry by </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>275osthirstore</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Phone </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>+393313364836</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Entry Time </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>19.06.2023 11:56 am</p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Completed Date </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>01.01.1970</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Status </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>Pending</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* second div */}
          <div>
            <div className=" border ">
              <p className=" text-xs pb-2 p-2  ">Sale Details</p>
              <table className="min-w-full text-xs  ">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col className="  " />
                </colgroup>
                <thead className="dark:bg-gray-700 bg-black text-white">
                  <tr className="text-left grid grid-cols-2    ">
                    <th className="p-3 text-xs ">Sale Details</th>
                  </tr>
                </thead>
                <tbody className=" bg-gray-200">
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Offer</p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>
                        WINDTRE SPA - Ricaricabile (Prepagato) Call Your Country
                        Plus - costa per 8.99€ al mese - internet al mese 70
                        giga - Wind3 una offerta mirata ai paesi Argentina (solo
                        fisso), Australia, Austria, Bangladesh, Brasile, Belgio,
                        Bulgaria, Canada, Cina,
                      </p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">First Name </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>PATWARI</p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Codice Fiscale </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p></p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Address </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p></p>
                    </td>
                  </tr>
                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Sale Price </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p></p>
                    </td>
                  </tr>

                  <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                    <td className="p-3 ">
                      <p className=" ">Ricarica </p>
                    </td>
                    <td className="p-3 bg-gray-50 ">
                      <p>€10</p>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className=" border ">
                <p className=" text-xs pb-2 p-2  ">MNP - Number Portability</p>
                <table className="min-w-full text-xs  ">
                  <colgroup>
                    <col />
                    <col />
                    <col />
                    <col />
                    <col />
                    <col className="  " />
                  </colgroup>
                  <thead className="dark:bg-gray-700 bg-black text-white ">
                    <tr className="text-left grid grid-cols-2    ">
                      <th className="p-3 text-xs ">MNP - Number Portability</th>
                    </tr>
                  </thead>
                  <tbody className=" bg-gray-200">
                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">Operator </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p> </p>
                      </td>
                    </tr>

                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">ICCID Number </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p> </p>
                      </td>
                    </tr>

                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">Mobile Number </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p></p>
                      </td>
                    </tr>

                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">Foto della vecchia SIM </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">
                          Originale e Fotocopia Codice Fiscale{" "}
                        </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p></p>
                      </td>
                    </tr>
                    <tr className="border border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                      <td className="p-3 ">
                        <p className=" ">Notes </p>
                      </td>
                      <td className="p-3 bg-gray-50 ">
                        <p></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOrderView;
