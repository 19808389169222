import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminBillsTable from "./AdminBillsTable";

const AdminManagePayment = () => {
  const [bills, setBills] = useState([]);

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = () => {
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/bills")
      .then((response) => {
        setBills(response.data.customers);
      })
      .catch((error) => {
        // console.log("Error fetching bills: ", error);
      });
  };

  const filterBills = bills?.filter((bill) => bill?.debit > 0);

  return (
    <div>
      <div>
        <div className="max-w-3xl mx-auto text-center mt-5">
          <h1 className="text-4xl flex justify-center gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="material-symbols:manage-history-rounded"></Icon>
            Manage Bills Offer
          </h1>
        </div>
      </div>

      <div className="overflow-x-auto m-5">
        <table className="min-w-full text-xs bg-gray-100">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead>
            <tr className="text-center bg-yellow-400 text-white">
              <th className="p-3 border">#</th>
              <th className="p-3 border">Customer</th>

              <th className="p-3 border">Email</th>
              <th className="p-3 border">Date</th>
              <th className="p-3 border text-left">Details</th>
              <th className="p-3 border">Debit</th>
              <th className="p-3 border">Credit</th>
              <th className="p-3 border">Status</th>
              <th className="p-3 border">Action</th>
            </tr>
          </thead>
          <tbody>
            {filterBills?.map((payment, index) => (
              <AdminBillsTable
                payment={payment}
                index={index}
                fetchBills={fetchBills}
              ></AdminBillsTable>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminManagePayment;
