import React from "react";
import Hero from "./Hero";
import Services from "./Services";
import UpCommingProject from "./UpCommingProject";
import ServiceInfo from "./ServiceInfo";
import WhyChoseUs from "./WhyChoseUs";
import Team from "./Team";
import Faq from "./Faq";
import Company from "./Company";

const LandingPage = () => {
  return (
    <div className=" min-h-screen">
      <Hero></Hero>
      <Services></Services>
      <UpCommingProject></UpCommingProject>
      <ServiceInfo></ServiceInfo>
      <WhyChoseUs></WhyChoseUs>
      {/* <Team></Team> */}
      <Faq></Faq>
      <Company></Company>
    </div>
  );
};

export default LandingPage;
