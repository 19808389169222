import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const AdminManageOfferPost = () => {
  const [operatorOffers, setOperatorOffers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteOfferId, setDeleteOfferId] = useState(null);

  useEffect(() => {
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/operatorOffer")
      .then((response) => {
        setOperatorOffers(response.data.operatorOffers);
      })
      .catch((error) => {
        console.log("Error fetching operator offers: ", error);
      });
  }, []);

  const handleDeleteOffer = (offerId) => {
    axios
      .delete(`https://servergenzam.genzamwallet.com/api/v1/operatorOffer/${offerId}`)
      .then((response) => {
        setOperatorOffers((prevOffers) =>
          prevOffers.filter((offer) => offer._id !== offerId)
        );
        setShowModal(false);
      })
      .catch((error) => {
        console.log("Error deleting offer: ", error);
      });
  };
  return (
    <div>
      <div>
        <div className="max-w-3xl mx-auto text-center mt-5">
          <h1 className="text-4xl flex justify-center gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="material-symbols:manage-history-rounded" />
            Manage Latest & Upcoming Offer
          </h1>
        </div>
      </div>

      <div className="overflow-x-auto m-5">
        <table className="min-w-full text-xs bg-gray-100">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="bg-black text-white">
            <tr className="text-center">
              <th className="p-3 border">#</th>
              <th className="p-3 border">Type</th>
              <th className="p-3 border">Operator</th>
              <th className="p-3 border">Offer Name</th>
              <th className="p-3 border">Default Price</th>
              <th className="p-3 border">Status</th>
              <th className="p-3 border">Action</th>
            </tr>
          </thead>
          <tbody>
            {operatorOffers?.map((offer, index) => (
              <tr
                key={offer?._id}
                className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
              >
                <td className="p-3 border">
                  <p>{index + 1}</p>
                </td>
                <td className="p-3 text-center border">
                  <p>{offer?.posttype}</p>
                </td>
                <td className="p-3 text-center border">
                  <p>{offer?.operator}</p>
                </td>
                <td className="p-3 text-center border">
                  <p>{offer?.offerName}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">{offer?.defaultPrice}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">{offer?.poststatus}</p>
                </td>
                <td className="p-3 text-xl items-center justify-center flex">
                  <p className="flex gap-x-3 text-black">
                    <Link
                      to={`/adminDashboard/adminmanageofferpostedit/${offer._id}`}
                    >
                      <Icon
                        icon="basil:edit-solid"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                    <Icon
                      icon="ant-design:delete-filled"
                      onClick={() => {
                        setDeleteOfferId(offer._id);
                        setShowModal(true);
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center backdrop">
          <div className="bg-secondary w-96 p-6 rounded shadow-lg">
            <h2 className="text-lg text-white font-semibold mb-4">
              Confirm Delete
            </h2>
            <p className="text-white">
              Are you sure you want to delete this offer?
            </p>
            <div className="flex justify-end mt-6">
              <button
                className="text-white rounded mr-4 bg-green-600 p-2 hover:bg-green-800"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="text-white p-2 rounded bg-red-600 hover:bg-red-800 font-semibold"
                onClick={() => {
                  handleDeleteOffer(deleteOfferId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminManageOfferPost;
