import { Icon } from "@iconify/react";
import React, { useState } from "react";
import PostHooks from "../../Hooks/PostHooks";
import { useEffect } from "react";

const PostSimOperator = () => {
  const [inputFields, setInputFields] = useState([
    {
      offerName: "",
      iban: "",
      costPerMonth: 0,
      totalRecharge: 0,
      offerValidFor: "",
      status: "",
      freeInternetPack: "",
      freeMinutesForLocalOperator: "",
      freeMinutesForInternational: "",
      internationalMinutesValidFor: "",
    },
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const operatorName = e.target?.operator.value;
    const offerName = inputFields;

    const operator = { operatorName, offerName };
    // console.log(operator);
    await PostHooks(
      "https://servergenzam.genzamwallet.com/api/v1/simOperator",
      operator,
      "luceOrder successfully submitted"
    );
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      offerName: "",
      iban: "",
      costPerMonth: 0,
      totalRecharge: 0,
      offerValidFor: "",
      status: "",
      freeInternetPack: "",
      freeMinutesForLocalOperator: "",
      freeMinutesForInternational: "",
      internationalMinutesValidFor: "",
    });
    setInputFields(values);
  };

  const handelRemove = (index) => {
    const updatedItems = [...inputFields];
    updatedItems.splice(index, 1);
    setInputFields(updatedItems);
    console.log(inputFields);
  };

  const handleInputChange = (index, event, name) => {
    const values = [...inputFields];
    if (name === "offerName") {
      values[index].offerName = event.target.value;
    }
    if (name === "internationalMinutesValidFor") {
      values[index].internationalMinutesValidFor = event.target.value;
    }
    if (name === "freeMinutesForInternational") {
      values[index].freeMinutesForInternational = event.target.value;
    }
    if (name === "freeMinutesForLocalOperator") {
      values[index].freeMinutesForLocalOperator = event.target.value;
    }
    if (name === "freeMinutesForLocalOperator") {
      values[index].freeMinutesForLocalOperator = event.target.value;
    }
    if (name === "freeInternetPack") {
      values[index].freeInternetPack = event.target.value;
    }
    if (name === "status") {
      values[index].status = event.target.value;
    }
    if (name === "offerValidFor") {
      values[index].offerValidFor = event.target.value;
    }
    if (name === "totalRecharge") {
      values[index].totalRecharge = event.target.value;
    }
    if (name === "costPerMonth") {
      values[index].costPerMonth = event.target.value;
    }
    if (name === "iban") {
      values[index].iban = event.target.value;
    }
    setInputFields(values);
  };

  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-2xl flex justify-center  gap-x-5 items-center font-semibold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2 w-fit mx-auto">
            <Icon icon="flat-color-icons:sim-card"></Icon>
            POST SIM OPERATOR
          </h1>
        </div>
      </div>

      {/* post table  */}
      <div className="bg-white border border-4 rounded-lg shadow relative m-10">
        <div className="flex items-start justify-between p-5 border-b rounded-t">
          <h3 className="text-xl font-semibold">SIM Details</h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="product-modal"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="p-6 space-y-6">
          {/* form start */}
          <form onSubmit={handleSubmit}>
            <div className="">
              <div className="">
                <label
                  htmlFor="product-name"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Operator Name
                </label>
                <input
                  type="text"
                  name="operator"
                  required
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Operator Name ”"
                />
              </div>

              <div className="w-full mt-4">
                <div className="w-full ">
                  {inputFields?.map((inputField, index) => (
                    <div className="flex items-center gap-x-1">
                      <div className="w-full border rounded-lg shadow p-2">
                        <label className="font-medium text-dark">
                          Offer Name<span className="text-warning">*</span>
                        </label>
                        <div className="flex items-center gap-x-1 mb-2">
                          <div className="w-full mt-2">
                            <input
                              onChange={(event) =>
                                handleInputChange(index, event, "offerName")
                              }
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                              placeholder="Enter Offer Name"
                              type="text"
                              name="offerName"
                            />
                          </div>
                        </div>

                        <label className="font-medium text-dark">
                          Facilities<span className="text-warning">*</span>
                        </label>
                        <div className="grid md:grid-cols-2 grid-cols-2 gap-2 bg-primary/10 mt-2 p-2 rounded-md text-sm w-full">
                          <div className="">
                            <label
                              htmlFor="price"
                              className="text-sm font-medium text-gray-900 block mb-2"
                            >
                              Cost Per Month{" "}
                            </label>
                            <input
                              type="Cost Per Month "
                              name="costPerMonth"
                              onChange={(event) =>
                                handleInputChange(index, event, "costPerMonth")
                              }
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                              placeholder="€"
                              required
                            />
                          </div>
                          <div className="">
                            <label
                              htmlFor="price"
                              className="text-sm font-medium text-gray-900 block mb-2"
                            >
                              totalRecharge
                            </label>
                            <input
                              type="number"
                              onChange={(event) =>
                                handleInputChange(index, event, "totalRecharge")
                              }
                              name="totalRecharge"
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                              placeholder="€"
                              required
                            />
                          </div>
                          <div className="">
                            <label
                              htmlFor="price"
                              className="text-sm font-medium text-gray-900 block mb-2"
                            >
                              Offer Valid For
                            </label>
                            <div className="relative">
                              <select
                                name="offerValidFor"
                                onChange={(event) =>
                                  handleInputChange(
                                    index,
                                    event,
                                    "offerValidFor"
                                  )
                                }
                                required
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                              >
                                <option value="unlimited">Unlimited</option>
                                <option value="limited">Limited</option>
                              </select>
                              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <svg
                                  className="h-5 w-5 text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <label
                              htmlFor="price"
                              className="text-sm font-medium text-gray-900 block mb-2"
                            >
                              Status
                            </label>
                            <div className="relative">
                              <select
                                name="status"
                                onChange={(event) =>
                                  handleInputChange(index, event, "status")
                                }
                                required
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                              >
                                <option selected disabled>
                                  please select
                                </option>
                                <option value="Available for Sale">
                                  Available for Sale
                                </option>
                                <option value="Not available for Sale">
                                  Not available for Sale
                                </option>
                              </select>
                              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <svg
                                  className="h-5 w-5 text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <label className="text-sm font-medium text-gray-900 block mb-2">
                              Free Internet Pack (GB){" "}
                            </label>
                            <input
                              required
                              type="text"
                              name="freeInternetPack"
                              onChange={(event) =>
                                handleInputChange(
                                  index,
                                  event,
                                  "freeInternetPack"
                                )
                              }
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                              placeholder="00 GIGA"
                            />
                          </div>
                          <div className="">
                            <label
                              htmlFor="price"
                              className="text-sm font-medium text-gray-900 block mb-2"
                            >
                              Free Minuites for Local Operator{" "}
                            </label>
                            <input
                              required
                              type="text"
                              onChange={(event) =>
                                handleInputChange(
                                  index,
                                  event,
                                  "freeMinutesForLocalOperator"
                                )
                              }
                              name="freeMinutesForLocalOperator"
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                              placeholder="Enter free Minuites for Local Operator"
                            />
                          </div>
                          <div className="">
                            <label
                              htmlFor="price"
                              className="text-sm font-medium text-gray-900 block mb-2"
                            >
                              Free Minutes for International
                            </label>
                            <div className="relative">
                              <select
                                required
                                onChange={(event) =>
                                  handleInputChange(
                                    index,
                                    event,
                                    "freeMinutesForInternational"
                                  )
                                }
                                name="freeMinutesForInternational"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                              >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <svg
                                  className="h-5 w-5 text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>

                          <div className="">
                            <label
                              htmlFor="price"
                              className="text-sm font-medium text-gray-900 block mb-2"
                            >
                              International Minutes Valid For{" "}
                            </label>
                            <input
                              required
                              type="text"
                              onChange={(event) =>
                                handleInputChange(
                                  index,
                                  event,
                                  "internationalMinutesValidFor"
                                )
                              }
                              name="internationalMinutesValidFor"
                              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                              placeholder="Enter International Minutes Valid For"
                            />
                          </div>

                          {/* iban */}
                          <div className="">
                            <label
                              htmlFor="price"
                              className="text-sm font-medium text-gray-900 block mb-2"
                            >
                              IBAN
                            </label>
                            <div className="relative">
                              <select
                                required
                                onChange={(event) =>
                                  handleInputChange(index, event, "iban")
                                }
                                name="iban"
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                              >
                                <option selected disabled>
                                  None
                                </option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <svg
                                  className="h-5 w-5 text-gray-400"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="space-y-1">
                        <div
                          onClick={handleAddFields}
                          className="bg-primary hover:bg-primary/80 h-full py-1.5 px-3 rounded-full text-white cursor-pointer"
                        >
                          +
                        </div>
                        {index !== 0 && (
                          <div
                            onClick={() => handelRemove(index)}
                            className="bg-red-600 hover:bg-red-500 h-full py-1.5 px-3 rounded-full text-white cursor-pointer"
                          >
                            X
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-5 rounded-b">
              <button
                className="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                type="submit"
              >
                Post
              </button>
            </div>
          </form>

          {/* close form */}
        </div>
      </div>
    </div>
  );
};

export default PostSimOperator;
