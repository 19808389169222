import React from "react";
import { Link } from "react-router-dom";
import Clock from "../Clock/Clock";

const PaymentHistory = () => {
  return (
    <div className=" min-h-screen ">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - PAYMENT HISTORY{" "}
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary rounded-md m-5">
        <div className="container  mx-auto sm:p-4 dark:text-gray-100 ">
          <p className="mb-4 font-semibold leadi text-xs"> Payment History</p>
          <div className="overflow-x-auto">
            <table className="min-w-full text-xs">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24"></col>
              </colgroup>
              <thead className="dark:bg-gray-700">
                <tr className="text-left">
                  <th className="p-3">#</th>
                  <th className="p-3">Code</th>
                  <th className="p-3">Recived Date </th>
                  <th className="p-3">Amount </th>
                  <th className="p-3 ">Entry By </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b border-opacity-20 dark:border-gray-700 dark:bg-gray-900 ">
                  <td className="p-3">
                    <p>1</p>
                  </td>
                  <td className="p-3">
                    <p>00 </p>
                  </td>
                  <td className="p-3">
                    <p>00.00.2023 </p>
                  </td>
                  <td className="p-3">
                    <p>€00.00 </p>
                  </td>

                  <td className="p-3">
                    <p>Gengam-It </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
