import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../Shared/Pagination";
import AuthUser from "../../Hooks/authUser";
import Clock from "../Clock/Clock";

const UserList = () => {
  const { userInfo } = AuthUser();
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const url = `https://servergenzam.genzamwallet.com/api/v1/user/specific?page=${page}&&size=${size}&&fieldName=${"referResellerId"}&&fieldValue=${
      userInfo?._id
    }`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setUserData(data?.data);
        setQuantity(data?.total);
        console.log(data);
      });
  }, [page, size, userInfo?._id]);

  const users = userData?.filter((u) => u?.role === "user");

  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - USER LIST
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary m-5 p-5 rounded-md text-xs pb-5">
        <div className="flex  w-lg  bg-lightPrimary  rounded-sm shadow-lg my-5 mx-5 p-6 ">
          <div className="w-full ">
            <p className=" text-sm">Search User</p>
            <form className="mt-2 sm:flex sm:items-center">
              <input
                id="q"
                name="q"
                className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-sm"
                placeholder="name,email,status etc"
                type="search"
                autoFocus
                value=""
              />
              <button
                type="submit"
                className="mt-3 inline-flex text-xs w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Search
              </button>
            </form>
          </div>
        </div>
        <div class="relative overflow-x-auto  bg-lightPrimary p-5 shadow-md sm:rounded-lg m-5">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs uppercase bg-black text-white">
              <tr>
                <td class="px-6 py-3 font-semibold">#</td>
                <td class="px-6 py-3 font-semibold">Entry by</td>
                <td class="px-6 py-3 font-semibold">User Name</td>

                <td class="px-6 py-3 font-semibold">User Email</td>

                <td class="px-6 py-3 font-semibold">name</td>
                <td class="px-6 py-3 font-semibold">Type</td>
                <td class="px-6 py-3 font-semibold">Status</td>
                <td class="px-6 py-3 font-semibold">Action</td>
              </tr>
            </thead>
            <tbody>
              {users?.map((user, i) => (
                <tr
                  key={user?._id}
                  user={user}
                  class="bg-white border-b text-xs dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td class="px-6 py-4">{i + 1}</td>
                  <td class="px-6 py-4">{user?.referResellerName} </td>
                  <td class="px-6 py-4">{user?.userName} </td>

                  <td class="px-6 py-4">{user?.email} </td>
                  <td class="px-6 py-4">{user?.name} </td>
                  <td class="px-6 py-4">{user?.role} </td>
                  <td class="px-6 py-4">{user?.status} </td>

                  <td class="flex items-center px-6 py-4 space-x-3">
                    <Link
                      to={user?._id}
                      class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          quantity={quantity}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
        />
      </div>
    </div>
  );
};

export default UserList;
