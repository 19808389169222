import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SimOrderAgentUser = () => {
  // main data state
  const [pending, setPending] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [onProcess, setOnProcess] = useState([]);

  // filter data state
  const [filterPending, setFilterPending] = useState([]);
  const [filterCompleted, setFilterCompleted] = useState([]);
  const [filterRejected, setFilterRejected] = useState([]);
  const [filterOnProcess, setFilterOnProcess] = useState([]);

  // search state
  const [pendingSearch, setPendingSearch] = useState("");
  const [rejectedSearch, setRejectedSearch] = useState("");
  const [completedSearch, setCompletedSearch] = useState("");
  const [onProcessSearch, setOnProcessSearch] = useState("");
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/orderSim/specific/?fieldName=${"orderStatus"}&&fieldValue=${"pending"}`
    )
      .then((res) => res.json())
      .then((data) => {
        setPending(data?.data);
        setFilterPending(data?.data);
      });
  }, []);
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/orderSim/specific/?fieldName=${"orderStatus"}&&fieldValue=${"completed"}`
    )
      .then((res) => res.json())
      .then((data) => {
        setCompleted(data?.data);
        setFilterCompleted(data?.data);
      });
  }, []);
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/orderSim/specific/?fieldName=${"orderStatus"}&&fieldValue=${"rejected"}`
    )
      .then((res) => res.json())
      .then((data) => {
        setRejected(data?.data);
        setFilterRejected(data?.data);
      });
  }, []);
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/orderSim/specific/?fieldName=${"orderStatus"}&&fieldValue=${"onProcess"}`
    )
      .then((res) => res.json())
      .then((data) => {
        setOnProcess(data?.data);
        setFilterOnProcess(data?.data);
      });
  }, []);

  // for filter
  const filterPendingData = pending?.filter((f) => {
    return (
      f?.ordererName?.toLowerCase().includes(pendingSearch?.toLowerCase()) ||
      f?.ordererEmail?.toLowerCase().includes(pendingSearch?.toLowerCase())
    );
  });
  const filterRejectedData = rejected?.filter((f) => {
    return (
      f?.ordererName?.toLowerCase().includes(rejectedSearch?.toLowerCase()) ||
      f?.ordererEmail?.toLowerCase().includes(rejectedSearch?.toLowerCase())
    );
  });
  const filterCompletedData = completed?.filter((f) => {
    return (
      f?.ordererName?.toLowerCase().includes(completedSearch?.toLowerCase()) ||
      f?.ordererEmail?.toLowerCase().includes(completedSearch?.toLowerCase())
    );
  });
  const filterOnProcessData = onProcess?.filter((f) => {
    return (
      f?.ordererName?.toLowerCase().includes(onProcessSearch?.toLowerCase()) ||
      f?.ordererEmail?.toLowerCase().includes(onProcessSearch?.toLowerCase())
    );
  });

  console.log(filterPending);

  const handleDelete = (id) => {
    // Make a DELETE request to your API to delete the record with the given ID
    fetch(`https://servergenzam.genzamwallet.com/api/v1/orderSim/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        // Update the state to remove the deleted record
        setFilterPending((prevPending) =>
          prevPending.filter((item) => item._id !== id)
        );
      })
      .catch((error) => {
        console.error("Error deleting record:", error);
      });
  };

  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-4xl flex justify-center  gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="icon-park:sim"></Icon>
            SIM Order
          </h1>
        </div>
      </div>
      <div class="m-6">
        <div class="flex flex-wrap -mx-6">
          {/* div start */}
          <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
            <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
              <div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
                <Icon
                  className=" text-3xl"
                  icon="flat-color-icons:sales-performance"
                ></Icon>
              </div>

              <div class="mx-5">
                <h4 class="text-2xl font-semibold text-gray-700">
                  {pending?.length}
                </h4>
                <div class="text-gray-500">Pending Order</div>
              </div>
            </div>
          </div>

          <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
            <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
              <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
                <Icon className=" text-3xl" icon="mdi:cart"></Icon>
              </div>

              <div class="mx-5">
                <h4 class="text-2xl font-semibold text-gray-700">
                  {completed?.length}
                </h4>
                <div class="text-gray-500">Total Complete Order</div>
              </div>
            </div>
          </div>

          <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
            <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
              <div class="p-3 rounded-full bg-pink-600 bg-opacity-75">
                <Icon
                  className=" text-3xl"
                  icon="streamline:interface-page-controller-loading-1-progress-loading-load-wait-waiting"
                ></Icon>
              </div>

              <div class="mx-5">
                <h4 class="text-2xl font-semibold text-gray-700">
                  {rejected?.length}
                </h4>
                <div class="text-gray-500">Total Reject Order </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
            <h2 className="mb-4 text-1xl font-semibold leading">
              Pedding Order List
            </h2>
            <div className=" mb-2">
              <input
                onChange={(e) => setPendingSearch(e.target.value)}
                placeholder=" Enter Name Or Email"
                type="text"
                className=" text-xs inline rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm  "
              />
              <button
                onClick={() => setFilterPending(filterPendingData)}
                className="mt-3  inline-flex w-full items-center justify-center rounded-md border border-transparent  bg-yellow-400 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Search
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full text-xs bg-gray-100">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col className="w-24" />
                </colgroup>
                <thead className=" bg-black text-white">
                  <tr className="text-center">
                    <th className="p-3 border">#</th>
                    <th className="p-3 border">Role</th>
                    <th className="p-3 border">Name</th>
                    <th className="p-3 border">Email</th>
                    <th className="p-3 border">Phone</th>
                    <th className="p-3 border">Status</th>

                    <th className="p-3">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filterPending?.map((p, i) => (
                    <tr
                      key={p?._id}
                      p={p}
                      className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
                    >
                      <td className="p-3 border">
                        <p>{i + 1}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p>{p?.ordererRole}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p>{p?.ordererName}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className="dark:text-gray-400 ">{p?.ordererEmail}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className="dark:text-gray-400">{p?.ordererPhone}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className=" bg-yellow-400 p-1 rounded">
                          {p?.orderStatus}
                        </p>
                      </td>
                      <td className="p-3 text-xl items-center justify-center flex">
                        <Link
                          to={`/adminDashboard/simorderagentuser/simOrder/${p?._id}`}
                        >
                          <div>
                            <Icon
                              className=" hover:bg-primary  rounded-md  cursor-pointer"
                              icon="carbon:view-filled"
                            />
                          </div>
                        </Link>

                        <div>
                          <Icon
                            className="hover:bg-red-500 rounded-md cursor-pointer"
                            icon="material-symbols:delete-outline"
                            onClick={() => handleDelete(p?._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* second div */}
        <div>
          <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
            <h2 className="mb-4 text-1xl font-semibold leading">
              On Process Order List
            </h2>
            <div className=" mb-2">
              <input
                onChange={(e) => setOnProcessSearch(e.target.value)}
                placeholder=" Enter Name Or Email"
                type="text"
                className=" text-xs inline rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm  "
              />
              <button
                onClick={() => setFilterOnProcess(filterOnProcessData)}
                className="mt-3  inline-flex w-full items-center justify-center rounded-md border border-transparent   bg-indigo-500 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Search
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full text-xs bg-gray-100">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col className="w-24" />
                </colgroup>
                <thead className=" text-white bg-black">
                  <tr className="text-center">
                    <th className="p-3 border">#</th>
                    <th className="p-3 border">Role</th>
                    <th className="p-3 border">Name</th>
                    <th className="p-3 border">Email</th>
                    <th className="p-3 border">Phone</th>
                    <th className="p-3 border">Status</th>

                    <th className="p-3 border">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filterOnProcess?.map((p, i) => (
                    <tr
                      key={p?._id}
                      p={p}
                      className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
                    >
                      <td className="p-3 border">
                        <p>{i + 1}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p>{p?.ordererRole}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p>{p?.ordererName}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className="dark:text-gray-400">{p?.ordererEmail}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className="dark:text-gray-400">{p?.ordererPhone}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className=" bg-green-400 p-1 rounded">
                          {p?.orderStatus}
                        </p>
                      </td>
                      <td className="p-3 text-xl items-center justify-center flex ">
                        <Link
                          to={`/adminDashboard/simorderagentuser/simOrder/${p?._id}`}
                        >
                          <div>
                            <Icon
                              className=" hover:bg-primary  rounded-md  cursor-pointer"
                              icon="carbon:view-filled"
                            />
                          </div>
                        </Link>
                        <div>
                          <Icon
                            className="hover:bg-red-500 rounded-md cursor-pointer"
                            icon="material-symbols:delete-outline"
                            onClick={() => handleDelete(p?._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
          <h2 className="mb-4 text-1xl font-semibold leading">
            Complete Order List
          </h2>
          <div className=" mb-2">
            <input
              onChange={(e) => setCompletedSearch(e.target.value)}
              placeholder=" Enter Name Or Email"
              type="text"
              className=" text-xs inline rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm  "
            />
            <button
              onClick={() => setFilterCompleted(filterCompletedData)}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Search
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full text-xs bg-gray-100">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24" />
              </colgroup>
              <thead className=" text-white bg-black">
                <tr className="text-center">
                  <th className="p-3 border">#</th>
                  <th className="p-3 border">Role</th>
                  <th className="p-3 border">Name</th>
                  <th className="p-3  border">Email</th>
                  <th className="p-3 border">Phone</th>
                  <th className="p-3 border">Status</th>

                  <th className="p-3 border">Action</th>
                </tr>
              </thead>
              <tbody>
                {filterCompleted?.map((p, i) => (
                  <tr
                    key={p?._id}
                    p={p}
                    className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
                  >
                    <td className="p-3 border">
                      <p>{i + 1}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p>{p?.ordererRole}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p>{p?.ordererName}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className="dark:text-gray-400">{p?.ordererEmail}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className="dark:text-gray-400">{p?.ordererPhone}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className=" bg-green-600 p-1 rounded">
                        {p?.orderStatus}
                      </p>
                    </td>
                    <td className="p-3 text-xl items-center justify-center flex">
                      <Link
                        to={`/adminDashboard/simorderagentuser/simOrder/${p?._id}`}
                      >
                        <div>
                          <Icon
                            className=" hover:bg-primary  rounded-md  cursor-pointer"
                            icon="carbon:view-filled"
                          />
                        </div>
                      </Link>
                      {/* <div>
                        <Icon
                          className="hover:bg-red-500 rounded-md cursor-pointer"
                          icon="material-symbols:delete-outline"
                          onClick={() => handleDelete(p?._id)}
                        />
                      </div> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div>
        <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
          <h2 className="mb-4 text-1xl font-semibold leading">
            Reject Order List
          </h2>
          <div className=" mb-2">
            <input
              onChange={(e) => setRejectedSearch(e.target.value)}
              placeholder=" Enter Name Or Email"
              type="text"
              className=" text-xs inline rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm  "
            />
            <button
              onClick={() => setFilterRejected(filterRejectedData)}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Search
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full text-xs bg-gray-100">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24" />
              </colgroup>
              <thead className=" text-white bg-black">
                <tr className="text-center">
                  <th className="p-3 border">#</th>
                  <th className="p-3 border">Role</th>
                  <th className="p-3 border">Name</th>
                  <th className="p-3 border">Email</th>
                  <th className="p-3 border">Phone</th>
                  <th className="p-3 border">Status</th>

                  <th className="p-3 border">Action</th>
                </tr>
              </thead>
              <tbody>
                {filterRejected?.map((p, i) => (
                  <tr
                    key={p?._id}
                    p={p}
                    className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
                  >
                    <td className="p-3 border">
                      <p>{i + 1}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p>{p?.ordererRole}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p>{p?.ordererName}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className="dark:text-gray-400">{p?.ordererEmail}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className="dark:text-gray-400">{p?.ordererPhone}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className=" bg-red-600 p-1 rounded">
                        {p?.orderStatus}
                      </p>
                    </td>
                    <td className="p-3 text-xl items-center justify-center flex">
                      <Link
                        to={`/adminDashboard/simorderagentuser/simOrder/${p?._id}`}
                      >
                        <div>
                          <Icon
                            className=" hover:bg-primary  rounded-md  cursor-pointer"
                            icon="carbon:view-filled"
                          />
                        </div>
                      </Link>
                      {/* <div>
                        <Icon
                          className="hover:bg-red-500 rounded-md cursor-pointer"
                          icon="material-symbols:delete-outline"
                          onClick={() => handleDelete(p?._id)}
                        />
                      </div> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimOrderAgentUser;
