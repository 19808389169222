import { Icon } from "@iconify/react";
import React from "react";

const Services = () => {
  return (
    <div>
      <div class="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
        <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Project
        </h2>

        <p class="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
          The product can personalize user experiences by understanding
          individual preferences and tailoring recommendations or content based
          on user behavior and historical data.
        </p>
      </div>
      <div className="mx-auto mt-10 mb-10 max-w-6xl text-center p-6">
        <div className="grid mx-auto max-w-3xl gap-4 items-stretch text-left sm:grid-cols-3 sm:gap-8 sm:text-center">
          <a
            className="flex flex-col w-full items-center rounded-xl border border-black border-opacity-10 px-4 py-6 text-black duration-200 hover:border-opacity-0 hover:no-underline hover:shadow-lg dark:text-white dark:hover:bg-white dark:hover:bg-opacity-10"
            href="#"
            target="_blank"
          >
            <Icon
              className="w-12 h-32 sm:w-32 sm:h-32"
              icon="flat-color-icons:sim-card"
            ></Icon>
            {/* <img
              className="w-12 h-32 sm:w-32 sm:h-32"
              src="https://swiperjs.com/images/projects/framework7.svg"
              alt="Framework7"
            /> */}
            <div className="mt-4 font-semibold text-black dark:text-white">
              Sim Activation
            </div>
            <div className="text-sm opacity-75">
              Say goodbye to the hassle of activating your new SIM card.
            </div>
          </a>
          <a
            className="flex flex-col w-full items-center rounded-xl border border-black border-opacity-10 px-4 py-6 text-black duration-200 hover:border-opacity-0 hover:no-underline hover:shadow-lg dark:text-white dark:hover:bg-white dark:hover:bg-opacity-10"
            href="#"
            target="_blank"
          >
            <Icon
              className="w-12 h-32 sm:w-32 sm:h-32"
              icon="logos:wifi"
            ></Icon>
            <div className="mt-4 font-semibold text-black dark:text-white">
              Wi-fi Activation
            </div>
            <div className="text-sm opacity-75">
              Get ready to experience seamless internet connectivity with our
              Wi-Fi Activation service.
            </div>
          </a>
          <a
            className="flex flex-col w-full items-center rounded-xl border border-black border-opacity-10 px-4 py-6 text-black duration-200 hover:border-opacity-0 hover:no-underline hover:shadow-lg dark:text-white dark:hover:bg-white dark:hover:bg-opacity-10"
            href="#"
            target="_blank"
          >
            <Icon
              className="w-12 h-32 sm:w-32 sm:h-32"
              icon="flat-color-icons:online-support"
            ></Icon>
            <div className="mt-4 font-semibold text-black dark:text-white">
              24 Online Support
            </div>
            <div className="text-sm opacity-75">
              We believe in providing unmatched customer support.
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services;
