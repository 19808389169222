import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AdminCompletePaymentTable from "./AdminCompletePaymentTable";
import Loading from "../../Shared/Loading/Loading";

const LoadingComponent = () => {
  return (
    <div className="flex items-center justify-center h-20">
      <p>{Loading}</p>
    </div>
  );
};

const AdminCompletePayment = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = () => {
    setLoading(true); // Set loading to true before fetching bills
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/bills")
      .then((response) => {
        setBills(response.data.customers);
        setLoading(false); // Set loading to false after fetching bills
      })
      .catch((error) => {
        console.log("Error fetching bills: ", error);
        setLoading(false); // Set loading to false in case of an error
      });
  };

  const filterBills = bills?.filter((bill) => bill?.debit === 0);

  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-4xl flex justify-center  gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="material-symbols:manage-history-rounded"></Icon>
            Manage Complete Bills
          </h1>
        </div>
      </div>

      <div className="overflow-x-auto m-5">
        <table className="min-w-full text-xs bg-gray-100">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="">
            <tr className="text-center bg-green-600 text-white">
              <th className="p-3 border">#</th>
              <th className="p-3 border">Customer</th>

              <th className="p-3 border">Email</th>
              <th className="p-3 border">Date</th>
              <th className="p-3 border text-left">Details</th>
              <th className="p-3 border">Debit</th>
              <th className="p-3 border">Credit</th>
              <th className="p-3 border">Status</th>
              {/* <th className="p-3">Action</th> */}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <LoadingComponent /> // Show loading component while fetching bills
            ) : (
              filterBills?.map((payment, index) => (
                <AdminCompletePaymentTable
                  fetchBills={fetchBills}
                  payment={payment}
                  index={index}
                  key={payment._id} // Add key prop to avoid React warning
                ></AdminCompletePaymentTable>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminCompletePayment;
