import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const UserOrderHistoryTable = ({ order, phone }) => {
  return (
    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 text-xs">
      <th
        scope="row"
        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {order?._id}
      </th>
      <td class="px-6 py-4">{order?.orderType}</td>
      <td class="px-6 py-4">19.04.2023</td>
      <td class="px-6 py-4">
        {order?.operator
          ? order?.operator
          : order?.operatorDetails[0]?.operatorName}
      </td>
      <td class="px-6 py-4">{phone}</td>

      <td class="px-6 py-4  ">
        <span className=" bg-yellow-200 p-1 rounded">
          {order?.paymentStatus}
        </span>
      </td>

      <td class="px-6 py-4">{order?.orderStatus}</td>
      <td class=" px-6 py-4 ">
        <Link
          to={`/userDashboard/order/${order?.orderType}/${order?._id}`}
          class="font-medium text-blue-600 text-center dark:text-blue-500 hover:underline"
        >
          {" "}
          <Icon className="" icon="carbon:view-filled"></Icon>{" "}
        </Link>
      </td>
      <td class="flex items-center justify-center px-6 py-4 space-x-3">
        {/* {order?.orderStatus === "pending" && ( */}
        <Link
          to={`update/${order?.orderType}/${order?._id}`}
          class="font-medium text-blue-600 text-center dark:text-blue-500 hover:underline"
        >
          <Icon className="" icon="fa-solid:edit"></Icon>
        </Link>
        {/* )} */}
      </td>
    </tr>
  );
};

export default UserOrderHistoryTable;
