import axios from "axios";
import React, { useState } from "react";
import AuthUser from "../../Hooks/authUser";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

const UserOrderSim = () => {
  const { userInfo } = AuthUser();
  console.log(userInfo);
  const [formData, setFormData] = useState({
    coopVoce: "",
    digi: "",
    fastWeb: "",
    heraComm: "",
    hoOtherName: "",
    iliad: "",
    iren: "",
    kenaMobileNoverca: "",
    linkem: "",
    lycaMobile: "",
    ordererId: userInfo?._id,
    ordererName: userInfo?.name,
    ordererEmail: userInfo?.email,
    ordererPhone: userInfo?.phone,
    ordererRole: userInfo?.role,
    others: "",
    posteMobile2014: "",
    posteMobileFull: "",
    rabonaMobile: "",
    ringo: "",
    skyTvOrNetflix: "",
    skyWiFi: "",
    skyGlass: "",
    timTelecomItalias: "",
    tiscali: "",
    unoMobile: "",
    veryMobile: "",
    vodafone: "",
    windTreSpa: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://servergenzam.genzamwallet.com/api/v1/orderSim",
        formData
      );
      if (response.status === 201) {
        console.log(formData);
        alert("order submitted successfully");
        setFormData({
          coopVoce: "",
          digi: "",
          fastWeb: "",
          heraComm: "",
          hoOtherName: "",
          iliad: "",
          iren: "",
          kenaMobileNoverca: "",
          linkem: "",
          ordererId: userInfo?._id,
          ordererName: userInfo?.name,
          ordererEmail: userInfo?.email,
          ordererPhone: userInfo?.phone,
          ordererRole: userInfo?.role,
          lycaMobile: "",
          others: "",
          posteMobile2014: "",
          posteMobileFull: "",
          rabonaMobile: "",
          ringo: "",
          skyTvOrNetflix: "",
          skyWiFi: "",
          skyGlass: "",
          timTelecomItalias: "",
          tiscali: "",
          unoMobile: "",
          veryMobile: "",
          vodafone: "",
          windTreSpa: "",
        });
      } else {
        alert("Failed to submit order. Please try again later.");
      }
    } catch (error) {
      alert(
        "An error occurred while submitting the order. Please try again later."
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevenFormData) => ({
      ...prevenFormData,
      [name]: value,
    }));
  };

  return (
    <div className=" min-h-screen ">
      <div className=" bg-thirdLightPrimary  flex    justify-between">
        <div>
          <p className="    font-semibold p-2 ">USER DASHBOARD - SIM ORDER </p>
        </div>
        <div>
          {" "}
          <Link to="/userDashboard/user-request-sim-history">
            {" "}
            <p className=" font-semibold p-2 underline flex  gap-2 justify-start  items-center ">
              {" "}
              <Icon icon="dashicons:cart"></Icon> Request SIM History
            </p>
          </Link>
        </div>
      </div>
      <div className="overflow-x-auto  p-5  ">
        <form onSubmit={handleSubmit}>
          <table className="min-w-full text-xs bg-thirdLightPrimary  rounded-md ">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col className="" />
            </colgroup>
            <thead className="dark:bg-gray-700">
              <tr className="text-left grid grid-cols-2 bg-gray-200 rounded-md  ">
                <th className="p-3">Operator</th>
                <th className="p-3 ">Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Coop Voce</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.coopVoce}
                    name="coopVoce"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">DIGI </p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.digi}
                    name="digi"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">FAST WEB </p>
                </td>
                <td className="p-3">
                  <input
                    name="fastWeb"
                    onChange={handleChange}
                    value={formData.fastWeb}
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">HERA COMM </p>
                </td>
                <td className="p-3">
                  <input
                    name="heraComm"
                    onChange={handleChange}
                    value={formData.heraComm}
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">HO Other Name (Vei)</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.hoOtherName}
                    name="hoOtherName"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">iliad </p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.iliad}
                    name="iliad"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">IREN</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.iren}
                    name="iren"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">KENA MOBILE- NOVERCA </p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.kenaMobileNoverca}
                    name="kenaMobileNoverca"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">LINKEM</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.linkem}
                    name="linkem"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">LYCA MOBILE</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.lycaMobile}
                    name="lycaMobile"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">OTHERS</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.others}
                    name="others"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">POSTE MOBILE // 2014</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.posteMobile2014}
                    name="posteMobile2014"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">POSTE MOBILE FULL</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.posteMobileFull}
                    name="posteMobileFull"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">RABONA MOBILE </p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.rabonaMobile}
                    name="rabonaMobile"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">RINGO </p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.ringo}
                    name="ringo"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">SKY TV OR NETFLIX </p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.skyTvOrNetflix}
                    name="skyTvOrNetflix"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">SKY WI-FI </p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.skyWiFi}
                    name="skyWiFi"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">SKYGLASS</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.skyGlass}
                    name="skyGlass"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">TIM Telecom Italia</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.timTelecomItalias}
                    name="timTelecomItalias"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">TISCALI</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.tiscali}
                    name="tiscali"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">UNO MOBILE</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.unoMobile}
                    name="unoMobile"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">VERYMOBILE</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.veryMobile}
                    name="veryMobile"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">VODAFONE</p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.vodafone}
                    name="vodafone"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">WIND TRE S.P.A </p>
                </td>
                <td className="p-3">
                  <input
                    onChange={handleChange}
                    value={formData.windTreSpa}
                    name="windTreSpa"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>

              <div className=" p-5">
                <button
                  type="submit"
                  className=" bg-primary text-white hover:bg-secondary text-xs p-2 rounded-md "
                >
                  Save Order
                </button>
              </div>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default UserOrderSim;
