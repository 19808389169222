import React, { useEffect, useState } from "react";
import "flowbite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AuthUser from "../../../Hooks/authUser";
import { singleImageUpload } from "../../../Hooks/ImageUpload";
import Loading from "../../../Shared/Loading/Loading";
import { useParams } from "react-router-dom";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const UpdateGasOrder = () => {
  const { userInfo } = AuthUser();
  const [order, setOrder] = useState([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [cIdentityImg, setCIdentityImg] = useState("");
  const [codiceFiscaleImg, setCodiceFiscaleImg] = useState("");
  const [caricaModuloDiSkyImg, setCaricaModuloDiSkyImg] = useState("");
  const [dichiarationImg, setDichiarationImg] = useState("");
  const [pagamentoImg, setPagamento] = useState("");
  const [soggoirnoImg, setSoggoirnoImg] = useState("");
  const [patente, setPatenteImg] = useState("");
  const [gasDichiration, setGasDichirationImg] = useState("");
  const [prezzo, setPrezzoImg] = useState("");
  const [vecchaiaBolletta, setVecchaiaBollettaImg] = useState("");

  const [operators, setOperators] = useState([]);
  const [operatorName, setOperatorName] = useState("");

  useEffect(() => {
    setLoading(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/newGasOrder/${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrder(data);
        setLoading(false);
      });
  }, [id, refresh]);

  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/gasOperator")
      .then((res) => res.json())
      .then((data) => setOperators(data));
  }, []);
  const filterOffer = operators?.filter(
    (op) => op?.operatorName === operatorName
  );

  const handelAddGasOrder = async (e) => {
    e.preventDefault();
    const operator = e.target?.operator?.value;
    const name = e.target?.name?.value;
    const surName = e.target?.surName?.value;
    const dateOfBirth = startDate;
    const phone = e.target?.phone?.value;
    const taxIdCode = e.target?.taxIdCode?.value;
    const email = e.target?.email?.value;
    const activateAddress = e.target?.activateAddress?.value;
    const cIdentity = e.target?.cIdentity?.value;
    const citta = e.target?.citta?.value;
    const soggoirno = e.target?.soggoirno?.value;
    const province = e.target?.province?.value;
    const cap = e.target?.cap?.value;
    const payment = e.target?.payment?.value;
    const CodicePdr = e.target?.CodicePdr?.value;
    const matricolaContatore = e.target?.matricolaContatore?.value;
    const clienteWindtre = e.target?.clienteWindtre?.value;
    const numberoCellulare = e.target?.numberoCellulare?.value;
    const clienteAltriOperatore = e.target?.clienteAltriOperatore?.value;
    const numeroCellulare = e.target?.numberoCellulare?.value;

    const introduction = {
      name,
      surName,
      dateOfBirth: dateOfBirth ? dateOfBirth : order?.introduction?.dateOfBirth,
      taxIdCode,
      phone,
      email,
      activateAddress,
      cIdentity,
      soggoirno,
      cap,
      citta,
      province,
    };
    const documents = {
      vecchaiaBolletta: vecchaiaBolletta
        ? vecchaiaBolletta
        : order?.documents?.vecchaiaBolletta,
      prezzo: prezzo ? prezzo : order?.documents?.prezzo,
      gasDichiration: gasDichiration
        ? gasDichiration
        : order?.documents?.gasDichiration,
      patente: patente ? patente : order?.documents?.patente,
      soggoirnoImg: soggoirnoImg
        ? soggoirnoImg
        : order?.documents?.soggoirnoImg,
      pagamentoImg: pagamentoImg
        ? pagamentoImg
        : order?.documents?.pagamentoImg,
      dichiarationImg: dichiarationImg
        ? dichiarationImg
        : order?.documents?.dichiarationImg,
      caricaModuloDiSkyImg: caricaModuloDiSkyImg
        ? caricaModuloDiSkyImg
        : order?.documents?.caricaModuloDiSkyImg,
      codiceFiscaleImg: codiceFiscaleImg
        ? codiceFiscaleImg
        : order?.documents?.codiceFiscaleImg,
      cIdentityImg: cIdentityImg
        ? cIdentityImg
        : order?.documents?.cIdentityImg,
    };
    const newGasOrder = {
      operator: operator ? operator : order?.operatorDetails[0]?.operatorName,
      operatorDetails: filterOffer?.length
        ? filterOffer
        : order?.operatorDetails,
      payment,
      CodicePdr,
      matricolaContatore,
      clienteWindtre,
      numberoCellulare,
      clienteAltriOperatore,
      numeroCellulare,
      introduction,
      documents,
      resellerId: userInfo?._id,
      resellerEmail: userInfo?.email,
      resellerName: userInfo?.name,
      role: userInfo?.role,
      resellerNumber: userInfo?.phone,
    };
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/newGasOrder/${id}`;
    await UpdateHooks(BASE_URL, newGasOrder, true, "Gas Order Updated");
    setRefresh(!refresh);
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    if (event?.target?.name === "prezzo") {
      singleImageUpload(formData, setPrezzoImg);
    }
    if (event?.target?.name === "gasDichiration") {
      singleImageUpload(formData, setGasDichirationImg);
    }
    if (event?.target?.name === "patente") {
      singleImageUpload(formData, setPatenteImg);
    }
    if (event?.target?.name === "soggoirnoImg") {
      singleImageUpload(formData, setSoggoirnoImg);
    }
    if (event?.target?.name === "pagamento") {
      singleImageUpload(formData, setPagamento);
    }
    if (event?.target?.name === "dichiaration") {
      singleImageUpload(formData, setDichiarationImg);
    }
    if (event?.target?.name === "caricaModuloDiSky") {
      singleImageUpload(formData, setCaricaModuloDiSkyImg);
    }
    if (event?.target?.name === "codiceFiscale") {
      singleImageUpload(formData, setCodiceFiscaleImg);
    }
    if (event?.target?.name === "cIdentityImg") {
      singleImageUpload(formData, setCIdentityImg);
    }
    if (event?.target?.name === "vecchaiaBolletta") {
      singleImageUpload(formData, setVecchaiaBollettaImg);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className=" min-h-screen">
      <div className=" m-5 bg-thirdLightPrimary p-5 ">
        <form
          onSubmit={handelAddGasOrder}
          className="  bg-lightPrimary rounded-md p-5"
        >
          <div className="">
            <p className="  font-semibold pb-10 ">New Gas Order</p>
          </div>
          <div className=" w-2/4 ">
            <p className=" text-xs pb-3 font-semibold">
              Operator{" "}
              <span className="font-normal text-yellow-600">
                {order?.operatorDetails &&
                  order?.operatorDetails[0]?.operatorName}
              </span>
            </p>
            {order?.operatorDetails && (
              <select
                type="text"
                onChange={(e) => setOperatorName(e.target.value)}
                name="operator"
                className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                required
              >
                <option selected value="">
                  ..Choose...
                </option>
                {operators?.map((operator) => (
                  <option
                    selected={
                      order?.operatorDetails[0]?.operatorName ==
                      operator?.operatorName
                        ? true
                        : false
                    }
                    value={operator?.operatorName}
                    key={operator?._id}
                  >
                    {operator?.operatorName}
                  </option>
                ))}
              </select>
            )}
          </div>
          {operatorName && (
            <div className="mt-3 bg-white p-3 rounded-lg">
              <p className="text-2xl font-medium">
                {filterOffer[0]?.operatorName}
              </p>
              <p className="text-sm mt-2">{filterOffer[0]?.operatorDetails}</p>
            </div>
          )}
          <div className=" text-xs pt-3 font-semibold  ">
            <p>Introduction:</p>
          </div>

          <div className=" grid grid-cols-2 gap-4 pt-5">
            <div>
              <label
                htmlFor="name"
                className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                defaultValue={order?.introduction?.name}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Name"
                required
              />
            </div>
            <div>
              <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                Surname
              </label>
              <input
                type="text"
                name="surName"
                defaultValue={order?.introduction?.surName}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Sure Name"
                required
              />
            </div>
          </div>

          {/* component */}

          <div className=" grid grid-cols-4 gap-4 pt-4 ">
            <div>
              <label
                htmlFor="date"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                Date of birth
              </label>
              <DatePicker
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(date) => setStartDate(date)}
              />
            </div>

            <div>
              <div>
                <label
                  htmlFor="tax-code"
                  className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
                >
                  Tax ID code
                </label>
                <input
                  type="text"
                  defaultValue={order?.introduction?.taxIdCode}
                  id="sure_name"
                  name="taxIdCode"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Tax Code"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                Mobile
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.phone}
                name="phone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Mobile Number"
                required
              />
            </div>
            <div>
              <label
                htmlFor="e-mail"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                E-mail
              </label>
              <input
                type="text"
                name="email"
                defaultValue={order?.introduction?.email}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Mobile Number"
                required
              />
            </div>
          </div>

          <div>
            <div>
              <p className=" text-xs font-semibold pt-2 pb-2">
                The address where you will activate the Wi-Fi (fixed) should be
                write here,
              </p>
              <textarea
                name="activateAddress"
                defaultValue={order?.introduction?.activateAddress}
                className="h-40 px-3 text-sm py-1  outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                placeholder="Add your text here"
              ></textarea>
            </div>
          </div>

          <div className=" grid grid-cols-2 gap-4 pt-5">
            <div>
              <label
                htmlFor="Identity"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                C. Identity
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.cIdentity}
                name="cIdentity"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter C. Identity"
                required
              />
            </div>
            <div>
              <label
                htmlFor="SOGGOIRNO"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                SOGGOIRNO
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.soggoirno}
                name="soggoirno"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter SOGGOIRNO"
                required
              />
            </div>
          </div>

          <div className=" grid grid-cols-3 gap-4 pt-4 ">
            <div>
              <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                CAP
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.cap}
                name="cap"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter CAP "
                required
              />
            </div>

            <div>
              <div>
                <label
                  htmlFor="first_name"
                  className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
                >
                  Citta
                </label>
                <input
                  type="text"
                  name="citta"
                  defaultValue={order?.introduction?.citta}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=" Enter Citta "
                  required
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="Province "
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                Province
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.province}
                name="province"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=" Enter Province "
                required
              />
            </div>
          </div>

          {/* Document section  */}

          <div>
            <div className=" text-xs pt-3 pb-3 font-semibold  ">
              <p>Documents:</p>
            </div>
            <div className=" lg:grid lg:grid-cols-2 text-xs gap-4 ">
              <div className="max-w-2xl ">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  C. Identity
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="cIdentityImg"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  CODICE FISCALE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="codiceFiscale"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  Carica modulo di Sky
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="caricaModuloDiSky"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  DICHIARATION
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="dichiaration"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  PAGAMENTO E INVIO DELLA BOLLETTA ANCHE CONTO CORRENTE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="pagamento"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  SOGGOIRNO
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="soggoirnoImg"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  PATENTE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="patente"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  POD EE/PDR GAS DICHIRATION
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="gasDichiration"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  PREZZO NETTO NATURA CASA GAS
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    onChange={handleChangeUploadImage}
                    name="prezzo"
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" pt-5 text-xs ">
            <div className=" w-2/4 ">
              <p className=" text-xs pb-3 font-semibold">
                Payment:{" "}
                <span className="font-norlam text-yellow-600">
                  {order?.payment}
                </span>
              </p>
              <select
                type="text"
                name="payment"
                className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                required
              >
                {" "}
                <option disabled selected>
                  please select
                </option>
                <option value="Bollettino Postale">Bollettino Postale</option>
                <option value="Conto Corrente / RID IBAN:">
                  Conto Corrente / RID IBAN:
                </option>
              </select>
            </div>

            <div className=" w-2/4 mt-2">
              <label
                htmlFor="first_name"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                Codice PDR
              </label>
              <input
                type="text"
                name="CodicePdr"
                defaultValue={order?.CodicePdr}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Codice PDR"
                required
              />
            </div>

            <div className=" grid grid-cols-2 gap-4 pt-5">
              <div>
                <label
                  htmlFor="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-xs"
                >
                  MATRICOLA CONTATORE
                </label>
                <input
                  type="text"
                  defaultValue={order?.matricolaContatore}
                  name="matricolaContatore"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter MATRICOLA CONTATORE"
                  required
                />
              </div>
              <div className="max-w-2xl text-xs">
                <label className="text-xs font-medium text-gray-500">
                  VECCHIA BOLLETTA
                </label>
                <div className="relative z-0 mt-0.5 flex w-full text-xs -space-x-px">
                  <input
                    name="vecchaiaBolletta"
                    onChange={handleChangeUploadImage}
                    type="file"
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
            </div>

            <div className=" grid grid-cols-2 gap-4 pt-5">
              <div className=" w-full ">
                <p className=" text-xs pb-3 font-semibold">
                  CLIENTE WINDTRE{" "}
                  <span className="font-norlam text-yellow-600">
                    {order?.clienteWindtre}
                  </span>
                </p>
                <select
                  type="text"
                  name="clienteWindtre"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option disabled selected>
                    Please Select
                  </option>
                  <option
                    selected={order?.clienteWindtre == "si" ? true : false}
                    value="si"
                  >
                    SI
                  </option>
                  <option
                    selected={order?.clienteWindtre == "no" ? true : false}
                    value="no"
                  >
                    No
                  </option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="NUMBERO-CELLULARE"
                  className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
                >
                  NUMBERO CELLULARE
                </label>
                <input
                  type="text"
                  defaultValue={order?.numberoCellulare}
                  name="numberoCellulare"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter NUMBERO CELLULARE"
                  required
                />
              </div>

              <div className=" w-full ">
                <p className=" text-xs pb-3 font-semibold">
                  CLIENTE ALTRI OPERATORE{" "}
                  <span className="font-norlam text-yellow-600">
                    {order?.clienteAltriOperatore}
                  </span>
                </p>
                <select
                  type="text"
                  name="clienteAltriOperatore"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option disabled selected>
                    Please Select
                  </option>
                  <option
                    selected={
                      order?.clienteAltriOperatore == "si" ? true : false
                    }
                    value="si"
                  >
                    SI
                  </option>
                  <option
                    selected={
                      order?.clienteAltriOperatore == "no" ? true : false
                    }
                    value="no"
                  >
                    NO
                  </option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="NUMERO-CELLULARE"
                  className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
                >
                  NUMERO CELLULARE
                </label>
                <input
                  type="text"
                  defaultValue={order?.numeroCellulare}
                  name="numeroCellulare"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter NUMERO CELLULARE"
                  required
                />
              </div>
            </div>
          </div>
          <div className=" pt-5">
            <button
              type="submit"
              class="text-white  bg-secondary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Update !
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateGasOrder;
