import ResellerHome from "../Component/Reseller/ResellerHome";
import SimSales from "../Component/SimSales/SimSales";
import UserAllReports from "../Pages/UserDashboard/UserOrderReports";
import UserAllSim from "../Pages/UserDashboard/UserAllSim";
import UserBillHistory from "../Pages/UserDashboard/UserBillHistory";
import UserBills from "../Pages/UserDashboard/UserBills";
import UserHome from "../Pages/UserDashboard/UserHome";
import UserInStock from "../Pages/UserDashboard/UserInStock";
import UserNewGasOrder from "../Pages/UserDashboard/UserNewGasOrder";
import UserNewLuceOrder from "../Pages/UserDashboard/UserNewLuceOrder";
import UserNewWifiOrder from "../Pages/UserDashboard/UserNewWifiOrder";
import UserOrder from "../Pages/UserDashboard/UserOrder";
import UserOrderHistory from "../Pages/UserDashboard/UserOrderHistory";
import UserOrderSim from "../Pages/UserDashboard/UserOrderSim";
import UserOrderView from "../Pages/UserDashboard/UserOrderView";
import UserPaymentHistory from "../Pages/UserDashboard/UserPaymentHistory";
import UserSimSales from "../Pages/UserDashboard/UserSimSales";
import UserSimSalesOrder from "../Pages/UserDashboard/UserSimSalesOrder";
import UserSimSold from "../Pages/UserDashboard/UserSimSold";
import UserTransactionReport from "../Pages/UserDashboard/UserTransactionReport";
import UserTransactionReportView from "../Pages/UserDashboard/UserTransactionReportView";
import UserViewWifi from "../Pages/UserDashboard/UserViewWifi";
import UserWifi from "../Pages/UserDashboard/UserWifi";
import UserOffer from "./UserOffer";
import UserViewOffer from "./UserViewOffer";
import UserOrderReports from "../Pages/UserDashboard/UserOrderReports";
import UserSimReports from "../Pages/UserDashboard/UserSimReports";
import UserChangePassword from "../Pages/UserDashboard/UserChangePassword";
import ChangePassword from "../Component/Settings/ChangePassword";
import UserViewWifiOrder from "../Pages/UserDashboard/ViewOrder/UserViewWifiOrder";
import UserViewGasOrder from "../Pages/UserDashboard/ViewOrder/UserViewGasOrder";
import UserViewLuceOrder from "../Pages/UserDashboard/ViewOrder/UserViewLuceOrder";
import UserEditSim from "../Pages/UserDashboard/UserEditSim";
import UserUpdateWifiOrder from "../Pages/UserDashboard/UserUpdateOrderHistory/UserUpdateWifiOrder";
import UserUpdateGasOrder from "../Pages/UserDashboard/UserUpdateOrderHistory/UserUpdateGasOrder";
import UserUpdateLuceOrder from "../Pages/UserDashboard/UserUpdateOrderHistory/UserUpdateLuceOrder";
import UserUpdateOrder from "../Pages/UserDashboard/UserUpdateOrder";
import SimReport from "../Component/SimReports.js/SimReport";
import UserRequestSimHistory from "../Pages/UserDashboard/UserRequestSimHistory";
import UserSimInvoice from "../Pages/UserDashboard/UserSimInvoice";

const UserRoute = [
  // { path: "resellerhome", Component: ResellerHome },
  // { path: "simSales", Component: SimSales },
  { path: "userhome", Component: UserHome },
  { path: "user-sim-sales", Component: UserSimSales },
  {
    path: "/userDashboard/user-sim-sales/:id",
    Component: UserSimSalesOrder,
  },

  { path: "user-new-wifi-order", Component: UserNewWifiOrder },
  { path: "user-new-gas-order", Component: UserNewGasOrder },
  { path: "user-new-luce-order", Component: UserNewLuceOrder },
  { path: "user-order-history", Component: UserOrderHistory },
  { path: "user-all-sim-list", Component: UserAllSim },
  { path: "user-in-stock", Component: UserInStock },
  { path: "user-sim-sold", Component: UserSimSold },
  { path: "user-order-sim", Component: UserOrderSim },
  { path: "user-bills", Component: UserBills },
  {
    path: "/userDashboard/user-bills/billshistory",
    Component: UserBillHistory,
  },
  {
    path: "/userDashboard/user-bills/user-payment-history",
    Component: UserPaymentHistory,
  },
  {
    path: "/userDashboard/user-bills/transactionhistory",
    Component: UserTransactionReport,
  },
  {
    path: "/userDashboard/user-bills/transactionhistory/user-transaction-report-view",
    Component: UserTransactionReportView,
  },
  {
    path: "user-order",
    Component: UserOrder,
  },
  {
    path: "/userDashboard/user-order/user-view-order",
    Component: UserOrderView,
  },
  { path: "user-offers", Component: UserOffer },
  {
    path: "/userDashboard/user-offers/user-offer-view/:id",
    Component: UserViewOffer,
  },
  { path: "user-wifi", Component: UserWifi },
  {
    path: "/userDashboard/user-wifi/user-view-wifi/:id",
    Component: UserViewWifi,
  },
  { path: "user-order-reports", Component: UserOrderReports },
  { path: "user-sim-reports", Component: SimReport },
  { path: "userchangepassword", Component: UserChangePassword },
  { path: "user-change-password", Component: ChangePassword },

  // { path: "order/updateorder", Component: UpdateOrder },
  { path: "order/wifi/:id", Component: UserViewWifiOrder },
  { path: "order/gas/:id", Component: UserViewGasOrder },
  { path: "order/luce/:id", Component: UserViewLuceOrder },
  { path: "user-all-sim-list/:id", Component: UserEditSim },

  {
    path: "user-order-history/update/wifi/:id",
    Component: UserUpdateWifiOrder,
  },
  { path: "user-order-history/update/gas/:id", Component: UserUpdateGasOrder },
  {
    path: "user-order-history/update/luce/:id",
    Component: UserUpdateLuceOrder,
  },
  { path: "user-order/update/:id", Component: UserUpdateOrder },
  { path: "user-order/sim-invoice/:id", Component: UserSimInvoice },
  {
    path: "/userDashboard/user-request-sim-history",
    Component: UserRequestSimHistory,
  },
];

export default UserRoute;
