import React from "react";
import Login from "../../Component/Home/Login";
import LandingNavbar from "../../Component/Home/LandingNavbar";
import { Outlet } from "react-router-dom";
import Footer from "../../Shared/Loading/Footer";
import LandingFooter from "../../Component/Home/LandingFooter";
import WhyChoseUs from "../../Component/Home/WhyChoseUs";

const Home = () => {
  return (
    <div>
      <LandingNavbar></LandingNavbar>
      <Outlet></Outlet>
      <LandingFooter></LandingFooter>
    </div>
  );
};

export default Home;
