import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const Bot = () => {
  const whatsappNumber = "+393511789888"; // Replace with your WhatsApp number

  return (
    <div className="fixed bottom-5 sm:right-8 right-4 z-[999] cursor-pointer text-white text-4xl bg-green-500 w-16 h-16 flex items-center justify-center rounded-full animate-bounce">
      <a
        href={`https://wa.me/${whatsappNumber}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp className=" "></FaWhatsapp>
      </a>
    </div>
  );
};

export default Bot;
