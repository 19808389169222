import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const UpdateOrder = () => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  ">
        <p className="  font-semibold p-2 "> UPDATE ORDER</p>
      </div>
      <div className=" bg-thirdLightPrimary p-5 rounded-md text-xs m-5">
        <p>Vendita SIM</p>

        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

        <p>
          Operatore: <span className=" font-semibold"> WIND TRE S.P.A</span>{" "}
        </p>
        <p>
          Numero ICCID :{" "}
          <span className=" font-semibold"> **8939880846002008599</span>{" "}
        </p>

        <p>
          Numero di cellulare:{" "}
          <span className=" font-semibold">**3272047716</span>{" "}
        </p>
        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

        <div className=" grid grid-cols-2 gap-5 pb-5 text-xs">
          <div>
            <p className=" pb-2">ICCID Number</p>
            <input
              type="text"
              className=" w-full text-xs "
              placeholder="ICCID Number"
            />
          </div>
          <div>
            <p className="pb-2">Mobile</p>
            <input type="text" className=" w-full text-xs" placeholder="0000" />
          </div>
        </div>

        <div className="  border p-5 pb-5">
          <p>Offerta</p>
          <div className=" pt-5">
            <select
              type="text"
              id="first_name"
              className="bg-gray-50 border text-xs  border-gray-300  text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select"
              required
            >
              {" "}
              <option selected>..Choose...</option>
              <option>
                EASY PAY (CARTA DI CREDITO,POSTE PAY EVOULUTION,MASTER CARD,
                DEBIT CARD) OFFER Call Your Country Special Bangladesh EP 120
                GIGA Minuti Illimitati 10.1 GIGA per la navigazione in UE
              </option>
              <option>
                EASY PAY (CARTA DI CREDITO,POSTE PAY EVOULUTION,MASTER CARD,
                DEBIT CARD) OFFER Call Your Country Special Bangladesh EP 120
                GIGA Minuti Illimitati 10.1 GIGA per la navigazione in UE
              </option>
              <option>
                EASY PAY (CARTA DI CREDITO,POSTE PAY EVOULUTION,MASTER CARD,
                DEBIT CARD) OFFER Call Your Country Special Bangladesh EP 120
                GIGA Minuti Illimitati 10.1 GIGA per la navigazione in UE
              </option>
              <option>
                EASY PAY (CARTA DI CREDITO,POSTE PAY EVOULUTION,MASTER CARD,
                DEBIT CARD) OFFER Call Your Country Special Bangladesh EP 120
                GIGA Minuti Illimitati 10.1 GIGA per la navigazione in UE
              </option>
              <option>
                EASY PAY (CARTA DI CREDITO,POSTE PAY EVOULUTION,MASTER CARD,
                DEBIT CARD) OFFER Call Your Country Special Bangladesh EP 120
                GIGA Minuti Illimitati 10.1 GIGA per la navigazione in UE
              </option>
            </select>
          </div>

          <div className="overflow-x-auto pt-5">
            <table className="min-w-full text-xs bg-gray-50 rounded-md ">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="" />
              </colgroup>

              <thead className="dark:bg-gray-700">
                <tr className="text-left grid grid-cols-2 bg-gray-200 rounded-md  ">
                  <th className="p-3">Operator</th>
                  <th className="p-3 ">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border  border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3  ">
                    <p className=" ">Costo al mese </p>
                  </td>
                  <td className="p-3">
                    <p>€10.99</p>
                  </td>
                </tr>
                <tr className="border  border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3  ">
                    <p className=" ">Ricarica totale </p>
                  </td>
                  <td className="p-3">
                    <p>€10.99</p>
                  </td>
                </tr>
                <tr className="border  border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3  ">
                    <p className=" ">Offerta valida per </p>
                  </td>
                  <td className="p-3">
                    <p>UNLIMITED</p>
                  </td>
                </tr>
                <tr className="border  border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3  ">
                    <p className=" ">Pacchetto Internet gratuito (GB) </p>
                  </td>
                  <td className="p-3">
                    <p>
                      120 GIGA TUTTO MESE SOLO EASY PAY (CARTA DI CREDITO,POSTE
                      PAY EVOULUTION,MASTER CARD, DEBIT CARD)
                    </p>
                  </td>
                </tr>

                <tr className="border  border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3  ">
                    <p className=" ">Minuti gratuiti per internazionale </p>
                  </td>
                  <td className="p-3">
                    <p>500 MINUTE INTERNATIONAL DIRECT MINUTE CON CELLULARE</p>
                  </td>
                </tr>
                <tr className="border  border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3  ">
                    <p className=" ">Minuti illimitati a </p>
                  </td>
                  <td className="p-3">
                    <p>NO</p>
                  </td>
                </tr>
                <tr className="border  border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3  ">
                    <p className="  ">Minuti internazionali validi per </p>
                  </td>
                  <td className="p-3">
                    <p>
                      53 PAESI INCLUSI Minuti internazionali inclusi verso:
                      Argentina (solo fisso), Australia, Austria, Bangladesh,
                      Brasile, Belgio, Bulgaria, Canada, Cina, Cipro, Colombia,
                      Croazia, Danimarca, Finlandia, Francia, Germania,
                      Giappone, Gran Bretagna, Grecia, Guam, Hong Kong, India,
                      Indonesia, Islanda, Israele, Irlanda, Lituania,
                      Lussemburgo, Malesia, Malta, Marocco (solo fisso),
                      Messico, Mongolia, Norvegia, Olanda, Pakistan, Perù,
                      Polonia, Portogallo, Puerto Rico, Repubblica Ceca,
                      Romania, San Marino, Singapore, Slovacchia, Spagna, Stati
                      Uniti, Svezia, Sud Korea, Svizzera (solo fisso), Turchia
                      (solo fisso), Ungheria, Venezuela (solo fisso). Servizio
                      di telefonia mobile Wind Tre S.p.A. con Socio Unico -
                      Direzione e Coordinamento VIP-CKH Luxembourg S.à r.l. Sede
                      Legale: Largo Metropolitana, 5 - 20017 Rho (MI) Italia -
                      Reg. Imp/C.F.: 02517580920 Partita IVA: 13378520152 - RAEE
                      IT08020000002813 – Cap. sociale EURO 474.303.795,00 i.v
                      Prezzo Costo mensile (salvo eventuali promozioni) Già
                      clienti euro - Nuovi clienti nativi euro 9.99€ - mese
                      Nuovi clienti in portabilità euro 9.99€ - mese Eventuali
                      sconti e loro durata Costo mensile promozionale per x mesi
                      euro - Costo SIM euro 10€ Costi legati al consumo in caso
                      di superamento dei volumi inclusi nel prezzo ricorrente
                      Voce in Italia (non previsto se minuti illimitati)
                      Euro/min - Voce minuti verso l’estero Euro/min *vedi
                      tabella sms Euro/SMS 10 Eurocent SMS dati Euro/GB - *
                      Superati i minuti verso l’estero e i paesi non inclusi, si
                      applicano le tariffe previste per Call Your Country Super
                      con scatto alla risposta di 19 cent e tariffazione a
                      scatti da 60 secondi.
                    </p>
                  </td>
                </tr>

                <tr className="border  border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3  ">
                    <p className="  ">Altre informazioni </p>
                  </td>
                  <td className="p-3">
                    <p>
                      53 PAESI INCLUSI Minuti internazionali inclusi verso:
                      Argentina (solo fisso), Australia, Austria, Bangladesh,
                      Brasile, Belgio, Bulgaria, Canada, Cina, Cipro, Colombia,
                      Croazia, Danimarca, Finlandia, Francia, Germania,
                      Giappone, Gran Bretagna, Grecia, Guam, Hong Kong, India,
                      Indonesia, Islanda, Israele, Irlanda, Lituania,
                      Lussemburgo, Malesia, Malta, Marocco (solo fisso),
                      Messico, Mongolia, Norvegia, Olanda, Pakistan, Perù,
                      Polonia, Portogallo, Puerto Rico, Repubblica Ceca,
                      Romania, San Marino, Singapore, Slovacchia, Spagna, Stati
                      Uniti, Svezia, Sud Korea, Svizzera (solo fisso), Turchia
                      (solo fisso), Ungheria, Venezuela (solo fisso). Servizio
                      di telefonia mobile Wind Tre S.p.A. con Socio Unico -
                      Direzione e Coordinamento VIP-CKH Luxembourg S.à r.l. Sede
                      Legale: Largo Metropolitana, 5 - 20017 Rho (MI) Italia -
                      Reg. Imp/C.F.: 02517580920 Partita IVA: 13378520152 - RAEE
                      IT08020000002813 – Cap. sociale EURO 474.303.795,00 i.v
                      Prezzo Costo mensile (salvo eventuali promozioni) Già
                      clienti euro - Nuovi clienti nativi euro 9.99€ - mese
                      Nuovi clienti in portabilità euro 9.99€ - mese Eventuali
                      sconti e loro durata Costo mensile promozionale per x mesi
                      euro - Costo SIM euro 10€ Costi legati al consumo in caso
                      di superamento dei volumi inclusi nel prezzo ricorrente
                      Voce in Italia (non previsto se minuti illimitati)
                      Euro/min - Voce minuti verso l’estero Euro/min *vedi
                      tabella sms Euro/SMS 10 Eurocent SMS dati Euro/GB - *
                      Superati i minuti verso l’estero e i paesi non inclusi, si
                      applicano le tariffe previste per Call Your Country Super
                      con scatto alla risposta di 19 cent e tariffazione a
                      scatti da 60 secondi.
                    </p>
                  </td>
                </tr>
                <div className=" grid grid-cols-2 gap-5 items-center border  border-opacity-20 bg-gray-100 p-5 m-2 ">
                  <div>
                    <p className=" pb-2">IBAN</p>
                    <input
                      type="text"
                      className=" w-full text-xs "
                      placeholder="ICCID Number"
                    />
                  </div>
                  <div>
                    <p className="pb-2">Mobile</p>
                    <input
                      type="file"
                      className=" w-full text-xs bg-gray-200"
                    />
                  </div>
                </div>
              </tbody>
            </table>
          </div>
        </div>

        <div className=" grid grid-cols-2 gap-5 p-4 border  ">
          <div className=" border p-5">
            <div>
              <select
                type="text"
                id="first_name"
                className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                required
              >
                {" "}
                <option selected>..Choose...</option>
                <option>Maschio</option>
                <option>Femmina</option>
              </select>
            </div>

            <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
              <div>
                <p className=" pb-2">Nome di battesimo </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client first name"
                />
              </div>
              <div>
                <p className=" pb-2">Nome di battesimo </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client last name"
                />
              </div>
            </div>

            <div className=" pt-2">
              <p className=" pb-2">Nome di battesimo </p>
              <input
                type="text"
                className=" w-full text-xs "
                placeholder=" Enter client first name"
              />
            </div>

            <div className=" grid grid-cols-2  items-center   border-opacity-20 pt-2   ">
              <div>
                <p className=" pb-2">Data di nascita </p>
                <DatePicker
                  className=" text-xs "
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <div>
                <p className=" pb-2">Nazionalità </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client nationality "
                />
              </div>
            </div>

            <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
              <div>
                <p className=" pb-2">Documento numero </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client document number"
                />
              </div>
              <div>
                <p className=" pb-2">Luogo di rilascio </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client document place of issue"
                />
              </div>
            </div>

            <div className=" grid grid-cols-2  items-center    border-opacity-20 pt-2   ">
              <div>
                <p className=" pb-2">Data di emissione del documento</p>
                <DatePicker
                  className="  text-xs "
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <div>
                <p className=" pb-2">Data di scadenza del documento </p>
                <DatePicker
                  className="  text-xs "
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                />
              </div>
            </div>

            <div>
              <p className=" pt-2">RESIDENTE IN VIA, NUMERO, CIVICO, INTERNO</p>
              <textarea
                className="h-40 mt-2 text-sm   outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                placeholder="Enter Client address"
              ></textarea>
            </div>

            <div className=" grid grid-cols-3 gap-5 items-center   border-opacity-20 pt-2   ">
              <div>
                <p className=" pb-2">DLOCALITA </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client localita"
                />
              </div>
              <div>
                <p className=" pb-2">PROV </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client prov"
                />
              </div>
              <div>
                <p className=" pb-2">CAP </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client cap"
                />
              </div>
            </div>
            <div className=" grid grid-cols-2 gap-5 items-center   border-opacity-20 pt-2   ">
              <div>
                <p className=" pb-2">Telephono </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client telephono"
                />
              </div>
              <div>
                <p className=" pb-2">E-mail </p>
                <input
                  type="text"
                  className=" w-full text-xs "
                  placeholder=" Enter client e-mail"
                />
              </div>
            </div>
          </div>
          {/* second div */}
          <div className=" border p-5 ">
            <div>
              <select
                type="text"
                id="first_name"
                className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                required
              >
                {" "}
                <option>€10 Ricarica</option>
                <option>€20 Ricarica</option>
                <option>€25 Ricarica</option>
                <option>€30 Ricarica</option>
                <option>€35 Ricarica</option>
              </select>
            </div>

            <p className="pt-5  pl-1">MNP/PORTABILITA CON ALTRO GESTORE</p>
            <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            <div>
              <select
                type="text"
                id="first_name"
                className="bg-gray-50 border   border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                required
              >
                {" "}
                <option>Coop Voce</option>
                <option>DIGI</option>
                <option>FAST WEB</option>
                <option>HERA COMM</option>
                <option>HO Other Name (vei)</option>
              </select>
            </div>

            <div>
              <p className=" pb-2 pt-2">VECHIO SCHEDA ICCD NUMERO:</p>
              <input
                type="text"
                className=" w-full text-xs "
                placeholder=" Enter 9 Digit ICCID Number"
              />
            </div>
            <div>
              <p className=" pb-2 pt-2">VECHIO NUMERO CELLULARE:</p>
              <input
                type="text"
                className=" w-full text-xs "
                placeholder=" Enter mobile number"
              />
            </div>
            <div>
              <p className=" pb-2 pt-2">
                Carica la foto della vecchia SIM/Upload the photo of the old
                SIM:
              </p>

              <input type="file" className=" w-full text-xs border " />
            </div>
            <div>
              <p className=" pb-2 pt-2">
                Carica la foto della vecchia SIM/Upload the photo of the old
                SIM:
              </p>
              <div className=" grid grid-cols-1 gap-4">
                <input type="file" className=" w-full text-xs border " />
                <input type="file" className=" w-full text-xs border " />
              </div>
            </div>
            <div>
              <p className=" pt-2">Appunti</p>
              <textarea
                className="h-40 mt-2 text-sm   outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                placeholder="Enter note"
              ></textarea>
            </div>

            <div>
              <p className=" pt-2 font-semibold">Appunti</p>
              <div className=" text-1px pl-5 pt-2">
                <p>1. La dimensione massima per file è di 2 MB.</p>
                <p>2. Carica solo file PNG, JPEG, PDF.</p>
              </div>

              <div className=" grid grid-cols-1 gap-4 pt-2 ">
                <input type="file" className=" w-full text-xs border " />
                <input type="file" className=" w-full text-xs border " />
                <input type="file" className=" w-full text-xs border " />
                <input type="file" className=" w-full text-xs border " />
                <input type="file" className=" w-full text-xs border " />
                <input type="file" className=" w-full text-xs border " />
              </div>
            </div>
          </div>

          <div>
            <button className=" text-left bg-primary text-white hover:bg-secondary text-xs p-2 rounded-md ">
              Conferma Vendita
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateOrder;
