import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const AdminCompletePaymentTable = ({ payment, index, fetchBills }) => {
  console.log(payment);
  const [userName, setuserName] = useState("");
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/user/specific/?fieldName=${"email"}&&fieldValue=${
        payment?.customerName
      }`
    )
      .then((response) => response.json())
      .then((data) => setuserName(data?.data[0]?.name));
  });
  const handleDeleteBill = (billId) => {
    axios
      .delete(`https://servergenzam.genzamwallet.com/api/v1/bills/${billId}`)
      .then(() => {
        // Bill successfully deleted, fetch the updated list of bills
        fetchBills();
      })
      .catch((error) => {
        console.log("Error deleting bill: ", error);
      });
  };

  return (
    <tr
      key={payment._id} // Use payment._id as the key
      className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
    >
      <td className="p-3 border">
        <p>{index + 1}</p>
      </td>
      <td className="p-3 text-center border">
        <p>{userName}</p>
      </td>
      <td className="p-3 text-center border">
        <p>{payment.customerName}</p>
      </td>
      <td className="p-3 text-center border">
        <p>{payment.date} </p>
      </td>
      <td className="p-3 text-left border">
        <p>{payment.billDetails}</p>
      </td>
      <td className="p-3 text-center border">
        <p className="dark:text-gray-400">{payment.debit}</p>
      </td>
      <td className="p-3 text-center border">
        <p className="dark:text-gray-400">{payment.credit}</p>
      </td>
      <td className="p-3 flex justify-center items-center  border">
        <p className="dark:text-gray-600 bg-green-600 p-1 rounded-md w-fit text-white">
          {payment.paymentStatus}
        </p>
      </td>
    </tr>
  );
};

export default AdminCompletePaymentTable;
