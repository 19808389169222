import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../../Shared/Pagination";

const AdminSimSale = () => {
  const [pQuantity, setPQuantity] = useState(0);
  const [rQuantity, setRQuantity] = useState(0);
  const [cQuantity, setCQuantity] = useState(0);
  const [oQuantity, setOQuantity] = useState(0);
  const [pPage, setPPage] = useState(0);
  const [rPage, setRPage] = useState(0);
  const [cPage, setCPage] = useState(0);
  const [oPage, setOPage] = useState(0);
  const [size, setSize] = useState(20);

  // main data state
  const [pending, setPending] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [onProcess, setOnProcess] = useState([]);

  // filter data state
  const [filterPending, setFilterPending] = useState([]);
  const [filterCompleted, setFilterCompleted] = useState([]);
  const [filterRejected, setFilterRejected] = useState([]);
  const [filterOnProcess, setFilterOnProcess] = useState([]);

  // search state
  const [pendingSearch, setPendingSearch] = useState("");
  const [rejectedSearch, setRejectedSearch] = useState("");
  const [completedSearch, setCompletedSearch] = useState("");
  const [onProcessSearch, setOnProcessSearch] = useState("");
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/simSale/specific/?fieldName=${"status"}&&fieldValue=${"pending"}&&size=${size}&&page=${pPage}`
    )
      .then((res) => res.json())
      .then((data) => {
        setPending(data?.data);
        setPQuantity(data?.total);
        setFilterPending(data?.data);
      });
  }, [size, pPage]);
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/simSale/specific/?fieldName=${"status"}&&fieldValue=${"completed"}&&size=${size}&&page=${cPage}`
    )
      .then((res) => res.json())
      .then((data) => {
        setCompleted(data?.data);
        setCQuantity(data?.total);
        setFilterCompleted(data?.data);
      });
  }, [cPage, size]);
  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/simSale/specific/?fieldName=${"status"}&&fieldValue=${"rejected"}&&size=${size}&&page=${rPage}`
    )
      .then((res) => res.json())
      .then((data) => {
        setRejected(data?.data);
        setRQuantity(data?.total);
        setFilterRejected(data?.data);
      });
  }, [size, rPage]);

  useEffect(() => {
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/simSale/specific/?fieldName=${"status"}&&fieldValue=${"onProcess"}&&size=${size}&&page=${rPage}`
    )
      .then((res) => res.json())
      .then((data) => {
        setOnProcess(data?.data);
        setRQuantity(data?.total);
        setFilterOnProcess(data?.data);
      });
  }, [size, oPage]);

  // for filter
  const filterPendingData = pending?.filter((f) => {
    return (
      f?.resellerName?.toLowerCase().includes(pendingSearch?.toLowerCase()) ||
      f?.resellerEmail?.toLowerCase().includes(pendingSearch?.toLowerCase()) ||
      (f?.saleData.iccidNumber && f?.saleData.iccidNumber == pendingSearch)
    );
  });
  const filterRejectedData = rejected?.filter((f) => {
    return (
      f?.resellerName?.toLowerCase().includes(rejectedSearch?.toLowerCase()) ||
      f?.resellerEmail?.toLowerCase().includes(rejectedSearch?.toLowerCase()) ||
      (f?.saleData.iccidNumber && f?.saleData.iccidNumber == rejectedSearch)
    );
  });
  const filterCompletedData = completed?.filter((f) => {
    console.log("f", f);
    return (
      f?.resellerName?.toLowerCase().includes(completedSearch?.toLowerCase()) ||
      f?.resellerEmail
        ?.toLowerCase()
        .includes(completedSearch?.toLowerCase()) ||
      (f?.saleData.iccidNumber && f?.saleData.iccidNumber == completedSearch)
    );
  });
  const filterOnProcessData = onProcess?.filter((f) => {
    console.log("f", f);
    return (
      f?.resellerName?.toLowerCase().includes(onProcessSearch?.toLowerCase()) ||
      f?.resellerEmail
        ?.toLowerCase()
        .includes(onProcessSearch?.toLowerCase()) ||
      (f?.saleData.iccidNumber && f?.saleData.iccidNumber == onProcessSearch)
    );
  });

  //delete features
  const [showModal, setShowModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  // Delete a sim sale by ID
  const deleteSimSale = async (id) => {
    try {
      const response = await fetch(
        `https://servergenzam.genzamwallet.com/api/v1/simSale/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Remove the deleted item from the state
        setPending((prevPending) =>
          prevPending.filter((item) => item._id !== id)
        );

        // Update the filtered data after deleting the item
        setFilterPending((prevFilterPending) =>
          prevFilterPending.filter((item) => item._id !== id)
        );
      } else {
        console.error("Failed to delete sim sale");
      }
    } catch (error) {
      console.error("Error deleting sim sale", error);
    } finally {
      // Close the modal
      setShowModal(false);
    }
  };

  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-4xl flex justify-center  gap-x-5 items-center font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            <Icon icon="icon-park:sim"></Icon>
            SIM Sales
          </h1>
        </div>
      </div>
      <div class="m-6">
        <div class="flex flex-wrap -mx-6">
          {/* div start */}
          <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
            <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
              <div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
                <Icon
                  className=" text-3xl"
                  icon="flat-color-icons:sales-performance"
                ></Icon>
              </div>

              <div class="mx-5">
                <h4 class="text-2xl font-semibold text-gray-700">
                  {pQuantity}
                </h4>
                <div class="text-gray-500">Pending Sim Sale</div>
              </div>
            </div>
          </div>

          <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
            <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
              <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
                <Icon className=" text-3xl" icon="mdi:cart"></Icon>
              </div>

              <div class="mx-5">
                <h4 class="text-2xl font-semibold text-gray-700">
                  {cQuantity}
                </h4>
                <div class="text-gray-500">Completed Sim Sale</div>
              </div>
            </div>
          </div>

          <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
            <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
              <div class="p-3 rounded-full bg-pink-600 bg-opacity-75">
                <Icon
                  className=" text-3xl"
                  icon="streamline:interface-page-controller-loading-1-progress-loading-load-wait-waiting"
                ></Icon>
              </div>

              <div class="mx-5">
                <h4 class="text-2xl font-semibold text-gray-700">
                  {rQuantity}
                </h4>
                <div class="text-gray-500">Reject Sim Sale </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
            <h2 className="mb-4 text-1xl font-semibold leading">
              Pedding SIM Sales List
            </h2>
            <div className=" mb-2">
              <input
                placeholder=" Enter Name Or Email"
                onChange={(e) => setPendingSearch(e.target.value)}
                type="text"
                className=" text-xs inline rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm  "
              />
              <button
                onClick={() => setFilterPending(filterPendingData)}
                className="mt-3  inline-flex w-full items-center justify-center rounded-md border border-transparent  bg-yellow-400 px-4 py-2 font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Search
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full text-xs bg-gray-100">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col className="w-24" />
                </colgroup>
                <thead className=" text-white bg-yellow-400">
                  <tr className="text-center">
                    <th className="p-3 border">#</th>
                    <th className="p-3 border">Role</th>
                    <th className="p-3 border">Name</th>
                    <th className="p-3 border">Email</th>
                    <th className="p-3 border">ICCID Number</th>

                    <th className="p-3 border">Status</th>

                    <th className="p-3 border">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filterPending?.map((p, i) => (
                    <tr
                      key={p?.key}
                      p={p}
                      className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
                    >
                      <td className="p-3 border">
                        <p>{i + 1}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p>{p?.role}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p>{p?.resellerName}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className="dark:text-gray-400">{p?.resellerEmail}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className="dark:text-gray-400">
                          {p?.saleData.iccidNumber}
                        </p>
                      </td>

                      <td className="p-3 text-center border">
                        <p className=" bg-yellow-400 p-1 px-2 w-fit mx-auto rounded">
                          {p?.status}
                        </p>
                      </td>
                      <td className="p-3 text-xl items-center justify-center gap-5 flex">
                        <Link to={`/adminDashboard/adminsimsale/${p?._id}`}>
                          <div>
                            <Icon
                              className=" hover:bg-primary   rounded-md  cursor-pointer"
                              icon="carbon:view-filled"
                            />
                          </div>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {showModal && (
                  <div className="fixed inset-0 z-10 flex items-center justify-center backdrop">
                    <div className="bg-secondary w-96 p-6 rounded shadow-lg">
                      <h2 className="text-lg text-white font-semibold mb-4">
                        Confirm Delete
                      </h2>
                      <p className=" text-white">
                        Are you sure you want to delete this Order?
                      </p>
                      <div className="flex justify-end mt-6">
                        <button
                          className="text-white  rounded mr-4 bg-green-600 p-2 hover:bg-green-800"
                          onClick={() => setShowModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="text-white p-2 rounded bg-red-600 hover:bg-red-800 font-semibold"
                          onClick={() => {
                            deleteSimSale(deleteItemId);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </table>
            </div>
            <Pagination
              quantity={pQuantity}
              page={pPage}
              setPage={setPPage}
              size={size}
              setSize={setSize}
            />
          </div>
        </div>
        {/* second div */}
      </div>
      <div>
        <div>
          <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
            <h2 className="mb-4 text-1xl font-semibold leading">
              On Process SIM Sales List
            </h2>
            <div className=" mb-2">
              <input
                placeholder=" Enter Name Or Email"
                onChange={(e) => setOnProcessSearch(e.target.value)}
                type="text"
                className=" text-xs inline rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm  "
              />
              <button
                onClick={() => setFilterOnProcess(filterOnProcessData)}
                className="mt-3  inline-flex w-full items-center justify-center rounded-md border border-transparent  bg-primary/90 px-4 py-2 font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Search
              </button>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full text-xs bg-gray-100">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col className="w-24" />
                </colgroup>
                <thead className=" text-white bg-primary">
                  <tr className="text-center">
                    <th className="p-3 border">#</th>
                    <th className="p-3 border">Role</th>
                    <th className="p-3 border">Name</th>
                    <th className="p-3 border">Email</th>
                    <th className="p-3 border">ICCID Number</th>

                    <th className="p-3 border">Status</th>

                    <th className="p-3 border">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filterOnProcess?.map((p, i) => (
                    <tr
                      key={p?.key}
                      p={p}
                      className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
                    >
                      <td className="p-3 border">
                        <p>{i + 1}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p>{p?.role}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p>{p?.resellerName}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className="dark:text-gray-400">{p?.resellerEmail}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className="dark:text-gray-400">{p?.iccidNumber}</p>
                      </td>
                      <td className="p-3 text-center border">
                        <p className=" bg-primary p-1 px-2 w-fit mx-auto rounded">
                          {p?.status}
                        </p>
                      </td>
                      <td className="p-3 text-xl items-center justify-center gap-5 flex">
                        <Link to={`/adminDashboard/adminsimsale/${p?._id}`}>
                          <div>
                            <Icon
                              className=" hover:bg-primary   rounded-md  cursor-pointer"
                              icon="carbon:view-filled"
                            />
                          </div>
                        </Link>
                        <div>
                          <Icon
                            onClick={() => {
                              setDeleteItemId(p?._id);
                              setShowModal(true);
                            }}
                            className="hover:bg-primary rounded-md cursor-pointer"
                            icon="material-symbols:delete-outline"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {showModal && (
                  <div className="fixed inset-0 z-10 flex items-center justify-center backdrop">
                    <div className="bg-secondary w-96 p-6 rounded shadow-lg">
                      <h2 className="text-lg text-white font-semibold mb-4">
                        Confirm Delete
                      </h2>
                      <p className=" text-white">
                        Are you sure you want to delete this Order?
                      </p>
                      <div className="flex justify-end mt-6">
                        <button
                          className="text-white  rounded mr-4 bg-green-600 p-2 hover:bg-green-800"
                          onClick={() => setShowModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="text-white p-2 rounded bg-red-600 hover:bg-red-800 font-semibold"
                          onClick={() => {
                            deleteSimSale(deleteItemId);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </table>
            </div>
            <Pagination
              quantity={pQuantity}
              page={pPage}
              setPage={setPPage}
              size={size}
              setSize={setSize}
            />
          </div>
        </div>
        {/* second div */}
      </div>

      <div>
        <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
          <h2 className="mb-4 text-1xl font-semibold leading">
            Done SIM Sales List
          </h2>
          <div className=" mb-2">
            <input
              onChange={(e) => setCompletedSearch(e.target.value)}
              placeholder=" Enter Name Or Email"
              type="text"
              className=" text-xs inline rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm  "
            />
            <button
              onClick={() => setFilterCompleted(filterCompletedData)}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Search
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full text-xs bg-gray-100">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24" />
              </colgroup>
              <thead className=" bg-green-500 text-white ">
                <tr className="text-center">
                  <th className="p-3 border">#</th>
                  <th className="p-3 border">Role</th>
                  <th className="p-3 border">Name</th>
                  <th className="p-3 border">Email</th>
                  <th className="p-3 border">ICCID Number</th>

                  <th className="p-3 border">Status</th>

                  <th className="p-3 border">Action</th>
                </tr>
              </thead>
              <tbody>
                {filterCompleted?.map((p, i) => (
                  <tr
                    key={p?.key}
                    p={p}
                    className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
                  >
                    <td className="p-3 border">
                      <p>{i + 1}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p>{p?.role}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p>{p?.resellerName}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className="dark:text-gray-400">{p?.resellerEmail}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className="dark:text-gray-400">{p?.iccidNumber}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className=" bg-green-500 p-1 px-2 w-fit mx-auto rounded">
                        {p?.status}
                      </p>
                    </td>
                    <td className="p-3 text-xl items-center justify-center flex">
                      <Link to={`/adminDashboard/adminsimsale/${p?._id}`}>
                        <div>
                          <Icon
                            className=" hover:bg-primary  rounded-md  cursor-pointer"
                            icon="carbon:view-filled"
                          />
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            quantity={cQuantity}
            page={cPage}
            setPage={setCPage}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>

      <div>
        <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
          <h2 className="mb-4 text-1xl font-semibold leading">
            Reject SIM Sales List
          </h2>
          <div className=" mb-2">
            <input
              onChange={(e) => setRejectedSearch(e.target.value)}
              placeholder=" Enter Name Or Email"
              type="text"
              className=" text-xs inline rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm  "
            />
            <button
              onClick={() => setFilterRejected(filterRejectedData)}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Search
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full text-xs bg-gray-100">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="w-24" />
              </colgroup>
              <thead className=" bg-red-600 text-white">
                <tr className="text-center">
                  <th className="p-3">#</th>
                  <th className="p-3">Role</th>
                  <th className="p-3">Name</th>
                  <th className="p-3">Email</th>
                  <th className="p-3 border">ICCID Number</th>

                  <th className="p-3">Status</th>

                  <th className="p-3">Action</th>
                </tr>
              </thead>
              <tbody>
                {filterRejected?.map((p, i) => (
                  <tr
                    key={p?.key}
                    p={p}
                    className="border-b border-opacity-20 text-center dark:border-gray-700 dark:bg-gray-900"
                  >
                    <td className="p-3">
                      <p>{i + 1}</p>
                    </td>
                    <td className="p-3 text-center">
                      <p>{p?.role}</p>
                    </td>
                    <td className="p-3 text-center">
                      <p>{p?.resellerName}</p>
                    </td>
                    <td className="p-3 text-center">
                      <p className="dark:text-gray-400">{p?.resellerEmail}</p>
                    </td>
                    <td className="p-3 text-center border">
                      <p className="dark:text-gray-400">{p?.iccidNumber}</p>
                    </td>
                    <td className="p-3 text-center">
                      <p className=" bg-red-600 p-1 px-2 w-fit mx-auto rounded">
                        {p?.status}
                      </p>
                    </td>
                    <td className="p-3 text-xl items-center justify-center flex">
                      <Link to={`/adminDashboard/adminsimsale/${p?._id}`}>
                        <div>
                          <Icon
                            className=" hover:bg-primary  rounded-md  cursor-pointer"
                            icon="carbon:view-filled"
                          />
                        </div>
                      </Link>
                      <div>
                        <Icon
                          onClick={() => {
                            setDeleteItemId(p?._id);
                            setShowModal(true);
                          }}
                          className="hover:bg-primary rounded-md cursor-pointer"
                          icon="material-symbols:delete-outline"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            quantity={rQuantity}
            page={rPage}
            setPage={setRPage}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>
    </div>
  );
};

export default AdminSimSale;
