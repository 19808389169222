import React from "react";
import { Link } from "react-router-dom";
import Clock from "../../Component/Clock/Clock";
import { useState } from "react";
import { useEffect } from "react";
import AuthUser from "../../Hooks/authUser";
import Loading from "../../Shared/Loading/Loading";

const UserBillHistory = () => {
  const { userInfo } = AuthUser();
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(userInfo?.email);
  useEffect(() => {
    setLoading(true);
    fetch(
      `https://servergenzam.genzamwallet.com/api/v1/bills/specific/bill?fieldName=${"customerName"}&&fieldValue=${
        userInfo?.email
      }`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data?.data);
        setBills(data?.data);
        setLoading(false);
      });
  }, [userInfo?.email]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="min-h-screen">
      <div className="bg-thirdLightPrimary flex justify-between">
        <div>
          <p className="font-semibold p-2">USER DASHBOARD - BILL HISTORY</p>
        </div>
        <div>
          <p className="font-semibold p-2">
            <Clock></Clock>
          </p>
        </div>
      </div>

      <div className="bg-thirdLightPrimary m-5 p-5 rounded-md">
        <div className="container  bg-lightPrimary rounded-md mx-auto sm:p-4 dark:text-gray-100">
          <p className="mb-4 font-semibold leading-tight text-xs">
            Bills History
          </p>
          <div className="overflow-x-auto">
            <table className="table-auto w-full text-xs bg-gray-400 rounded-md">
              <thead className="text-white bg-black">
                <tr>
                  <th className="border px-4 py-2">#</th>
                  <th className="border px-4 py-2">Code</th>
                  <th className="border px-4 py-2">Bill for</th>
                  <th className="border px-4 py-2">Bill Month</th>
                  <th className="border px-4 py-2">Debit</th>
                  <th className="border px-4 py-2">Credit</th>
                  <th className="border px-4 py-2">Amount</th>
                  <th className="border px-4 py-2">Invoice</th>
                </tr>
              </thead>
              <tbody>
                {bills?.map((bill, index) => (
                  <tr
                    key={bill._id}
                    className="border px-2 py-1 bg-white text-center"
                  >
                    <td className="border px-2 py-1 text-center">
                      {index + 1}
                    </td>
                    <td className="border px-4 py-2 text-center">{bill._id}</td>
                    <td className="border px-4 py-2 text-center">
                      {bill.service}
                    </td>
                    <td className="border px-2 py-1 text-center">
                      {bill.date}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {bill.debit}
                    </td>
                    <td className="border px-2 py-1 text-center">
                      {bill.credit}
                    </td>
                    <td className="border px-4 py-2 text-center">
                      {bill.balance}
                    </td>
                    <td className="border px-4 py-2 text-center">Print</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBillHistory;
