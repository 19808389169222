import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";

const AdminHome = () => {
  // const [users, setUsers] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("https://servergenzam.genzamwallet.com/api/v1/user")
  //     .then((response) => {
  //       setUsers(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error loading user data:", error);
  //     });
  // }, []);
  // if (!Array.isArray(users)) {
  //   return <p>Loading...</p>; // or show an error message if appropriate
  // }

  // const totalUsers = users.length;

  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/user")
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.error("Error loading user data:", error);
      });
  }, []);

  if (!Array.isArray(users)) {
    return <p>Loading...</p>; // or show an error message if appropriate
  }

  // Filter users based on role being "user"
  const filteredUsers = users.filter((user) => user.role === "user");
  const filteredReseller = users.filter((user) => user.role === "reseller");

  // Get the total count of filtered users
  const totalUsers = filteredUsers.length;
  const totalReseller = filteredReseller.length;
  const totalClient = totalUsers + totalReseller;

  return (
    <div>
      <div>
        <div class="max-w-3xl mx-auto text-center mt-5">
          <h1 class="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
            Welcome To Genzam It
          </h1>
          <p class="text-lg text-gray-800 mb-8">
            Genzam It is a cutting-edge technology company that aims to
            revolutionize the way we interact with the world.
          </p>
        </div>

        {/* //information card  */}
        <div class="m-6">
          <div class="flex flex-wrap -mx-6">
            {/* div start */}
            <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
              <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
                <div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
                  <Icon
                    className=" text-3xl"
                    icon="flat-color-icons:sales-performance"
                  ></Icon>
                </div>

                <div class="mx-5">
                  <h4 class="text-2xl font-semibold text-gray-700">4644</h4>
                  <div class="text-gray-500">Total Sale</div>
                </div>
              </div>
            </div>

            <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
              <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
                <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
                  <Icon className=" text-3xl" icon="mdi:cart"></Icon>
                </div>

                <div class="mx-5">
                  <h4 class="text-2xl font-semibold text-gray-700">3453</h4>
                  <div class="text-gray-500">Total Complete Order</div>
                </div>
              </div>
            </div>

            <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
              <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
                <div class="p-3 rounded-full bg-pink-600 bg-opacity-75">
                  <Icon
                    className=" text-3xl"
                    icon="streamline:interface-page-controller-loading-1-progress-loading-load-wait-waiting"
                  ></Icon>
                </div>

                <div class="mx-5">
                  <h4 class="text-2xl font-semibold text-gray-700">678</h4>
                  <div class="text-gray-500">Total Pedding Order </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* information card two */}

        <div class="m-6">
          <div class="flex flex-wrap -mx-6">
            {/* div start */}
            <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
              <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
                <div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
                  <Icon
                    className=" text-3xl"
                    icon="teenyicons:user-solid"
                  ></Icon>
                </div>

                <div class="mx-5">
                  <h4 class="text-2xl font-semibold text-gray-700">
                    {totalClient}
                  </h4>
                  <div class="text-gray-500">Total Client</div>
                </div>
              </div>
            </div>

            <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
              <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
                <div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
                  <Icon
                    className=" text-3xl"
                    icon="teenyicons:user-solid"
                  ></Icon>
                </div>

                <div class="mx-5">
                  <h4 class="text-2xl font-semibold text-gray-700">
                    {totalReseller}
                  </h4>
                  <div class="text-gray-500">Total Reseller</div>
                </div>
              </div>
            </div>

            <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
              <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
                <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
                  <Icon className=" text-3xl" icon="vaadin:user"></Icon>
                </div>

                <div class="mx-5">
                  <h4 class="text-2xl font-semibold text-gray-700">
                    {totalUsers}
                  </h4>
                  <div class="text-gray-500">Total User</div>
                </div>
              </div>
            </div>

            {/* <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
              <div class="flex items-center px-5 py-6 shadow-md rounded-md bg-slate-100">
                <div class="p-3 rounded-full bg-pink-600 bg-opacity-75">
                  <Icon
                    className=" text-3xl"
                    icon="cryptocurrency-color:gas"
                  ></Icon>
                </div>

                <div class="mx-5">
                  <h4 class="text-2xl font-semibold text-gray-700">678</h4>
                  <div class="text-gray-500">Total Luce Order</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
