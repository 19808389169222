import React, { useEffect, useRef, useState } from "react";
import "./AdminDashboard.css";
import { NavLink, useNavigate, Outlet, Link } from "react-router-dom";
// import logo from "../Assets/images/logo.svg";
import { TbMedicineSyrup } from "react-icons/tb";
import { FiLogIn, FiUsers } from "react-icons/fi";
import { BiBorderAll } from "react-icons/bi";

import {
  MdOutlineDashboardCustomize,
  MdOutlineMailOutline,
  MdOutlineArticle,
} from "react-icons/md";
import { RxCaretDown } from "react-icons/rx";
import AuthUser from "../../Hooks/authUser";
import { Icon } from "@iconify/react";
import user from "../../Assets/user.png";

const AdminDashboard = () => {
  const { userInfo, logout } = AuthUser();
  const navWrapper = useRef();
  //hide sidenav by default
  const [issideNavOpen, setSidenavOpen] = useState(false);

  //show  medicine
  const [openMedicine, setOpenMedicine] = useState(false);
  //show  orders

  const [openOrder, setOpenOrder] = useState(false);

  //show news
  const [openNews, setOpenNews] = useState(false);

  //show blog
  const [openBlog, setOpenBlog] = useState(false);

  const navigate = useNavigate();

  //show sidenav on toggle
  const handleToggle = () => {
    issideNavOpen === true ? setSidenavOpen(false) : setSidenavOpen(true);
  };

  //open drop down
  const handleMedicineDropdown = (e) => {
    openMedicine === true ? setOpenMedicine(false) : setOpenMedicine(true);
    //console.log('clicked')
  };

  //open order drop down
  const handleOrderDropdown = (e) => {
    openOrder === true ? setOpenOrder(false) : setOpenOrder(true);
    //console.log('clicked')
  };

  //open blog drop down
  const handleBlogDropdown = (e) => {
    openBlog === true ? setOpenBlog(false) : setOpenBlog(true);
    //console.log('clicked')
  };

  //close sidebar on click

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        issideNavOpen &&
        navWrapper.current &&
        !navWrapper.current.contains(event.target)
      ) {
        setSidenavOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    //return wrapper.current;
  }, [issideNavOpen]);

  useEffect(() => {
    if (userInfo?.role === "admin") {
    } else {
      logout();
      navigate("/login");
    }
  }, []);
  //active style for sidenav

  let activeStyle = {
    backgroundColor: "#3B4D36",
  };

  return (
    <section className="">
      <div className="w-full">
        <div className="md:flex">
          <div className="w-full lg:w-1/5 lg:block hidden">
            <div
              id="drawer-navigation"
              className="drawer_height w-1/5  z-40 fixed left-0 top-0 p-4 overflow-y-auto h-full bg-secondary dark:bg-gray-800"
              tabindex="-1"
              aria-labelledby="drawer-navigation-label"
            >
              <div className="py-4 ">
                <Link
                  to={"/"}
                  className="flex items-center justify-center text-white"
                >
                  {/* <img src={logo} className="h-8 mr-3" alt="Renix Logo" /> */}
                  <p>Genzam it</p>
                </Link>

                <ul className="space-y-2 pt-8">
                  <li>
                    <NavLink
                      to={"adminhome"}
                      className="flex items-center gap-4 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <MdOutlineDashboardCustomize />
                      </span>

                      <span className="">Dashboard</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"adminalluserlist"}
                      className="flex items-center gap-4 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <FiUsers />
                      </span>

                      <span className="">All User List</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"adminallagentlist"}
                      className="flex items-center gap-4 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <FiUsers />
                      </span>

                      <span className="">All Agent List</span>
                    </NavLink>
                  </li>
                  <li>
                    <span
                      onClick={handleMedicineDropdown}
                      className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                    >
                      <div className="flex gap-4 items-center">
                        <span className="text-lg">
                          {/* <TbMedicineSyrup /> */}
                          <Icon icon="pepicons-pop:wifi-circle-filled"></Icon>
                        </span>

                        <span className="">Luce/Gas/Wifi</span>
                      </div>
                      <span
                        className={`text-2xl transition_move ${
                          openMedicine === true ? "rotate-180" : ""
                        }`}
                      >
                        <RxCaretDown />
                      </span>
                    </span>

                    <ul
                      className={`drop_down transition_move ${
                        openMedicine === true ? "block" : "hidden"
                      }`}
                    >
                      <li>
                        <NavLink
                          to={"adminluceorderlist"}
                          className="flex items-center  p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-2">Luce Order List</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"admingasorderlist"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Gas Order List</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"adminwifiorderlist"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Wifi Order List</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"adminPostWifiOperator"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Post wifi Operator</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"managewifioperator"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Manage wifi Operator</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"adminPostGasOperator"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Post Gas Operator</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"managegasoperator"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Manage Gas Operator</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"adminPostLuceOperator"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Post Luce Operator</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"manageluceoperator"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Manage Luce Operator</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to={"wifiOfferpost"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Wifi Offer Post</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"adminwifiofferpostmanage"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Wifi Offer Manage</span>
                        </NavLink>
                      </li>
                      {/* <li>
                        <NavLink
                          to={"medicine/allMedicines"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">All Medicines</span>
                        </NavLink>
                      </li> */}
                    </ul>
                  </li>
                  <li>
                    <span
                      onClick={handleOrderDropdown}
                      className="flex items-center justify-between cursor-pointer gap-5 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                    >
                      <div className="flex items-center gap-4">
                        <span className="text-lg">
                          <Icon icon="icon-park-outline:sim"></Icon>
                        </span>

                        <span className=""> Sim Orders</span>
                      </div>
                      <span
                        className={`text-2xl transition_move ${
                          openOrder === true ? "rotate-180" : ""
                        }`}
                      >
                        <RxCaretDown />
                      </span>
                    </span>
                    <ul
                      className={`drop_down  ${
                        openOrder === true ? "block" : "hidden"
                      }`}
                    >
                      <li>
                        <NavLink
                          to={"simorderagentuser"}
                          className="flex items-center p-2 text-[14px]  hover:bg-textColor font-normal  py-2.5 rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">SIM Orders Agent/User</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/adminDashboard/postSimOperator"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3"> Post Sim Operator</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"managesimoperator"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Manage Sim Operator</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/adminDashboard/adminsimpost"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3"> SIM Post</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"adminmanagesim"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Manage SIM </span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/adminDashboard/adminsimsale"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">SIM Sales Order</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to={"/adminDashboard/adminlatstofferpost"}
                          className="flex items-center p-2 text-[14px]  hover:bg-textColor font-normal  py-2.5 rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3"> SIM Offer Post</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"/adminDashboard/manageletestupcommingoffer"}
                          className="flex items-center p-2 text-[14px]  hover:bg-textColor font-normal  py-2.5 rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3"> SIM Offer Manage</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* //first blog */}

                  {/* second blogs */}
                  <li>
                    <span
                      onClick={handleBlogDropdown}
                      className="flex items-center cursor-pointer gap-4 justify-between px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                    >
                      <div className="flex items-center gap-4">
                        <span className="text-lg text-white">
                          <MdOutlineArticle />
                        </span>

                        <span className="">Payment</span>
                      </div>
                      <span
                        className={`text-2xl transition_move ${
                          openBlog === true ? "rotate-180" : ""
                        }`}
                      >
                        <RxCaretDown />
                      </span>
                    </span>
                    <ul
                      className={`drop_down  ${
                        openBlog === true ? "block" : "hidden"
                      }`}
                    >
                      <li>
                        <NavLink
                          to={"generatebills"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Generate Bills</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"adminmanagepayment"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Manage Payment</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          to={"admincompletebills"}
                          className="flex items-center p-2 text-[14px] hover:bg-textColor  font-normal py-2.5  rounded-md dark:text-white dark:hover:bg-gray-700 text-white"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          <span className="ml-3">Complete Payment</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <NavLink
                      to={"contactlist"}
                      className="flex items-center cursor-pointer gap-4  px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <Icon icon="material-symbols:contact-mail"></Icon>
                      </span>

                      <span>Contact List</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"adminpushnotification"}
                      className="flex items-center cursor-pointer gap-4  px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <Icon icon="basil:notification-on-solid"></Icon>
                      </span>

                      <span>Push Notification</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"adminscrollingtext"}
                      className="flex items-center cursor-pointer gap-4  px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <Icon icon="ant-design:notification-filled"></Icon>
                      </span>

                      <span>Scrolling Text </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={"adminnewuser"}
                      className="flex items-center cursor-pointer gap-4  px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <Icon icon="gridicons:create"></Icon>
                      </span>

                      <span> Create New User</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={"adminnewagent"}
                      className="flex items-center cursor-pointer gap-4  px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <Icon icon="gridicons:create"></Icon>
                      </span>

                      <span> Create New Reseller</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      to={"admin-reports"}
                      className="flex items-center cursor-pointer gap-4  px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      <span className="text-lg">
                        <Icon icon="mdi:report-line"></Icon>
                      </span>

                      <span> Report</span>
                    </NavLink>
                  </li>
                  <li
                    onClick={logout}
                    className="flex cursor-pointer items-center gap-4 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                  >
                    <span className="text-lg">
                      <FiLogIn className="text-white" />
                    </span>

                    <span className="">LogOut</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* dashboard main part */}
          <div className="w-full lg:w-4/5">
            <div>
              <div className="bg-secondary lg:bg-[#E7F7F4]  py-4 px-3">
                <div className="flex items-center justify-around ">
                  <span
                    className=" text-white  mx-3 lg:hidden cursor-pointer"
                    title="Open sidenav"
                    onClick={handleToggle}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-8 h-8"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                      />
                    </svg>
                  </span>

                  {/* user dropdown */}
                  <div
                    className=" mr-6 flex items-center cursor-pointer relative w-fit ml-auto"
                    //  onClick={handleShow}
                  >
                    <p className="font-semibold mr-2">{userInfo?.name}</p>
                    <img
                      src={user}
                      alt="user"
                      className="rounded-full w-8 h-8 md:w-10 md:h-10 "
                    />
                  </div>
                </div>
                {/* <hr className='my-3 md:hidden' />                                  */}
                {/*mobile search form */}
                {/* <div className="py-4  md:hidden px-40 text-center flex justify-center items-center">
                                    <form onSubmit='' className='relative text-center'>
                                        <span className="absolute top-3 left-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </span>

                                        <input
                                            type="text"
                                            name="searchBar"
                                            id="searchBar"
                                            className="pl-14 py-2.5 pr-2 block  rounded-lg border-gray-300 bg-card text-sm "
                                            placeholder="Search here..."
                                        />
                                    </form>
                                </div>  */}
              </div>
              <Outlet />
              {/* sidenav for mobile screen */}
              <div ref={navWrapper} className="">
                <div
                  id="drawer-navigation"
                  className={`side_nav_admin block lg:hidden z-40 h-screen p-4  bg-secondary w-80 dark:bg-gray-800 ${
                    issideNavOpen === true ? "activ" : ""
                  }`}
                  tabindex="-1"
                >
                  {/* <h5 id="drawer-navigation-label" className="text-base font-semibold text-gray-500 uppercase dark:text-gray-400">Menu</h5> */}

                  <button
                    type="button"
                    data-drawer-dismiss="drawer-navigation"
                    aria-controls="drawer-navigation"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-5 right-5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={handleToggle}
                  >
                    <svg
                      aria-hidden="true"
                      className="w-6 h-6"
                      fill="white"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="sr-only">Close menu</span>
                  </button>
                  <div className=" overflow-y-auto px-7">
                    <NavLink
                      to={"/"}
                      className="flex items-center justify-center pt-10 text-white"
                    >
                      {/* <img src={logo} className="h-8 mr-3" alt="Renix Logo" />
                       */}
                      <p>Genzam it</p>
                    </NavLink>

                    <ul className="space-y-2 pt-8">
                      <li>
                        <Link
                          to="/adminDashboard/overview"
                          // onClick={(e) => e.preventDefault()}

                          className="flex items-center gap-5 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                        >
                          <span className="text-lg">
                            <MdOutlineDashboardCustomize />
                          </span>

                          <span className="">Dashboard</span>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/adminDashboard/allAccounts"
                          // onClick={(e) => e.preventDefault()}

                          className="flex items-center gap-5 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                        >
                          <span className="text-lg">
                            <FiUsers />
                          </span>

                          <span className="">All Accounts</span>
                        </Link>
                      </li>
                      <li>
                        <span
                          onClick={handleMedicineDropdown}
                          className="flex items-center cursor-pointer gap-4 justify-between px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                        >
                          <div className="flex items-center gap-4">
                            <span className="text-lg">
                              <TbMedicineSyrup />
                            </span>

                            <span className="">Medicine</span>
                          </div>

                          <span
                            className={`text-2xl transition_move ${
                              openMedicine === true ? "rotate-180" : ""
                            }`}
                          >
                            <RxCaretDown />
                          </span>
                        </span>

                        <ul
                          className={`pl-3  ${
                            openMedicine === true ? "block" : "hidden"
                          }`}
                        >
                          <li>
                            <Link
                              to={
                                "/adminDashboard/medicine/addMedicineCategory"
                              }
                              className="flex items-center  hover:bg-textColor  p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-2">Add Category</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={
                                "/adminDashboard/medicine/allMedicineCategory"
                              }
                              className="flex items-center hover:bg-textColor  p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">All Categories</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/adminDashboard/medicine/addMedicine"}
                              className="flex items-center  hover:bg-textColor p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">Add Medicine</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/adminDashboard/medicine/allMedicines"}
                              className="flex items-center hover:bg-textColor  p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">All Medicines</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <span
                          onClick={handleOrderDropdown}
                          className="flex items-center cursor-pointer gap-4 justify-between px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                        >
                          <div className="flex items-center gap-4">
                            <span className="text-lg">
                              <BiBorderAll />
                            </span>

                            <span className="">Orders</span>
                          </div>

                          <span
                            className={`text-2xl transition_move ${
                              openOrder === true ? "rotate-180" : ""
                            }`}
                          >
                            <RxCaretDown />
                          </span>
                        </span>
                        <ul
                          className={`pl-3  ${
                            openOrder === true ? "block" : "hidden"
                          }`}
                        >
                          <li>
                            <Link
                              to={"/adminDashboard/orders/pendingOrders"}
                              className="flex items-center hover:bg-textColor  p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">Pending Orders</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/adminDashboard/orders/confirmedOrders"}
                              className="flex items-center hover:bg-textColor  p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">Confirmed Orders</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/adminDashboard/orders/rejectedOrders"}
                              className="flex items-center hover:bg-textColor  p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">Rejected Orders</span>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <span
                          onClick={handleBlogDropdown}
                          className="flex items-center cursor-pointer gap-4 justify-between px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                        >
                          <div className="flex items-center gap-4">
                            <span className="text-lg text-white">
                              <MdOutlineArticle />
                            </span>

                            <span className="">Blog</span>
                          </div>

                          <span
                            className={`text-2xl transition_move ${
                              openBlog === true ? "rotate-180" : ""
                            }`}
                          >
                            <RxCaretDown />
                          </span>
                        </span>
                        <ul
                          className={`pl-3  ${
                            openBlog === true ? "block" : "hidden"
                          }`}
                        >
                          <li>
                            <Link
                              to={"/adminDashboard/blog/addBlogCategory"}
                              className="flex items-center  hover:bg-textColor p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">Add Category</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/adminDashboard/blog/addBlog"}
                              className="flex items-center hover:bg-textColor  p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">Add Blog</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={"/adminDashboard/blog/allBlogs"}
                              className="flex items-center  hover:bg-textColor p-2 text-[14px] font-normal  rounded-lg dark:text-white dark:hover:bg-gray-700 text-white"
                            >
                              {/* <span className='text-lg'>
                                                        <TbMedicineSyrup />
                                                    </span> */}

                              <span className="ml-3">All Blogs</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link
                          to={""}
                          className="flex items-center px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                        >
                          <span className="text-lg">
                            <MdOutlineMailOutline />
                          </span>

                          <span className="ml-3">Contact List</span>
                        </Link>
                      </li>
                      <li
                        onClick={logout}
                        className="flex cursor-pointer items-center gap-5 px-2 py-2.5 text-[14px] font-normal rounded dark:text-white dark:hover:bg-gray-700 text-white hover:bg-textColor"
                      >
                        <span className="text-lg">
                          <FiLogIn className="text-white" />
                        </span>

                        <span className="">LogOut</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminDashboard;
