import React, { useEffect, useState } from "react";
import { singleImageUpload } from "../../../Hooks/ImageUpload";
import AuthUser from "../../../Hooks/authUser";
import "flowbite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import Loading from "../../../Shared/Loading/Loading";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const UpdateWifiOrder = () => {
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();
  const [operators, setOperators] = useState([]);
  const [operatorName, setOperatorName] = useState("");
  const [offerName, setOfferName] = useState("");
  const [cIdentityImg, setCidentityImg] = useState(null);
  const [soggoirnoImg, setSoggoirnoImg] = useState(null);
  const [taxIdCodeImg, setTaxIdCodeImg] = useState(null);
  const [patenteImg, setPatenteImg] = useState(null);
  const [startDate, setStartDate] = useState("");
  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    if (event?.target?.name === "patenteImg") {
      singleImageUpload(formData, setPatenteImg);
    }
    if (event?.target?.name === "taxIdCodeImg") {
      singleImageUpload(formData, setTaxIdCodeImg);
    }
    if (event?.target?.name === "soggoirnoImg") {
      singleImageUpload(formData, setSoggoirnoImg);
    }
    if (event?.target?.name === "cIdentityImg") {
      singleImageUpload(formData, setCidentityImg);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/newWifiOrder/getNewWifiOrder/${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrder(data);
        setLoading(false);
      });
  }, [id, refresh]);

  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/wifiOperator")
      .then((res) => res.json())
      .then((data) => setOperators(data));
  }, []);
  const filterOffer = operators?.filter(
    (op) => op?.operatorName === operatorName
  );
  const offerDetails = filterOffer[0]?.packages?.filter(
    (op) => op?.offerName === offerName
  );

  const handelNewWifiOrder = async (e) => {
    e.preventDefault();
    const firstName = e.target?.name?.value;
    const lastName = e.target?.surName?.value;
    const email = e.target?.email?.value;
    const phone = e.target?.phone?.value;
    const dateOfBirth = startDate;
    const taxIdCode = e.target?.taxIdCode?.value;
    const wifiActivateArea = e.target?.wifiActivateArea?.value;
    const viaName = e.target?.viaName?.value;
    const civic = e.target?.civic?.value;
    const cap = e.target?.cap?.value;
    const citta = e.target?.citta?.value;
    const province = e.target?.province?.value;
    const payment = e.target?.payment?.value;
    const operator = e.target?.operator?.value;

    const newWifiOrder = {
      cap,
      payment: payment ? payment : order?.newWifiOrder?.payment,
      province,
      citta,
      civic,
      viaName,
      wifiActivateArea,
      taxIdCode,
      dateOfBirth: dateOfBirth ? dateOfBirth : order?.newWifiOrder?.dateOfBirth,
      firstName,
      lastName,
      patenteImg: patenteImg ? patenteImg : order?.newWifiOrder?.patenteImg,
      cIdentityImg: cIdentityImg
        ? cIdentityImg
        : order?.newWifiOrder?.cIdentityImg,
      soggoirnoImg: soggoirnoImg
        ? soggoirnoImg
        : order?.newWifiOrder?.soggoirnoImg,
      taxIdCodeImg: taxIdCodeImg
        ? taxIdCodeImg
        : order?.newWifiOrder?.taxIdCodeImg,
      phone,
      email,
    };
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/newWifiOrder/newWifiOrderStatus/${id}`;

    await UpdateHooks(
      BASE_URL,
      {
        newWifiOrder,
        operator,
        offerDetails: offerDetails?.length ? offerDetails : order?.offerDetails,
        operatorDetails: filterOffer?.length
          ? filterOffer
          : order?.operatorDetails,
      },
      true,
      "Wifi Order Updated"
    );
    setRefresh(!refresh);
  };
  if (loading) {
    return <Loading />;
  }

  return (
    <div className=" m-5 p-5 bg-thirdLightPrimary ">
      <form
        onSubmit={handelNewWifiOrder}
        className="  bg-lightPrimary rounded-md border p-5"
      >
        <div className="">
          <p className="  font-semibold pb-10 ">New Wifi Order</p>
        </div>
        <div className=" w-2/4  ">
          <p className=" text-xs pb-3 font-semibold">
            Operator{" "}
            <span className="font-normal text-yellow-600">
              {order?.operatorDetails &&
                order?.operatorDetails[0]?.operatorName}
            </span>
          </p>
          <select
            type="text"
            name="operator"
            onChange={(e) => setOperatorName(e.target.value)}
            id="first_name"
            className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg w-full p-2.5 dark:bg-thirdLightPrimary "
            placeholder="Select"
          >
            <option selected value="">
              ..Choose...
            </option>
            {operators?.map((operator) => (
              <option value={operator?.operatorName} key={operator?._id}>
                {operator?.operatorName}
              </option>
            ))}
          </select>
        </div>

        {/* conditional div */}
        <div className=" w-full bg-white/90 p-3 mt-3 rounded-md">
          <p className=" text-xs pb-3 font-semibold">
            Package{" "}
            <span className="font-normal text-yellow-600">
              {order?.offerDetails && order?.offerDetails[0]?.offerName}
            </span>
          </p>
          <select
            type="text"
            name="offerName"
            onChange={(e) => setOfferName(e.target.value)}
            id="first_name"
            className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg w-full p-2.5 dark:bg-thirdLightPrimary "
            placeholder="Select"
          >
            <option selected>..Choose...</option>
            {filterOffer[0]?.packages?.map((offer) => (
              <option value={offer?.offerName}>{offer?.offerName}</option>
            ))}
          </select>

          {/* facilities table */}
          {filterOffer[0]?.packages?.map((offer) => (
            <div className="mt-3">
              {offer?.offerName === offerName && (
                <div className="w-full overflow-x-auto p-2 rounded-lg mt-3">
                  <table
                    className="w-full text-left border border-collapse rounded sm:border-separate border-slate-200"
                    cellspacing="0"
                  >
                    <tbody>
                      <tr className="bg-gray-100">
                        <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                          Offer Name
                        </td>
                        <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                          {offer?.offerName}
                        </td>
                      </tr>
                      <tr className="bg-gray-100">
                        <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                          Offer Details
                        </td>
                        <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                          {offer?.offerDetails}
                        </td>
                      </tr>
                      <tr className="bg-gray-100">
                        <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                          Verifica la copertura della fibra Sky Wi-fi
                        </td>
                        <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                          {offer?.verificaLa}
                        </td>
                      </tr>
                      <tr className="bg-gray-100">
                        <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                          Per favore È necessario stampare il modulo e riempirlo
                        </td>
                        <td className="h-9 px-6 text-sm transition duration-300 border-t border-l first:border-l-0 border-slate-200 stroke-slate-500 text-slate-500 ">
                          {offer?.perFavore}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className=" text-xs pt-3 font-semibold  ">
          <p>Introduction:</p>
        </div>

        <div className=" grid grid-cols-2 gap-4 pt-5">
          <div>
            <label
              htmlFor="first_name"
              className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
            >
              Name
            </label>
            <input
              type="text"
              id="first_name"
              defaultValue={order?.newWifiOrder?.firstName}
              name="name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="First Name"
              required
            />
          </div>
          <div>
            <label
              htmlFor="first_name"
              className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
            >
              Surname
            </label>
            <input
              type="text"
              defaultValue={order?.newWifiOrder?.lastName}
              name="surName"
              id="sure_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Sure Name"
              required
            />
          </div>
        </div>

        {/* component */}

        <div className=" grid grid-cols-4 gap-4 pt-4 ">
          <div className="">
            <label
              htmlFor="Date"
              className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
            >
              Date of birth
            </label>
            <DatePicker
              className="bg-gray-50 border border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(date) => setStartDate(date)}
            />
          </div>

          <div>
            <div>
              <label
                htmlFor="first_name"
                className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
              >
                Tax ID code
              </label>
              <input
                type="text"
                name="taxIdCode"
                defaultValue={order?.newWifiOrder?.taxIdCode}
                id="sure_name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Sure Name"
                required
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="Mobile"
              className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
            >
              Mobile
            </label>
            <input
              type="text"
              defaultValue={order?.newWifiOrder?.phone}
              name="phone"
              id="mobile"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=" Enter Mobile Number"
              required
            />
          </div>

          <div>
            <label
              htmlFor="first_name"
              className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
            >
              E-email
            </label>
            <input
              type="text"
              defaultValue={order?.newWifiOrder?.email}
              name="email"
              id="sure_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter E-mail"
              required
            />
          </div>
        </div>

        <div>
          <div>
            <p className=" text-xs font-semibold pt-2 pb-2">
              The address where you will activate the Wi-Fi (fixed) should be
              write here, just Drizzo
            </p>
            <textarea
              name="wifiActivateArea"
              defaultValue={order?.newWifiOrder?.wifiActivateArea}
              className="h-40 px-3 py-1 text-xs   outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
              placeholder="Add your text here"
            ></textarea>
          </div>
        </div>

        <div className=" grid grid-cols-2 gap-4 pt-5">
          <div>
            <label
              htmlFor="ViaName"
              className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
            >
              Via Name
            </label>
            <input
              type="text"
              id="ViaName"
              defaultValue={order?.newWifiOrder?.viaName}
              name="viaName"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=" Enter Via Name"
              required
            />
          </div>
          <div>
            <label
              htmlFor=" civic"
              className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
            >
              Civic
            </label>
            <input
              type="text"
              defaultValue={order?.newWifiOrder?.civic}
              id=" civic"
              name="civic"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Civic"
              required
            />
          </div>
        </div>

        <div className=" grid grid-cols-3 gap-4 pt-4 ">
          <div>
            <label
              htmlFor="cap"
              className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
            >
              CAP
            </label>
            <input
              type="text"
              defaultValue={order?.newWifiOrder?.cap}
              id="cap"
              name="cap"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Cap"
              required
            />
          </div>

          <div>
            <div>
              <label
                htmlFor="citta"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                Citta
              </label>
              <input
                type="text"
                defaultValue={order?.newWifiOrder?.citta}
                id="citta"
                name="citta"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Citta"
                required
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="province"
              className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
            >
              Province
            </label>
            <input
              type="text"
              name="province"
              defaultValue={order?.newWifiOrder?.province}
              id="province "
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Province"
              required
            />
          </div>
        </div>

        {/* Document section  */}

        <div>
          <div className=" text-xs pt-3 font-semibold  ">
            <p>Documents:</p>
          </div>

          <div className=" lg:grid lg:grid-cols-2  gap-4 text-xs ">
            <div className="max-w-2xl pt-2">
              <label htmlFor="photobutton" className="text-xs font-medium ">
                C. Identity
              </label>
              <div className="relative z-0 mt-0.5 flex w-full -space-x-px text-xs">
                <input
                  id="photobutton"
                  type="file"
                  onChange={handleChangeUploadImage}
                  name="cIdentityImg"
                  className="block w-full  cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                />
              </div>
            </div>

            <div className="max-w-2xl pt-2 text-xs">
              <label htmlFor="photobutton" className="text-xs font-medium ">
                SOGGOIRNO
              </label>
              <div className="relative z-0 mt-0.5 flex w-full -space-x-px text-xs">
                <input
                  id="photobutton"
                  type="file"
                  onChange={handleChangeUploadImage}
                  name="soggoirnoImg"
                  className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                />
              </div>
            </div>
            <div className="max-w-2xl">
              <label htmlFor="photobutton" className="text-xs font-medium ">
                TAX ID CODE
              </label>
              <div className="relative z-0 mt-0.5 flex w-full -space-x-px text-xs">
                <input
                  id="photobutton"
                  type="file"
                  onChange={handleChangeUploadImage}
                  name="taxIdCodeImg"
                  className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                />
              </div>
            </div>
            <div className="max-w-2xl">
              <label htmlFor="photobutton" className="text-xs font-medium ">
                PATENTE
              </label>
              <div className="relative z-0 mt-0.5 flex w-full -space-x-px text-xs">
                <input
                  id="photobutton"
                  type="file"
                  onChange={handleChangeUploadImage}
                  name="patenteImg"
                  className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" pt-5 ">
          <div className=" text-xs pb-3 font-semibold  ">
            <p>Payment:</p>
          </div>

          <select
            type="text"
            name="payment"
            id="first_name"
            className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg w-full p-2.5 dark:bg-thirdLightPrimary "
            placeholder="Select"
            required
          >
            {" "}
            <option selected>Postal</option>
            <option>Current Account / RID IBAN</option>
          </select>

          <div className=" pt-5">
            <button
              type="submit"
              class="text-white  bg-secondary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Update Order
            </button>{" "}
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateWifiOrder;
