import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../Shared/Loading/Loading";

const AdminAllAgentList = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);

  useEffect(() => {
    fetchResellerUsers();
  }, []);

  const fetchResellerUsers = () => {
    axios
      .get("https://servergenzam.genzamwallet.com/api/v1/user")
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        console.error("Error loading user data:", error);
      });
  };

  const handleDeleteUser = (userId) => {
    axios
      .delete(`https://servergenzam.genzamwallet.com/api/v1/user/${userId}`)
      .then((response) => {
        toast.success("User deleted successfully!");
        fetchResellerUsers();
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const handleMakeUser = (userId) => {
    axios
      .patch(`https://servergenzam.genzamwallet.com/api/v1/user/${userId}`, {
        role: "user",
      })
      .then((response) => {
        toast.success("User role changed successfully!");
        fetchResellerUsers();
      })
      .catch((error) => {
        console.error("Error changing user role:", error);
      });
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users.filter((user) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      user?.role === "reseller" &&
      (user?.name?.toLowerCase()?.includes(lowerCaseQuery) ||
        user?.email?.toLowerCase()?.includes(lowerCaseQuery) ||
        user?.phone?.toLowerCase()?.includes(lowerCaseQuery) ||
        user?._id?.toLowerCase()?.includes(lowerCaseQuery) ||
        (user?.address &&
          user?.address?.toLowerCase()?.includes(lowerCaseQuery)))
    );
  });

  if (!Array.isArray(users)) {
    return <p>Loading</p>;
  }

  return (
    <div>
      <div className="max-w-3xl mx-auto text-center mt-5">
        <h1 className="text-4xl font-bold text-gray-900 leading-tight mb-2 border-b-2 border-gray-500 pb-2">
          All Agent List
        </h1>
        <p className="text-lg text-gray-800 mb-8">
          The "All Agent List" is a crucial feature within the admin panel of a
          web application or system.
        </p>
      </div>

      <div>
        <form
          onSubmit={(e) => e.preventDefault()}
          className="mt-10 mx-auto max-w-xl py-2 px-6 rounded-full bg-gray-50 border flex focus-within:border-gray-300"
        >
          <input
            type="text"
            placeholder="Search Agent by Store ID , Email , Phone , Name"
            className="bg-transparent w-full focus:outline-none pr-4 font-semibold border-0 focus:ring-0 px-0 py-0"
            name="searchagent"
            value={searchQuery}
            onChange={handleSearch}
          />
          <button
            className="flex flex-row items-center justify-center min-w-[130px] px-4 rounded-full font-medium tracking-wide border disabled:cursor-not-allowed disabled:opacity-50 transition ease-in-out duration-150 text-base bg-primary text-white font-medium tracking-wide border-transparent py-1.5 h-[38px] -mr-3"
            onClick={handleSearch}
          >
            Search
          </button>
        </form>
      </div>

      <div className="container p-2 mx-auto sm:p-4 dark:text-gray-100">
        <h2 className="mb-4 text-1xl font-semibold leading">
          Total Agent: {filteredUsers?.length}
        </h2>
        <div className="overflow-x-auto">
          <table className="min-w-full text-xs">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col className="w-24" />
            </colgroup>
            <thead className="bg-black text-white">
              <tr className="text-center">
                <th className="p-3 border">#</th>
                <th className="p-3 border">Name</th>
                <th className="p-3 border">Email</th>
                <th className="p-3 border">Phone</th>
                <th className="p-3 border">Change Role</th>
                <th className="p-3 border">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers?.map((user, index) => (
                <tr
                  key={user._id}
                  className="border-b border-opacity-20 text-center dark:border-gray-700 bg-gray-400"
                >
                  <td className="p-3 border">
                    <p>{index + 1}</p>
                  </td>
                  <td className="p-3 text-center border">
                    <p>{user?.name}</p>
                  </td>
                  <td className="p-3 text-center border">
                    <p className="dark:text-gray-400">{user?.email}</p>
                  </td>
                  <td className="p-3 text-center border">
                    <p className="dark:text-gray-400">{user?.phone}</p>
                  </td>
                  <td className="p-3 text-center border">
                    <p className="dark:text-gray-400">
                      {user.role !== "user" && (
                        <button
                          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                          onClick={() => handleMakeUser(user?._id)}
                        >
                          Make User
                        </button>
                      )}
                    </p>
                  </td>
                  <td className="p-3 text-xl text-black items-center gap-x-5 justify-center flex">
                    <div
                      onClick={() => {
                        setDeleteUserId(user._id);
                        setShowModal(true);
                      }}
                    >
                      <Icon
                        className="rounded-md cursor-pointer"
                        icon="ic:sharp-delete"
                      />
                    </div>
                    <div>
                      <Link
                        to={`/adminDashboard/adminagentviewprofile/${user?._id}`}
                      >
                        <Icon
                          className="rounded-md cursor-pointer"
                          icon="carbon:view-filled"
                        />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center backdrop">
          <div className="bg-secondary w-96 p-6 rounded shadow-lg">
            <h2 className="text-lg text-white font-semibold mb-4">
              Confirm Delete
            </h2>
            <p className="text-white">
              Are you sure you want to delete this agent?
            </p>
            <div className="flex justify-end mt-6">
              <button
                className="text-white rounded mr-4 bg-green-600 p-2 hover:bg-green-800"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="text-white p-2 rounded bg-red-600 hover:bg-red-800 font-semibold"
                onClick={() => {
                  handleDeleteUser(deleteUserId);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminAllAgentList;