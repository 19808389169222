import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";
import Pagination from "../../Shared/Pagination";
import Clock from "../Clock/Clock";

const Agent = () => {
  const { userInfo } = AuthUser();
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(6);
  const [quantity, setQuantity] = useState(0);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const url = `https://servergenzam.genzamwallet.com/api/v1/user/specific?page=${page}&&size=${size}&&fieldName=${"referResellerId"}&&fieldValue=${
      userInfo?._id
    }`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setUserData(data?.data);
        setQuantity(data?.total);
      });
  }, [page, size, userInfo?._id]);

  console.log(userData);
  const users = userData?.filter((u) => u?.role === "reseller");
  console.log(users);
  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">RESELLER DASHBOARD - AGENT</p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary p-5 m-5 rounded-md pb-5">
        <div className="  bg-lightPrimary  rounded-sm shadow-lg my-5 mx-5 p-6 ">
          <div className="   ">
            <p className=" text-sm pb-2">Search Offer</p>
            <form className=" grid grid-cols-3 w-full gap-5 text-xs">
              <div className="">
                <input
                  id="q"
                  name="q"
                  className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                  placeholder="Operator , Offer Name etc."
                  type="search"
                  autoFocus
                  value=""
                />
              </div>
              <div>
                {" "}
                <select
                  type="text"
                  id="first_name"
                  className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                  required
                >
                  {" "}
                  <option selected value="active">
                    Active
                  </option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              <div>
                <button
                  type="submit"
                  className="mt-3 inline-flex text-xs w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="relative  bg-lightPrimary p-5 overflow-x-auto shadow-md sm:rounded-lg m-5">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs uppercase bg-black text-white">
              <tr>
                <td class="px-6 py-3 text-xs font-semibold">#</td>

                <td class="px-6 py-3 text-xs font-semibold ">Agent Code</td>
                <td class="px-6 py-3 text-xs font-semibold ">Agent Name</td>
                <td class="px-6 py-3 text-xs font-semibold ">Agent Phone</td>

                <td class="px-6 py-3 text-xs font-semibold ">Agent Email</td>

                <td class="px-6 py-3 text-xs font-semibold">City</td>
                <td class="px-6 py-3 text-xs font-semibold">Country</td>
                <td class="px-6 py-3 text-xs font-semibold">Status</td>

                <td class="px-6 py-3 text-xs font-semibold">Action</td>
              </tr>
            </thead>
            <tbody>
              {users?.map((user, i) => (
                <tr
                  key={user?._id}
                  user={user}
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 text-xs dark:hover:bg-gray-600"
                >
                  <td class="px-6 py-4">{i + 1}</td>
                  <td class="px-6 py-4 ">{user?.agentCode}</td>
                  <td class="px-6 py-4 ">{user?.name}</td>
                  <td class="px-6 py-4 ">{user?.phone}</td>

                  <td class="px-6 py-4 ">{user?.email}</td>

                  <td class="px-6 py-4">{user?.city}</td>
                  <td class="px-6 py-4">{user?.country}</td>

                  <td class="px-6 py-4">{user?.status} </td>

                  <td class="flex items-center px-6 py-4 space-x-3">
                    <div
                      to=""
                      class="font-medium text-blue-600 dark:text-blue-500 hover:underline flex gap-1"
                    >
                      <Link to={user?._id}>
                        {" "}
                        <p>Edit</p>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          quantity={quantity}
          page={page}
          setPage={setPage}
          size={size}
          setSize={setSize}
        />
      </div>
    </div>
  );
};

export default Agent;
