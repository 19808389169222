import React, { useEffect, useState } from "react";
import "flowbite";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { singleImageUpload } from "../../../Hooks/ImageUpload";
import AuthUser from "../../../Hooks/authUser";
import Loading from "../../../Shared/Loading/Loading";
import { useParams } from "react-router-dom";
import UpdateHooks from "../../../Hooks/UpdateHooks";

const UpdateLuceOrder = () => {
  const { userInfo } = AuthUser();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [order, setOrder] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [cIdentityImg, setCIdentityImg] = useState("");
  const [codiceFiscaleImg, setCodiceFiscaleImg] = useState("");
  const [caricaModuloDiSkyImg, setCaricaModuloDiSkyImg] = useState("");
  const [dichiarationImg, setDichiarationImg] = useState("");
  const [pagamentoImg, setPagamentoImg] = useState("");
  const [soggoirnoImg, setSoggoirnoImg] = useState("");
  const [patenteImg, setPatenteImg] = useState("");
  const [gasDichirationImg, setGasDichirationImg] = useState("");
  const [prezzoImg, setPrezzoImg] = useState("");
  const [boccetaCompleto, setBoccetaCompletoImg] = useState("");
  const [vecchiaBollettaImg, setVecchiaBollettaImg] = useState("");

  const [operators, setOperators] = useState([]);
  const [operatorName, setOperatorName] = useState("");

  useEffect(() => {
    setLoading(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/luceOrders/${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrder(data);
        setLoading(false);
      });
  }, [id, refresh]);

  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/luceOperator")
      .then((res) => res.json())
      .then((data) => setOperators(data));
  }, []);
  const filterOffer = operators?.filter(
    (op) => op?.operatorName === operatorName
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target?.name?.value;
    const surName = e.target?.surName?.value;
    const dateOfBirth = startDate;
    const codiceFiscale = e.target?.codiceFiscale?.value;
    const phone = e.target?.phone?.value;
    const email = e.target?.email?.value;
    const activateAddress = e.target?.activateAddress?.value;
    const viaName = e.target?.viaName?.value;
    const province = e.target?.province?.value;
    const cap = e.target?.cap?.value;
    const citta = e.target?.citta?.value;
    const civic = e.target?.civic?.value;
    const operator = e.target?.operator?.value;
    const payment = e.target?.payment?.value;
    const matricolaContatore = e.target?.matricolaContatore?.value;
    const clientWindtre = e.target?.clientWindtre?.value;
    const numberCellulare = e.target?.numberCellulare?.value;
    const clientAltriOperatore = e.target?.clientAltriOperatore?.value;
    const umeroCellulare = e.target?.umeroCellulare?.value;
    const introduction = {
      civic,
      cap,
      province,
      citta,
      viaName,
      activateAddress,
      email,
      phone,
      codiceFiscale,
      dateOfBirth: dateOfBirth ? dateOfBirth : order?.introduction?.dateOfBirth,
      surName,
      name,
    };
    const documents = {
      cIdentityImg: cIdentityImg
        ? cIdentityImg
        : order?.documents?.cIdentityImg,
      codiceFiscaleImg: codiceFiscaleImg
        ? codiceFiscaleImg
        : order?.documents?.codiceFiscaleImg,
      caricaModuloDiSkyImg: caricaModuloDiSkyImg
        ? caricaModuloDiSkyImg
        : order?.documents?.caricaModuloDiSkyImg,
      dichiarationImg: dichiarationImg
        ? dichiarationImg
        : order?.documents?.dichiarationImg,
      pagamentoImg: pagamentoImg
        ? pagamentoImg
        : order?.documents?.pagamentoImg,
      soggoirnoImg: soggoirnoImg
        ? soggoirnoImg
        : order?.documents?.soggoirnoImg,
      patenteImg: patenteImg ? patenteImg : order?.documents?.patenteImg,
      gasDichirationImg: gasDichirationImg
        ? gasDichirationImg
        : order?.documents?.gasDichirationImg,
      prezzoImg: prezzoImg ? prezzoImg : order?.documents?.prezzoImg,
      boccetaCompleto: boccetaCompleto
        ? boccetaCompleto
        : order?.documents?.boccetaCompleto,
      vecchiaBollettaImg: vecchiaBollettaImg
        ? vecchiaBollettaImg
        : order?.documents?.vecchiaBollettaImg,
    };
    const luceOrder = {
      documents,
      introduction,
      operator: operator ? operator : order?.operatorDetails[0]?.operatorName,
      operatorDetails: filterOffer?.length
        ? filterOffer
        : order?.operatorDetails,
      payment,
      matricolaContatore,
      clientWindtre,
      numberCellulare,
      clientAltriOperatore,
      umeroCellulare,
    };
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/luceOrders/${id}`;
    await UpdateHooks(BASE_URL, luceOrder, true, "Luce Order Updated");
    setRefresh(!refresh);
  };

  const handleChangeUploadImage = async (event) => {
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    if (event?.target?.name === "prezzo") {
      singleImageUpload(formData, setPrezzoImg);
    }
    if (event?.target?.name === "gasDichiration") {
      singleImageUpload(formData, setGasDichirationImg);
    }
    if (event?.target?.name === "patente") {
      singleImageUpload(formData, setPatenteImg);
    }
    if (event?.target?.name === "soggoirno") {
      singleImageUpload(formData, setSoggoirnoImg);
    }
    if (event?.target?.name === "pagamento") {
      singleImageUpload(formData, setPagamentoImg);
    }
    if (event?.target?.name === "dichiaration") {
      singleImageUpload(formData, setDichiarationImg);
    }
    if (event?.target?.name === "caricaModuloDiSky") {
      singleImageUpload(formData, setCaricaModuloDiSkyImg);
    }
    if (event?.target?.name === "codiceFiscaleImg") {
      console.log("codic");
      singleImageUpload(formData, setCodiceFiscaleImg);
    }
    if (event?.target?.name === "cIdentity") {
      singleImageUpload(formData, setCIdentityImg);
    }
    if (event?.target?.name === "boccetaCompleto") {
      singleImageUpload(formData, setBoccetaCompletoImg);
    }
    if (event?.target?.name === "vecchiaBolletta") {
      singleImageUpload(formData, setVecchiaBollettaImg);
    }
  };
  if (loading) {
    return <Loading />;
  }
  return (
    <div className=" min-h-screen">
      <div className=" m-5  bg-thirdLightPrimary p-5">
        <form
          action=""
          onSubmit={handleSubmit}
          className="  bg-lightPrimary p-5 rounded-md"
        >
          <div className="">
            <p className="  font-semibold pb-10 ">New Luce Order</p>
          </div>
          <div className=" w-2/4 ">
            <p className=" text-xs pb-3 font-semibold">
              Operator{" "}
              <span className="font-normal text-yellow-600">
                {order?.operatorDetails &&
                  order?.operatorDetails[0]?.operatorName}
              </span>
            </p>
            <select
              type="text"
              onChange={(e) => setOperatorName(e.target.value)}
              name="operator"
              className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Select"
            >
              {" "}
              <option selected value="">
                ..Choose...
              </option>
              {operators?.map((operator) => (
                <option value={operator?.operatorName} key={operator?._id}>
                  {operator?.operatorName}
                </option>
              ))}
            </select>
          </div>
          {operatorName && (
            <div className="mt-3 bg-white p-3 rounded-lg">
              <p className="text-2xl font-medium">
                {filterOffer[0]?.operatorName}
              </p>
              <p className="text-sm mt-2">{filterOffer[0]?.operatorDetails}</p>
            </div>
          )}
          <div className=" text-xs pt-3 font-semibold  ">
            <p>Introduction:</p>
          </div>

          <div className=" grid grid-cols-2 gap-4 pt-5 text-xs">
            <div>
              <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                Name
              </label>
              <input
                type="text"
                name="name"
                defaultValue={order?.introduction?.name}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=" Enter First Name"
                required
              />
            </div>
            <div>
              <label
                htmlFor="SurName"
                className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
              >
                Surname
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.surName}
                name="surName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Surname"
                required
              />
            </div>
          </div>

          {/* component */}

          <div className=" grid grid-cols-4 gap-4 pt-4 ">
            <div>
              <label
                htmlFor="Date-of-birth"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                Date of birth
              </label>
              <DatePicker
                name="dateOfBirth"
                onChange={(date) => setStartDate(date)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>

            <div>
              <div>
                <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                  Codice Fiscale
                </label>
                <input
                  type="text"
                  defaultValue={order?.introduction?.codiceFiscale}
                  name="codiceFiscale"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Codice Fiscale "
                />
              </div>
            </div>

            <div>
              <label className="block mb-2 font-medium text-gray-900 dark:text-white text-xs">
                Mobile
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.phone}
                name="phone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Mobile Number"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
              >
                E-mail
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.email}
                name="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter E-mail"
              />
            </div>
          </div>

          <div>
            <div>
              <p className=" text-xs font-semibold pt-2 pb-2">
                The address where you will activate the Wi-Fi (fixed) should be
                write here, just Drizzo
              </p>
              <textarea
                name="activateAddress"
                defaultValue={order?.introduction?.activateAddress}
                className="h-40 px-3 text-sm py-1  outline-none border-gray-300 w-full resize-none border rounded-lg placeholder:text-sm"
                placeholder="Add your text here"
              ></textarea>
            </div>
          </div>
          <div className=" grid grid-cols-2 gap-4 pt-5">
            <div>
              <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                Via Name
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.viaName}
                name="viaName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder=" Enter Via Name"
              />
            </div>
            <div>
              <label className="block mb-2 font-medium text-gray-900 dark:text-white text-xs">
                Civic
              </label>
              <input
                type="text"
                name="civic"
                defaultValue={order?.introduction?.civic}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Civic"
              />
            </div>
          </div>

          <div className=" grid grid-cols-3 gap-4 pt-4 ">
            <div>
              <label
                htmlFor="cap"
                className="block mb-2  font-medium text-gray-900 dark:text-white text-xs"
              >
                CAP
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.cap}
                name="cap"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Cap"
              />
            </div>

            <div>
              <div>
                <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                  Citta
                </label>
                <input
                  type="text"
                  defaultValue={order?.introduction?.citta}
                  name="citta"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Citta"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="province"
                className="block mb-2 font-medium text-gray-900 dark:text-white text-xs"
              >
                Province
              </label>
              <input
                type="text"
                defaultValue={order?.introduction?.province}
                name="province"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Province"
              />
            </div>
          </div>

          {/* Document section  */}

          <div>
            <div className=" text-xs pt-3 pb-3 font-semibold  ">
              <p>Documents:</p>
            </div>
            <div className=" lg:grid lg:grid-cols-2 text-xs gap-4 ">
              <div className="max-w-2xl ">
                <label className="text-xs font-medium text-gray-500">
                  C. Identity
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="cIdentity"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  CODICE FISCALE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="codiceFiscaleImg"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  Carica modulo di Sky
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="caricaModuloDiSky"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>

              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  DICHIARATION
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="dichiaration"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  PAGAMENTO E INVIO DELLA BOLLETTA ANCHE CONTO CORRENTE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="pagamento"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  SOGGOIRNO
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="soggoirno"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  PATENTE
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="patente"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label
                  htmlFor="photobutton"
                  className="text-xs font-medium text-gray-500"
                >
                  POD EE/PDR GAS DICHIRATION
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="gasDichiration"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  PREZZO NETTO NATURA CASA GAS
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="prezzo"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-sm transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" pt-5 ">
            <div className=" w-2/4 ">
              <p className=" text-xs pb-3 font-semibold">Payment:</p>
              <select
                type="text"
                name="payment"
                className="bg-gray-50 border  border-gray-300  text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Select"
                required
              >
                {" "}
                <option disabled selected>
                  please select
                </option>
                <option
                  selected={
                    order?.payment == "Bollettino Postale" ? true : false
                  }
                  value="Bollettino Postale"
                >
                  Bollettino Postale
                </option>
                <option
                  selected={
                    order?.payment == "Conto Corrente / RID IBAN:"
                      ? true
                      : false
                  }
                  value="Conto Corrente / RID IBAN:"
                >
                  Conto Corrente / RID IBAN:
                </option>
              </select>
            </div>

            <div className=" lg:grid lg:grid-cols-2 gap-4 ">
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  Bocceta Completo
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="boccetaCompleto"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
              <div className="max-w-2xl">
                <label className="text-xs font-medium text-gray-500">
                  VECCHIA BOLLETTA
                </label>
                <div className="relative z-0 mt-0.5 flex w-full -space-x-px">
                  <input
                    name="vecchiaBolletta"
                    type="file"
                    onChange={handleChangeUploadImage}
                    className="block w-full cursor-pointer appearance-none rounded-l-md border border-gray-200 bg-white px-3 py-2 text-xs transition focus:z-10 focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                  />
                </div>
              </div>
            </div>

            <div className=" grid grid-cols-2 gap-4 pt-5">
              <div>
                <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                  MATRICOLA CONTATORE
                </label>
                <input
                  type="text"
                  defaultValue={order?.matricolaContatore}
                  name="matricolaContatore"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter MATRICOLA CONTATORE"
                />
              </div>
            </div>

            <div className=" grid grid-cols-2 gap-4 pt-5">
              <div className=" w-full ">
                <p className=" text-xs pb-3 font-semibold">CLIENTE WINDTRE</p>
                <select
                  type="text"
                  name="clientWindtre"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                >
                  {" "}
                  <option disabled selected>
                    Select
                  </option>
                  <option
                    selected={order?.clientWindtre == "si" ? true : false}
                    value="si"
                  >
                    SI
                  </option>
                  <option
                    selected={order?.clientWindtre == "no" ? true : false}
                    value="no"
                  >
                    NO
                  </option>
                </select>
              </div>
              <div>
                <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                  NUMBERO CELLULARE
                </label>
                <input
                  type="text"
                  defaultValue={order?.numberCellulare}
                  name="numberCellulare"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter NUMBERO CELLULARE"
                />
              </div>

              <div className=" w-full ">
                <p className=" text-xs pb-3 font-semibold">
                  CLIENTE ALTRI OPERATORE
                </p>
                <select
                  type="text"
                  name="clientAltriOperatore"
                  className="bg-gray-50 border  border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Select"
                >
                  {" "}
                  <option disabled selected>
                    Select
                  </option>
                  <option
                    selected={
                      order?.clientAltriOperatore == "si" ? true : false
                    }
                    value="si"
                  >
                    SI
                  </option>
                  <option
                    selected={
                      order?.clientAltriOperatore == "no" ? true : false
                    }
                    value="no"
                  >
                    NO
                  </option>
                </select>
              </div>

              <div>
                <label className="block mb-2  font-medium text-gray-900 dark:text-white text-xs">
                  UMERO CELLULARE
                </label>
                <input
                  type="text"
                  name="umeroCellulare"
                  defaultValue={order?.umeroCellulare}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter UMERO CELLULARE"
                />
              </div>
            </div>
          </div>
          <div className=" pt-5">
            <button
              type="submit"
              class="text-white  bg-secondary focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Update !
            </button>{" "}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateLuceOrder;
