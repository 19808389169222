import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Loading from "../../Shared/Loading/Loading";
import Clock from "../../Component/Clock/Clock";
import Pagination from "../../Shared/Pagination";

const Wifi = () => {
  const [wifiOffers, setWifiOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("");
  const [filteredWifiOffers, setFilteredWifiOffers] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(12);

  useEffect(() => {
    const fetchWifiOffers = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/wifiOffer?size=${size}&&page=${page}`
        );
        setWifiOffers(response.data?.wifiOffers);
        setQuantity(response.data?.total);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching WiFi offers:", error);
        setLoading(false);
      }
    };

    fetchWifiOffers();
  }, [page, size]);

  useEffect(() => {
    const filteredData = wifiOffers.filter((offer) => {
      const operatorNameMatch = offer.WifiOperatorName.toLowerCase().includes(
        searchQuery.toLowerCase()
      );
      const offerNameMatch = offer.wifiOfferName
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      if (filterCriteria === "") {
        return operatorNameMatch || offerNameMatch;
      } else {
        return (
          (operatorNameMatch || offerNameMatch) &&
          offer.wifiOfferStatus === filterCriteria
        );
      }
    });

    setFilteredWifiOffers(filteredData);
  }, [searchQuery, filterCriteria, wifiOffers]);

  const handleReset = () => {
    setSearchQuery("");
    setFilterCriteria("");
    setFilteredWifiOffers([]);
  };

  if (loading) {
    return <Loading />;
  }

  const dataToShow =
    filteredWifiOffers.length > 0 ? filteredWifiOffers : wifiOffers;

  return (
    <div className="min-h-screen">
      <div className="bg-thirdLightPrimary flex justify-between">
        <div>
          <p className="font-semibold p-2">USER DASHBOARD - WI-FI</p>
        </div>
        <div>
          <p className="font-semibold p-2">
            <Clock />
          </p>
        </div>
      </div>
      <div className="bg-thirdLightPrimary m-5 p-5 rounded-md pb-5">
        <div className="rounded-sm  bg-lightPrimary shadow-lg my-5 mx-5 p-6">
          <div>
            <p className="text-sm pb-2">Search Offer</p>
            <form className="grid grid-cols-2 w-full gap-5 text-xs">
              <div className="">
                <input
                  name="search"
                  className="inline w-full text-lg rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1 sm:text-xs"
                  placeholder="Operator, Offer Name etc."
                  type="search"
                  autoFocus
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>

              <div>
                <button
                  type="button"
                  onClick={handleReset}
                  className="mt-3 inline-flex text-xs  w-full items-center justify-center rounded-md border border-transparent bg-primary px-4 py-2 font-medium text-white shadow-sm hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="relative  bg-lightPrimary p-5 overflow-x-auto shadow-md sm:rounded-lg m-5">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-black dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Operator
                </th>
                <th scope="col" className="px-6 py-3">
                  Offer Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {dataToShow.map((offer, index) => (
                <tr
                  key={offer?._id}
                  className="bg-white border-b text-xs dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {offer?.WifiOperatorName}
                  </td>
                  <td className="px-6 py-4">{offer?.wifiOfferName}</td>
                  <td className="px-6 py-4">{offer?.wifiOfferStatus}</td>
                  <td className="flex items-center px-6 py-4 space-x-3">
                    <Link
                      to={`/userDashboard/user-wifi/user-view-wifi/${offer._id}`}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Pagination
            quantity={quantity}
            page={page}
            setPage={setPage}
            size={size}
            setSize={setSize}
          />
        </div>
      </div>
    </div>
  );
};

export default Wifi;
