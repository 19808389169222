import React from "react";

const CompanyServices = () => {
  return (
    <div className=" min-h-screen">
      <div class="bg-gray-200 px-2 m-5"></div>

      <div id="features" class="mx-auto max-w-6xl">
        <p class="text-center text-base font-semibold leading-7 text-primary-500">
          Features
        </p>
        <h2 class="text-center font-display text-3xl font-bold tracking-tight text-slate-900 md:text-4xl">
          Simplified Writing Experience
        </h2>
        <ul class="mt-16 grid grid-cols-1 gap-6 text-center text-slate-700 md:grid-cols-3">
          <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
            <img
              src="https://www.svgrepo.com/show/530438/ddos-protection.svg"
              alt=""
              class="mx-auto h-10 w-10"
            ></img>
            <h3 class="my-3 font-display font-medium">Empower Your Business</h3>
            <p class="mt-1.5 text-sm leading-6 text-secondary-500">
              Trade, activate, assist - unlock growth with our comprehensive
              digital solutions
            </p>
          </li>
          <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
            <img
              src="https://www.svgrepo.com/show/530442/port-detection.svg"
              alt=""
              class="mx-auto h-10 w-10"
            ></img>
            <h3 class="my-3 font-display font-medium">
              Your Digital Service Catalyst
            </h3>
            <p class="mt-1.5 text-sm leading-6 text-secondary-500">
              Trade, activate, assist. Elevate your business with Genz's dynamic
              solutions
            </p>
          </li>
          <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
            <img
              src="https://www.svgrepo.com/show/530444/availability.svg"
              alt=""
              class="mx-auto h-10 w-10"
            ></img>
            <h3 class="my-3 font-display font-medium">
              Digital Services Amplified
            </h3>
            <p class="mt-1.5 text-sm leading-6 text-secondary-500">
              Elevate your business with our trade, activation, and assistance
              solutions.
            </p>
          </li>
          <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
            <a href="/pricing" class="group">
              <img
                src="https://www.svgrepo.com/show/530440/machine-vision.svg"
                alt=""
                class="mx-auto h-10 w-10"
              ></img>
              <h3 class="my-3 font-display font-medium group-hover:text-primary-500">
                Seamless Digital Synergy
              </h3>
              <p class="mt-1.5 text-sm leading-6 text-secondary-500">
                Trade, activate, assist – Unleash your digital potential with
                Genzam-IT
              </p>
            </a>
          </li>
          <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
            <a href="/templates" class="group">
              <img
                src="https://www.svgrepo.com/show/530450/page-analysis.svg"
                alt=""
                class="mx-auto h-10 w-10"
              ></img>
              <h3 class="my-3 font-display font-medium group-hover:text-primary-500">
                Digital Dynamo
              </h3>
              <p class="mt-1.5 text-sm leading-6 text-secondary-500">
                "Empower your business with trade, activation, and assistance
                prowess.
              </p>
            </a>
          </li>
          <li class="rounded-xl bg-white px-6 py-8 shadow-sm">
            <a href="/download" class="group">
              <img
                src="https://www.svgrepo.com/show/530453/mail-reception.svg"
                alt=""
                class="mx-auto h-10 w-10"
              ></img>
              <h3 class="my-3 font-display font-medium group-hover:text-primary-500">
                Digital Advantage Hub
              </h3>
              <p class="mt-1.5 text-sm leading-6 text-secondary-500">
                Trade, activate, assist – Elevating businesses with Genz's
                cutting-edge solutions.
              </p>
            </a>
          </li>
        </ul>
      </div>

      <div></div>
    </div>
  );
};

export default CompanyServices;
