import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UpdateHooks from "../../Hooks/UpdateHooks";

const EditSimOperator = () => {
  const { id } = useParams();
  const [simOperator, setSimOperator] = useState([]);

  const [inputFields, setInputFields] = useState(simOperator);

  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const response = await axios.get(
          `https://servergenzam.genzamwallet.com/api/v1/simOperator/${id}`
        );

        // Populate the form with the fetched data
        setSimOperator(response.data);
        setInputFields(response?.data?.offerName);
      } catch (error) {
        alert(
          "An error occurred while fetching offer data. Please try again later."
        );
      }
    };

    fetchOfferData();
  }, [id]);

  const handleInputChange = (index, event, name) => {
    const values = [...inputFields];
    if (name === "offerName") {
      values[index].offerName = event.target.value;
    }
    if (name === "internationalMinutesValidFor") {
      values[index].internationalMinutesValidFor = event.target.value;
    }
    if (name === "freeMinutesForInternational") {
      values[index].freeMinutesForInternational = event.target.value;
    }
    if (name === "freeMinutesForLocalOperator") {
      values[index].freeMinutesForLocalOperator = event.target.value;
    }
    if (name === "freeMinutesForLocalOperator") {
      values[index].freeMinutesForLocalOperator = event.target.value;
    }
    if (name === "freeInternetPack") {
      values[index].freeInternetPack = event.target.value;
    }
    if (name === "status") {
      values[index].status = event.target.value;
    }
    if (name === "offerValidFor") {
      values[index].offerValidFor = event.target.value;
    }
    if (name === "totalRecharge") {
      values[index].totalRecharge = event.target.value;
    }
    if (name === "costPerMonth") {
      values[index].costPerMonth = event.target.value;
    }
    if (name === "iban") {
      values[index].iban = event.target.value;
    }
    setInputFields(values);
  };

  const operator = {
    operatorName: simOperator?.operatorName,
    offerName: inputFields,
  };
  const handelUpdate = () => {
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/simOperator/${id}`;

    UpdateHooks(BASE_URL, operator, true, "Sim operator updated Updated");
  };

  return (
    <div className=" m-5">
      <div className=" space-y-2 items-center gap-x-1">
        {simOperator?.offerName?.map((Data, index) => (
          <div key={Data?._id} className="w-full border rounded-lg shadow p-2">
            <label className="font-medium text-dark">
              Offer Name<span className="text-warning">*</span>
            </label>
            <div className="flex items-center gap-x-1 mb-2">
              <div className="w-full mt-2">
                <input
                  defaultValue={Data.offerName}
                  onChange={(event) =>
                    handleInputChange(index, event, "offerName")
                  }
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter Offer Name"
                  type="text"
                  name="offerName"
                />
              </div>
            </div>

            <label className="font-medium text-dark">
              Facilities<span className="text-warning">*</span>
            </label>
            <div className="grid md:grid-cols-2 grid-cols-2 gap-2 bg-primary/10 mt-2 p-2 rounded-md text-sm w-full">
              <div className="">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Cost Per Month{" "}
                </label>
                <input
                  type="Cost Per Month "
                  name="costPerMonth"
                  defaultValue={Data.costPerMonth}
                  onChange={(event) =>
                    handleInputChange(index, event, "costPerMonth")
                  }
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="€"
                  required
                />
              </div>
              <div className="">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  totalRecharge
                </label>
                <input
                  type="number"
                  name="totalRecharge"
                  defaultValue={Data.totalRecharge}
                  onChange={(event) =>
                    handleInputChange(index, event, "totalRecharge")
                  }
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="€"
                  required
                />
              </div>
              <div className="">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Offer Valid For
                </label>
                <div className="relative">
                  <select
                    name="offerValidFor"
                    defaultValue={Data.offerValidFor}
                    onChange={(event) =>
                      handleInputChange(index, event, "offerValidFor")
                    }
                    required
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option value="unlimited">Unlimited</option>
                    <option value="limited">Limited</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Status
                </label>
                <div className="relative">
                  <select
                    name="status"
                    defaultValue={Data.status}
                    onChange={(event) =>
                      handleInputChange(index, event, "status")
                    }
                    required
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option selected disabled>
                      please select
                    </option>
                    <option value="Available for Sale">
                      Available for Sale
                    </option>
                    <option value="Not available for Sale">
                      Not available for Sale
                    </option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="">
                <label className="text-sm font-medium text-gray-900 block mb-2">
                  Free Internet Pack (GB){" "}
                </label>
                <input
                  required
                  type="text"
                  name="freeInternetPack"
                  defaultValue={Data.freeInternetPack}
                  onChange={(event) =>
                    handleInputChange(index, event, "freeInternetPack")
                  }
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="00 GIGA"
                />
              </div>
              <div className="">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Free Minuites for Local Operator{" "}
                </label>
                <input
                  required
                  type="text"
                  name="freeMinutesForLocalOperator"
                  defaultValue={Data.freeMinutesForLocalOperator}
                  onChange={(event) =>
                    handleInputChange(
                      index,
                      event,
                      "freeMinutesForLocalOperator"
                    )
                  }
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter free Minuites for Local Operator"
                />
              </div>
              <div className="">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  Free Minutes for International
                </label>
                <div className="relative">
                  <select
                    required
                    name="freeMinutesForInternational"
                    defaultValue={Data.freeMinutesForInternational}
                    onChange={(event) =>
                      handleInputChange(
                        index,
                        event,
                        "freeMinutesForInternational"
                      )
                    }
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  International Minutes Valid For{" "}
                </label>
                <input
                  required
                  type="text"
                  name="internationalMinutesValidFor"
                  defaultValue={Data.internationalMinutesValidFor}
                  onChange={(event) =>
                    handleInputChange(
                      index,
                      event,
                      "internationalMinutesValidFor"
                    )
                  }
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5"
                  placeholder="Enter International Minutes Valid For"
                />
              </div>

              {/* iban */}
              <div className="">
                <label
                  htmlFor="price"
                  className="text-sm font-medium text-gray-900 block mb-2"
                >
                  IBAN {`Selected ${Data.iban}`}
                </label>
                <div className="relative">
                  <select
                    required
                    name="iban"
                    defaultValue={Data.iban}
                    onChange={(event) =>
                      handleInputChange(index, event, "iban")
                    }
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full py-2.5 pl-3 pr-10"
                  >
                    <option selected disabled>
                      None
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

        <button
          onClick={handelUpdate}
          className="font-semibold text-white text-sm bg-primary rounded-md px-4 py-2 mt-5 hover:scale-105 duration-300"
        >
          UPDATE !
        </button>
      </div>
    </div>
  );
};

export default EditSimOperator;
