import React, { useState } from "react";

const OrderFilter = ({
  setGasFilterOrders,
  setLuceFilterOrders,
  setWifiFilterOrders,
  gasOrders,
  luceOrders,
  wifiOrders,
}) => {
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [orderType, setOrderType] = useState("");
  console.log(code, phone, paymentStatus, orderStatus);
  const gasData = gasOrders?.filter((gas) => {
    return (
      gas?.orderStatus.toLowerCase() == orderStatus.toLowerCase() ||
      gas?.orderType.toLowerCase() == orderType.toLowerCase() ||
      gas?.paymentStatus.toLowerCase() == paymentStatus.toLowerCase() ||
      gas?._id == code ||
      gas?.introduction?.phone == phone
    );
  });
  const luceData = luceOrders?.filter((luce) => {
    return (
      luce?.orderStatus.toLowerCase() == orderStatus.toLowerCase() ||
      luce?.orderType.toLowerCase() == orderType.toLowerCase() ||
      luce?.paymentStatus.toLowerCase() == paymentStatus.toLowerCase() ||
      luce?._id == code ||
      luce?.introduction?.phone == phone
    );
  });
  const wifiData = wifiOrders?.filter((wifi) => {
    return (
      wifi?.orderStatus.toLowerCase() == orderStatus.toLowerCase() ||
      wifi?.orderType.toLowerCase() == orderType.toLowerCase() ||
      wifi?.paymentStatus.toLowerCase() == paymentStatus.toLowerCase() ||
      wifi?._id == code ||
      wifi?.newWifiOrder?.phone == phone
    );
  });

  const handelFilter = (e) => {
    e.preventDefault();
    setGasFilterOrders(gasData);
    setWifiFilterOrders(wifiData);
    setLuceFilterOrders(luceData);
  };
  //   console.log("filterdata", searchData);
  return (
    <div className="   m-2  border shadow-lg bg-thirdLightPrimary rounded-md p-5 ">
      <form onSubmit={handelFilter} className=" grid grid-cols-6 gap-4   ">
        <div>
          <input
            type="text"
            onChange={(e) => setCode(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Enter Order Code "
          />
        </div>

        <div>
          <div>
            <input
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Mobile"
            />
          </div>
        </div>

        <div className="  ">
          <select
            type="text"
            onChange={(e) => setPaymentStatus(e.target.value)}
            className="bg-gray-50 border  border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Payment Status"
          >
            {" "}
            <option selected>Payment Status</option>
            <option value="Pending">Pending</option>
            <option value="Confirmed">Confirmed</option>
            <option value="">None</option>
          </select>
        </div>
        <div className="">
          <select
            type="text"
            onChange={(e) => setOrderStatus(e.target.value)}
            className="bg-gray-50 border  border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Order Status"
          >
            {" "}
            <option selected>Order Status</option>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="On Process">On Process</option>
            <option value="Canceled">Canceled</option>
            <option value="">None</option>
          </select>
        </div>
        <div className="">
          <select
            type="text"
            onChange={(e) => setOrderType(e.target.value)}
            className="bg-gray-50 border  border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Order Status"
          >
            {" "}
            <option selected>Order Type</option>
            <option value="luce">Luce</option>
            <option value="gas">Gas</option>
            <option value="wifi">Wifi</option>
            <option value="">None</option>
          </select>
        </div>
        <div>
          <button
            type="submit"
            className="bg-primary  border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrderFilter;
