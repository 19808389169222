import { Input } from "@material-tailwind/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import CreateUserHook from "../../Hooks/createUserHook";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";
import Clock from "../Clock/Clock";

const NewAgent = () => {
  const { userInfo } = AuthUser();
  const navigate = useNavigate();
  const handelSubmit = (e) => {
    e.preventDefault();
    const userName = e.target.userName.value;

    const email = e.target.email?.value;
    const password = e.target.password?.value;
    const name = e.target.name?.value;
    const status = e.target.status?.value;
    const phone = e.target.phone?.value;
    const agentCode = e.target.agentCode?.value;
    const contactPerson = e.target.contactPerson?.value;
    const codiceFiscal = e.target.codiceFiscal?.value;
    const pivaNumber = e.target.pivaNumber?.value;
    const pecId = e.target.pecId?.value;
    const codiceUnivoco = e.target.codiceUnivoco?.value;
    const address = e.target.address?.value;
    const country = e.target.country?.value;
    const city = e.target.city?.value;

    const user = {
      name,
      userName,
      password,
      email,
      status,
      phone,
      city,
      country,
      address,
      codiceUnivoco,
      pecId,
      pivaNumber,
      codiceFiscal,
      contactPerson,
      agentCode,
      role: "reseller",
      referResellerEmail: userInfo?.email,
      referResellerId: userInfo?._id,
      referResellerName: userInfo?.name,
    };
    console.log(user);
    CreateUserHook(user, navigate);
    e.target.reset();
  };
  return (
    <div className="min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">
            RESELLER DASHBOARD - NEW AGENT
          </p>
        </div>
        <div>
          {" "}
          <p className=" font-semibold p-2 ">
            <Clock></Clock>
          </p>
        </div>
      </div>
      <div className=" bg-thirdLightPrimary m-5  rounded-md">
        <div className="overflow-x-auto   ">
          <form onSubmit={handelSubmit}>
            <table className="min-w-full text-xs bg-gray-50 rounded-md ">
              <colgroup>
                <col />
                <col />
                <col />
                <col />
                <col />
                <col className="" />
              </colgroup>

              <tbody>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">User Name</p>
                  </td>
                  <td className="p-3 ">
                    <input
                      name="userName"
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Enter Your User Name"
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Agent Code</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="Agent Code"
                      name="agentCode"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Agent Name</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Agent Name "
                      name="name"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Contact Person </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Contact Person"
                      name="contactPerson"
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Phone </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Phone"
                      name="phone"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">E-mail </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" E-email"
                      name="email"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">CODICE FISCAL</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" CODICE FISCAL"
                      name="codiceFiscal"
                      required
                    />
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">P.IVA NUMBER </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder="P.IVA NUMBER "
                      name="pivaNumber"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">PEC ID</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" PEC ID"
                      name="pecId"
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">CODICE UNIVOCO</p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" CODICE UNIVOCO"
                      name="codiceUnivoco"
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Address </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" Address"
                      name="address"
                      required
                    />
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Country </p>
                  </td>
                  <td className="p-3 ">
                    <div>
                      {" "}
                      <select
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Select"
                        name="country"
                        required
                      >
                        {" "}
                        <option selected>Italy</option>
                        <option>UK</option>
                      </select>
                    </div>
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">City </p>
                  </td>
                  <td className="p-3 ">
                    <div>
                      {" "}
                      <select
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Select"
                        name="city"
                        required
                      >
                        {" "}
                        <option selected>Naples</option>
                        <option>Napoli</option>
                        <option>Novara</option>
                        <option>NOVELLARA (RE)</option>
                        <option>ORDERZO (TV)</option>
                      </select>
                    </div>
                  </td>
                </tr>

                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Status </p>
                  </td>
                  <td className="p-3 ">
                    <div>
                      {" "}
                      <select
                        type="text"
                        id="first_name"
                        className="bg-gray-50 border text-xs  border-gray-300  text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Select"
                        name="status"
                        required
                      >
                        {" "}
                        <option selected value="active">
                          Active
                        </option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </td>
                </tr>
                <tr className="border-b border-opacity-20  bg-gray-100 m-2 grid grid-cols-2 items-center">
                  <td className="p-3 ">
                    <p className=" ">Password </p>
                  </td>
                  <td className="p-3  ">
                    <input
                      className="inline w-full text-xs rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 leading-5 placeholder-gray-500 focus:border-red-400 focus:placeholder-red-200 focus:outline-none focus:ring-1  sm:text-xs"
                      placeholder=" type your password"
                      name="password"
                      required
                    />
                  </td>
                </tr>

                <div className=" p-5">
                  <button className=" bg-primary p-3 rounded-md">
                    {" "}
                    Save Agent{" "}
                  </button>
                </div>
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewAgent;
