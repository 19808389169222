import React, { useState, useEffect } from 'react';
import './Notification.css'; // Create a CSS file for the Notification component styles

const Notification = ({ text, isVisible }) => {
  return (
    <div className={`notification ${isVisible ? 'visible' : ''}`}>
      <span className="notification-text">{text}</span>
    </div>
  );
};

export default Notification;