import React, { useEffect, useState } from "react";
import { useParams,useLocation } from "react-router-dom";
import axios from "axios";

const AdminViewReport = () => {
  const { resellerId } = useParams();
  const [data, setData] = useState({ simSales: [] });
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedMonth = searchParams.get("month");
  const selectedYear = searchParams.get("year");
  useEffect(() => {
    // Fetch data from your API
    axios
      .get(`https://servergenzam.genzamwallet.com/api/v1/simSale`)
      .then((response) => {
        setData(response.data); // Set the fetched data in state
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Filter orders based on the provided resellerId
  const resellerOrders = data.simSales.filter((order) => {
    return (
      order.resellerId === resellerId &&
      new Date(order.updatedAt).getMonth() === parseInt(selectedMonth) &&
      new Date(order.updatedAt).getFullYear() === parseInt(selectedYear) &&
      order.status === "completed"

    );
  });

  return (
    <div>


      


      <div className="overflow-x-auto">
        <table className="min-w-full text-xs">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col className="w-24" />
          </colgroup>
          <thead className="bg-black text-white rounded-sm text-xs">
            <tr className="text-center">
              <th className="p-3 border">#</th>
              <th className="p-3 border ">Order Date</th>

              <th className="p-3 border">Operator Name</th>
              <th className="p-3 border">ICCID Number</th>

              <th className="p-3 border">Offerta</th>
              <th className="p-3 border">Address</th>
              <th className="p-3 border">Status</th>
              <th className="p-3 border">Customer Name</th>
            </tr>
          </thead>
          <tbody>
            {resellerOrders.map((order, index) => (
              <tr
                key={order._id}
                className="border-b border-opacity-20 text-center dark:border-gray-700 bg-gray-400 text-black"
              >
                <td className="p-3 border">
                  <p>{index + 1}</p>
                </td>
                <td className="p-3 text-center border">
                  {new Date(
                    order.updatedAt
                  ).toLocaleDateString()}{" "}
                </td>
                <td className="p-3 text-center border">
                  <p>{order.saleData.operatorName}</p>
                </td>
                <td className="p-3 text-center border">
                  <p>{order.iccidNumber}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">{order.offerta}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">
                    {order.residentInStreetNumberCivicInterrior}
                  </p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">{order.status}</p>
                </td>
                <td className="p-3 text-center border">
                  <p className="dark:text-gray-400">
                    {order.firstName} {order.surName}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminViewReport;