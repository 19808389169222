import React from "react";
import AuthUser from "../../Hooks/authUser";

const RequestSimTable = ({ index, sim }) => {
  const { userInfo } = AuthUser();
  const propertyNames = {
    coopVoce: "Coop Voce",
    digi: "DIGI",
    fastWeb: "FAST WEB",
    heraComm: "HERA COMM",
    hoOtherName: "HO Other Name (Vei)",
    iliad: "iliad",
    iren: "IREN",
    kenaMobileNoverca: "KENA MOBILE- NOVERCA",
    linkem: "LINKEM",
    lycaMobile: "LYCA MOBILE",
    others: "OTHERS",
    posteMobile2014: "POSTE MOBILE // 2014",
    posteMobileFull: "POSTE MOBILE FULL",
    rabonaMobile: "RABONA MOBILE ",
    ringo: "RINGO",
    skyTvOrNetflix: "SKY TV OR NETFLIX ",
    skyWiFi: "SKY WI-FI ",
    skyGlass: "SKYGLASS",
    timTelecomItalias: "TIM Telecom Italia",
    tiscali: "TISCALI",
    unoMobile: "UNO MOBILE",
    veryMobile: "VERYMOBILE",
    vodafone: "VODAFONE",
    windTreSpa: "WIND TRE S.P.A ",
  };

  const filterNum = Object.values(sim).filter((f) => typeof f === "number");

  const total = filterNum.reduce((total, value) => total + value, 0);
  console.log(total);
  return (
    <tr className="border bg-white px-2 py-1 text-center">
      <td className="border px-2 py-1">{index + 1}</td>
      <td className="border px-4 py-2">
        {new Date(sim.createdAt).toLocaleDateString()}
      </td>
      <td className="border px-4 py-2">
        {new Date(sim.updatedAt).toLocaleDateString()}
      </td>

      <td className="border px-4 py-2">{userInfo.name}</td>
      <td className="border text-left px-4 py-2">
        {sim.coopVoce && (
          <p>
            {propertyNames.coopVoce}: {sim.coopVoce}
          </p>
        )}
        {sim.digi && (
          <p>
            {propertyNames.digi}: {sim.digi}
          </p>
        )}
        {sim.fastWeb && (
          <p>
            {propertyNames.fastWeb}: {sim.fastWeb}
          </p>
        )}
        {sim.heraComm && (
          <p>
            {propertyNames.heraComm}: {sim.heraComm}
          </p>
        )}
        {sim.hoOtherName && (
          <p>
            {propertyNames.hoOtherName}: {sim.hoOtherName}
          </p>
        )}
        {sim.iliad && (
          <p>
            {propertyNames.iliad}: {sim.iliad}
          </p>
        )}
        {sim.iren && (
          <p>
            {propertyNames.iren}: {sim.iren}
          </p>
        )}
        {sim.kenaMobileNoverca && (
          <p>
            {propertyNames.kenaMobileNoverca}: {sim.kenaMobileNoverca}
          </p>
        )}
        {sim.linkem && (
          <p>
            {propertyNames.linkem}: {sim.linkem}
          </p>
        )}
        {sim.lycaMobile && (
          <p>
            {propertyNames.lycaMobile}: {sim.lycaMobile}
          </p>
        )}
        {sim.others && (
          <p>
            {propertyNames.others}: {sim.others}
          </p>
        )}
        {sim.posteMobile2014 && (
          <p>
            {propertyNames.posteMobile2014}: {sim.posteMobile2014}
          </p>
        )}
        {sim.posteMobileFull && (
          <p>
            {propertyNames.posteMobileFull}: {sim.posteMobileFull}
          </p>
        )}
        {sim.rabonaMobile && (
          <p>
            {propertyNames.rabonaMobile}: {sim.rabonaMobile}
          </p>
        )}
        {sim.ringo && (
          <p>
            {propertyNames.ringo}: {sim.ringo}
          </p>
        )}
        {sim.skyTvOrNetflix && (
          <p>
            {propertyNames.skyTvOrNetflix}: {sim.skyTvOrNetflix}
          </p>
        )}
        {sim.skyWiFi && (
          <p>
            {propertyNames.skyWiFi}: {sim.skyWiFi}
          </p>
        )}
        {sim.skyGlass && (
          <p>
            {propertyNames.skyGlass}: {sim.skyGlass}
          </p>
        )}
        {sim.timTelecomItalias && (
          <p>
            {propertyNames.timTelecomItalias}: {sim.timTelecomItalias}
          </p>
        )}
        {sim.tiscali && (
          <p>
            {propertyNames.tiscali}: {sim.tiscali}
          </p>
        )}
        {sim.unoMobile && (
          <p>
            {propertyNames.unoMobile}: {sim.unoMobile}
          </p>
        )}
        {sim.veryMobile && (
          <p>
            {propertyNames.veryMobile}: {sim.veryMobile}
          </p>
        )}
        {sim.vodafone && (
          <p>
            {propertyNames.vodafone}: {sim.vodafone}
          </p>
        )}
        {sim.windTreSpa && (
          <p>
            {propertyNames.windTreSpa}: {sim.windTreSpa}
          </p>
        )}
      </td>
      <td className="border px-4 py-2  text-black bold">
        <p className=" "> {total}</p>
      </td>
      <td className="border px-4 py-2  text-white">
        <p className=" bg-green-600 p-1"> {sim.orderStatus}</p>
      </td>
    </tr>
  );
};

export default RequestSimTable;
