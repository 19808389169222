import React, { useEffect } from "react";

import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../Shared/Loading/Footer";
// import UserWebNavBar from "./UserWebNavBar";
import AuthUser from "../../Hooks/authUser";
import Bot from "../../Component/Bot/Bot";
import UserNavbar from "../../Component/MobileResponsiveNavbar/UserNavbar";

const UserDashboard = () => {
  const navigate = useNavigate();
  const { userInfo, logout } = AuthUser();
  useEffect(() => {
    if (userInfo?.role === "user") {
    } else {
      logout();
      navigate("/login");
    }
  }, []);
  return (
    <div className=" bg-secondary ">
      {/* <UserWebNavBar></UserWebNavBar> */}
      <UserNavbar></UserNavbar>
      <Outlet></Outlet>
      <Bot></Bot>
      <Footer></Footer>
    </div>
  );
};

export default UserDashboard;
