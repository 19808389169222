import axios from "axios";
import React, { useEffect, useState } from "react";

const AdminGenerateBills = () => {
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState("");
  useEffect(() => {
    fetch("https://servergenzam.genzamwallet.com/api/v1/user")
      .then((res) => res.json())
      .then((data) => setUsers(data?.data));
  }, []);

  const [formData, setFormData] = useState({
    debit: 0,
    credit: 0,

    date: "",
    entryBy: "",
    service: "",
    billDetails: "",
    paymentStatus: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("https://servergenzam.genzamwallet.com/api/v1/bills", {
        ...formData,
        customerName: userName,
        balance: formData?.debit,
      });
      if (response.status === 201) {
        // console.log(response?.data);
        alert("Order submitted successfully");
        setFormData({
          customerName: userName,
          debit: "",
          credit: "",
          balance: "",
          date: "",
          entryBy: "",
          service: "",
          billDetails: "",
          paymentStatus: "",
        });
      } else {
        alert("Failed to submit order. Please try again later.");
      }
    } catch (error) {
      alert(
        "An error occurred while submitting the order. Please try again later."
      );
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <div>
      <div className="max-w-md mx-auto mt-10 bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="text-2xl py-4 px-6 bg-gray-900 text-white text-center font-bold uppercase">
          Generate Bills for Customers
        </div>
        <form className="py-4 px-6" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="name"
            >
              Customers
            </label>
            <select
              type="text"
              name="customerName"
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Select"
              required
            >
              <option value="">Select Customer</option>
              {users?.map((user) => (
                <option value={user?.email} key={user?._id}>
                  {user?.email}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="service"
            >
              Payment Status
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="service"
              name="paymentStatus"
              value={formData.paymentStatus}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Due">Due</option>
              <option value="Complete">Complete</option>
            </select>
          </div>

          {formData?.paymentStatus === "Due" ? (
            <div className="mb-4">
              <label
                className="block text-gray-700 font-bold mb-2"
                htmlFor="email"
              >
                Debit
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                name="debit"
                value={formData.debit}
                onChange={handleChange}
                placeholder="Enter Your Debit Amount"
                required
              />
            </div>
          ) : (
            <div className="mb-4">
              <label className="block text-gray-700 font-bold mb-2">
                Credit
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                name="credit"
                value={formData.credit}
                onChange={handleChange}
                placeholder="Enter Your credit Amount"
                required
              />
            </div>
          )}
          {/* //date */}
          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="date"
            >
              Date
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              placeholder="Select a date"
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="service"
            >
              Service
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
            >
              <option value="">Select a service</option>
              <option value="sim">SIM</option>
              <option value="wifi">Wi-fi</option>
              <option value="luce">Luce</option>
              <option value="gas">Gas</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="service"
            >
              Entry By
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="service"
              name="entryBy"
              value={formData.entryBy}
              onChange={handleChange}
              required
            >
              <option disabled value="">
                Select
              </option>
              <option value="Genzam-IT">Genzam-IT</option>
            </select>
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="message"
            >
              Bill Details
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              rows="4"
              name="billDetails"
              value={formData.billDetails}
              onChange={handleChange}
              required
              placeholder="Enter Bill additional information"
            ></textarea>
          </div>
          <div className="flex items-center justify-center mb-4">
            <button
              className="bg-gray-900 text-white py-2 px-4 rounded hover:bg-gray-800 focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Generate Bill
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminGenerateBills;
