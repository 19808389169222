import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UpdateHooks from "../../Hooks/UpdateHooks";

const AdminSimOrderAgentUserView = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetch(`https://servergenzam.genzamwallet.com/api/v1/orderSim/getOrderById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFormData(data);
      });
  }, [id, refresh]);

  const handelUpdate = async (e) => {
    e.preventDefault();
    const BASE_URL = `https://servergenzam.genzamwallet.com/api/v1/orderSim/${id}`;
    if (orderStatus) {
      await UpdateHooks(
        BASE_URL,
        {
          orderStatus: orderStatus ? orderStatus : formData?.orderStatus,
        },
        true,
        "Order Status Updated"
      );
    } else {
      alert("please select status");
    }

    setRefresh(!refresh);
  };

  return (
    <div className=" min-h-screen">
      <div className=" bg-thirdLightPrimary  flex   justify-between">
        <div>
          <p className="    font-semibold p-2 ">VIEW ORDER</p>
        </div>
      </div>
      <div className="overflow-x-auto  p-5  ">
        <div>
          <table className="min-w-full text-xs bg-thirdLightPrimary  rounded-md ">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col className="" />
            </colgroup>
            <thead className="dark:bg-gray-700">
              <tr className="text-left grid grid-cols-2 bg-gray-200 rounded-md  ">
                <th className="p-3">Operator</th>
                <th className="p-3 ">Quantity</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Coop Voce</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.coopVoce}
                    name="coopVoce"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">DIGI </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.digi}
                    name="digi"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>

              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">FAST WEB </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    name="fastWeb"
                    value={formData?.fastWeb}
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">HERA COMM </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    name="heraComm"
                    value={formData?.heraComm}
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">HO Other Name (Vei)</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.hoOtherName}
                    name="hoOtherName"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">iliad </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.iliad}
                    name="iliad"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">IREN</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.iren}
                    name="iren"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">KENA MOBILE- NOVERCA </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.kenaMobileNoverca}
                    name="kenaMobileNoverca"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">LINKEM</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.linkem}
                    name="linkem"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">LYCA MOBILE</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.lycaMobile}
                    name="lycaMobile"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">OTHERS</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.others}
                    name="others"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">POSTE MOBILE // 2014</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.posteMobile2014}
                    name="posteMobile2014"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">POSTE MOBILE FULL</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.posteMobileFull}
                    name="posteMobileFull"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">RABONA MOBILE </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.rabonaMobile}
                    name="rabonaMobile"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">RINGO </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.ringo}
                    name="ringo"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">SKY TV OR NETFLIX </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.skyTvOrNetflix}
                    name="skyTvOrNetflix"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">SKY WI-FI </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.skyWiFi}
                    name="skyWiFi"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">SKYGLASS</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.skyGlass}
                    name="skyGlass"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">TIM Telecom Italia</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.timTelecomItalias}
                    name="timTelecomItalias"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">TISCALI</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.tiscali}
                    name="tiscali"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">UNO MOBILE</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.unoMobile}
                    name="unoMobile"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">VERYMOBILE</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.veryMobile}
                    name="veryMobile"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">VODAFONE</p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.vodafone}
                    name="vodafone"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">WIND TRE S.P.A </p>
                </td>
                <td className="p-3 cursor-not-allowed">
                  <input
                    value={formData?.windTreSpa}
                    name="windTreSpa"
                    type="number"
                    className=" w-full"
                  />
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center">
                <td className="p-3 ">
                  <p className=" ">Order Status</p>
                </td>
                <td className="p-3 ">
                  <p>{formData?.orderStatus}</p>
                </td>
              </tr>
              <tr className="border-b border-opacity-20 bg-gray-100 m-2 grid grid-cols-2 items-center py-2">
                <td className="p-3 ">
                  <p className=" ">Order Status</p>
                </td>
                <select
                  onChange={(e) => setOrderStatus(e.target.value)}
                  id="paymentStatus"
                  name="paymentStatus"
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option selected disabled>
                    ... select ...
                  </option>
                  <option value="pending">Pending</option>
                  <option value="completed">Completed</option>
                  <option value="onProcess">On Process</option>
                  <option value="rejected">Reject</option>
                </select>
              </tr>

              <div className=" p-5">
                <button
                  onClick={handelUpdate}
                  className=" bg-primary text-white hover:bg-secondary text-xs p-2 rounded-md "
                >
                  Update Order
                </button>
              </div>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminSimOrderAgentUserView;
