import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AuthUser from "../../Hooks/authUser";
import { useEffect } from "react";
import ReactToPrint from "react-to-print"; // Import ReactToPrint
import { Icon } from "@iconify/react";
import moment from "moment"; // Import Moment.js

const SimInvoice = () => {
  const { userInfo } = AuthUser();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const componentRef = React.useRef();

  const [sim, setSim] = useState([]);
  useEffect(() => {
    setLoading(true);
    fetch(`https://servergenzam.genzamwallet.com/api/v1/simSale/getSimSaleById/${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSim(data);
        setLoading(false);
      });
  }, [id]);

  console.log(sim);

  const handlePrint = () => {
    window.print();
  };
  const formattedCreatedAtDate = sim?.createdAt
    ? moment(sim.createdAt).format("YYYY-MM-DD")
    : "";
  const formattedUpdatedAtDate = sim?.createdAt
    ? moment(sim.updatedAt).format("YYYY-MM-DD")
    : "";

  return (
    <div className="min-h-screen">
      <div className=" bg-thirdLightPrimary  flex  justify-between">
        <div>
          <p className="    font-semibold p-2 ">RESELLER DASHBOARD</p>
        </div>

        <div>
          {" "}
          <p className=" font-semibold p-2  mr-10">
            <div className="  ">
              <ReactToPrint
                trigger={() => (
                  <Icon
                    icon="twemoji:printer"
                    className=" text-2xl cursor-pointer "
                  ></Icon>
                )}
                content={() => componentRef.current}
              />
            </div>
          </p>
        </div>
      </div>
      <div className=" ">
        <div className="  text-center text-2xl font-bold "></div>

        <div className="ml-10 ">
          <div ref={componentRef} className=" mt-5 ">
            <div class="bg-white m-5  overflow-hidden shadow rounded-lg border">
              <div className=" grid grid-cols-2">
                <div class="px-4 py-5 sm:px-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Genzam-IT
                  </h3>

                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    {" "}
                    Invoice To
                  </p>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    {" "}
                    Order - <span> {sim?.status}</span>
                  </p>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Invoice#- {sim?._id}
                  </p>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Date- {new Date(sim?.updatedAt).toLocaleDateString()}
                  </p>
                </div>

                <div class="px-4 py-5 sm:px-6">
                  <p class="text-lg leading-6 font-medium text-gray-900">
                    {" "}
                    Author
                  </p>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Contact Information
                  </p>
                  <p class="mt-1 max-w-2xl text-sm flex  justify-start  items-center  gap-2 text-gray-500">
                    <Icon icon="material-symbols:add-call-outline"></Icon>
                    +39 351178 9888
                  </p>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500 flex  gap-2 justify-start  items-center ">
                    <Icon icon="ic:outline-email"></Icon>
                    genzamofficial@gmail.com
                  </p>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500 flex  gap-2 justify-start  items-center ">
                    <Icon icon="fluent-mdl2:website"></Icon>
                    www.genzam.it
                  </p>
                </div>
              </div>

              <div class="border-t border-gray-200 px-4 py-5 sm:p-0 ">
                <dl class="sm:divide-y sm:divide-gray-200 ">
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ">
                    <dt class="text-xs  font-medium text-gray-500">Name</dt>
                    <dd class="mt-1 text-xs  text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.firstName} {sim.surName}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">
                      Place of issue
                    </dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.placeOfIssue}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">
                      Nationality
                    </dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.nationality}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">Address</dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.residentInStreetNumberCivicInterrior}
                    </dd>
                  </div>

                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"></div>
                </dl>
              </div>

              <div class="px-4 pb-1 sm:px-6">
                <h3 class="text-md leading-6 font-medium text-gray-900">
                  SIM Details{" "}
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">Genzam-IT</p>
              </div>

              <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">Operator</dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.saleData?.operatorName}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">Offer</dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.offerta}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">
                      ICCID Number
                    </dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.saleData?.iccidNumber}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">
                      SIM Number
                    </dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.saleData?.simNumber}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">
                      Buy Price{" "}
                    </dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.saleData?.buyPrice}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">
                      SIM Status{" "}
                    </dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.saleData?.status}
                    </dd>
                  </div>

                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"></div>
                </dl>
              </div>

              <div class="px-4 pb-2 sm:px-6">
                <h3 class="text-md leading-6 font-medium text-gray-900">
                  Order Details
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">Genzam-IT</p>
              </div>

              <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">ICCID </dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.iccidNumber}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class=" text-xs font-medium text-gray-500">Mobile</dt>
                    <dd class="mt-1  text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.mobile}
                    </dd>
                  </div>
                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class=" text-xs font-medium text-gray-500">Entry by</dt>
                    <dd class="mt-1  text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {sim?.resellerName}
                    </dd>
                  </div>

                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-xs font-medium text-gray-500">
                      Entry Time
                    </dt>
                    <dd class="mt-1 text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {formattedCreatedAtDate}
                    </dd>
                  </div>

                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class=" text-xs font-medium text-gray-500">
                      Completed Date
                    </dt>
                    <dd class="mt-1  text-xs text-gray-900 sm:mt-0 sm:col-span-2">
                      {formattedUpdatedAtDate}
                    </dd>
                  </div>

                  <div class="py-1 sm:grid sm:grid-cols-3 sm:gap-4  mb-5 sm:px-6"></div>
                  <p className=" p-2  text-xs font-medium text-gray-500 flex  justify-start  items-center gap-2">
                    <Icon icon="fluent-mdl2:website"></Icon>
                    www.genzam.it
                  </p>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div></div>
      </div>
    </div>
  );
};

export default SimInvoice;
