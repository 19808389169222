import AdminAgentViewProfile from "../Component/AdminDashboard/AdminAgentViewProfile";
import AdminAllAgentList from "../Component/AdminDashboard/AdminAllAgentList";
import AdminAllUserList from "../Component/AdminDashboard/AdminAllUserList";
import AdminCompletePayment from "../Component/AdminDashboard/AdminCompletePayment";
import AdminGenerateBills from "../Component/AdminDashboard/AdminGenerateBills";
import AdminHome from "../Component/AdminDashboard/AdminHome";
import AdminLatestOfferPost from "../Component/AdminDashboard/AdminLatestOfferPost";
import AdminLuceOrderList from "../Component/AdminDashboard/AdminLuceOrderList";
import AdminGasOrderView from "../Component/AdminDashboard/AdminLuciGasWifi/AdminGasOrderView";
import AdminLuceOrderView from "../Component/AdminDashboard/AdminLuciGasWifi/AdminLuceOrderView";
import AdminWifiOrderView from "../Component/AdminDashboard/AdminLuciGasWifi/AdminWifiOrderView";
import ManageGasOperator from "../Component/AdminDashboard/AdminLuciGasWifi/ManageGasOperator";
import ManageWifiOperator from "../Component/AdminDashboard/AdminLuciGasWifi/ManageWifiOperator";
import PostGasOperator from "../Component/AdminDashboard/AdminLuciGasWifi/PostGasOperator";
import PostLuceOperator from "../Component/AdminDashboard/AdminLuciGasWifi/PostLuceOperator";
import PostWifiOperator from "../Component/AdminDashboard/AdminLuciGasWifi/PostWifiOperator";
import EditGasOperator from "../Component/AdminDashboard/AdminLuciGasWifi/EditGasOperator";
import AdminManageOfferPost from "../Component/AdminDashboard/AdminManageOfferPost";
import AdminManageOfferPostEdit from "../Component/AdminDashboard/AdminManageOfferPostEdit";
import AdminManagePayment from "../Component/AdminDashboard/AdminManagePayment";
import AdminManagePushNotification from "../Component/AdminDashboard/AdminManagePushNotification";
import AdminPushNotification from "../Component/AdminDashboard/AdminPushNotification";
import AdminSimOrderAgentUserView from "../Component/AdminDashboard/AdminSimOrderAgentUserView";
import AdminSimPost from "../Component/AdminDashboard/AdminSimPost";
import AdminSimSale from "../Component/AdminDashboard/AdminSimSale";
import AdminSimSalesView from "../Component/AdminDashboard/AdminSimSalesView";
import AdminUpdatePaymentView from "../Component/AdminDashboard/AdminUpdatePaymentView";
import AdminUserViewProfile from "../Component/AdminDashboard/AdminUserViewProfile";
import AdminWifiOfferPost from "../Component/AdminDashboard/AdminWifiOfferPost";
import AdminWifiOrderList from "../Component/AdminDashboard/AdminWifiOrderList";
import AdmingasOrderList from "../Component/AdminDashboard/AdmingasOrderList";
import ContactList from "../Component/AdminDashboard/ContactList";
import EditSimOperator from "../Component/AdminDashboard/EditSimOperator";
import ManageSimOperator from "../Component/AdminDashboard/ManageSimOperator";
import PostSimOperator from "../Component/AdminDashboard/PostSimOperator";
import SimOrderAgentUser from "../Component/AdminDashboard/SimOrderAgentUser";
import ViewContactDetails from "../Component/AdminDashboard/ViewContactDetails";
import ManageLuceOperator from "../Component/AdminDashboard/AdminLuciGasWifi/ManageLuceOperator";
import EditLuceOperator from "../Component/AdminDashboard/AdminLuciGasWifi/EditLuceOperator";
import AdminWifiOfferPostManage from "../Component/AdminDashboard/AdminWifiOfferPostManage";
import AdminWifiOfferEdit from "../Component/AdminDashboard/AdminWifiOfferEdit";
import AdminNewUser from "../Component/AdminDashboard/AdminNewUser";
import AdminNewAgent from "../Component/AdminDashboard/AdminNewAgent";
import EditWifiOperator from "../Component/AdminDashboard/AdminLuciGasWifi/EditWifiOperator";
import AdminScrollingText from "../Component/AdminDashboard/AdminScrollingText";
import AdminScrollingTextManage from "../Component/AdminDashboard/AdminScrollingTextManage";
import AdminManageSim from "../Component/AdminDashboard/AdminManageSim";
import { Component } from "react";
import AdminViewAllSim from "../Component/AdminDashboard/AdminViewAllSim";
import AdminReports from "../Component/AdminDashboard/AdminReports";
import AdminViewReport from "../Component/AdminDashboard/AdminViewReport";

const AdminRoute = [
  // just for demo
  { path: "adminhome", Component: AdminHome },
  { path: "adminalluserlist", Component: AdminAllUserList },
  {
    path: "adminallagentlist",
    Component: AdminAllAgentList,
  },
  { path: "adminluceorderlist", Component: AdminLuceOrderList },
  { path: "adminwifiorderlist", Component: AdminWifiOrderList },
  { path: "admingasorderlist", Component: AdmingasOrderList },
  { path: "simorderagentuser", Component: SimOrderAgentUser },
  {
    path: "/adminDashboard/simorderagentuser/simOrder/:id",
    Component: AdminSimOrderAgentUserView,
  },

  { path: "/adminDashboard/adminsimpost", Component: AdminSimPost },
  {
    path: "/adminDashboard/adminlatstofferpost",
    Component: AdminLatestOfferPost,
  },
  {
    path: "/adminDashboard/manageletestupcommingoffer",
    Component: AdminManageOfferPost,
  },
  { path: "/adminDashboard/adminsimsale", Component: AdminSimSale },
  { path: "/adminDashboard/adminsimsale/:id", Component: AdminSimSalesView },
  { path: "/adminDashboard/postSimOperator", Component: PostSimOperator },
  { path: "/adminDashboard/postSimOperator", Component: PostSimOperator },
  {
    path: "/adminDashboard/adminmanageofferpostedit/:offerId",
    Component: AdminManageOfferPostEdit,
  },
  { path: "wifiOfferpost", Component: AdminWifiOfferPost },
  { path: "adminPostWifiOperator", Component: PostWifiOperator },
  { path: "adminPostGasOperator", Component: PostGasOperator },
  { path: "adminPostLuceOperator", Component: PostLuceOperator },
  {
    path: "/adminDashboard/adminuserviewprofile/:id",
    Component: AdminUserViewProfile,
  },
  {
    path: "/adminDashboard/adminagentviewprofile/:id",
    Component: AdminAgentViewProfile,
  },
  { path: "generatebills", Component: AdminGenerateBills },
  { path: "adminmanagepayment", Component: AdminManagePayment },
  {
    path: "/adminDashboard/adminupdatepaymentview/:id",
    Component: AdminUpdatePaymentView,
  },
  {
    path: "admincompletebills",
    Component: AdminCompletePayment,
  },
  {
    path: "contactlist",
    Component: ContactList,
  },

  { path: "/adminDashboard/contactview/:id", Component: ViewContactDetails },
  {
    path: "adminpushnotification",
    Component: AdminPushNotification,
  },
  {
    path: "/adminDashboard/adminmanagepushnotification",
    Component: AdminManagePushNotification,
  },

  {
    path: "/adminDashboard/adminwifiorderlist/wifiOrder/:id",
    Component: AdminWifiOrderView,
  },
  {
    path: "/adminDashboard/admingasorderlist/gasOrder/:id",
    Component: AdminGasOrderView,
  },
  {
    path: "/adminDashboard/adminluceorderlist/luceOrder/:id",
    Component: AdminLuceOrderView,
  },
  {
    path: "managesimoperator",
    Component: ManageSimOperator,
  },
  {
    path: "/adminDashboard/editsimoperator/:id",
    Component: EditSimOperator,
  },
  {
    path: "managewifioperator",
    Component: ManageWifiOperator,
  },
  {
    path: "/adminDashboard/editwifioperator/:id",
    Component: EditWifiOperator,
  },
  {
    path: "managegasoperator",
    Component: ManageGasOperator,
  },
  {
    path: "/adminDashboard/editgasoperator/:id",
    Component: EditGasOperator,
  },
  {
    path: "manageluceoperator",
    Component: ManageLuceOperator,
  },
  {
    path: "/adminDashboard/editluceoperator/:id",
    Component: EditLuceOperator,
  },
  {
    path: "adminwifiofferpostmanage",
    Component: AdminWifiOfferPostManage,
  },
  {
    path: "/adminDashboard/adimwifiofferedit/:id",
    Component: AdminWifiOfferEdit,
  },
  {
    path: "adminnewuser",
    Component: AdminNewUser,
  },
  {
    path: "adminnewagent",
    Component: AdminNewAgent,
  },
  { path: "adminscrollingtext", Component: AdminScrollingText },
  {
    path: "/adminDashboard/adminscrollingtextmanage",
    Component: AdminScrollingTextManage,
  },
  {
    path: "adminmanagesim",
    Component: AdminManageSim,
  },
  {
    path: "/adminDashboard/AdminViewAllSim/:id",
    Component: AdminViewAllSim,
  },
  {
    path: "admin-reports",
    Component: AdminReports,
  },
  {
    path: "/adminDashboard/admin-view-reports/:resellerId",
    Component: AdminViewReport,
  },
];

export default AdminRoute;